import React, {memo, useEffect, useState} from 'react';
import {getGlobal, useGlobal} from 'reactn';
import {makeStyles} from '@material-ui/styles';
import AddProjectPopup from '../../TaskListApp/AddProjectPopup/AddProjectPopup';
import EditProject from './editProject';
import ProjectInfo from './ProjectInfo';
import {GridContextProvider, GridDropZone, GridItem, swap} from 'react-grid-dnd';
import {db} from '../../../firebaseConfig';
import {CALENDAR_COLLECTION} from '../../../config/constants';
import {concat, filter, findIndex} from 'lodash';
import styled from 'styled-components';
import {Tooltip} from 'antd';
import {CheckOutlined} from '@ant-design/icons';
import useIntlId from '../../../hooks/useIntlId';
import AddButton from '../../Dialog/AddButton';
import SaveButton from '../../Dialog/SaveButton';

const rowHeight = 40
const isOrderChange = (prevSource = [], nexSource = []) => {
    if (prevSource.length !== nexSource.length)
        return true
    let result = false
    prevSource.forEach((item, index) => {
        if (item !== nexSource[index]) {
            result = true
        }
    })
    return result
}

const ProjectItem = styled.button`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0px 10px;
    border-radius: 25px;
    height: 27px;

    &:hover {
        cursor: pointer;
    }
;
    border: none;
    color: #fff;
    background-color: ${props => props.item ? `${props.item.color || props.item.backgroundColor}` : "#000"};

`
const ProjectItemActive = styled(ProjectItem)`
    border: ${props => props.item ? `solid 2px ${props.item.color || props.item.backgroundColor}` : "none"};
    color: ${props => props.item ? `${props.item.color || props.item.backgroundColor}` : "#000"};
    background-color: #fff;
    padding: 0px 15px;

`

function ProjectSettings(props) {
    const [totalProjects, setTotal] = useState([])
    const [addPro, setAddPro] = useState(false)
    const [selected, setSelected] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [isChangedOrder, setIsChangedOrder] = useState(false)
    const [loading, setLoading] = useState(false)
    const [calendarUser] = useGlobal("calendarUser")
    // const [googleCalendarList] = useGlobal("googleCalendarList")
    // const [projects] = useGlobal('projects')
    const [allProject] = useGlobal('allProject')
    const [ggAllProject] = useGlobal('ggAllProject')
    const classes = useStyles()
    const [proIntl, projectsIntl] = useIntlId(['setting.projectSettings', 'app.profile.projects'])


    useEffect(() => {
        // const { projects, googleCalendarList} = getGlobal()
        const googleSettings = calendarUser?.googleSettings || {}
        const {googleList = []} = calendarUser
        const geniamCals = filter(allProject, p => !p.is_deleted && !p.is_sync && !p.is_google)
        const googleCals = filter(ggAllProject, g => !g?.id?.includes("#holiday")).map(p => {
            const linkInfo = googleSettings[p.id] || {}
            return {...p, ...linkInfo, is_google: true, name: p?.summary, color: p?.backgroundColor,}
        })
        let allCals = concat(geniamCals, googleCals)
        if (!googleList.length) {
            setTotal(allCals)
            return
        }
        let nextProject = []
        googleList.forEach(id => {
            let idx = findIndex(allCals, {id})
            if (idx !== -1) {
                nextProject.push(allCals[idx])
                allCals.splice(idx, 1)
            }
        })
        setTotal(concat(nextProject, allCals))

    }, [calendarUser, allProject, ggAllProject])

    useEffect(() => {
        const {googleList = []} = calendarUser
        if (isOrderChange(googleList, totalProjects.map(cal => cal.id))) {
            // changed order . display save btn
            setIsChangedOrder(true)
        } else {
            setIsChangedOrder(false)
        }
    }, [totalProjects, calendarUser])

    const rows = Math.ceil(totalProjects.length / 4)
    const height = rows * rowHeight + 3
    const onChange = async (sourceId, sourceIndex, targetIndex, targetId) => {
        const nextState = swap(totalProjects, sourceIndex, targetIndex)
        setTotal([...nextState])
    }

    const updateOrder = async () => {
        try {
            setLoading(true)
            const {user} = getGlobal()
            await db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
                .set({
                    googleList: totalProjects.map(cal => cal.id)
                }, {merge: true})
            // .catch(console.log)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.titleTop}>{proIntl}</div>
            <div className={classes.mt30}>
                <GridContextProvider
                    onChange={onChange}
                    // className={classes.container}
                >
                    <GridDropZone
                        id="projects"
                        boxesPerRow={4}
                        rowHeight={rowHeight}
                        style={{width: "100%", height, background: '#fff', borderRadius: 20}}
                        // disableDrag={true}
                    >
                        {
                            totalProjects.map(pro => {
                                return (
                                    <GridItem key={pro.id} className={classes.itemProject}>
                                        <Tooltip title={pro.name || pro.summary}
                                                 overlayStyle={{zIndex: 9999}}
                                                 color={pro.color}
                                                 destroyTooltipOnHide={{keepParent: false}}
                                        >
                                            {
                                                selected?.id === pro.id ?
                                                    <div className={classes.relative}>
                                                        <ProjectItemActive
                                                            onClick={() => {
                                                                if (selected?.id !== pro.id) {
                                                                    setIsEdit(false)
                                                                    setSelected(pro)
                                                                } else {
                                                                    setSelected(null)
                                                                }

                                                            }}
                                                            item={pro}
                                                        >
                                                            {pro.name || pro.summary}
                                                        </ProjectItemActive>
                                                        <CheckOutlined className={classes.iconActive}/>
                                                    </div>
                                                    :
                                                    <ProjectItem
                                                        onClick={() => {
                                                            if (selected?.id !== pro.id) {
                                                                setIsEdit(false)
                                                                setSelected(pro)
                                                            } else {
                                                                setSelected(null)
                                                            }

                                                        }}
                                                        item={pro}
                                                    >
                                                        {pro.name || pro.summary}
                                                    </ProjectItem>
                                            }
                                        </Tooltip>
                                    </GridItem>
                                )
                            })
                        }
                    </GridDropZone>
                </GridContextProvider>
                <div className={`${classes.mt30} clf-flex-center`}>
                    <AddButton
                        onClick={() => setAddPro(true)}
                        name={projectsIntl}
                        margin={'0px 20px 0px 0px'}
                        width={'150px'}
                    />
                    <SaveButton
                        onClick={updateOrder}
                        disable={!isChangedOrder || loading}
                    />
                    {addPro && <AddProjectPopup addProject={addPro} setAddProject={setAddPro}/>}
                </div>

                {selected?.id ?
                    <div className={classes.divProject}>
                        {
                            isEdit ?
                                <EditProject project={selected} totalProjects={totalProjects} setProject={setSelected}
                                             setIsEdit={setIsEdit}/>
                                :
                                <ProjectInfo project={selected} totalProjects={totalProjects} setEdit={setIsEdit}
                                             setProject={setSelected}/>
                        }

                    </div>
                    :
                    null
                }

            </div>

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
        margin: "auto",
        textAlign: 'center',
        paddingTop: 20
    },
    titleTop: {
        fontSize: '1.3rem',
        color: '#000'
    },
    container: {
        width: '100%',
        display: "flex"
    },
    divProject: {
        paddingBottom: 25
    },
    btnAdded: {},
    btn: {
        // backgroundColor: 'white',
        border: 'none',
        margin: 5,
        padding: 4,
        width: "calc(100% - 10px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        '&:hover': {
            cursor: 'pointer'
        }
    },
    services: {
        backgroundColor: '#fff !important',
        // color: 'white',
        margin: 5,
        padding: 4,
        width: "calc(100% - 10px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        '&:hover': {
            cursor: 'pointer'
        }
    },
    dropZone: {
        minHeight: "400px", width: "100%"
    },
    mt30: {
        marginTop: 30
    },
    icon: {
        marginRight: 10
    },
    saveIconNew: {
        fill: theme.props.primaryColor,
        marginLeft: -43,
        marginRight: 10,
        marginTop: 1
    },
    saveIconSave: {
        fill: theme.props.primaryColor,
        marginLeft: -40,
        marginRight: 10,
        marginTop: 1
    },
    itemProject: {
        padding: 3,
        boxSizing: "border-box"
    },
    relative: {
        position: "relative",
    },
    iconActive: {
        position: "absolute",
        top: "50%",
        width: 18,
        height: 18,
        marginTop: -7,
        right: 7,
        color: "#1790FF"
    }
}))
export default memo(ProjectSettings)
