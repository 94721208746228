import axios from 'axios'
import {getGlobal, setGlobal, useGlobal} from 'reactn'
import {API_GENIAM, GET_USER_INFO, LOGIN_URL} from "../config/constants";
import Cookies from 'js-cookie'
import {COOKIE_GENIAM_ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, removeCookie,} from "../common/cookies";
import qs from "query-string";
import {auth} from "../firebaseConfig";
import store from 'store'
import {useCollection, useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {useEffect} from "react";
import {orderBy, remove} from "lodash";
import {refetchSource} from "./refetchSourcce";
import {myFreeTimeDocRef, myPrjGECollRef, myPrjGGCollRef} from "../common/firebaseRef";
import {createDefaultMyFreeTime} from "../common/calendarInfo";
import useIntlId from "../hooks/useIntlId";
import {myCal} from "../common/myCal";

export const getUserData = async () => {
    const {accessToken, firebaseToken} = getGlobal();
    const query = qs.parse(window.location.search)
    const path = window.location.pathname
    localStorage.removeItem('userInfo');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('zoomConnectData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('firebaseToken');
    if (!accessToken && !firebaseToken && path === '/event' && query.id && query.from && query.tokenMeeting) {
        const data = await refreshToken()
        if (!data)
            return window.location.assign(`/eventAnonymous?id=${query.id}&from=${query.from}&tokenMeeting=${query.tokenMeeting}`)
        else
            return window.location.assign(`/event?id=${query.id}&from=${query.from}`)
    }
    if (!accessToken && !firebaseToken && path === '/eventAnonymous' && query.id && query.from && query.tokenMeeting)
        return null;
    if (!accessToken && path === '/scheduleAdjustment' && query.uid && query.usr && query.timeExp) {
        try {
            await refreshToken()
        } catch (e) {
            return null;
        }
    }
    if (!accessToken || !firebaseToken) await refreshToken()

    try {
        const {data} = await axios.get(GET_USER_INFO)
        setGlobal({user: data});
        store.set("userInfo", data)
        return data
    } catch (e) {
        console.log(e)
        return null
    }
}

export const refreshToken = async () => {
    const refreshToken = Cookies.get(COOKIE_GENIAM_REFRESH_TOKEN_KEY) || getGlobal().refreshToken
    const query = qs.parse(window.location.search)
    const path = window.location.pathname
    if (!refreshToken && path === "/scheduleAdjustment")
        return null
    if (!refreshToken && path === '/event' && query.id && query.from && query.tokenMeeting)
        return null

    if (!refreshToken && path === '/eventAnonymous' && query.id && query.from)
        return null

    if (!refreshToken) {
        window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
    }
    try {
        const res = await axios.post(API_GENIAM + '/refresh', {refreshToken});
        const data = res.data;
        setGlobal({
            accessToken: data.accessToken,
            firebaseToken: data.firebaseToken
        });

        return data;
    } catch (e) {
        removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
        removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
        window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
        return null;
    }
}

export const signInFirebase = async () => {
    try {
        const data = await refreshToken()
        await auth.signInWithCustomToken(data.firebaseToken)
        // console.log(res);
    } catch (e) {
        console.log(e);
    }
}

export const useMyCalSettings = () => {
    const [displaySettings, setDisplaySetting] = useGlobal("workTimeDisplay")
    const [settings] = useCollectionData(myCal()?.collection(`settings`))
    useEffect(() => {
        if (!settings)
            return;
        const data = orderBy(settings, 'endDay', 'asc')
        setDisplaySetting(data, () => {
            refetchSource("timeWork")
        })
        // eslint-disable-next-line
    }, [settings])

    return [displaySettings]
}

export const useMyThemeList = () => {
    const [themeList] = useCollectionData(myCal()?.collection(`listTheme`))
    useEffect(() => {
        if (themeList) {
            setGlobal({listTheme: themeList})
        }
        // eslint-disable-next-line
    }, [themeList])

}

export const useMyGEProjects = () => {
    const [projects, setProjects] = useGlobal('projects');
    const [list] = useCollection(myPrjGECollRef().where("is_deleted", "==", false))
    useEffect(() => {
        if (list) {
            let data = list.docs.map(pro => ({
                ...pro.data(),
                id: pro.id,
                msSelected: pro.data().msSelected !== undefined ? pro.data().msSelected : true,
                apSelected: pro.data().apSelected !== undefined ? pro.data().apSelected : true,
            }));
            remove(data, d => d?.is_deleted)
            setProjects([...data])
        }
        // eslint-disable-next-line
    }, [list])

    return [projects]
}

export const useMyGGProjects = () => {
    const [projectSync, setProjectsSync] = useGlobal("projectsSync");
    const [list] = useCollection(myPrjGGCollRef())

    useEffect(() => {
        if (list) {
            const projects = list.docs.map(snap => ({...snap.data(), id: snap.id}))
            // remove old value selected appointment
            remove(projects, p => p?.id?.includes('pro_selected_'))
            setProjectsSync(projects)
        }
        // eslint-disable-next-line
    }, [list])

    return [projectSync]
}

export const useMyFreeTimeData = () => {
    const [, setMyFreeTime] = useGlobal('myFreeTime');
    const [user] = useGlobal('user');
    const [myFreeTimeData, loading] = useDocumentData(myFreeTimeDocRef())
    const [expressBoxMsg, expressTitleMsg, expressPlaceMsg] = useIntlId(['event.express.box', 'event.express.title', 'event.express.place']);
    const language = user?.language;

    const viewBox = {
        [language]: [
            {label: expressBoxMsg, checked: true},
            {label: expressTitleMsg, checked: true},
            {label: expressPlaceMsg, checked: true}
        ],
    }

    useEffect(() => {
        if (!user?.user_id || loading)
            return null;

        if (myFreeTimeData) {
            setMyFreeTime(myFreeTimeData)
        } else {
            createDefaultMyFreeTime(user.user_id, viewBox[language])
        }


        // eslint-disable-next-line
    }, [myFreeTimeData])
}

// export const useMyUserData = () => {
//     const [, setUserInfo] = useGlobal("userInfo");
//     const [myUser] = useDocumentData(myUserDocRef())
//     const [user] = useGlobal('user')
//
//     useEffect(() => {
//         if (!myUser) {
//             setUserInfo({
//                 id: user.user_id,
//                 start: moment().format(),
//                 end: moment().add(8, 'weeks').format()
//             })
//             return;
//         }
//
//         if (!myUser?.start || !myUser?.end) {
//             setUserInfo({
//                 id: user.user_id,
//                 start: moment().subtract(1, 'days').format(),
//                 end: moment().add(8, 'weeks').format()
//             })
//             return;
//         }
//         setUserInfo({...myUser})
//         // eslint-disable-next-line
//     }, [myUser])
// }

