import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn'
import {makeStyles} from "@material-ui/styles";
import {toast} from "react-toastify";
import {getUserDocRef} from "../../../../common/firebaseRef/meetings";
import {filter, find, findIndex, isUndefined} from 'lodash'
import {db} from "../../../../firebaseConfig";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ReactComponent as ArrowDownBlueSVG} from "../../../../asSvg/icons/arrowDownBlue.svg";
import {ReactComponent as GoogleSvg} from "../../../../asSvg/google-icon.svg";
import {ReactComponent as GeniamSvg} from "../../../../asSvg/geniam-icon.svg";
import IconIdentity from "../../../Calendar/google/identityIcon";
import ListSetting from "../../../MenuAppBar/ListSetting";
import {updateUserSettings} from "../actions";
// import {updateEvents} from "./actions";
import qs from "query-string";
import {CALENDAR_COLLECTION} from "../../../../config/constants";
import {omitByDeep} from "../../../../common/exLodash";
import projectSelectData from "../../common/projectSelectTransferData";
import useIntlId from "../../../../hooks/useIntlId";
import {useAppointmentEvent} from "../../../../context/AppointmentEventContext";

const useStyles = makeStyles(theme => ({
    root: {
        // minWidth: 300,
        width: '100%',
        minWidth: '100px',
        margin: "5px 0",
        "& .MuiSelect-root": {
            height: 25,
            padding: "5px 5px 5px 10px",
            lineHeight: "25px",
            background: "transparent",
            zIndex: 2,
            "& .itemTextProjectSelection": {
                maxWidth: "calc(100% - 50px)",
            }
        },
        "& .MuiFilledInput-root": {
            backgroundColor: "#fff",
            borderRadius: "25px",
            paddingRight: 5,
        },
        "& .MuiFilledInput-underline:before": {
            display: "none"
        },
        "& .MuiFilledInput-underline:after": {
            display: "none"
        }
    },
    container: {
        // display: 'flex',
        textAlign: 'end',
        margin: '7px 0px'
    },
    circle: {
        borderRadius: "50%",
        width: 15,
        height: 15
    },
    itemList: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        width: "100%"
    },
    itemListNotSelected: {
        display: "flex",
        alignItems: "center",
        opacity: 0.6,
        fontStyle: "italic",
    },
    itemText: {
        marginLeft: 10,
        fontWeight: "bold",
        // maxWidth: "calc(100% - 50px)",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    testclass: {
        background: "#f1f1f1 !important",
        width: "calc(100% - 30px)!important",
        height: "32px !important",
        marginLeft: 30,
    },
    iconArow: {
        fill: theme.props.primaryColor,
        position: "absolute",
        right: 3,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        zIndex: 1,
    },
    divIconIdentity: {
        width: 15,
        border: "none",
        background: "transparent",
        display: "flex",
        alignItems: "center"
    },
    divIconSetting: {
        position: 'relative',
        '&:focus': {
            outline: 'none',
        },
    },
    iconSetting: {
        position: 'absolute',
        top: '50%',
        right: '20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer'
    },
    projectDiv: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: '5px',
        margin: '5px 0px 5px 3px',
        display: 'flex',
        alignItems: 'center'
        // backgroundColor: theme.props.primaryColor,
    },
    divError: {
        fontSize: '1rem',
        padding: '5px',
        margin: '5px 0px 5px 3px',
        display: 'flex',
        alignItems: 'center',
        color: 'red',
    },
    svgIcon: {
        width: 23,
        height: 23,
        marginRight: 5
    },
    svgIconGoogle: {
        width: 20,
        height: 20,
        marginRight: 5
    },
    selectContainer: {}
}))

const readerRole = ["reader", "freeBusyReader"]

function ProjectSelection({callBack, setIdentitySync, isMyFreeTime = false, isShareUrl = false}) {
    const classes = useStyles()
    const [calendarUser] = useGlobal("calendarUser")
    const [GoogleColor] = useGlobal('GoogleColor')
    const [identities, setIdentities] = useState([])
    const {meeting, users} = useAppointmentEvent()
    const [value, setValue] = useState('')
    const [valueIdentity, setValueIdentity] = useState('')
    const [projects] = useGlobal("allProject")
    const [openSetting, setOpenSetting] = useGlobal('openSetting')
    const [, setActiveIndex] = useGlobal("settingTab")
    const [listSettingShareUrl] = useGlobal("listSettingShareUrl")
    const query = qs.parse(window.location.search)
    const [user] = useGlobal("user")
    const [leftHomeAppMyCalendar, leftHomeAppGoogleCalendar, noIdentity, noProject, myProjects, myIdentities, noTitleIntl] = useIntlId([
        'leftHomeApp.MyCalendar',
        'leftHomeApp.GoogleCalendar',
        'settings.noValidIdentity',
        'settings.noValidProject',
        'app.profile.projects',
        'app.profile.identities',
        'setting.identityNoTitle'
    ])

    useEffect(() => {
        if (!user?.user_id || !projects?.length)
            return null;

        const updateData = async () => {
            if (user?.user_id && isMyFreeTime && window.location.pathname === '/shareUrl') {
                // truong hop share Url - Da check loop
                if (query?.idShare) {
                    let idxST = findIndex(listSettingShareUrl, item => item.id === query?.idShare)
                    if (idxST !== -1) {
                        // neu project hien tai dang ton tai trong list project
                        if (Boolean(listSettingShareUrl[idxST]?.project)) {
                            let index = findIndex(projects, p => p.uuid === listSettingShareUrl[idxST].project)
                            if (index !== -1) {
                                setValue(projects[index]?.sync_id || listSettingShareUrl[idxST]?.project)
                                // neu da ton tai identity
                                if (listSettingShareUrl[idxST]?.colorId) {
                                    setValueIdentity(listSettingShareUrl[idxST]?.colorId)
                                }
                            }
                        } else {
                            // neu khong co user setting project
                            const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/scheduleAdjustment/${query?.idShare}`)
                            const {project, isGoogle, identity} = projectSelectData(calendarUser, projects, identities)
                            setValue(project);
                            setValueIdentity(identity);
                            await ref.set({
                                project: project,
                                isGoogle: isGoogle,
                            }, {merge: true})
                        }
                    }
                    return;
                }

                // truong hop /shareUrl
                if (Boolean(calendarUser?.timeExpUrl)) {
                    const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
                    // neu da ton tai project o calendar setting timeExpUrl
                    if (Boolean(calendarUser?.timeExpUrl?.project)) {
                        // neu project ton tai set gia tri project vao value selected
                        setValue(calendarUser.timeExpUrl.project)
                        // check truong hop null colorId
                        if (calendarUser.timeExpUrl.colorId) {
                            //new co colorId
                            setValueIdentity(calendarUser.timeExpUrl.colorId)
                        }else {
                            setValueIdentity(null)
                        }
                    } else {
                        if (!projects?.length)
                            return;
                        const {project, isGoogle, identity} = projectSelectData(calendarUser, projects, identities)
                        setValue(project)
                        setValueIdentity(identity)
                        calendarUser.timeExpUrl.project = project
                        calendarUser.timeExpUrl.isGoogle = isGoogle
                        await ref.set(calendarUser, {merge: true})
                    }
                    return;
                }
                return;
            }

            if (user?.user_id && users?.length && window.location.pathname === '/event') {
                let idx = findIndex(users, u => u.id === user.user_id)
                if (!meeting?.id || (idx !== -1 && !users[idx].id) || (idx === -1)) return;
                if (idx !== -1 && users[idx]?.project) {
                    if (!users[idx].project?.is_google) {
                        let index = findIndex(projects, p => p.uuid === users[idx]?.project.uuid)
                        const userRef = getUserDocRef(meeting.id, user.user_id)
                        if (index === -1) {
                            setValue(projects[0]?.uuid)
                            await userRef.set(omitByDeep({
                                project: {
                                    uuid: projects[0]?.uuid,
                                    is_google: Boolean(projects[0]?.is_google)
                                }
                            }, isUndefined), {merge: true})
                            return;
                        }
                        if (index !== -1 && projects[index]?.is_sync) {
                            await userRef.set({
                                project: {
                                    uuid: projects[index]?.sync_id,
                                    is_google: Boolean(projects[index]?.is_google)
                                }
                            }, {merge: true})
                        }
                        setValue(projects[index]?.id || users[idx]?.project.uuid)
                    } else {
                        setValue(users[idx]?.project.uuid)
                    }
                } else {
                    //truong hop co lastProjectSelect
                    let userRef = getUserDocRef(meeting.id, user.user_id)
                    if (calendarUser?.lastProjectSelect) {
                        setValue(calendarUser?.lastProjectSelect.project_uuid)
                        await userRef.set(omitByDeep({
                            project: {
                                uuid: calendarUser?.lastProjectSelect.project_uuid,
                                is_google: calendarUser?.lastProjectSelect.is_google
                            }
                        }, isUndefined), {merge: true})
                    } else {
                        if (!projects.length) return
                        setValue(projects[0]?.uuid)
                        await userRef.set(omitByDeep({
                            project: {
                                uuid: projects[0]?.uuid,
                                is_google: Boolean(projects[0]?.is_google)
                            }
                        }, isUndefined), {merge: true})
                    }
                }
                if (idx !== -1 && users[idx]?.colorId) {
                    setValueIdentity(users[idx]?.colorId)
                } else {
                    setValueIdentity(null)
                }
            }

            if (user?.user_id && isShareUrl && window.location.pathname === '/scheduleAdjustment') {
                if (calendarUser?.lastProjectSelect) {
                    const {project_uuid} = calendarUser.lastProjectSelect
                    const checkProject = find(projects, {id: project_uuid})
                    if (checkProject) {
                        setValue(calendarUser?.lastProjectSelect.project_uuid)
                        callBack(calendarUser?.lastProjectSelect.project_uuid)
                    } else {
                        setValue(projects[0].uuid)
                        callBack(projects[0].uuid)
                    }
                } else {
                    setValue(projects[0].uuid)
                    callBack(projects[0].uuid)
                }
            }
        }

        updateData().then()
        // eslint-disable-next-line
    }, [user, users, calendarUser, query?.idShare, listSettingShareUrl, meeting, projects])

    useEffect(() => {
        if (value) {
            setIdentitiesSelectable(value)
        }
        // eslint-disable-next-line
    }, [projects, value])

    function goToSetting(name) {
        if (name === 'project') {
            setOpenSetting(true)
            setActiveIndex(4)
        } else if (name === 'identity') {
            setOpenSetting(true)
            setActiveIndex(5)
        }
    }

    const setIdentitiesSelectable = (project) => {
        let idx = findIndex(projects, p => (p.id === project || p.sync_id === project))
        const {identitySettings = []} = calendarUser
        if (idx === -1)
            return
        let nextIdes = []
        if (projects[idx].is_google) {
            if (!identitySettings.length)
                nextIdes = GoogleColor.map((color, index) => {
                    return {
                        id: index + 1,
                        color,
                        name: "",
                        isArchive: false,
                        isDeleted: false
                    }
                })
            else
                nextIdes = filter(identitySettings, i => !i.isDeleted && !i.isArchive && i.id < 12)
        } else {
            if (identitySettings.length)
                nextIdes = filter(identitySettings, i => !i.isDeleted && !i.isArchive)
            else
                nextIdes = GoogleColor.map((color, index) => {
                    return {
                        id: index + 1,
                        color,
                        name: "",
                        isArchive: false,
                        isDeleted: false
                    }
                })
        }
        setIdentities(nextIdes)
    }

    const handleChange = async (e) => {
        let idx = findIndex(projects, {id: e.target.value})
        if (idx === -1 || !e.target.value)
            return;
        if (isShareUrl && window.location.pathname === "/scheduleAdjustment") {
            if (callBack && typeof callBack === 'function') {
                setValue(e.target.value)
                setIdentitiesSelectable(e.target.value)
                return callBack(e.target.value)
            }
        }
        if (isMyFreeTime && window.location.pathname === "/shareUrl") {
            setIdentitiesSelectable(e.target.value)
            if (query?.idShare) {
                let idxST = findIndex(listSettingShareUrl, item => item.id === query?.idShare)
                const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/scheduleAdjustment/${query?.idShare}`)
                if (idxST === -1)
                    return
                await ref.set({
                    project: e.target.value,
                    isGoogle: Boolean(projects[idx].is_google)
                }, {merge: true})
                return
            }
            await updateUserSettings('project', e.target.value, Boolean(projects[idx].is_google), projects[idx])
            return;
        }
        let idxUser = findIndex(users, u => (u.id === user.user_id || u.user_id === user.user_id))
        if (idxUser === -1)
            return;
        // set identities user can select
        const oldValue = value
        // let oldUser = {...users[idxUser]}

        if (callBack && typeof callBack === 'function') {
            setValue(e.target.value)
            setIdentitiesSelectable(e.target.value)
            return callBack(e.target.value)
        }

        setIdentitiesSelectable(value)
        setValue(e.target.value)
        // update db value
        try {
            const userRef = getUserDocRef(meeting.id, user.user_id)
            const batch = db.batch()
            batch.update(userRef, {
                project: {uuid: projects[idx].id, is_google: Boolean(projects[idx].is_google)},
                idSetting: ""
            })
            if (Boolean(projects[idx].is_google) && users[idxUser]?.colorId > 11) {
                batch.update(userRef, {
                    colorId: 1,
                    idSetting: ""
                })
            }
            await batch.commit()
            // await updateEvents({...oldUser, project: projects[idx]}, meeting.id)
        } catch (e) {
            toast.error(e.toString())
            setValue(oldValue)
        }
    }

    const handleChangeIdentities = async (e) => {
        let idxIdentity = findIndex(identities, {id: e.target.value})
        if (idxIdentity === -1)
            return
        const oldValueIdentity = valueIdentity
        setValueIdentity(e.target.value)

        if (isMyFreeTime) {
            if (query?.idShare && window.location.pathname === "/shareUrl") {
                let idx = findIndex(listSettingShareUrl, item => item.id === query?.idShare)
                const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/scheduleAdjustment/${query?.idShare}`)
                if (idx === -1)
                    return
                await ref.set({
                    colorId: e.target.value
                }, {merge: true})
                return
            }
            await updateUserSettings('colorId', e.target.value)
            return;
        }

        if (setIdentitySync && typeof setIdentitySync === 'function')
            return setIdentitySync(e.target.value)
        // update db value
        try {
            const userRef = getUserDocRef(meeting.id, user.user_id)
            const batch = db.batch()
            batch.set(userRef, {
                colorId: e.target.value || '',
                idSetting: ""
            }, {merge: true})
            await batch.commit()
            // await updateEvents({colorId: e.target.value}, meeting.id)
        } catch (e) {
            console.log(e);
            toast.error(e.toString())
            setValueIdentity(oldValueIdentity)
        }
    }

    return (
        <div className={classes.container}>
            <FormControl variant="filled" fullWidth className={classes.root}>
                <Select
                    value={value}
                    onChange={handleChange}
                    IconComponent={props => (
                        <ArrowDownBlueSVG {...props} className={classes.iconArow}/>
                    )}
                >
                    <div className={classes.divIconSetting}>
                        <MenuItem value={''} className={classes.projectDiv}>
                            {myProjects}
                        </MenuItem>
                        <div className={classes.iconSetting}>
                            <img onClick={() => goToSetting('project')}
                                 src='/imgs/newSvgs/setting.svg' alt=''/>
                        </div>
                    </div>
                    <div className={classes.projectDiv}>
                        <GeniamSvg className={classes.svgIcon}/>
                        {leftHomeAppMyCalendar}
                    </div>
                    {
                        projects.filter(p => !readerRole.includes(p?.accessRole) && !Boolean(p?.is_google))?.length ?
                            projects.filter(p => !readerRole.includes(p?.accessRole) && !Boolean(p?.is_google)).map(pro => {
                                    return (
                                        <MenuItem
                                            value={pro?.is_sync ? pro.sync_id : pro.id}
                                            key={pro?.is_sync ? pro.sync_id : pro.id}>
                                            <div
                                                className={(pro?.apSelected) ? classes.itemList : classes.itemListNotSelected}>
                                                <div className={classes.circle} style={{backgroundColor: pro.color}}>

                                                </div>
                                                <div
                                                    className={`${classes.itemText} fontSourceHanSans itemTextProjectSelection`}>
                                                    {pro.name || pro.summary}
                                                </div>

                                            </div>
                                        </MenuItem>
                                    )
                                }
                            )
                            :
                            <div className={classes.divError}>{noProject}</div>
                    }
                    <div className={classes.projectDiv}>
                        <GoogleSvg className={classes.svgIconGoogle}/>
                        {leftHomeAppGoogleCalendar}
                    </div>
                    {
                        projects.filter(p => !readerRole.includes(p?.accessRole) && Boolean(p?.is_google))?.length ?
                            projects.filter(p => !readerRole.includes(p?.accessRole) && Boolean(p?.is_google)).map(pro => {
                                    return (
                                        <MenuItem
                                            value={pro?.is_sync ? pro.sync_id : pro.id}
                                            key={pro?.is_sync ? pro.sync_id : pro.id}>
                                            <div
                                                className={(pro?.apSelected) ? classes.itemList : classes.itemListNotSelected}>
                                                <div className={classes.circle} style={{backgroundColor: pro.color}}>

                                                </div>
                                                <div
                                                    className={`${classes.itemText} fontSourceHanSans itemTextProjectSelection`}>
                                                    {pro.name || pro.summary}
                                                </div>

                                            </div>
                                        </MenuItem>
                                    )
                                }
                            )
                            :
                            <div className={classes.divError}>{noProject}</div>
                    }
                </Select>
            </FormControl>
            <FormControl variant="filled" className={classes.root}>
                <Select
                    value={valueIdentity}
                    onChange={handleChangeIdentities}
                    IconComponent={props => (
                        <ArrowDownBlueSVG {...props} className={classes.iconArow}/>
                    )}
                >
                    <div className={classes.divIconSetting}>
                        <MenuItem value={''} className={classes.projectDiv}>
                            {myIdentities}
                        </MenuItem>
                        <div className={classes.iconSetting}>
                            <img onClick={() => goToSetting('identity')}
                                 src='/imgs/newSvgs/setting.svg' alt=''/>
                        </div>
                    </div>
                    {/*<div className={classes.projectDiv}>*/}
                    {/*    <GeniamSvg className={classes.svgIcon}/>*/}
                    {/*    {geIdentities}*/}
                    {/*</div>*/}
                    {/*{*/}
                    {/*    filter(identities, i => i.id > 11)?.length ?*/}
                    {/*        filter(identities, i => i.id > 11).map(ide => {*/}
                    {/*            return <MenuItem value={ide.id} key={ide.id}>*/}
                    {/*                <div className={classes.itemList}>*/}
                    {/*                    <div className={classes.divIconIdentity}>*/}
                    {/*                        <IconIdentity color={ide.color}/>*/}
                    {/*                    </div>*/}
                    {/*                    <div className={`${classes.itemText} fontSourceHanSans itemTextProjectSelection`}>*/}
                    {/*                        {ide.name || "(no title)"}*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </MenuItem>*/}
                    {/*        })*/}
                    {/*        :*/}
                    {/*        <div className={classes.divError}>{noIdentity}</div>*/}
                    {/*}*/}
                    {/*<div className={classes.projectDiv}>*/}
                    {/*    <GoogleSvg className={classes.svgIconGoogle}/>*/}
                    {/*    {ggIdentities}*/}
                    {/*</div>*/}
                    {
                        filter(identities, i => i.id <= 11)?.length ?
                            filter(identities, i => (i.id <= 11 && i.name && i.name !== '(no title)')).map(ide => {
                                return <MenuItem value={ide.id} key={ide.id}>
                                    <div className={classes.itemList}>
                                        <div className={classes.divIconIdentity}>
                                            <IconIdentity color={ide.color}/>
                                        </div>
                                        <div
                                            className={`${classes.itemText} fontSourceHanSans itemTextProjectSelection`}>
                                            {ide.name || noTitleIntl}
                                        </div>
                                    </div>
                                </MenuItem>
                            })
                            :
                            <div className={classes.divError}>{noIdentity}</div>
                    }
                </Select>
            </FormControl>
            {
                openSetting && <ListSetting setOpenSetting={setOpenSetting} openSetting={openSetting}/>
            }
        </div>
    );
}

export default ProjectSelection;
