import React, {useEffect, useState} from 'react';
import {Col, Input, Row, Space, Tooltip, Typography} from "antd";
import IconIdentity from "../../Calendar/google/identityIcon";
import {List} from "@material-ui/core";
import {ReactComponent as EditSVG} from '../../../asSvg/editBGPrimary.svg'
import {ReactComponent as RemoveSVG} from '../../../asSvg/removeBGPrimary.svg'
import {ReactComponent as SaveSVG} from '../../../asSvg/saveBGPrimary.svg'
import {ReactComponent as CloseSVG} from "../../../asSvg/closeRound.svg";
import {GridContextProvider, GridDropZone, GridItem} from "react-grid-dnd";
import {concat, orderBy} from "lodash";
import {onChangeIdentities, saveIdentities} from "../../../common/dndIdentities/onChangeIdentities";
import SaveButton from "../../Dialog/SaveButton";
import useIntlId from "../../../hooks/useIntlId";

function TabGoogleIdentities({classes, identities, archives, edit, setEdit, setArchiveIdentity, loading, onUpdate}) {
    const [lists, setLists] = useState(identities.filter(item => item.id <= 11))
    const [checkDisable, setCheckDisable] = useState(true)
    const [newLists, setNewLists] = useState([])
    const [loadingSave, setLoadingSave] = useState(false)
    const numRows = Math.round(lists.filter(item => !item.isArchive).length / 2)
    const height = numRows * 50 + 10
    const [noTitleIntl, changeNameIntl] = useIntlId(['setting.identityNoTitle','setting.identity.changename'])
    useEffect(() => {
        setNewLists(identities || [])
        setLists(identities.filter(item => item.id <= 11))
        // eslint-disable-next-line
    }, [identities])

    const onChange = async (sourceId, sourceIndex, targetIndex) => {
        setCheckDisable(false)
        onChangeIdentities(lists, sourceIndex, targetIndex, setNewLists, setLists, newLists)
    }

    const onSave = async () => {
        setLoadingSave(true)
        await saveIdentities(concat(newLists, archives))
        setCheckDisable(true)
        setLoadingSave(false)
    }

    return (
        <List className={classes.ListItem}>
            <GridContextProvider
                onChange={onChange}>
                <GridDropZone
                    id="projects"
                    boxesPerRow={2}
                    rowHeight={50}
                    className={classes.boxDnD}
                    style={{height}}
                    // disableDrag={true}
                >
                    {orderBy(lists, "index", "asc").map((identity, index) => {
                        if (edit?.id === identity.id) {
                            return (
                                <GridItem key={identity.id} className={classes.divGrid}>
                                    <div className={classes.divRowAntd}>
                                        <Row gutter={[16, 16]} align={'middle'}>
                                            <Col span={3}>
                                                <IconIdentity padding={'5px 0px 0px 0px'} color={identity.color}/>
                                            </Col>
                                            <Col span={15} className={`${classes.textInput} ${classes.colInput}`}>
                                                <Input
                                                    placeholder={changeNameIntl}
                                                    autoFocus
                                                    onPressEnter={() => {
                                                        if (edit.name !== identity.name) {
                                                            onUpdate(edit)
                                                            return
                                                        }
                                                        setEdit(null)
                                                    }}
                                                    value={edit.name}
                                                    onChange={e => {
                                                        setEdit({...edit, name: e.target.value})
                                                    }}
                                                />
                                            </Col>
                                            <Col span={6}>
                                                <Space size={'small'} className={classes.spaceAntd}>
                                                    <Tooltip
                                                        title="Save"
                                                        placement="bottom"
                                                        getPopupContainer={() => document.getElementById('list-setting-identity-save' + index)}
                                                    >
                                                        <SaveSVG
                                                            onClick={() => onUpdate(edit)}
                                                            id={'list-setting-identity-save' + index}
                                                            className={classes.iconAntd}
                                                            disabled={loading}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip
                                                        title="Cancel"
                                                        placement="bottom"
                                                        getPopupContainer={() => document.getElementById('list-setting-identity-cancel' + index)}
                                                    >
                                                        <CloseSVG
                                                            id={'list-setting-identity-cancel' + index}
                                                            className={classes.iconAntd}
                                                            onClick={() => setEdit(null)}
                                                            disabled={loading}
                                                        />
                                                    </Tooltip>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </div>
                                </GridItem>
                            )
                        }
                        return (
                            <GridItem key={identity.id}>
                                <div className={classes.divRowAntd}>
                                    <Row gutter={[16, 16]} align={'middle'} key={index}>
                                        <Col span={3}>
                                            <IconIdentity padding={'5px 0px 0px 0px'} color={identity.color}/>
                                        </Col>
                                        <Col span={15} className={classes.colInput} onClick={() => {
                                            setEdit(identity)
                                        }}>
                                            <Typography>{identity.name || noTitleIntl}</Typography>
                                        </Col>
                                        <Col span={6}>
                                            <Space size={'small'} className={classes.spaceAntd}>
                                                <Tooltip
                                                    title="Edit"
                                                    placement="bottom"
                                                    getPopupContainer={() => document.getElementById('list-setting-identity-edit' + index)}
                                                >
                                                    <EditSVG
                                                        onClick={() => {
                                                            setEdit(identity)
                                                        }} id={'list-setting-identity-edit' + index}
                                                        className={classes.iconAntd}
                                                    />
                                                </Tooltip>
                                                <Tooltip
                                                    title="Archive"
                                                    placement="bottom"
                                                    getPopupContainer={() => document.getElementById('list-setting-identity-archive' + index)}
                                                >
                                                    <RemoveSVG
                                                        onClick={() => {
                                                            setArchiveIdentity(identity)
                                                        }}
                                                        id={'list-setting-identity-archive' + index}
                                                        className={classes.iconAntd}
                                                    />
                                                </Tooltip>
                                            </Space>
                                        </Col>
                                    </Row>
                                </div>
                            </GridItem>
                        )
                    })}
                </GridDropZone>
            </GridContextProvider>
            {
                !checkDisable && <div className={'clf-flex-center'}>
                    <SaveButton
                        className={`${classes.rightBtn} ${classes.primary}`}
                        disable={checkDisable}
                        onClick={onSave}
                        // name={'Save change'}
                        minWidth={"150px"}
                        loading={loadingSave}
                    />
                </div>
            }
        </List>
    );
}

export default TabGoogleIdentities;
