import {findIndex} from "lodash";

export const getUserView = (view) => {
    if (!view || !Array.isArray(view))
        return [{field: "box", checked: true}, {field: "title", checked: true}]
    return [{field: "box", checked: Boolean(view[0] && view[0]?.checked)}, {
        field: "title",
        checked: Boolean(view[1] && view[1]?.checked)
    }]
}

export const getDefaultUserView = () => {
    return [{field: "box", checked: true}, {field: "title", checked: true}]
}
export const filterUser = (userIds, members) => {
    let arr = []
    userIds.forEach(item => {
        let idx = findIndex(members, {'id': item})
        if (idx !== -1) {
            arr.push(members[idx])
        }
    })
    return arr
}
