export const API_METAMOR = process.env.REACT_APP_API_METAMOR
export const API_TASKS = process.env.REACT_APP_API_TASKS
export const TASK_SETTING = process.env.REACT_APP_TASK_REDIRECT + '/setting'
export const CAL_API = process.env.REACT_APP_API_TASKS
export const METAMOR_WS = process.env.REACT_APP_METAMOR_WS
export const GOOGLE_CALENDAR_WATCH = process.env.REACT_APP_GOOGLE_CALENDAR_WATCH
export const GENIAM_API = process.env.REACT_APP_API_GENIAM
export const CART_URL = process.env.REACT_APP_CART_URL
export const GET_GOOGLE_CALENDAR_LIST = 'https://www.googleapis.com/calendar/v3/users/me/calendarList'
export const GET_GOOGLE_CALENDAR_EVENTS = 'https://www.googleapis.com/calendar/v3/calendars'
export const DELETE_GOOGLE_EVENT = 'https://www.googleapis.com/calendar/v3/calendars'
export const GOOGLE_CALENDAR_APIS = 'https://www.googleapis.com/calendar/v3/calendars'
export const GET_EVENTS = API_TASKS + '/calendar-event'
export const UPDATE_EVENT = API_TASKS + '/events/modify'
export const CREATE_EVENT = API_TASKS + '/events/create'
export const RESTORE_EVENT = API_TASKS + '/events/calendar-restore'
export const CREATE_GROUP = API_TASKS + '/group/create'
export const SHARE_GROUP = API_TASKS + '/group/info-group'
export const ADD_GROUP = API_TASKS + '/group/add-group'
export const UPDATE_GROUP = API_TASKS + '/projects/changeColor'
export const DELETE_GROUP = API_TASKS + '/group/delete'
export const GET_TASK = API_TASKS + '/tasks'
export const DELETE_EVENT = API_TASKS + '/events/calendar-delete'
export const EVENT_SHIFT_COPY = API_TASKS + '/events/shift-copy'
export const EVENT_ALT_COPY = API_TASKS + '/events/alt-copy'
export const GET_TIME = CAL_API + '/calendar/time'
export const SETTING_MIN_TIME = CAL_API + '/calendar/update-mintime'
export const CREATE_SETTING_TIME = CAL_API + '/calendar/create-time'
export const SETTING_MAX_TIME = CAL_API + '/calendar/update-maxtime'
export const SET_FOCUS_TEXT = API_TASKS + '/textFocus/set'
export const GET_FOCUS_TEXT = API_TASKS + '/textFocus'
export const CHECK_USER = API_TASKS + "/users"
export const TASKS = API_TASKS + "/tasks"
export const CATEGORIES = API_TASKS + "/categories"
export const PRIORITIES = API_TASKS + "/priorities"
export const PRIORITY = API_TASKS + "/priority"
export const PROJECTS = API_TASKS + "/projects"
export const SYNC_PROJECT = PROJECTS + '/sync'
export const IDENTITY = API_TASKS + "/identity"
export const INIT_TASK = CAL_API + "/tasks/init"

