export function getPosition(el) {
    // console.log(el);
    let xPos = el.offsetWidth || 0
    let yPos = 0
    while (el) {
        if (el.tagName === "BODY") {
            let xScroll = el.scrollLeft || document.documentElement.scrollLeft
            let yScroll = el.scrollTop || document.documentElement.scrollTop

            xPos += (el.offsetLeft - xScroll + el.clientLeft)
            yPos += (el.offsetTop - yScroll + el.clientTop)
        } else {
            //  all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft)
            yPos += (el.offsetTop - el.scrollTop + el.clientTop)
        }

        el = el.offsetParent;
    }
    return {
        left: xPos,
        top: yPos
    };
}
