import moment from "moment";


export const getDateRange = (range = [0, 1]) => {
    const week = checkRange(range)
    return {
        start: moment().add(week[0], "weeks").startOf("day").format(),
        end: moment().add(week[1] + 1, "weeks").startOf("day").format()
    }
}

const checkRange = range => {
    if (!range)
        return [0, 1]
    if (typeof range === "number")
        return [0, range === 0 ? 1 : range]
    if (!Array.isArray(range))
        return [0, 1]
    if (range?.length !== 2)
        return [0, range[0] === 0 ? 1 : range[0]]
    return range
}
