import {auth, db} from "../../firebaseConfig";
import {CALENDAR_COLLECTION, USER_COLLECTION} from "../../config/constants";
import {getGlobal} from 'reactn'
import {message} from "antd";

const getUser = (prop) => {
    const u = getGlobal()?.user || auth.currentUser?.toJSON()
    if (!u) {
        message.error('no user. login again!')
        return;
    }
    return prop ? u[prop] : u;
}

// const getUserId = () => getUser('user_id')

export const myEvents = () => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(getUser('user_id'))
        .collection('events')
}
export const myEvent = (docId) => {
    return myEvent().doc(docId)
}
export const myGoogleEvents = (calId) => {
    return db.collection(USER_COLLECTION)
        .doc(getUser('user_id'))
        .collection('calendars')
        .doc(calId)
        .collection('events')
}

export const myCalendars = (userId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('projects')
}

export const projectRef = (ptjId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(getUser('user_id'))
        .collection('projects')
        .doc(ptjId)
}

export const userInfo = (userId) => {
    return db.collection(USER_COLLECTION).doc(userId)
}

export const membersRef = (userId, memberId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection("members")
        .doc(memberId)
}


export const notificationsDoc = (userId, noticeId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('notifications')
        .doc(noticeId)
}

export const myCalendarRef = () => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(getUser('user_id'))
}

export const getThemeDocRef = (userId, themeId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}/listTheme/${themeId}`)
}
