import React, {useEffect, useState} from 'react';
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import moment from "moment";
import HoverEventCalendar from "../../Calendar/handlers/HoverEvent";
import HoverVote from "../VoteBox/HoverVote";

export function eventRender(info, classes, isTooltip, meeting, isLocal) {
    if (info.event.extendedProps?.freeSlot || info.event.extendedProps?.generalFree)
        return null

    if (info.event.title) {
        return <HoverEventCalendar
            hoverTitle={info.event}
            placement={'rightTop'}
            isLocal={isLocal}
        >
            <div className={`${classes.events} event-hover`} id={info.event.id}>
                <span>{info.event.title}</span>
            </div>
        </HoverEventCalendar>
    }

    if (info.event.extendedProps?.isEventVote && info.event.extendedProps?.meetingId === meeting?.id && info.event.extendedProps?.isAccepted) {
        return (
            <HoverVote
                placement={'rightTop'}
            >
                <div className={`${classes.events} event-hover`} id={info.event.id}>
                    <DoneRoundedIcon className={classes.checkbox}/>
                </div>
            </HoverVote>)
    }

    if (info.event.extendedProps?.isEventVote && meeting && info.event.extendedProps?.meetingId === meeting?.id && !info.event.extendedProps?.isAccepted) {
        return (
            <HoverVote
                placement={'rightTop'}
            >
                <div className={`${classes.events} event-hover`} id={info.event.id}>
                    <CountDown info={info}/>
                </div>
            </HoverVote>)
    }

    if (!info.event.extendedProps?.freeSlot || !info.event.extendedProps?.generalFree) {
        // console.log(info.event)
        const checkFree = info.event.extendedProps?.checkFree || false;
        return <div className={`${classes.events} event-hover`} id={info.event.id}>
            {info.event.title}
            {checkFree === true &&
                <input className={"check-free-noneClick"} type="checkbox" id={info.event.id} checked={true}/>}
        </div>
    }
}

const CountDown = (
    {
        info
    }
) => {
    const [time, setTime] = useState(null)
    useEffect(() => {
        const timer = setInterval(() => {
            const time = new Date(moment(info.event.extendedProps.createdAt).add(30, "seconds").format()).getTime()
            const now = new Date().getTime()
            const second = Math.floor((time - now) / 1000)
            if (second < 0) {
                clearInterval(timer)
                return
            }
            if (second === 0) {
                // clear interval + submit
                if (info.event.extendedProps.isAccepted) {
                    clearInterval(timer)
                    return
                }

                // submitVoteAuto(info.event.extendedProps.meetingId, info.event.id, info.event)
                clearInterval(timer)
            }
            setTime(second)
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [info])
    return (
        <div>
            {time && time > 0 && time < 30 ?
                time
                :
                null}
        </div>
    )
}
