import React, {useContext, useState} from 'react';
import {MenuAppBar} from "../../splitting";
import {NavigationGlobal, RecommendAnnounceBar} from "@Andom-Geniam/git-globalnavi";
import {auth, db} from "../../../firebaseConfig";
import {setGlobal, useGlobal} from "reactn";
import axios from "axios";
import {IS_PRODUCTION, LOGIN_URL, LOGOUT_URL} from "../../../config/constants";
import removeAllTokens from "../../../common/removeAllToken";
import removeLoginOrRegisterKeys from "../../../common/removeLoginOrRegisterKeys";
import {LanguageMessageContext} from "../../../context/LanguageMessageContext";
import {useAnnouncement} from "../../../context/AnnouncementContext";

function MenuAppBarGlobal({viewEvent, setViewEvent}) {
    const [user] = useGlobal('user')
    const [loading, setLoading] = useState(false)
    const intl = useContext(LanguageMessageContext)
    const {lock} = useAnnouncement()
    const onClickSetting = () => {
        setGlobal({
            openSetting: true,
            settingTab: 7
        })
    }
    const handleGotoSettings = () => {
        setGlobal({
            openSetting: true,
            settingTab: 8
        })
    }
    const onLogout = async (e) => {
        setLoading(true);
        try {
            await axios.post(LOGOUT_URL);
            await auth.signOut()
            removeAllTokens();
            removeLoginOrRegisterKeys()
            localStorage.clear()
            window.location = `${LOGIN_URL}?redirect_url=${window.location.href}`
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    };

    const onClickAccount = () => {
        window.open(`https://${IS_PRODUCTION ? 'myaccount' : 'myaccount-stg'}.geniam.com/account`, '_blank')

    }
    return (
        <div>
            <div className={"flex items-center justify-between bg-[#f1f1f1] max-w-full overflow-auto"}>
                <MenuAppBar
                    viewEvent={viewEvent}
                    setViewEvent={setViewEvent}
                />
                <NavigationGlobal
                    user={user}
                    db={db}
                    service={"appy"}
                    onClickLogOut={onLogout}
                    switchAccount={onLogout}
                    onClickAccount={onClickAccount}
                    intl={intl}
                    onClickSetting={onClickSetting}
                />
            </div>
            <RecommendAnnounceBar
                lock={lock}
                user={user}
                db={db}
                service={"appy"}
                handleGotoSettings={handleGotoSettings}
            />
        </div>
    );
}

export default MenuAppBarGlobal;
