import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBROAxpDRqRIqZvrAGE7UK7uvaIUcxooS0",
    authDomain: "geniam-c8d4c.firebaseapp.com",
    databaseURL: "https://geniam-c8d4c.firebaseio.com",
    projectId: "geniam-c8d4c",
    storageBucket: "geniam-c8d4c.appspot.com",
    messagingSenderId: "92022981782",
    appId: "1:92022981782:web:17a2ab710f867e60b56eb7",
    measurementId: "G-ZZVDYP9EN4"
};


firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()


// config emulater

// const db = firebase.firestore()
// db.useEmulator('localhost', 5002)
// const auth = firebase.auth()
// const storage = firebase.storage()
//
// export {
//     db,auth,storage
// }


//
// if (IS_PRODUCTION) {
// //enable local cache
//     firebase.firestore().settings({
//         cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
//         merge: true,
//     });
//     firebase.firestore().enablePersistence({synchronizeTabs: true})
//         .then(() => {
//             console.log('firestore cached')
//         })
//         .catch(function (err) {
//             // alert(err.message)
//             console.error(err)
//         });
// // Subsequent queries will use persistence, if it was enabled successfully
// }

const Fire = firebase
export default Fire
if (typeof window !== "undefined") window.Fire = Fire

