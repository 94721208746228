import {CALENDAR_COLLECTION, MEETING_COLLECTION} from "../../../config/constants";
import {db} from "../../../firebaseConfig";
import {toast} from "react-toastify";
import {findIndex} from "lodash";
import {getGlobal, setGlobal} from 'reactn'
import qs from "query-string";
import moment from "moment";
import {transferAnonymousData} from "./dbUpdate/transferAnonymousData";
import {handleSharedEmail} from "../../Mails/Actions";
import {getUserView} from "../../../common/formatUserView";
import {alertNotMeeting} from "../../../context/common/alertNotMeeting";

export const handleAccessEventsUserData = async (meeting) => {
    try {
        const {user} = getGlobal()
        let {userIds, viewEvent,} = meeting

        const batch = db.batch()

        const userRef = db.doc(`${MEETING_COLLECTION}/${meeting.id}/users/${user.user_id}`)

        const meetingRef = db.doc(`${MEETING_COLLECTION}/${meeting.id}`)
        userIds.push(user.user_id)
        //insert new data invited to meetings users collection
        const userSnap = await db.doc(`${MEETING_COLLECTION}/${meeting.id}/users/${user.user_id}`).get()
        const userData = {
            email: user.email,
            nickname: user.nickname || "",
            updatedAt: moment().utc().format(),
            joined: moment().utc().format(),
            isChecked: true,
            hiddenDays: [],
            freeTime: 0,
            role: 'viewer',
            workTime: {
                start: 0,
                end: 24
            },
            viewEvent: getUserView(viewEvent),
            startDay: moment().format('YYYY-MM-DD'),
            endDay: moment().add(14, "day").format('YYYY-MM-DD'),
            id: user.user_id,
            createdAt: moment().utc().format(),
            typeTimeSharing: "auto",
            timeRange: [0, 1],
            project: null,
            task: "",
            colorId: null
        }
        if (userSnap.exists) {
            userData.isChecked = userSnap.data()?.isChecked || false
            userData.role = userSnap.data()?.role || 'viewer'
        }
        batch.set(userRef, userData, {merge: true})

        const members = meeting?.members || [];

        let index = findIndex(members, {id: user.user_id})
        if (index === -1) {
            members.push({
                id: user.user_id,
                email: user.email,
                avatar: user?.avatar || null,
            })
        }

        batch.set(meetingRef, {userIds: userIds, members}, {merge: true})
        // import free events
        // const geniamEvent = await geniamEvents(startDay, endDay)
        // // const googleEvent = await googleEvents(startDay, endDay)
        //
        // geniamEvent.forEach(evt => {
        //     delete evt.isEventVote
        //     const freeRef = db.doc(`${MEETING_COLLECTION}/${meeting.id}/busyColl/${evt.id}`)
        //     batch.set(freeRef, {...evt, userIds: firebase.firestore.FieldValue.arrayUnion(user.user_id)}, {merge: true})
        // })

        //commit changed
        await handleSharedEmail(batch, meeting.id, 'email.message.join')

        // await batch.commit()
        // // send joined notice to other users - can be fail
        // axios.post(GENIAM_API + '/sharedEmail', {
        //     owner: meeting.owner,
        //     meetingId: meeting.id,
        //     messageId: `email.message.join`
        // }).catch(e => {
        //     console.log(e);
        // })
        window.history.replaceState({}, 'event', `/event?id=${meeting.id}`)
    } catch (e) {
        console.log(e.toString())
        toast.error(e.toString())
        return window.location = "/event"
    } finally {
    }
}

// export const getEventSuccess = async (userId, callback) => {
//     return db.collection(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting`)
//         .where('isDeleted', '==', false)
//         .onSnapshot(async snap => {
//             let events = []
//             let urlSchedules = []
//             snap.docs.forEach(doc => {
//                 let data = doc.data()
//                 if (data.scheduleId)
//                     urlSchedules.push(data)
//                 if (!data.isSync)
//                     events.push({
//                         ...data,
//                         id: doc.id,
//                         backgroundColor: "black",
//                         editable: false,
//                         isEventVote: null
//                     })
//             })
//
//             // console.log(events);
//             if (callback) {
//                 await callback(events, urlSchedules)
//             }
//             return events
//         })
// }
//
// export const getMyFreeTimeSetting = async (userId, callback) => {
//     return db.doc(`${CALENDAR_COLLECTION}/${userId}/myFreeTime/myfreetime`)
//         // .doc(userId)
//         // .collection('myFreeTime')
//         // .doc('myfreetime')
//         .onSnapshot(async doc => {
//             const defaultValue = {
//                 freeTime: 0,
//                 hiddenDays: [],
//                 workTime: {
//                     start: 7,
//                     end: 24
//                 },
//                 // viewEvent: [{label: '予定BOX', checked: true}, {label: 'タイトル', checked: true}, {
//                 //     label: '場所',
//                 //     checked: true
//                 // }]
//             }
//             const result = doc.data()
//             setGlobal({
//                 myFreeTime: {...defaultValue, ...result}
//             })
//
//             if (callback) {
//                 await callback({...defaultValue, ...result})
//             }
//         })
// }

export const isUserExists = async (meeting, users, anonymousList, history = [], groupExists = "") => {
    const {user, eventJoining, eventCancelJoin} = getGlobal();

    const {id, to, by, from, anonymousEmail, meetingPrimary} = qs.parse(window.location.search)
    if ((by && by === user?.user_id) || (from && from === user?.user_id)) {
        if (meetingPrimary === undefined) {
            history?.push(`/event?id=${id}`)
            return
        } else {
            history?.push(`/event?id=${id}&meetingPrimary=${meetingPrimary}`)
            return
        }
    }
    // option co thong tin user anonymous -> transfer
    if (anonymousEmail) {
        await transferAnonymousData(id, from, anonymousEmail.replace(" ", "+"), meeting)
        return console.log('Transfer anonymous data -> user Geniam')
    }

    let userIdx = findIndex(users, {id: user.user_id})

    // da tham gia
    if (userIdx !== -1 && (users[userIdx].joined)) {
        if (meetingPrimary === undefined) {
            history?.push(`/event?id=${id}`)
            return
        } else {
            history?.push(`/event?id=${id}&meetingPrimary=${meetingPrimary}`)
            return
        }
    }


    // moi email khac email dang su dung
    if (to && to.replace(" ", "+") !== user.email) {
        return setGlobal({
            switchEmail: {
                id,
                by,
                to
            }
        })
    }
    // chua co thong tin user: moi qua link
    if ((userIdx === -1 && !from) || meeting?.isDeleted) {
        alertNotMeeting(groupExists)
        window.location = "/event"
        return
        //
        // return window.history.replaceState({}, 'event', `/event`)
    }

    // if ((concat(users, anonymousList).length - 1) > meeting?.numUser)
    //     return window.location.assign(`/eventNotFound?error=maxUserJoin`)

    await setGlobal({eventJoining: true})

    if(!eventCancelJoin) {
        await handleAccessEventsUserData(meeting);
        await setGlobal({eventJoining: false})
    }
}

export const updateMyFreeTime = async (field, value) => {
    const {user} = getGlobal()
    const timeUpdated = moment().utc().format()
    if (!user)
        return
    try {
        const myFreeRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/myFreeTime/myfreetime`)
        await myFreeRef.set({[field]: value, updatedAt: timeUpdated}, {merge: true})
    } catch (e) {
        console.log(e.toString())
    }
}


export const updateMyFreeTimeMore = async (boxEdit = [], dateSelections = []) => {
    const {user} = getGlobal()
    const timeUpdated = moment().utc().format()
    if (!user)
        return
    try {
        const myFreeRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/myFreeTime/myfreetime`)
        await myFreeRef.set({busyBox: boxEdit, dateSelections: dateSelections, updatedAt: timeUpdated}, {merge: true})
    } catch (e) {
        console.log(e.toString())
    }
}

export const updateScheduleAdjustment = async (field, value, id) => {
    // console.log("update done")
    const {user} = getGlobal()
    const timeUpdated = moment().utc().format()
    if (!user)
        return
    try {
        const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/scheduleAdjustment/${id}`)
        const snap = await ref.get()
        if (!snap.exists)
            return;
        let data = snap.data();
        let userData = data?.user || {}
        await ref.set({user: {...userData, [field]: value}, updatedAt: timeUpdated}, {merge: true})
    } catch (e) {
        console.log(e.toString())
    }
}

export const updateScheduleAdjustmentMore = async (boxEdit = [], dateSelections = [], id) => {
    const {user} = getGlobal()
    const timeUpdated = moment().utc().format()
    if (!user)
        return
    try {
        const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/scheduleAdjustment/${id}`)
        const snap = await ref.get()
        if (!snap.exists)
            return;
        let data = snap.data();
        // console.log("data:",data)
        let userData = data?.user || {}
        await ref.set({
            user: {...userData, busyBox: [...boxEdit], dateSelections: [...dateSelections]},
            updatedAt: timeUpdated
        }, {merge: true})
    } catch (e) {
        console.log(e.toString())
    }
}


export const handleBoxFreeShare = async (boxFree = [], myFreeTime, user, idShare) => {
    const batch = db.batch()
    let evtRef
    if (!idShare) {
        evtRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/myFreeTime/myfreetime`)
    } else {
        evtRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/scheduleAdjustment/${idShare}`)
    }
    if (!idShare) {
        batch.set(evtRef, {exBoxFreeShare: [...boxFree]}, {merge: true})
    } else {
        batch.set(evtRef, {user: {...myFreeTime, exBoxFreeShare: [...boxFree]}}, {merge: true})
    }
    batch.commit().then();
}

export const updateUserSettings = async (field, value, isGoogle, project) => {
    try {
        console.log("updated project")
        const {user, calendarUser} = getGlobal()
        if (value === 'Project')
            return;

        const {timeExpUrl = {}} = calendarUser

        timeExpUrl[field] = value;

        const refSettingTime = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)

        if (field === 'project') {
            if (Boolean(project?.is_google) && timeExpUrl?.colorId > 11) {
                timeExpUrl.colorId = '';
            }
            timeExpUrl.isGoogle = isGoogle;
        }
        await refSettingTime.set({timeExpUrl}, {merge: true})
    } catch (e) {

    }

};

export const updateAllUserSettings = async (setting) => {
    try {
        const {user} = getGlobal()
        const refSettingTime = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
        await refSettingTime.set({
            timeExpUrl: setting
        }, {merge: true})
    } catch (e) {

    }
};
