import {getGlobal, setGlobal} from 'reactn'
import {db} from '../../../../firebaseConfig'
import {CALENDAR_COLLECTION, MEETING_COLLECTION} from "../../../../config/constants";
import {remove} from 'lodash'
import moment from "moment";
import {toast} from "react-toastify";
import {
    getAnonymousDocRef,
    getMeetingDocRef,
    getUserDocRef,
    getVoteBoxCollRef
} from "../../../../common/firebaseRef/meetings";

export const transferAnonymousData = async (meetingId, from, anonymousEmail, meeting) => {
    const {user} = getGlobal()

    try {
        if (!meetingId || !from || !anonymousEmail)
            return console.log('missing some data! transfer error')

        if (!user)
            return console.log('missing user data! transfer error')

        if (user.email !== anonymousEmail) {
            // check if user.email already in event
            let userIdsThisMeeting = meeting.userIds
            if (userIdsThisMeeting.includes(user.user_id))
                return window.history.replaceState({}, 'event', `/event?id=${meetingId}`)
            return window.history.replaceState({}, 'event', `/event`)
        }

        // check user login/register have in this event meetingId

        const batch = db.batch()
        const now = moment().utc().format()
        // get Anonymous meeting joined data
        const anonymousEventData = await db.collection(`${MEETING_COLLECTION}`)
            .where('anonymousUsers', 'array-contains', anonymousEmail)
            .get()
        // if have data , transfer data to user geniam, remove data anonymous
        if (anonymousEventData.size) {
            setGlobal({
                eventTransfer: true
            })
            await Promise.all(anonymousEventData.docs.map(async doc => {
                const groupEventRef = getMeetingDocRef(doc.id)
                // update transfer joined
                let anonymousUsers = doc.data().anonymousUsers
                let userIds = doc.data().userIds
                // anonymousUsers -> userIds
                remove(anonymousUsers, ano => ano === anonymousEmail)
                userIds.push(user.user_id)
                // update to groupEvent anonymousUsers -> userIds

                const anonymousUserRef = getAnonymousDocRef(doc.id, anonymousEmail)
                const userRef = getUserDocRef(doc.id, user.user_id)

                // get oldAnonymous Coll Data
                const anonymousSnap = await anonymousUserRef.get()

                // update anonymousColl & usersColl
                if (anonymousSnap.exists) {
                    batch.delete(anonymousUserRef)
                    batch.set(userRef, {
                        ...anonymousSnap.data(),
                        id: user.user_id,
                        user_id: user.user_id,
                        email: user.email,
                        nickname: user.nickname,
                        isChecked: false,
                        joined: moment().utc().format(),
                        isPublic: true,
                        isAnonymous: false
                    }, {merge: true})
                }

                // update Busy Coll
                // const geniamUserEvent = await geniamEvents(startDay, endDay)
                // const googleUserEvent = await googleEvents(startDay, endDay)
                // const otherMeetings = await myMeetings(user.user_id)
                //
                // concat(geniamUserEvent, googleUserEvent, otherMeetings).forEach(evt => {
                //     const busyRef = getBusyDocRef(doc.id, evt.id)
                //     delete evt.isEventVote
                //     batch.set(busyRef, {
                //         ...evt,
                //         userIds: firebase.firestore.FieldValue.arrayUnion(user.user_id),
                //     }, {merge: true})
                // })

                // transfer vote by user anonymous
                const voteBoxAnonymousColl = getVoteBoxCollRef(doc.id)

                const voteBoxAnonymousSnap = await voteBoxAnonymousColl
                    .get()

                if (voteBoxAnonymousSnap.size) {
                    await Promise.all(voteBoxAnonymousSnap.docs.map(async vote => {
                        // let voted = vote.data()?.voted || {}
                        // if (voted[user.email] && voted[user.email] !== undefined) {
                        //     console.log(voted[user.email]);
                        //     let votedUser = voted[user.email]
                        //     const voteCollRef = db.doc(`${MEETING_COLLECTION}/${doc.id}/voteBoxColl/${vote.id}`)
                        //     delete voted[user.email]
                        //     voted[user.user_id] = votedUser
                        //     batch.set(voteCollRef, {
                        //         voted,
                        //         updatedAt: now
                        //     }, {merge: true})
                        //
                        //     // add event vote to calendar event
                        //     let eventDocRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/eventBoxMeeting/${vote.id}`)
                        //     batch.set(eventDocRef, {
                        //         ...vote.data(),
                        //         voted,
                        //         eventId: vote.id,
                        //         meetingId: meeting.id,
                        //         title: meeting.name,
                        //         updatedAt: now
                        //     }, {merge: true})
                        // } else {
                        // add event vote to calendar event
                        let eventDocRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/eventBoxMeeting/${vote.id}`)
                        batch.set(eventDocRef, {
                            ...vote.data(),
                            eventId: vote.id,
                            meetingId: meeting.id,
                            title: meeting.name,
                            updatedAt: now
                        }, {merge: true})
                        // }
                    }))
                }
                batch.set(groupEventRef, {anonymousUsers, userIds, updatedAt: now}, {merge: true})
            }))
        }
        // commit update all
        await batch.commit()
        setGlobal({
            eventTransfer: false
        })
        window.history.replaceState({}, 'event', `/event?id=${meetingId}`)
    } catch (e) {
        console.log(e.toString())
        toast.error(e.toString())
        return window.location = "/event"
    } finally {

    }
}
