import React, {memo} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, List, Tab, Tabs} from '@material-ui/core';
import {useGlobal} from 'reactn';
import ProjectSettings from './ProjectSettings';
import CalendarEventSetting from './CalendarEventSetting';
import AppointmentSyncSetting from './AppointmentGoogleSync';
import CalendarSyncSetting from "./CalendarGoogleSync";
import IdentitySettings from './IdentitySettings';
import VideoConferencing from './VideoConferencing';
import DialogCustom from '../Custom/DialogCustom';
import useIntlId from '../../hooks/useIntlId';
import MeetingSettingList from './SaveSettings/MeetingSettingList';
import NavigationSettingTab from "./Navigation/NavigationSettingTab";
import AnnouncementBar from "./AnnouncementBar";

const styles = theme => ({
    rootLeft: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: "#f1f1f1",
        padding: 6,
        borderRadius: "30px 0 0 30px"
    },
    nested: {
        paddingLeft: theme.spacing(4),
        borderTopRightRadius: ' 40px',
        borderBottomRightRadius: '40px',
        backgroundColor: '#E3F2FD'
    },
    root: {
        '& .MuiPaper-root': {
            paddingBottom: "15px",
            boxSizing: "content-box",
            overflow: "unset",
        },
        '& .MuiDialogTitle-root': {
            padding: 3,
            textAlign: "right",
        },
        "& .MuiDialog-paperFullWidth": {
            minWidth: 967,
            maxWidth: "auto",
            height: "calc(100% - 64px) !important",
            borderRadius: 15,
        },
        [theme.breakpoints.down('sm')]: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "auto",
            }
        }
    },
    root2: {
        height: "100%",
        overflowY: "auto",
    },
    paper: {
        textAlign: 'left',
        color: theme.palette.text.secondary,
        height: '100%',
    },
    textTabs: {
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: "bold",
        color: theme.props.primaryColor,
        lineHeight: 1.2,
        minHeight: "unset",
        minWidth: "unset",
        height: 42,
        marginBottom: 4,
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    selected: {
        background: theme.props.primaryColor,
        color: "#fff",
        borderRadius: "25px"
    },
    header: {
        position: "relative",
        lineHeight: "42px",
        display: "block",
        width: "100%",
        fontSize: "1.7rem"
    },

    displayTime: {
        display: 'flex'
    },
    displayTypo: {
        lineHeight: '40px', margin: 0
    },
    divider: {
        margin: "15px 10px 15px 0px", height: 3
    },
    iconAdd: {
        marginLeft: '50%', fontSize: 30,
        marginTop: 10
    },
    settingBox: {
        width: '99%',
        border: '3px solid black',
        padding: '5px',
        margin: '5px',
        display: 'flex'
    },
    boxLeft: {
        width: '35%'
    },
    boxRight: {
        width: '75%'
    },
    divTime: {
        display: 'flex',
        padding: 5,
    },
    divTimeText: {
        fontWeight: "bold",
        width: '12.5%'
    },
    divTimeValue: {
        margin: '0 7px',
        minWidth: '30px',
        fontWeight: 700
    },
    divTimeCancel: {
        fontWeight: 'bold',
        color: 'rgb(248, 147, 32)',
        display: 'flex',
        padding: 5,
        lineHeight: '30px'
    },
    divTimeCancelValue: {
        fontWeight: 700,
        color: 'rgb(248, 147, 32)',
        // marginRight: 10,
        letterSpacing: 0,
        whiteSpace: 'nowrap'
    },
    addDayOff: {
        border: "1px solid rgb(248, 147, 32)",
        color: 'rgb(248, 147, 32)',
        marginLeft: 'auto',
        height: "30px",
        lineHeight: '15px',
        borderRadius: 'inherit',
        minWidth: 75
    },
    buttonDelete: {
        color: 'rgb(248, 147, 32)',
        marginLeft: 'auto',
        height: "30px",
        lineHeight: '15px',
        borderRadius: 'inherit'
    },
    divExclude: {
        fontWeight: 'bold',
        display: 'flex',
        padding: 5,
        lineHeight: '40px'
    },
    remove: {
        padding: 10
    },
    removeDiv: {
        textAlight: 'center',
        padding: 10
    },
    removeBtn: {
        margin: 5
    },
    tabBox: {
        background: "#fff",
        borderRadius: "30px",
        padding: 6,
    },
    paperContent: {
        background: "#f1f1f1",
        borderRadius: "0 25px 25px 25px",
        // padding: 15,
    },
    containerPopup: {
        minWidth: "967px",
        height: "100%",
        padding: "15px 20px 10px 20px",
        overflowY: "auto"
    },
    none: {
        display: 'none'
    }
});

function ListSetting(props) {
    const {classes} = props;
    const [activeIndex, setActiveIndex] = useGlobal("settingTab")
    const [openSetting, setOpenSetting] = useGlobal('openSetting')
    const [settingSave, tasks, AppointmentGoogleSync, CalendarGoogleSync] = useIntlId(['event.settingButton', 'settings.eventsSetting', 'settings.appointment.googleSync', 'settings.calendar.googleSync'])

    const handleChange = (_, activeIndex) => {
        setActiveIndex(activeIndex)
    };
    const handleClose = () => {
        setOpenSetting(false);
    };
    const [videoConfIntl, projectsIntl, idIntl, globalNavi, listTabAnnouceBar] = useIntlId(['app.profile.videoconferencing', 'app.profile.projects', 'app.profile.identities', 'settings.tab.globalNavi', 'setting.listTab.annouceBar'])

    return (
        <div>
            <DialogCustom
                className={classes.root}
                open={openSetting}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"lg"}
                id={"dialogCustom"}
            >
                <div className={classes.root2}>
                    <Grid container className={classes.containerPopup}>
                        <Grid item xs={3}>
                            <div className={classes.paper}>
                                <List
                                    component="nav"
                                    className={classes.rootLeft}
                                >
                                    <VerticalTabs
                                        value={activeIndex}
                                        onChange={handleChange}
                                        className={classes.tabBox}
                                    >
                                        <Tab
                                            className={`${classes.textTabs + ' ' + (activeIndex === 0 ? classes.selected : null)} fontSourceHanSans`}
                                            label={tasks}
                                            // onClick={() => props.history.push('setting?key=events')}
                                        />
                                        <Tab
                                            className={`${classes.textTabs + ' ' + classes.none + ' ' + (activeIndex === 1 ? classes.selected : null)} fontSourceHanSans`}
                                            label={CalendarGoogleSync}
                                        />
                                        <Tab
                                            className={`${classes.textTabs + ' ' + (activeIndex === 2 ? classes.selected : null)} fontSourceHanSans`}
                                            label={AppointmentGoogleSync}
                                        />
                                        <Tab
                                            className={`${classes.textTabs + ' ' + (activeIndex === 3 ? classes.selected : null)} fontSourceHanSans`}
                                            label={videoConfIntl}
                                        />
                                        <Tab
                                            className={`${classes.textTabs + ' ' + (activeIndex === 4 ? classes.selected : null)} fontSourceHanSans`}
                                            label={projectsIntl}
                                        />
                                        <Tab
                                            className={`${classes.textTabs + ' ' + (activeIndex === 5 ? classes.selected : null)} fontSourceHanSans`}
                                            label={idIntl}
                                        />
                                        <Tab
                                            className={`${classes.textTabs + ' ' + (activeIndex === 6 ? classes.selected : null)} fontSourceHanSans`}
                                            label={settingSave}
                                        />
                                        <Tab
                                            className={`${classes.textTabs + ' ' + (activeIndex === 7 ? classes.selected : null)} fontSourceHanSans`}
                                            label={globalNavi}
                                        />
                                        <Tab
                                            className={`${classes.textTabs + ' ' + (activeIndex === 8 ? classes.selected : null)} fontSourceHanSans`}
                                            label={listTabAnnouceBar}
                                        />
                                    </VerticalTabs>
                                </List>
                            </div>

                        </Grid>
                        <Grid item xs={9}>
                            <div className={`${classes.paper} ${classes.paperContent}`}>
                                {/*{activeIndex === 0 &&*/}
                                {/*<DisplayTimeSetting/>*/}
                                {/*}*/}
                                {activeIndex === 0 &&
                                    <CalendarEventSetting/>}
                                {activeIndex === 1 &&
                                    <CalendarSyncSetting/>}
                                {activeIndex === 2 &&
                                    <AppointmentSyncSetting/>}
                                {activeIndex === 3 &&
                                    <VideoConferencing/>}
                                {/*{activeIndex === 4 &&*/}
                                {/*<CalendarView/>}*/}
                                {activeIndex === 4 &&
                                    <ProjectSettings/>}
                                {activeIndex === 5 &&
                                    <IdentitySettings/>}
                                {activeIndex === 6 &&
                                    <MeetingSettingList/>}
                                {activeIndex === 7 &&
                                    <NavigationSettingTab/>
                                }
                                {activeIndex === 8 &&
                                    <AnnouncementBar/>
                                }
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </DialogCustom>
        </div>
    )
}

const VerticalTabs = withStyles({
    flexContainer: {
        flexDirection: 'column'
    },
    indicator: {
        display: 'none',
    }
})(Tabs)


export default memo(withStyles(styles)(ListSetting))
