import {concat, findIndex, remove} from 'lodash'
import moment from "moment"
import toast from '../Page/toast'
import {getGlobal, setGlobal} from 'reactn'
import {CALENDAR_COLLECTION} from "../config/constants";
import {db} from "../firebaseConfig";
import {ggTokenChecked} from "../components/Calendar/google/auth";
import updateWatchChannelMyself from "../common/updateWatchChannelMyself";
import getUserTimeZone from "../common/moment/getUserTimeZone";
import {getMeetingCollRef, getUserDocRef} from "../common/firebaseRef/meetings";
import useIntlId from "../hooks/useIntlId";
import {myCalendarRef} from "../common/firebaseRef/calendar";
import store from "store";

export const RefreshGoogleToken = async () => {
    try {
        const res = await window.gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse()
        localStorage.setItem('googleAccessToken', res.access_token)
        return res.access_token
    } catch (e) {
        return null
    }
}

export const GCalendarList = async (firstload = false) => {
    const {googleStatus, calendarUser} = getGlobal()
    if (googleStatus.is_login) {
        try {
            // check token exists
            const checked = await ggTokenChecked()
            if (!checked)
                return
            const request = window.gapi.client.calendar.calendarList.list()
            const {status, result} = await request.then()
            if (status !== 200) {
                return
            }

            let groups = result.items
            remove(groups, g => {
                return g.id.includes('#contacts@group.v.calendar.google.com')
            })
            const {googleList = []} = calendarUser
            let newList = []

            googleList.forEach(id => {
                let idx = findIndex(groups, {id})
                if (idx !== -1) {
                    newList.push(groups[idx])
                    groups.splice(idx, 1)
                }
            })
            newList = concat(newList, groups).map(l => {
                return {
                    ...l
                }
            })
            await setNewList([...newList])
            // update summaryOverride doi voi nhung lich duoc chia se de hien thi dung ten tren calendar
            newList.map(async project => {
                if (project.accessRole !== "owner" && (
                    project?.summaryOverride === "true" ||
                    project?.summaryOverride === "false" ||
                    project?.summaryOverride?.includes("appointment") ||
                    project?.summaryOverride?.includes("milestone"))
                ) {
                    await checkedCalendar(project.id, project.selected)
                }
            })
        } catch (e) {
            console.log(e);
        }

    } else {
        console.log('No GGtoken be find')
    }
}

const setListCalendar = (list) => {
    remove(list, g => g.id.includes("#holiday"))
    setGlobal({
        googleCalendarList: list,
        // loading: false,
    }, () => {
        // bug: some time new value not be set
        if (getGlobal().googleCalendarList?.length !== list.length) {
            // try set again one time
            setListCalendar(list)
        }
    })
}

// export const GCalendarEvents = async (id, color, start, end) => {
//     // const { syncToken = []} = getGlobal()
//     const max = 2500
//     let result = [], token = null
//     try {
//         let encode_id
//         let holiday = false
//         if (id.includes('#holiday')) {
//             encode_id = encodeURIComponent(id)
//             holiday = true
//         } else {
//             encode_id = id
//         }
//         const request = window.gapi.client.calendar.events.list({
//             'calendarId': id,
//             "singleEvents": true,
//             "timeMin": start,
//             "timeMax": end,
//             maxResults: max
//         })
//         const data = await request.then()
//         console.log(data)
//         // const res = await axios.get(GET_GOOGLE_CALENDAR_EVENTS + `/${encode_id}/events?timeMin=${start}&timeMax=${end}&maxResults=${max}&singleEvents=true`)
//         result = GCalendarEventFormat(data.result.items, id, color, holiday)
//         token = data.result.nextSyncToken
//
//     } catch (e) {
//         console.log(e)
//     }
//
//     return {events: result, token: token}
//
// }
//
// export const ListCalendar = async () => {
//     const {calendarSelected} = getGlobal()
//     let googleAccessToken = localStorage.getItem('googleAccessToken')
//     if (googleAccessToken) {
//         try {
//             const res = await axios.get(GET_GOOGLE_CALENDAR_LIST)
//             if (res.data && res.data.items && res.data.items.length !== 0) {
//                 let resuit = []
//                 let arr = res.data.items
//                 remove(arr, g => {
//                     return g.id.includes('#contacts@group.v.calendar.google.com')
//                 })
//                 arr.forEach(cal => {
//                     let index = findIndex(calendarSelected, {"id": cal.id})
//                     resuit.push({...cal, checked: index === -1 ? false : true})
//                 })
//                 setGlobal({googleCalendarList: resuit})
//             }
//         } catch (e) {
//             console.log(e)
//         }
//     }
// }

export const GCalendarEventFormat = (events, group_uuid, color, holiday = false) => {
    const {GoogleColor} = getGlobal()
    const [noTitleIntl] = useIntlId(['setting.identityNoTitle'])
    let result = []
    events.forEach(e => {
        if (e?.start && e?.end) {
            let allDay = e.start.date
            let start = e.start.dateTime || e.start.date
            let end = e.end.dateTime || e.end.date
            let resourceId = allDay ? group_uuid : null
            let title = removeEmoji(e.summary, e?.extendedProperties?.private?.task || null).trim(" ")
            let event = {
                ...e,
                id: e.id,
                uuid: e.id,
                start,
                end,
                title: title || noTitleIntl,
                colorId: e?.colorId ? Number(e.colorId) : null,
                backgroundColor: e?.colorId ? GoogleColor[e.colorId - 1] : color,
                borderColor: color,
                allDay,
                resourceId,
                duration: (moment(end) - moment(start)) / (60000 * 60), // minutes
                calendar_uuid: group_uuid,
                project_uuid: group_uuid,
                googleEvent: true,
                editable: !holiday && !Boolean(e?.extendedProperties?.private?.meetingId),
                draggable: !holiday && !Boolean(e?.extendedProperties?.private?.meetingId),
                holiday: holiday,
                task: e?.extendedProperties && e.extendedProperties?.private?.task ? e.extendedProperties.private.task : null,
                isSuccess: e?.extendedProperties && e?.extendedProperties?.private?.is_success ? Boolean(parseInt(e.extendedProperties.private.is_success)) : false,
                pro_uuid: e?.extendedProperties?.private?.project_uuid || null,
                isMileStone: Boolean(e?.extendedProperties?.private?.task === "mileStone"),
                isEventVote: Boolean(e?.extendedProperties?.private?.meetingId) || Boolean(e?.extendedProperties?.private?.shareUrl),
                linkZoom: e?.extendedProperties && e?.extendedProperties?.private?.linkZoom ? e.extendedProperties.private.linkZoom : null,
                zoomMeetingId: e?.extendedProperties && e?.extendedProperties?.private?.zoomMeetingId ? e.extendedProperties.private.zoomMeetingId : null,
                meetingId: e?.extendedProperties?.private?.meetingId || null,
                isMilestoneHide: Boolean(e?.extendedProperties?.private?.isMilestoneHide === "true"),
                isMilestoneNextMonth: Boolean(e?.extendedProperties?.private?.isMilestoneNextMonth === "true"),
                conferenceData: e?.conferenceData || null,
                googleTasksID: e?.extendedProperties?.private?.googleTasksID || null,
            }
            if (e.recurrence && Array.isArray(e.recurrence)) {
                event.recurrence = e.recurrence[0]
            }
            if (event.isEventVote && event.meetingId) {
                event.isAccepted = true
            }
            result.push(event)
        }
    })
    return result
};

const removeEmoji = (title, task) => {
    const {emojis} = getGlobal()
    let result = title || ""
    if (task && title) {
        let index = findIndex(emojis, {'name': task})
        if (index !== -1) {
            result = title.split(emojis[index].emoji)[0]
        }
    }
    return result
}
const getEmoji = (task) => {
    const {emojis} = getGlobal()
    let result = ''
    let index = findIndex(emojis, {'name': task})
    if (index !== -1) {
        result = emojis[index].emoji
    }
    return result
}
export const RemoveGoogleCalendar = () => {
    const {groups} = getGlobal()
    let nextGroups = [...groups]
    remove(nextGroups, g => {
        return g.is_google === true
    })
    setGlobal({groups: nextGroups})

}

export const AddGoogleEvent = async (event) => {
    // console.log(event);
    try {
        let id
        await ggTokenChecked()
        let calendarId = event.project_uuid
        const batch = window.gapi.client.newBatch()
        let data = parseResource(event)
        const request = window.gapi.client.calendar.events.insert({
            'calendarId': calendarId,
            'resource': data
        })
        batch.add(request)
        // await batch.then()
        const {result} = await batch.then();
        Object.keys(result).forEach(key => {
            let data = result[key]
            if (data && data.status === 200) {
                id = data.result.id;
                if (event.conferenceData)
                    updateGoogleEvent(event, id)
            }
        });
        updateWatchChannelMyself(calendarId)
        return {...event, id: id}
    } catch (e) {
        return null
    }
};

export const updateGoogleEvent = async (event, id) => {
    let data = parseResource(event)
    try {
        await ggTokenChecked()
        const batch = window.gapi.client.newBatch()
        const request = window.gapi.client.calendar.events.patch({
            "calendarId": event.project_uuid,
            "eventId": id,
            "resource": data,
            "sendNotifications": true,
            "conferenceDataVersion": 1
        });

        batch.add(request)
        await batch.then()
        // updateBusyColl(event)
        updateWatchChannelMyself(event.project_uuid)
        // console.log('update ok')
        return event
    } catch (e) {
        toast.error("UPDATE GOOGLE EVENT ERROR ", {autoClose: 2000})
        return null
    }
}
export const changeTimeGoogleEvent = async (event) => {
    let data = parseResource(event)
    try {
        await ggTokenChecked()
        const batch = window.gapi.client.newBatch()
        const request = window.gapi.client.calendar.events.patch({
            "calendarId": event.project_uuid,
            "eventId": event.id,
            "resource": {
                start: data.start,
                end: data.end
            },
            "sendNotifications": true,
            "conferenceDataVersion": 1
        });

        batch.add(request)
        await batch.then()
        // updateBusyColl(event)
        updateWatchChannelMyself(event.project_uuid)
        // console.log('update ok')
        return event
    } catch (e) {
        // toast.error("UPDATE GOOGLE EVENT ERROR ", {autoClose: 2000})
        return null
    }
}
export const getColorId = (color) => {
    let id = null
    if (color) {
        const {GoogleColor} = getGlobal()
        GoogleColor.forEach((value, index) => {
            if (value.toLowerCase() === color.toLowerCase()) {
                id = index + 1
            }
        })
    }
    return id
};

export const parseResource = event => {
    let emoji = getEmoji(event.task)
    if (event.colorId && event.colorId > 11)
        event.colorId = null

    let data = {
        "summary": event.title + emoji,
        "colorId": event.colorId || getColorId(event.backgroundColor),
        "extendedProperties": {
            "private": {
                "task": event.task || '',
                "is_success": event.isSuccess ? 1 : 0,
                "meetingId": event?.meetingId || "",
                "isMilestoneHide": event?.isMilestoneHide ? 'true' : 'false',
                "isMilestoneNextMonth": event?.isMilestoneNextMonth ? 'true' : 'false',
                "linkZoom": event?.linkZoom || '',
                "zoomMeetingId": event?.zoomMeetingId || '',
                "googleTasksID": event?.googleTasksID || '',
                "scheduleId": event?.scheduleId || '',
                "isEventVote": event?.isEventVote ? 'true' : 'false',
                "email": event?.email || '',
                "emailVoter": event?.emailVoter || '',
                "name": event?.name || '',
            }
        },
        "description": event.description || '',
        "location": event.location || ''
    };
    if (event.allDay) {
        let startDate = moment(event.start).format('YYYY-MM-DD')
        let endDate = moment(event.end).format('YYYY-MM-DD')
        data.start = {
            date: startDate,
            dateTime: null
        }
        data.end = {
            date: endDate !== startDate ? endDate : moment(event.end).add(1, "day").format('YYYY-MM-DD'),
            dateTime: null
        }
    } else {
        let startDate = moment(event.start).format()
        let endDate = moment(event.end).format()
        data.start = {
            dateTime: startDate,
            date: null
        }
        data.end = {
            dateTime: endDate,
            date: null
        }
    }

    // recurring
    if (event.recurrence) {
        data.recurrence = [event.recurrence]
        const timeZone = getUserTimeZone();
        if (event.allDay) {
            data.start.timeZone = null
            data.end.timeZone = null
        } else {
            data.start.timeZone = timeZone
            data.end.timeZone = timeZone
        }
    } else {
        data.recurrence = null
    }
    // check if create new Zoom meeting then add to google event Zoom meeting data, not old
    if (event.conferenceData && event.conferenceData.join_url) {
        let zoomValue = event.conferenceData;
        data.conferenceData = {
            "entryPoints": [
                {
                    "entryPointType": "video",
                    "uri": zoomValue.join_url,
                    "label": zoomValue.join_url,
                    "meetingCode": zoomValue.id,
                    "password": zoomValue.password
                }
            ],
            "conferenceSolution": {
                "key": {
                    "type": "addOn"
                },
                "name": "Zoom Meeting",
                "iconUri": "https://lh3.googleusercontent.com/ugWKRyPiOCwjn5jfaoVgC-O80F3nhKH1dKMGsibXvGV1tc6pGXLOJk9WO7dwhw8-Xl9IwkKZEFDbeMDgnx-kf8YGJZ9uhrJMK9KP8-ISybmbgg1LK121obq2o5ML0YugbWh-JevWMu4FxxTKzM2r68bfDG_NY-BNnHSG7NcOKxo-RE7dfObk3VkycbRZk_GUK_1UUI0KitNg7HBfyqFyxIPOmds0l-h2Q1atWtDWLi29n_2-s5uw_kV4l2KeeaSGws_x8h0zsYWLDP5wdKWwYMYiQD2AFM32SHJ4wLAcAKnwoZxUSyT_lWFTP0PHJ6PwETDGNZjmwh3hD6Drn7Z3mnX662S6tkoPD92LtMDA0eNLr6lg-ypI2fhaSGKOeWFwA5eFjds7YcH-axp6cleuiEp05iyPO8uqtRDRMEqQhPaiRTcw7pY5UAVbz2yXbMLVofojrGTOhdvlYvIdDgBOSUkqCshBDV4A2TJyDXxFjpSYaRvwwWIT0JgrIxLpBhnyd3_w6m5My5QtgBJEt_S2Dq4bXwCAA7VcRiD61WmDyHfU3dBiWQUNjcH39IKT9V1fbUcUkfDPM_AGjp7pwgG3w9yDemGi1OGlRXS4pU7UwF24c2dozrmaK17iPaExn0cmIgtBnFUXReY48NI8h2MNd_QysNMWYNYbufoPD7trSu6nS39wlUDQer2V"
            },
            "conferenceId": zoomValue.id,
            "notes": "Passcode: " + zoomValue.password,
            "parameters": {
                "addOnParameters": {
                    "parameters": {
                        "realMeetingId": zoomValue.id,
                        "meetingCreatedBy": zoomValue.host_email,
                        "meetingUuid": zoomValue.uuid,
                        "meetingType": zoomValue.type
                    }
                }
            }
        }
    } else if (event.conferenceData && event.conferenceData.conferenceId) {
        data.conferenceData = event.conferenceData
    } else
        data.conferenceData = '';
    // console.log({data});
    return data
}
const getEventForSync = async (uuid) => {
    try {
        // get all events of project
        const {user} = getGlobal()
        const snapEvents = await db.collection(CALENDAR_COLLECTION)
            .doc(user.user_id)
            .collection('events')
            .where("project_uuid", "==", uuid)
            .where("isTasks", "==", false)
            .where("isDeleted", "==", false)
            .get()
        return snapEvents.docs.map(doc => ({...doc.data(), id: doc.id}))

    } catch (e) {
        console.log(e)
        return null
    }
}
const updateProjectSync = async (projectId, googleCalendarId, email) => {
    const {user} = getGlobal()
    return await db.collection(CALENDAR_COLLECTION)
        .doc(user.user_id)
        .collection('projects')
        .doc(projectId)
        .set({
            sync_id: googleCalendarId,
            sync_email: email,
            is_sync: true
        }, {merge: true})
}
//
// const updateCalendarUserTimeExpUrl = async (oldProjectId = "", newProjectId = "") => {
//     try {
//         const {user} = getGlobal()
//         const calendarUserRef = db.collection(CALENDAR_COLLECTION).doc(user.user_id)
//         const doc = await calendarUserRef.get()
//         const data = doc.data();
//         if (data?.timeExpUrl?.project === oldProjectId) {
//             const timeExpUrl = data?.timeExpUrl
//             if (newProjectId) {
//                 timeExpUrl.project = newProjectId;
//                 timeExpUrl.isGoogle = true;
//             }
//             await calendarUserRef.set({timeExpUrl}, {merge: true})
//         }
//         const listSchedule = await calendarUserRef.collection('scheduleAdjustment').get()
//         if (!listSchedule.size) return null;
//         await Promise.all(listSchedule.docs.map(async doc => {
//             const scheduleData = doc.data();
//             if (!newProjectId || scheduleData?.isGoogle || scheduleData.project !== oldProjectId) return null;
//             const ref = calendarUserRef.collection('scheduleAdjustment').doc(doc.id)
//             await ref.set({project: newProjectId, isGoogle: true}, {merge: true})
//         }))
//         return null
//     } catch (e) {
//         console.log(e)
//         return null;
//     }
// }

export const updateProjectSyncMeetings = async (oldProjectId = "", newProjectId = '') => {
    try {
        const {user} = getGlobal()
        // console.log({user})
        // get all meeting of user
        const meetingRef = getMeetingCollRef()
            .where("isDeleted", '==', false)
            .where('userIds', "array-contains", user.user_id)
        const listMeetings = await meetingRef.get()
        if (!listMeetings.size) return null

        await Promise.all(listMeetings.docs.map(async (doc) => {
            const ref = getUserDocRef(doc.id, user.user_id)
            const userGet = await ref.get()
            const data = userGet.data()
            if (data?.project?.uuid !== oldProjectId) return null
            // check user setting project sync
            if (newProjectId) {
                data.project.uuid = newProjectId;
                data.project.is_google = true;
            } else data.project = null
            await ref.set({project: data.project}, {merge: true})
            // console.log({project: data.project, meetId: doc.id, oldProjectId, newProjectId})
        }))
        return null
    } catch (e) {
        console.log(e)
        return null
    }
}

export const Sync = async uuid => {
    const {projects, googleStatus} = getGlobal()
    let idx = findIndex(projects, {'uuid': uuid})
    if (idx !== -1) {
        try {
            setGlobal({inSync: true});
            setGlobal({syncProcess: 'Create Calendar '});
            const backgroundColor = projects[idx].color;
            const eventList = await getEventForSync(projects[idx].id || projects[idx].uuid);
            await ggTokenChecked();
            let request = window.gapi.client.calendar.calendars.insert({
                summary: projects[idx].name
            });
            let data = await request.then();
            if (data.status !== 200) {
                toast.error("create google calendar fail")
                return
            }
            const calendarId = data.result.id;
            request = window.gapi.client.calendar.calendarList.patch({
                "calendarId": calendarId,
                "colorRgbFormat": true,
                backgroundColor
            });

            setGlobal({syncProcess: 'Update calendar'})

            data = await request.then()
            if (data.status !== 200) {
                toast.error("update color fail")
                return
            }
            // sync all events to google
            setGlobal({syncProcess: 'Sync events'})
            if (eventList.length === 0) {
                await success(projects[idx], calendarId, googleStatus)
                return
            }
            const batch = window.gapi.client.newBatch()
            eventList.forEach(event => {
                let data = parseResource(event);
                let request = window.gapi.client.calendar.events.insert({
                    'calendarId': calendarId,
                    'resource': data
                })
                batch.add(request)
            })
            const {status} = await batch.then()
            if (status === 200) {
                await success(projects[idx], calendarId, googleStatus)
                return
            }
            setGlobal({inSync: false})
            toast.error('Sync fail. Please try again')

        } catch (e) {
            console.log(e);
            setGlobal({inSync: false})
            toast.error('Sync fail. Please try again')
        }
    }
}

const success = async (project, calendarId, googleStatus) => {
    await updateProjectSync(project?.uuid, calendarId, googleStatus?.userProfile?.email ? googleStatus?.userProfile?.email : null)
    // await updateProjectSyncMeetings(project?.uuid, calendarId)
    // await updateCalendarUserTimeExpUrl(project?.uuid, calendarId)
    await setGlobal({syncProcess: 'remove geniam calendar'})
    await GCalendarList()
    await setGlobal({syncProcess: 'complete...', inSync: false})
    return null
}

export const checkedCalendar = async (calendarId, info) => {
    try {
        await ggTokenChecked();
        const request = window.gapi.client.calendar.calendarList.update({
            "calendarId": calendarId,
            "summaryOverride": "",
            "selected": info?.selected || false
        })

        const {status, result} = await request.then()
        if (status !== 200) {
            console.log(result)
            // toast.error("Update gg calendar fail")
            return
        }
        return result
    } catch (e) {
        console.log(e);
    }
}

export const setNewList = async (newList, check = false) => {
    const {calendarUser} = getGlobal()
    if (newList.length > 0 && calendarUser?.uuid) {
        let listShow = calendarUser?.listGgCalenviewShow;
        if (listShow && listShow.length > 0) {
            let checked = false
            listShow.forEach(item => {
                if (typeof item === "string") checked = true
            })
            if (checked) return updateListGgCalenviewShow([])
            listShow.forEach((doc, ind) => {
                doc.index = ind + 1
                listShow[ind] = doc
            })
            newList = newList.map(t1 => ({...t1, ...listShow.find(t2 => t2.id === t1.id)}))
            newList.forEach((doc, ind) => {
                let cc = listShow.findIndex(item => item.id === doc.id)
                if (cc > -1) {
                    doc.selected = listShow[cc].check
                } else {
                    delete doc.checkbox
                    delete doc.check
                    delete doc.selected
                }
                newList[ind] = doc
            })
        } else {
            setGlobal({
                showDialogSelectCalendar: true
            })
        }
        setListCalendar(newList)
        store.set("GGList", newList)
    }
    return newList
}

export const updateListGgCalenviewShow = async (list) => {
    return await myCalendarRef()
        .set({
            listGgCalenviewShow: list
        }, {merge: true})
}
