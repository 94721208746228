import React, {useEffect, useState} from 'react'
import {CirclePicker} from "react-color"
import {Grid, Popover, Tooltip} from '@material-ui/core'
import {useKeyPress} from "../UseReact/UseKeyPress"

function SelectColor(props) {
    const {values, setValues, setAnchorEl, anchorEl} = props
    const [color, setColor] = useState(null)
    // const [oldColor, setOldColor] = useState(null)
    const open = Boolean(anchorEl)
    const arrColor = [
        "#88304e", "#533546", "#311d3f",
        "#f44336", "#e91e63", "#9c27b0",
        "#673ab7", "#3f51b5", "#2196f3",
        "#03a9f4", "#00bcd4", "#009688",
        "#4caf50", "#8bc34a", "#cddc39",
        "#ffeb3b", "#ffc107", "#ff9800",
        "#ff5722", "#795548", "#607d8b",
        "#616161", "#424242", "#212121",
    ]

    const handleUpColor = () => {
        if (anchorEl) {
            if (color) {
                let check = false
                let arr = arrColor.entries()
                for (let [index, value] of arr) {
                    if (color === value) {
                        if ((index - 11) >= 0) {
                            setColor(arrColor[index - 11])
                        } else {
                            const calLength = (arrColor.length % 11)
                            let calIndex = 0
                            if ((index + 1) > calLength) {
                                calIndex = (arrColor.length - calLength) - (11 - (index + 1))
                            } else {
                                calIndex = arrColor.length + (11 - calLength) - (11 - (index + 1))
                            }
                            setColor(arrColor[calIndex - 1])
                        }
                        check = true
                        break
                    }
                }

                if (!check) {
                    setColor(arrColor[0])
                }
            } else {
                setColor(arrColor[0])
            }
        }
    }
    const handleDownColor = () => {
        if (anchorEl) {
            if (color) {
                let check = false
                let arr = arrColor.entries()
                for (let [index, value] of arr) {
                    if (color === value) {
                        const calLength = (arrColor.length % 11)
                        if ((index + 11) < (arrColor.length)) {
                            setColor(arrColor[index + 11])
                        } else {
                            let calIndex = 0
                            if (index < (arrColor.length - calLength)) {
                                calIndex = 11 - (arrColor.length - calLength) + (index + 1)
                            } else {
                                calIndex = 11 - (arrColor.length - calLength + 11) + (index + 1)
                            }
                            setColor(arrColor[calIndex - 1])
                        }
                        check = true
                        break
                    }
                }

                if (!check) {
                    setColor(arrColor[0])
                }
            } else {
                setColor(arrColor[0])
            }
        }
    }
    const handleNextColor = () => {
        if (anchorEl) {
            if (color) {
                let check = false
                let arr = arrColor.entries()
                for (let [index, value] of arr) {
                    if (color === value) {
                        if ((index + 1) === arrColor.length) {
                            setColor(arrColor[0])
                        } else {
                            setColor(arrColor[index + 1])
                        }
                        check = true
                        break
                    }
                }

                if (!check) {
                    setColor(arrColor[0])
                }
            } else {
                setColor(arrColor[0])
            }
        }
    }
    const handleBackColor = () => {
        if (anchorEl) {
            if (color) {
                let check = false
                let arr = arrColor.entries()
                for (let [index, value] of arr) {
                    if (color === value) {
                        if (index === 0) {
                            setColor(arrColor[arrColor.length - 1])
                        } else {
                            setColor(arrColor[index - 1])
                        }
                        check = true
                        break
                    }
                }

                if (!check) {
                    setColor(arrColor[0])
                }
            } else {
                setColor(arrColor[0])
            }
        }
    }
    const handleMenuClose = () => {
        if (anchorEl) {
            setAnchorEl(null)
        }
        setColor(null)
    }
    const handleChangeColor = (value) => {
        setColor(value.hex)
        handleMenuClose()
    }

    useKeyPress('ArrowUp', handleUpColor, [color], Boolean(anchorEl))
    useKeyPress('ArrowDown', handleDownColor, [color], Boolean(anchorEl))
    useKeyPress('ArrowRight', handleNextColor, [color], Boolean(anchorEl))
    useKeyPress('ArrowLeft', handleBackColor, [color], Boolean(anchorEl))
    useKeyPress('Enter', handleMenuClose, [color], Boolean(anchorEl))

    useEffect(() => {
        if (open) {
            setColor(values.projectColor)
            // setOldColor(values.projectColor)
        }
        // eslint-disable-next-line
    }, [open])
    useEffect(() => {
        if (color) {
            setValues({...values, projectColor: color})
        }
        // eslint-disable-next-line
    }, [color])

    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Tooltip
                title={
                    <React.Fragment><p style={{textAlign: "center", margin: 0}}>
                        SELECT COLOR<br/>
                        Shortcuts: Key up, Key down, Key left, Key right<br/>
                        selected: enter
                    </p></React.Fragment>
                }
                placement="top"
            >
                <Grid container style={{margin: 10}} alignItems={"center"}>
                    <CirclePicker
                        circleSize={17}
                        circleSpacing={3}
                        color={values.projectColor}
                        colors={arrColor}
                        onChangeComplete={(color) => handleChangeColor(color)}
                    />
                </Grid>
            </Tooltip>
        </Popover>
    )
}

export default SelectColor
