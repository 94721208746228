import {getUserTimeRange} from "./getUserTimeRange";
import {uniq} from "lodash";
import {changeTz} from "../utils/time";

const getMySetting = (u = {}) => {
    let start, end, time_zone
    let workTime = {
        start: u?.workTime?.start || 0,
        end: u?.workTime?.end || 24
    }
    time_zone = u?.time_zone
    let hiddenDays = u?.hiddenDays || []
    let dateBusy = u?.dateBusy || []
    let freeTime = u?.freeTime || 0
    const {startDay, endDay} = getUserTimeRange(u)
    start = changeTz(startDay, time_zone)
    end = changeTz(endDay, time_zone)

    return {
        id: u?.id || u?.user_id,
        start, end,
        hiddenDays: uniq(hiddenDays),
        dateBusy: uniq(dateBusy),
        workTime,
        freeTime,
        time_zone
    }
}

export default getMySetting;

