import React, {createContext, useEffect, useState} from "react";
import Fire from "../firebaseConfig";
import moment from "moment";
import {useGlobal} from 'reactn'
import LangSetting from "../components/LangSetting";
import {isEmpty} from 'lodash'
import store from 'store'

export const LanguageMessageContext = createContext()

export const LanguageMessageProvider = ({children}) => {

    const msg = useMessages()

    if (isEmpty(msg))
        return <LangSetting/>
    return (
        <LanguageMessageContext.Provider value={msg}>
            {children}
        </LanguageMessageContext.Provider>
    )
}

const useMessages = () => {
    const [user] = useGlobal('user')
    const [language] = useGlobal('language')
    const lang = user?.language || language
    const localLange = store.get('langMsg')
    const [m, setM] = useState(localLange ? (localLange[lang] || null) : null);
    useEffect(() => {
        if (!lang) return
        // if (!m) {
        Fire.firestore().doc(`/intl/calendarIntl/langColl/${lang.toLowerCase()}`)
            .get()
            .then(doc => {
                // console.log('m loaded', doc.data())
                const langMsg = {...doc.data()}
                setM(langMsg)
                store.set('langMsg', {[lang]: langMsg, expired: moment().add(1, 'day')})
            })
            .catch(e => {
                // FirebaseError: Failed to get document because the client is offline
                window.location.reload();
            })
        // }
        let mLocale = lang.split('-')?.[0]
        if (mLocale === 'en') mLocale = 'en-gb'
        import(`moment/locale/${mLocale}`)
            .then(obj => {
                moment.locale(mLocale)
            })
            .catch(e => console.error('import lang error,', e))
        // eslint-disable-next-line
    }, [lang])
    return m
}
