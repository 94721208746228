import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Button} from "antd";

const useStyles = makeStyles(theme => ({
    primary: {
        marginRight: 2,
        minWidth: 125,
        minHeight: 35,
        fontWeight: '700',
        color: '#fff',
        fontSize: '1.3rem',
        borderRadius: 25,
        background: theme.props.primaryColor,
        borderColor: theme.props.primaryColor,
        '&:hover': {
            // background: '#3991e3 !important',
            background: theme.props.primaryColor,
            opacity: 0.8,
            borderColor: 'initial',
            color: '#FFF'
        },
        '&:focus': {
            background: theme.props.primaryColor,
            opacity: 0.8,
            borderColor: 'initial',
            color: '#FFF'
        }
    },
    secondary: {
        marginRight: 2,
        minWidth: 125,
        minHeight: 35,
        fontWeight: '700',
        color: '#fff',
        fontSize: '1.3rem',
        borderRadius: 25,
        background: theme.props.secondaryColor,
        borderColor: theme.props.secondaryColor,
        '&:hover': {
            background: theme.props.secondaryColor,
            opacity: 0.8,
            borderColor: 'initial',
            color: '#FFF'
        },
        '&:focus': {
            background: theme.props.secondaryColor,
            opacity: 0.8,
            borderColor: 'initial',
            color: '#FFF'
        }
    }
}));

function ButtonAntdComponent({
                                 display,
                                 iconComponent,
                                 name,
                                 onClick,
                                 size,
                                 typeClass,
                                 disable = false,
                                 loading,
                                 width,
                                 height,
                                 key,
                                 margin, padding,
                                 block = false,
                                 className,
                                 minWidth,
                                 minHeight
                             }) {
    const classes = useStyles();

    return (
        <Button
            className={`${classes[typeClass]} ${display} ${className}`}
            icon={iconComponent}
            size={size}
            onClick={onClick}
            disabled={disable}
            loading={loading}
            style={{width, height, margin, padding, minWidth, minHeight}}
            key={key}
            block={block}
        >
            {name}
        </Button>
    )
}

export default ButtonAntdComponent;
