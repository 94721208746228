import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import React from 'react';
import {ReactComponent as NewSVG} from '../../asSvg/new.svg';

export default function AddButton({onClick, ...others}) {
    return (
        <CLFButtonSVG
            iconComponent={<NewSVG/>}
            onClick={onClick}
            {...others}
        />
    )
}
