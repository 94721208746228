import {concat, filter, findIndex, isEmpty, isNumber, orderBy, remove, uniq} from "lodash";
import {db} from "../../../firebaseConfig";
import {CALENDAR_COLLECTION, MEETING_COLLECTION} from "../../../config/constants";
import moment from "moment";
import {getGlobal} from 'reactn'
import {adjustableMinMaxTime, freeSlot} from "../Fullcalendar/Actions/freeSlot";
import {v4 as uuidv4} from "uuid";
// import {ggTokenChecked} from "../../Calendar/google/auth";
// import {getGeniamEventsFromReccrence} from "../../../context/common/getGoogleEvents";
import momentTz from "moment-timezone";
import store from 'store';
import {getAllUsersProjectsColl, getCalendarProjectsDocRef} from "../../../common/firebaseRef/meetings";
import {rrulestr} from "rrule";
import {message} from 'antd';
// import validEvent from "../../../common/validEvent";

// const getTaskFOfUser = (idUser, usersTaskFree) => {
//     const userTask = usersTaskFree.find(usrT => usrT && usrT.id === idUser);
//     if (!userTask) return []
//     return userTask.taskFree;
// }

// export const checkTaskFree = (events, usersTaskFree) => {
//     // console.log(usersTaskFree)
//     if (!events || events.length === 0 || usersTaskFree?.length === 0) return events;
//     for (let i = 0; i < events.length; i++) {
//         const evt = events[i];
//         if (!evt) continue;
//         const tasksF = getTaskFOfUser(evt?.userId, usersTaskFree)
//         if (tasksF.includes(evt.task)) events[i].isTaskFree = true;
//         if (i >= 1 && events[i].eventId === events[i - 1].eventId && events[i].isTaskFree !== events[i - 1].isTaskFree) {
//             events[i].isTaskFree = false;
//             events[i - 1].isTaskFree = false;
//         }
//     }
//     return events;
// }


export const checkUserTaskFree = (events = [], taskFree = []) => {
    if (!events || events.length === 0) return [];
    for (let i = 0; i < events.length; i++) {
        const evt = events[i];
        if (!evt) continue;
        if (taskFree.includes(evt?.task)) events[i].isTaskFree = true;
        else events[i].isTaskFree = false;
    }
    return events;
}

export const getUserInUsers = (users, userId) => {
    if (!userId || !users?.length) return null
    for (let i = 0; i < users.length; i++) {
        if (users[i].id === userId) return users[i];
    }
    return null
}

export const getRole = (users, meeting = null) => {
    const {user} = getGlobal()
    const anonymousEmail = store.get('anonymousEmail') || null
    if (meeting && user.user_id === meeting?.owner) {
        return "owner"
    }
    let role = 'viewer'
    let idx = findIndex(users, {id: user.user_id})
    if (idx !== -1) {
        role = users[idx].role || "viewer"
    }
    if (isEmpty(user) && anonymousEmail) {
        let index = findIndex(users, {id: anonymousEmail})
        if (index !== -1)
            role = users[index].role || 'viewer'
    }
    return role
}
export const getEditMode = users => {
    const {user} = getGlobal()
    let result = false
    let idx = findIndex(users || [], {id: user.user_id})
    if (idx !== -1) {
        result = !Boolean(users[idx].isPublish)
    }
    return result
}

export const getEvents = (userId, id, calback) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('meetings')
        .doc(id)
        .collection('events')
        .onSnapshot(snapshot => {
            const events = snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                rendering: "background",
                backgroundColor: "#009A4C"
            }))
            if (calback)
                calback(events)
        })
}

export const getUsersMeeting = (meeting, setUserJoins) => {
    if (!meeting)
        return
    return db.collection(CALENDAR_COLLECTION)
        .doc(meeting.owner)
        .collection('meetings')
        .doc(meeting.id)
        .collection('users')
        .onSnapshot(snapshot => {
            let usersData = []
            snapshot.docs.forEach(doc => {
                usersData.push({...doc.data(), id: doc.id})
            })
            if (setUserJoins)
                setUserJoins(usersData)
        })
}

export const mySettings = (meeting) => {
    if (!meeting)
        return null
    const {user} = getGlobal()
    const {user_id} = user
    const index = findIndex(meeting.users, u => u.id === user_id)
    if (index !== -1)
        return {...meeting.users[index]}
    return null
}

export const myEvent = (events) => {
    const {user} = getGlobal()
    const {user_id} = user
    let result = []
    if (!user_id)
        return []
    events.forEach(evt => {
        if (((evt?.userId && evt?.userId === user_id) || (evt?.userIds && evt.userIds?.includes(user_id))) && !evt?.isSync) {
            result.push({...evt, editable: false, backgroundColor: "#808080", rendering: null})
        }
    })
    return result
}

export const eventsVote = (events, userId) => {
    return events.map(evt => {
        // const voted = evt.voted || {}
        let backgroundColor = "#f89320"
        return ({...evt, editable: false, rendering: null, backgroundColor})
    })
}

export const generalFree = (events, meeting, users, anonymousList = []) => {
    const userPublish = filter(concat(users, anonymousList), u => (u.isPublish || u.isAnonymous) && u.isChecked)
    if (events.length === 0)
        return []
    if (userPublish.length === 0)
        return []
    let hiddenDays = []
    let dateBusy = []
    userPublish.forEach(usr => {
        hiddenDays = concat(hiddenDays, usr.hiddenDays || [])
        dateBusy = concat(dateBusy, usr.dateBusy || [])
    })
    hiddenDays = uniq(hiddenDays)
    dateBusy = uniq(dateBusy)

    let start, end

    userPublish.forEach(u => {
        let userStart
        let userEnd
        if (u.isAnonymous)
            return;
        if (u.typeTimeSharing && u.isChecked && u.joined) {
            if (u.typeTimeSharing === "normal") {
                userStart = u.startDay
                userEnd = u.endDay
            } else {
                const DateRangeCalendar = getDateRange(u.timeRange || [0, 1])
                userStart = DateRangeCalendar.start
                userEnd = moment(DateRangeCalendar.end).subtract(1, "days")
            }
        }
        if (!start || moment(userStart).isAfter(start)) {
            start = userStart
        }
        if (!end || moment(userEnd).isBefore(end)) {
            end = userEnd
        }
    })

    if (moment(start).isAfter(end))
        return []
    // just 1 user
    if (userPublish.length === 1) {
        let result = freeSlot(start, end, getUserEvents(events, userPublish[0].id, meeting?.id), "#339933", userPublish[0]).map(e => ({
            ...e,
            rendering: "background",
            generalFree: true
        }))
        remove(result, e => isOffDay(hiddenDays, e) || dateBusy.includes(moment(e.start).format('YYYY-MM-DD')) || e.meetingId === meeting?.id)
        return result
    }

    // 2 and more user
    let startHour = 0, endHour = 24
    userPublish.forEach(u => {
        let userStart = u?.workTime?.start || 0
        let userEnd = u?.workTime?.end || 24
        if (userStart > startHour) {
            startHour = userStart
        }
        if (userEnd < endHour) {
            endHour = userEnd
        }
    })
    const lastedEvents = formatBeforeAfterEvents(events, userPublish, meeting?.id)
    let result = freeSlot(start, end, lastedEvents, "#339933", {
        workTime: {start: startHour, end: endHour},
        freeTime: 0
    }).map(e => ({
        ...e,
        rendering: "background",
        generalFree: true
    }))
    remove(result, e => isOffDay(hiddenDays, e) || dateBusy.includes(moment(e.start).format('YYYY-MM-DD')) || e.meetingId === meeting?.id)
    return result
}

const formatBeforeAfterEvents = (events, users) => {
    let result = []
    users.forEach(u => {
        const eventUsers = getUserEvents(events, u.id)
        if (!u.freeTime) {
            result.push(eventUsers)
        } else {
            result.push(formatBeforeAfterUserEvent(eventUsers, Number(u.freeTime)))
        }
    })
    return result.flat()
}

const formatBeforeAfterUserEvent = (events, interval) => {
    return events.map(e => {
        // if (e.isEventVote) return e
        return ({
            ...e,
            start: moment(e.start).subtract(interval, "minutes").format(),
            end: moment(e.end).add(interval, "minutes").format(),
        })
    })
}

const getUserEvents = (events, userId) => {
    return filter(events, e => (e.userId === userId || e.isEventVote) && !e?.isSync)
}

export const onEventTime = (events, start, end) => {
    let result = []
    events.forEach(evt => {
        if (moment(evt.start).isBetween(start, end) || moment(evt.end).isBetween(start, end)) {
            result.push(evt)
        }
    })
    return result
}

export const adjustmentEvents = (events, userInfo) => {
    const {workTime = {start: 7, end: 24}, freeTime, hiddenDays = []} = userInfo
    let nextEvents = [...events]
    remove(nextEvents, evt => isOffDay(hiddenDays, evt))
    return beforeAfterTime(adjustableMinMaxTime(nextEvents, workTime.start, workTime.end), parseInt(freeTime || 0), workTime)
}

const beforeAfterTime = (events = [], freeTime, workTime) => {
    let result = []
    events.forEach(evt => {
        if (Boolean(evt.isUserEdit)) {
            result.push(evt)
            return
        }
        let start = moment(evt.start).local().hours() !== workTime.start ? moment(evt.start).add(freeTime, "minutes").format() : evt.start
        let end = moment(evt.end).local().hours() !== workTime.end - 1 ? moment(evt.end).subtract(freeTime, "minutes").format() : evt.end
        if (moment(start).isBefore(end)) {
            result.push({...evt, start, end})
        }
    })
    return result
}

export const isOffDay = (hiddenDays = [], event) => {
    return (hiddenDays.includes(moment(event.start).local().isoWeekday()))
}

export const isExistEvents = (events, event, freeTime) => {
    let result = false
    let nextEvents = [...events]
    if (nextEvents?.length === 0) return result;
    remove(nextEvents, evt => Boolean(evt.allDay) || evt.isBusyBox)
    nextEvents.forEach(evt => {
        if (evt.checkFree) return
        let evtStart = moment(evt.start).subtract(freeTime, "minutes").format()
        let evtEnd = moment(evt.end).add(freeTime, "minutes").format()
        if (moment(event.start).isBetween(evtStart, evtEnd) || moment(event.end).isBetween(evtStart, evtEnd) ||
            (moment(event.start).isBefore(evtStart) && moment(event.end).isAfter(evtEnd)) ||
            (moment(event.start).isSame(evtStart) && moment(event.end).isSame(evtEnd))
        ) {
            result = true
        }
    })
    return result
}
export const isOverlapsExistsEvents = (events, event, freeTime) => {
    let result = false
    let nextEvents = [...events]
    if (nextEvents?.length === 0) return result;
    let orderEvents = orderBy(nextEvents, (e) => moment(e.start).format(), 'asc')
    const newEvent = {...event}
    for (let i = 0; i < orderEvents?.length; i++) {
        const evt = orderEvents[i];
        if (evt.checkFree) continue;
        let busyStart = moment(evt.start).subtract(freeTime, "minutes").format()
        let busyEnd = moment(evt.end).add(freeTime, "minutes").format()
        if ((moment(busyStart).isBetween(newEvent.start, newEvent.end)) || moment(newEvent.end).isSame(busyEnd)) {
            // if(!moment(busyEnd).isAfter(event.end))
            result = {
                ...newEvent,
                end: busyStart,
                endStr: busyStart
            }
            newEvent.end = busyStart
            newEvent.endStr = busyStart

        }
        if ((moment(busyEnd).isBetween(newEvent.start, newEvent.end) || moment(newEvent.start).isSame(busyStart))) {
            result = {
                ...newEvent,
                start: busyEnd,
                startStr: busyEnd
            }
            newEvent.start = busyEnd
            newEvent.startStr = busyEnd
        }
    }

    if (result) {
        const num = moment(result.end).diff(result.start, "minutes")
        return (num > 29) ? result : true
    }
    return result
}

export const checkVoteInMeet = (meetId, evt) => {
    if (!evt || !meetId) return false;
    return evt.meetingId && evt.meetingId === meetId;
}

export const removeUnSelectedProject = (event, projects) => {
    if (!event?.project_uuid && !event?.projectUuid)
        return false
    let proID = event?.project_uuid || event?.projectUuid
    let result = false
    let idx = findIndex(projects, p => p.projectUuid === proID && p.userId === event?.userId)
    if (idx !== -1 && !projects[idx].apSelected)
        result = true

    return result
}

export const eventSelecDays = (events, selectDays, type = true) => {
    let result = []
    events.forEach(evt => {
        console.log(selectDays.includes(moment(evt.start).format('YYYY-MM-DD')));
        if (selectDays.includes(moment(evt.start).format('YYYY-MM-DD'))) {

            result.push(evt)
        }
    })
    return result
}

export const isFreeTimeShareStep = users => {
    const {user} = getGlobal()
    let idx = findIndex(users, {id: user.user_id})
    if (idx !== -1 && !users[idx].isPublish) return false
    let result = false
    users.forEach(usr => {
        if (usr.isChecked && !usr.isPublish && usr.id !== user.user_id)
            result = true
    })
    return result
}

export const isStepShare = users => {
    let result = false
    users.forEach(usr => {
        if (usr.isChecked && !usr.isPublish)
            result = true
    })
    return result
}

export const createVoteBoxTime = (hours = 1, freeslots = [], votes) => {
    const result = freeslots.map(evt => createVoteBox(hours, evt))
    return removeExistVote(result.flat(), votes)
}

const createVoteBox = (time, event) => {
    const result = []
    let start = event.start
    while (moment(start).add(time, "hours").isSameOrBefore(event.end)) {
        let boxEnd = moment(start).add(time, "hours").format()
        result.push({
            ...event,
            start,
            end: boxEnd,
            backgroundColor: "#57AADD",
            editable: false,
            voteBox: true,
            rendering: null
        })
        start = boxEnd
    }
    return result
}

const removeExistVote = (autoVotes, existVotes) => {
    let result = []
    autoVotes.forEach(event => {
        if (!isSameVote(event, existVotes) && !isOverVote(event)) {
            result.push(event)
        }
    })
    return result

}

const isOverVote = (vote) => {
    return moment().isAfter(vote.start)
}

const isSameVote = (event, votes) => {
    let result = false
    votes.forEach(vote => {
        if (moment(event.start).isSame(vote.start) ||
            moment(event.start).isBetween(vote.start, vote.end) ||
            moment(vote.start).isBetween(event.start, event.end) ||
            moment(vote.end).isBetween(event.start, event.end)
        ) {
            result = true
        }
    })
    return result
}

export const autoSaveVote = async (voteInfo, meeting, user, autoVoteIntl) => {
    try {
        const anonymousEmail = store.get('anonymousEmail')
        if (!meeting.id)
            return null

        if (isEmpty(user) && !anonymousEmail)
            return null
        const batch = db.batch()
        const UID = "voteBox_" + uuidv4();
        let author = null;
        let authorName
        // const voted = {}
        if (!isEmpty(user)) {
            // voted[user.user_id] = "yes"
            author = user.user_id
            authorName = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname
        }
        if (isEmpty(user) && anonymousEmail) {
            // voted[anonymousEmail] = "yes"
            author = anonymousEmail
        }

        const data = {
            uuid: UID,
            author: author,
            start: moment(voteInfo.start).format(),
            end: moment(voteInfo.end).format(),
            duration: moment(voteInfo.end).diff(moment(voteInfo.start), 'hours', true),
            isEventVote: true,
            backgroundColor: "#57AADD",
            authorAvt: user?.avatar || "",
            authorName: authorName || "",
            isDeleted: false,
            createdAt: moment().format(),
            updatedAt: moment().format(),
            isAccepted: false,
            language: user?.language || "ja-jp",
            last_name: user?.last_name || '',
            first_name: user?.first_name || '',
            nickname: user?.nickname || '',
            tz: `${user?.time_zone}`,
            email: user.email || ""
        }
        const voteRef = db.doc(`${MEETING_COLLECTION}/${meeting.id}/voteBoxColl/${UID}`)
        batch.set(voteRef, data, {merge: true})

        await batch.commit()
        await message.info({
            content: `${autoVoteIntl}`,
            style: {
                marginTop: '85vh',
                fontWeight: "bold"
            },
            duration: 1
        });
    } catch (e) {
        console.log(e)
    } finally {

    }
}


export const getDateRange = (weeklyTime = [0, 1]) => {
    // if (weeklyTime[0] === weeklyTime[1])
    //     return {
    //         start: moment().add(0, "weeks").startOf("day").format(),
    //         end: moment().add(1, "weeks").startOf("day").format()
    //     }
    if (!Array.isArray(weeklyTime))
        return {
            start: moment().add(0, "day").startOf("day").format(),
            end: moment().add(weeklyTime * 7, "day").startOf("day").format()
        }
    return {
        start: moment().add(weeklyTime[0] * 7, "day").startOf("day").format(),
        end: moment().add((weeklyTime[1] + 1) * 7, "day").startOf("day").format()
    }

}

export const getAcceptVote = votes => {
    if (!votes || !votes.length)
        return []
    return filter(votes, v => v.isAccepted)
}

export const enoughFreeTime = (slots, time) => {
    let result = []
    slots.forEach(slot => {
        if (moment(slot.start).add(Number(time), "hours").isSameOrBefore(slot.end)) {
            result.push(slot)
        }
    })
    return result
}

export const isAcceptedVote = (voted, users) => {
    let result = true
    users.forEach(u => {
        if (voted[u.id] !== 'yes') {
            result = false
        }
    })
    return result
}

export const updateUsersProjects = async (projectID, userID, selected) => {
    try {
        const projectRef = getAllUsersProjectsColl(userID).doc(projectID)
        const snaps = await projectRef.get()
        if (!snaps.exists)
            return null;

        await projectRef.set({apSelected: selected}, {merge: true})

    } catch (e) {
        console.log(e)
    }
}

export const updateProjectSelected = async (projectID, userID, selected) => {
    try {
        const projectRef = getCalendarProjectsDocRef(userID, projectID)
        const snaps = await projectRef.get()
        if (!snaps.exists)
            return null;
        await projectRef.set({apSelected: selected}, {merge: true})

    } catch (e) {
        console.log(e)
    }
}


export const getEventsRecurrence = (events, edited = []) => {
    const result = events.map(e => {
        try {
            if (e?.recurrence) {
                const allDay = e.allDay;
                let start = e.start;
                const end = e.end;
                const endRange = moment().add(9, "weeks").format();
                const duration = moment(end).diff(start, allDay ? "day" : "minutes");
                let dtstart = `DTSTART:${moment(start).startOf("day").format("YYYYMMDDTHHmmss") + "Z"} `;
                if (dtstart.includes('Invalid date')) {
                    start = new Date(start.seconds * 1000).toISOString();
                    dtstart = `DTSTART:${moment(start).startOf("day").format("YYYYMMDDTHHmmss") + "Z"} `;
                }
                const eventDate = moment(start).format('YYYY-MM-DD');
                const rule = rrulestr(dtstart + e.recurrence);
                let dataDate
                let ignore = true
                if (rule?.options?.until && moment(rule.options.until).isBefore(moment(start).startOf("day"))) {
                    dataDate = [moment(start).format('YYYY-MM-DD')]
                    ignore = false
                } else {
                    dataDate = rule.between(new Date(moment(start).utc().subtract(1, "day").format("YYYY-MM-DD")), new Date(moment(endRange).format("YYYY-MM-DD"))).map(date => moment(date).format("YYYY-MM-DD"))
                }
                if (!dataDate?.includes(eventDate)) {
                    dataDate.unshift(eventDate)
                }
                const eventData = dataDate.map((date, index) => {
                    if (allDay) {
                        return {
                            ...e,
                            start: moment(date).format("YYYY-MM-DD"),
                            end: moment(date).add(duration, "day").format("YYYY-MM-DD"),
                            id: e.id + "_" + moment(date).format("YYYYMMDD"),
                            recurringEventId: e.id,
                            originStart: {
                                allDay: allDay,
                                start,
                                end,
                                isStart: index === 0
                            }
                        }
                    }
                    const startTime = moment(start).set({
                        years: moment(date).year(),
                        month: moment(date).month(),
                        date: moment(date).date()
                    }).format()
                    if (rule?.options?.until && ignore && moment(rule.options.until).isBefore(startTime)) {
                        return null
                    }
                    return {
                        ...e,
                        start: startTime,
                        end: moment(startTime).add(duration, "minutes").format(),
                        id: e.id + "_" + moment(date).set({
                            hours: moment(start).hours(),
                            minutes: moment(start).minutes(),
                            seconds: 0
                        }).utc().format("YYYYMMDDTHHmmss") + "Z",
                        recurringEventId: e.id,
                        originStart: {
                            allDay: allDay,
                            start: start,
                            end,
                            isStart: index === 0
                        },
                        editable: !e.isEventVote,
                        droppable: !e.isEventVote
                    }
                })
                edited.forEach(evt => {
                    let idx = findIndex(eventData, {id: evt.id})
                    if (idx !== -1) {
                        if (evt.status === "canceled") {
                            eventData.splice(idx, 1)
                        } else {
                            eventData[idx] = {
                                ...evt,
                                recurrence: e.recurrence,
                                originStart: eventData[idx].originStart
                            }
                        }
                    }
                })
                // console.log({eventData});
                return eventData.filter(x => x)
            } else {
                return []
            }
        } catch (e) {
            console.log(e);
            return []
        }
    })
    return result.flat()
}
export const compareProject = (prevData = [], nexData = []) => {
    if (prevData.length !== nexData.length)
        return true
    let result = true
    prevData.forEach((item, index) => {
        let idx = findIndex(nexData, {id: item.id, apSelected: !item.apSelected})
        if (idx !== -1)
            return result = false
    })
    return result
}

export const getBusyFromWorkTime = (start, end, workTime = {start: 0, end: 24}, zone = 'Asia/Saigon') => {
    if (!start || !end || !isNumber(workTime.start) || !isNumber(workTime.end))
        return []
    if (workTime.start === 0 && workTime.end === 24) {
        return []
    }
    if (workTime.end <= workTime.start) {
        return []
    }
    let result = []
    const startDate = momentTz.tz(start, zone).startOf("day").format()
    const diff = moment(end).diff(start, 'days')
    // console.log(diff);
    for (let i = 0; i <= diff; i++) {
        if (workTime.start > 0) {
            result.push({
                start: momentTz.tz(startDate, zone).add(i, 'days').startOf("day").local().format(),
                end: momentTz.tz(startDate, zone).add(i, 'days').startOf("day").add(workTime.start, 'hours').local().format(),
            })
        }
        if (workTime.end < 24) {
            result.push({
                start: momentTz.tz(startDate, zone).add(i, 'days').startOf("day").add(workTime.end, 'hours').local().format(),
                end: momentTz.tz(startDate, zone).add(i + 1, 'days').startOf("day").local().format(),
            })
        }

    }
    return result
}
