import axios from "axios";
import {refreshToken} from "../actions/user";
import {includes} from 'lodash'
import {RefreshGoogleToken} from "../actions/googleCalendar";
import localToken from './localToken'
import {COOKIE_GENIAM_ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, removeCookie} from "../common/cookies";
import {onLogOut} from "../common/googleCal/logOutGoogle";

// const ACCESS_TOKEN_KEY = 'accessToken'


const axiosDefault = () => {
    //REQUEST
    axios.interceptors.request.use(
        (config) => {
            // const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
            if (includes(config.url, "https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate") || includes(config.url, "https://asia-northeast1-geniam-c8d4c.cloudfunctions.net") || includes(config.url, 'https://api-zoom') || includes(config.url, 'localhost:8888'))
                return config
            const {token} = localToken();
            if (token) {
                if (includes(config.url, 'https://www.googleapis.com')) {
                    // if (!includes(config.url, CALENDAR_API_KEY)) {
                    //     const googleAccessToken = localStorage.getItem('googleAccessToken')
                    //     config.headers['Authorization'] = `Bearer ${googleAccessToken}`;
                    // }
                } else {
                    config.headers['Authorization'] = `Bearer ${token}`;
                    // config.headers['Strict-Transport-Security'] = `max-age=31556926 ; includeSubDomains`;
                    // config.headers['X-Frame-Options'] = `SAMEORIGIN`;
                    // config.headers['X-Content-Type-Options'] = `nosniff`;
                    // config.headers['X-Permitted-Cross-Domain-Policies'] = `none`;
                    // config.headers['X-XSS-Protection'] = `1; mode=block`;
                }
            }
            return config;
        },
        error => {
            if (error && error.request) {
                console.log(error);
                console.log(error.request);
                // toast.error(<ToastFormat error={error}/>)
            }
            return Promise.reject(error);
        });

    //RESPONSE
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            // const query = qs.parse(window.location.search);
            if (!error.response) {
                // toast.error(<ToastFormat error={error}/>)
                return Promise.reject(error)
            }

            if (
                (error.response.status === 400 && error.response.data.error_code === 'NotAuthorizedException')
                || (error.response.status === 401 && error.response.data.error === 'login required')
            ) {
                if (
                    error.response.data.error_message === 'Invalid Refresh Token'
                    || error.response.data.error_message === 'Refresh Token has been revoked'
                    || error.response.data.error_message === 'Refresh Token has expired'
                ) {
                    removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
                    removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
                    // window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
                    return Promise.reject(error)
                }
                const data = await refreshToken()
                if (data) {
                    originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
                    return axios(originalRequest)
                } else {
                    return Promise.reject(error)
                }

            }
            // refresh token google if fail request
            if (error.response.status === 401 && error.response.data.error.message === "Invalid Credentials") {
                const result = await RefreshGoogleToken()
                // console.log(result)
                if (result) {
                    originalRequest.headers['Authorization'] = `Bearer ${result}`;
                    //set new token
                    return axios(originalRequest)
                    // RefreshEvents(true)
                    // return Promise.reject(error)
                } else {
                    onLogOut()
                    return Promise.reject(error)
                }
            }

            return Promise.reject(error);
        }
    );
}
//
// function ToastFormat({error}) {
//     const {url} = error.config
//     const msg = error.toString()
//     return (
//         <div>
//             <div>
//                 {url}
//             </div>
//             <div>{msg}</div>
//         </div>
//     )
// }

export default axiosDefault;
