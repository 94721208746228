import {getGlobal} from 'reactn'
import {db} from "../../../firebaseConfig";
import {CALENDAR_COLLECTION, GOOGLE_GET_REFRESH_TOKEN} from "../../../config/constants";
import axios from 'axios'

const generatorRefresh = async (code, profile) => {
    try {
        const {data} = await axios.get(`${GOOGLE_GET_REFRESH_TOKEN}?code=${code}&redirectUri=${window.location.origin}`)
        const {user} = getGlobal()
        await db.collection(CALENDAR_COLLECTION).doc(user.user_id)
            .set({
                googleAuth: {
                    ...profile,
                    refreshToken: data.refresh_token
                }
            }, {merge: true})
    } catch (e) {
        console.log(e);
    }
}

export default generatorRefresh
