import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axiosConfig from './config/axios'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter} from "react-router-dom";
import GoogleAuth from "./components/Calendar/google/auth";

import './setGlobal'
import './firebaseConfig'
import {CalendarUserProvider} from "./context/CalendarUserContext";
import {LanguageMessageProvider} from "./context/LanguageMessageContext";
import ErrorBoundary from "./components/ErrorBounDary";
import {ThemeProviderContext} from "./context/ThemeContext";
import {CalendarEventsProvider} from "./context/CalendarEventsContext";
import {AnnouncementProvider} from "./context/AnnouncementContext";

axiosConfig()
GoogleAuth()

ReactDOM.render((
        <>
            <ThemeProviderContext>
                <BrowserRouter>
                    <ErrorBoundary>
                        <CalendarUserProvider>
                            <LanguageMessageProvider>
                                <AnnouncementProvider>
                                    <CalendarEventsProvider>
                                        <App/>
                                    </CalendarEventsProvider>
                                </AnnouncementProvider>
                            </LanguageMessageProvider>
                        </CalendarUserProvider>
                    </ErrorBoundary>
                </BrowserRouter>

                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    autoClose={3000}
                    position="bottom-center"
                />
            </ThemeProviderContext>
        </>
    ),
    document.getElementById('root')
)
;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();


reportWebVitals()
