export let LOGIN_URL = process.env.REACT_APP_LOGIN_URL
export let REGISTER_URL = process.env.REACT_APP_REGISTER_URL
export let SWITCH_ACCOUNT = process.env.REACT_APP_LOGIN_URL + '/select-account'
export let API_GENIAM = process.env.REACT_APP_API_GENIAM
export let API_ZOOM = process.env.REACT_APP_API_ZOOM
export let CREATE_GOOGLE_TASKS = process.env.REACT_APP_GOOGLE_TASKS + '/createTask';
export let DELETE_GOOGLE_TASKS = process.env.REACT_APP_GOOGLE_TASKS + '/deleteTask';
export let IS_PRODUCTION = process.env.REACT_APP_ENV === 'production'
export let CALENDAR_COLLECTION = IS_PRODUCTION ? "calendar" : "calendar-stg"
export let USER_COLLECTION = IS_PRODUCTION ? "users" : "users-stg"
export let ZOOM_COLLECTION = IS_PRODUCTION ? "metamors" : "metamors-stg"
export let MILESTONE_COLLECTION = IS_PRODUCTION ? "milestones" : "milestones-stg"
export let MEETING_COLLECTION = IS_PRODUCTION ? "meetings" : "meetings-stg"
export let GOOGLE_EMAIL_WATCH = "googleEmailWatch"
export let IDENTITY_COLLECTION = IS_PRODUCTION ? "identities" : "identities-stg"
export let RECOMMEND_COLLECTION = IS_PRODUCTION ? "recommend" : "recommend-stg"
export const HEALTHCHECK = API_GENIAM + '/healthcheck'
export const GOOGLE_REFRESH_TOKEN = API_GENIAM + '/googleApi/refreshToken'
export const GOOGLE_GET_REFRESH_TOKEN = API_GENIAM + '/googleApi/getRefreshToken'
export const ACCESS_TOKEN_KEY = 'accessToken'
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const ID_TOKEN_KEY = 'idToken';
export const LOGIN_KEY = 'login';
export const REGISTER_KEY = 'register';
export const ACCOUNTS_KEY = 'accounts';
export const USER_INFO_KEY = 'userInfo';
export const REDIRECT_URL_KEY = 'service_redirect_url';
export const USER_AVATAR_UPLOAD_URL = API_GENIAM + '/user/avatar';
export const LOGOUT_URL = API_GENIAM + "/logout";
export const METAMOR_URL = process.env.REACT_APP_METAMOR_URL
export let GET_USER_INFO = API_GENIAM + '/getuserdata'
export let CHANGE_ACCOUNT_INFO = API_GENIAM + '/change-account-info'
export let GET_USER_EMAIL = API_GENIAM + '/getemail'
export const CALENDAR_API_KEY = "AIzaSyB1nGsOlnZRfv5YPnVUPS_icNqgJ_Vk3xE"
export const COGNITO_LOGOUT = IS_PRODUCTION ? 'https://geniam.auth.ap-northeast-1.amazoncognito.com/logout' : 'https://geniam-dev.auth.ap-northeast-1.amazoncognito.com/logout'
export const COGNITO_CLIENT_ID = IS_PRODUCTION ? '4uak2jlcf653p4rh7hhftpp46a' : '5ihn71m5hb8jsh88kvmbbeejvo'

export const CREATED_ACTION = "CREATED"
export const UPDATED_ACTION = "UPDATED"
export const DELETED_ACTION = "DELETED"
export const CHANGE_TIME_ACTION = "CHANGE_TIME"

export const SUBDOMAIN_APPOINTMENT = IS_PRODUCTION ? 'appy' : 'appy-stg'
export const MAIL_TEMPLATES_SEND_MAIL_GRANT_ROLE_DOC = IS_PRODUCTION ? 'sendMailGrantRole' : 'sendMailGrantRole-stg'
export const MAIL_TEMPLATES_SEND_MAIL_DELETE_EVENT_VOTE_DOC = IS_PRODUCTION ? 'sendMailDeleteEventVote' : 'sendMailDeleteEventVote-stg'
export const MAIL_TEMPLATES_INVITE_EVENT_DOC = IS_PRODUCTION ? 'inviteEvent' : 'inviteEvent-stg'
export const MAIL_TEMPLATES_SEND_MAIL_EXPIRED_DOC = IS_PRODUCTION ? 'sendMailExpired' : 'sendMailExpired-stg'
export const MAIL_TEMPLATES_CHAT_SEND_MAIL_DOC = IS_PRODUCTION ? 'chatSendMail' : 'chatSendMail-stg'
export const MAIL_TEMPLATES_SEND_MAIL_NOTIFICATION_AUTO_SHARE = IS_PRODUCTION ? 'sendMailNotificationAutoShare' : 'sendMailNotificationAutoShare-stg'
export const MAIL_TEMPLATES_REMIND_FREE_TIME = IS_PRODUCTION ? 'remindFreetime' : 'remindFreetime-stg'

export const GLOBAL_NAVI_APPS_COLL = IS_PRODUCTION ? "GlobalNavigation" : "GlobalNavigation-stg"
export const APP_GLOBAL_ID = "appy"
