import {addCallback, getGlobal, setGlobal} from 'reactn'
import {isMobileOnly} from 'react-device-detect'
import moment from "moment";
import store from "store";
import qs from "query-string";
import {isEqual} from "lodash";

const calendarTab2 = store.get('calendarTab2')
const calendarTab1 = store.get('calendarTab1')
const fontSize = store.get('fontSize') || 9
const focus = store.get('focus') || {identity: true, project: true}
const view = isMobileOnly ? 'CustomWeek' : store.get('view')
const segment = store.get('segment')
const projectSortSetting = store.get('projectSortSetting') || {isSort: false, timeView: 6}
const fullHeight = store.get('fullHeight') || 'fullHeight'
const showTip = store.get('showTip') || 'show'
const localEdit = store.get("editFreeBox") || []
const query = qs.parse(window.location.search)
const tabShareUrl = query?.tabShareUrl || store.get('tabShareUrl')
const localUser = store.get('userInfo')
const viewLeftApp = store.get('viewLeftApp')
const gerenateFocus = (num) => {
    let result = {}
    for (let i = 0; i < num; i++) {
        result['focus-' + num] = true
    }
    return result
}

setGlobal({
    user: store.get("userInfo") || {},
    loading: false,
    calendarTab2: calendarTab2 ? calendarTab2 : 2,
    calendarTab1: calendarTab1 ? calendarTab1 : 2,
    reFetchDateNow: moment().format('YYYY-MM-DD'),
    groups: [],
    groupsMs: [],  //  groups milestone
    groupsAppointment: [], //groups appointment
    view: view ? view : 'CustomMonth',
    openNewEvent: null,
    EventAdding: {top: 0, left: 0},
    openEditingEvent: {
        open: false,
        anchorEl: null
    },
    // accountPopupAnchorEl: null,
    openDeleteEvent: false,
    EventEditting: {},
    focusText: [],
    taskFree: [],
    // usersTaskFree: [],
    tasks: {
        data: [],
        default: 0
    },
    tab: 1,
    segment: segment ? segment : 'one',
    googleStatus: {
        is_login: Boolean(store.get("googleAuth")),
        userProfile: store.get("googleAuth") || {},
        initialed: Boolean(store.get("googleAuth"))
    },
    googleCalendarList: store.get("GGList") || [],
    googleListing: false,
    EventTask: [],
    googleLibInit: false,
    times: {
        minTime: {
            value: 7,
            label: '07:00'
        },
        maxTime: {
            value: 24,
            label: '24:00'
        }
    },
    //Task
    timer: null,
    _events: [],
    newUser: false,
    identities: [],
    priorities: [],
    projects: [],
    categories: [],

    // Popup Task
    newEvent: {},
    editEvent: {},
    popup: {
        newEventPopup: false,
        editEventPopup: false,
        top: 0,
        left: 0,
    },

    calendarID: {
        id: 0,
        name: ''
    },
    anchor: {
        top: 0,
        left: 0
    },
    initDialog: false,
    process: '',
    openIconList: false,
    addIdentityDialog: false,
    openAddProject: false,
    GoogleColor: [
        "#7986CB",
        "#33B679",
        "#8E24AA",
        "#E67C73",
        "#F6BF26",
        "#F4511E",
        "#039BE5",
        "#616161",
        "#3F51B5",
        "#0B8043",
        "#D50000"
    ],
    syncToken: [],
    syncEvents: [],
    GChannel: store.get('GChannel') ? store.get('GChannel') : [],
    LChannel: store.get('LChannel') ? store.get('LChannel') : {},
    RightClickEl: null,
    RightEditEvent: {},
    recurringOpen: false,
    recurringEvent: {},
    changeOption: '',
    deleted: [],
    restoring: false,
    restored: null,
    hoverEvent: null,
    hoverTitle: {
        title: '',
        start: '',
        end: ''
    },
    syncProcess: '',
    inSync: false,
    sync_uuid: '',
    emojis: [
        {
            name: 'AssignmentTurnedIn',
            emoji: "✅"
        },
        {
            name: 'Group',
            emoji: "👥"
        },
        {
            name: 'AccountCircle',
            emoji: "👤"
        },
        {
            name: 'AssignmentInd',
            emoji: "🔄"
        },
    ],
    openSelectCalendar: false,
    calendarSelected: store.get('calendarSelected') ? store.get('calendarSelected') : [],
    allProject: [],
    ggAllProject: [],
    // height: '305px',
    // heightMap: {},
    // ReloadNeeded: false,
    thickLine: store.get('thickLine') ? store.get('thickLine') : [12, 18, null, null],
    treeData: [],
    openTime: new Date(),
    // focusEvent: {},
    packages: {},
    openPackageDialog: false,
    meetingTimes: {
        minTime: {
            value: 0,
            label: '00:00'
        },
        maxTime: {
            value: 24,
            label: '24:00'
        }
    },
    fontSize: fontSize,
    hiddenDays: [],
    apiCalendar: null,
    meetings: [],
    voteInfo: null,
    votesSelect: null,
    editVoteInfo: null,
    voteEvents: [],
    timeWarning: null,
    language: store.get("language") || "ja-jp",
    switchEmail: null,
    eventJoining: false,
    eventCancelJoin: true,
    eventTransfer: false,
    editFreeSlot: null,
    notifications: [],
    myFreeTime: null,
    dateSelections: [],
    tabMeeting: 0,
    hoverVote: null,
    openSetting: false,
    hoverPos: null,
    voteBox: null,
    hoverDay: null,
    workTimeDisplay: [],
    geEvents: {},
    ggEvents: {},
    settingTab: 0,
    calendarUser: {},
    clickInfo: null,
    nextSyncTokens: {},
    projectSortSetting: projectSortSetting,
    zoomOAuth: {},
    zoomMeetings: [],
    accessToken: null,
    firebaseToken: null,
    refreshToken: null,
    prevAction: [],
    eventContextMenu: null,
    listSearch: [],
    searchKey: "",
    eventSearchKey: "",
    openFocusColor: [],
    colors: store.get('colors') || {primary: '#1790FF', secondary: '#690003'},
    focus: focus,
    focusView: gerenateFocus(store.get('numOfLine') || 2),
    fullHeight: fullHeight ? fullHeight : '',
    addProject: false,
    tour: null,
    showTip: showTip,
    openAddTask: false,
    editDialog: false,
    meetingPrimary: true,
    editFreeTime: localEdit.length !== 0,
    dateBusy: [],
    copyCreateEvent: null,
    preview: null,
    listMeetings: [],
    openChat: null,
    settingSaveTab: 0,
    listSettingShareUrl: [],
    listCustomEvent: [],
    allProjectList: [],
    editShare: false,
    editFreeTimeHome: false,
    listHistory: [],
    listFuture: [],
    tabShareUrl: tabShareUrl ? tabShareUrl : "live",
    projectsSync: [],
    endLoadList: false,
    avatars: [],
    freeTimes: [],
    generalFreeTimes: [],
    listVoting: [],
    userInfo: {},
    listTheme: [],
    viewLeftApp: viewLeftApp ? viewLeftApp : 'simple',
    listMessageItem: [],
    googleCalendarApi: [],
    isArchive: false,
    navigationsApps: [],
    globalApps: [],
    addLinkApp: [],
    idCalenderHidden: [],
    userGeniamApps: [],
    userLinks: [],
    numberCal: 3,
});

if (process.env.REACT_APP_ENV === "production") {
    console.log = () => {
    }
}


export const mergeGlobalUser = (data) => {
    const gUser = getGlobal()?.user
    return setGlobal({
        user: {
            ...gUser,
            ...data
        }
    })
}

const setMomentLocale = (language) => {
    let locale = language.split('-')?.[0]
    if (locale === 'en')
        locale = 'en-gb'
    import(`moment/locale/${locale}`)
        .then(d => {
            moment.locale(locale)
        })
}


let prevUser = {...localUser}

addCallback((global, dispatcherMap, stateChange) => {
    const newUserData = global.user;
    if (!isEqual(newUserData, prevUser)) {
        // console.log(diff(newUserData, prevUser))
        store.set('userInfo', newUserData)
        if (newUserData?.language) {
            setMomentLocale(newUserData.language)
        }
        prevUser = {...newUserData}
    }
});
