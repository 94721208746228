import toast from '../Page/toast'
import {getGlobal, setGlobal} from 'reactn'
import {db} from '../firebaseConfig'
import {
    API_ZOOM,
    CALENDAR_COLLECTION,
    CHANGE_TIME_ACTION,
    CREATED_ACTION,
    DELETED_ACTION,
    UPDATED_ACTION
} from "../config/constants";
import moment from "moment";
import {v4 as uuidv4} from 'uuid'
import {
    AddGoogleEvent,
    changeTimeGoogleEvent,
    GCalendarEventFormat,
    parseResource,
    updateGoogleEvent
} from "./googleCalendar";
import {refetchOneSource} from "./refetchSourcce";
import {ggTokenChecked} from "../components/Calendar/google/auth";
import updateWatchChannelMyself from "../common/updateWatchChannelMyself";
import axios from "axios";
import {checkTimeTitleEvent, handleDeleteGoogleTasksSchedule} from "./googleTasks";
import {handleUpdateZoomMeeting} from "./zoom";
import {handleGetValue} from "../components/ConferenceButton";
import {concat, findIndex, isUndefined, omitBy, uniqBy} from "lodash";

export const CalendarReloadOne = (i) => {
    if (window['calendar' + i]) {
        window['calendar' + i].refetchEvents()

    }
}
export const CalendarReloadAll = () => {
    const {tab, calendarTab2, calendarTab1} = getGlobal()
    const month = tab === 1 ? calendarTab1 : calendarTab2
    for (let i = 0; i < month; i++) {
        CalendarReloadOne(i)
    }
}


export const ChangeTimeEvent = async (event, numberId) => {
    const {start, end, allDay} = event

    try {
        const {user} = getGlobal()
        const time = moment().utc().format()
        const data = {
            start: moment(start).utc().format(),
            end: moment(end).utc().format(),
            allDay,
            duration: (moment(end).utc() - moment(start).utc()) / (60000 * 60),
            updated_at: time
        }
        await db.collection(CALENDAR_COLLECTION)
            .doc(user.user_id)
            .collection('events')
            .doc(event.id)
            .set(data,
                {merge: true}
            )
        return event
    } catch (e) {
        toast.error(e.toString())
        return null
    }

}
export const updateEvent = async (event, isDeleteRecurrenceId = false) => {
    try {
        let start, end
        if (event.allDay) {
            start = moment(event.start).startOf("day").utc().format()
            end = moment(event.end).startOf("day").utc().format()
        } else {
            start = moment(event.start).utc().format()
            end = moment(event.end).utc().format()
        }
        const {user} = getGlobal()
        const time = moment().utc().format()
        const batch = db.batch()
        const data = {
            ...event,
            colorId: null,
            start,
            end,
            duration: (moment(end).utc() - moment(start).utc()) / (60000 * 60),
            updated_at: time
        }
        const eventRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${event.id}`)
        batch.set(
            eventRef,
            omitBy(data, isUndefined),
            {merge: true}
        )
        if (isDeleteRecurrenceId) {
            const snap = await db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events`)
                .where("recurringEventId", "==", event.id)
                .get()
            snap.docs.forEach(doc => {
                const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${doc.id}`)
                batch.delete(ref)
            })
        }
        await batch.commit()
        return event
    } catch (e) {
        console.log(e);
        toast.error("UPDATE EVENT ERROR ", {autoClose: 2000})
        return null
    }
}
export const newEvent = async (event) => {
    try {
        const {user} = getGlobal()
        const time = moment().utc().format()
        const colorId = event.colorId || null
        const identity = event.identity || null
        const data = {
            isDeleted: false,
            isSuccess: false,
            isTasks: false,
            ...event,
            colorId,
            identity,
            start: moment(event.start).utc().format(),
            end: moment(event.end).utc().format(),
            duration: (moment(event.end).utc() - moment(event.start).utc()) / (60000 * 60),
            created_at: time,
            updated_at: time,
            isEventVote: false,
            isAccepted: false,
        }
        const eventRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${event.id}`)
        await eventRef.set(omitBy(data, isUndefined), {merge: true})
        return event
    } catch (e) {
        console.log(e);
        toast.error("ADD EVENT ERROR ", {autoClose: 2000})
        return null
    } finally {

    }

}

export function removeURLParameter(parameter, url) {
    const fragment = url.split('#');
    const urlparts = fragment[0].split('?');

    if (urlparts.length >= 2) {
        const urlBase = urlparts.shift(); //get first part, and remove from array
        const queryString = urlparts.join("?"); //join it back up

        const prefix = encodeURIComponent(parameter) + '=';
        const pars = queryString.split(/[&;]/g);
        for (let i = pars.length; i-- > 0;) {               //reverse iteration as may be destructive
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {   //idiom for string.startsWith
                pars.splice(i, 1);
            }
        }
        url = urlBase + (pars.length > 0 ? '?' + pars.join('&') : '');
        if (fragment[1]) {
            url += "#" + fragment[1];
        }
    }
    return url;
}

export const applyDrag = (arr, dragResult) => {
    const {removedIndex, addedIndex, payload} = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        if (result.length > 1) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }
    return uniqBy(result, 'uuid')
}


export const eventShiftCopy = async (info, numberId) => {
    try {
        const {user, EventEditting: {allDay, start, end, title}} = getGlobal()
        const {
            backgroundColor,
            borderColor,
            extendedProps: {project_uuid, identity, isSuccess, task, googleEvent, location, description}
        } = info.event

        const batch = db.batch()
        // gerenate event
        const numOfEvents = moment(info.event.start).date() - moment(start).date()
        if (numOfEvents === 0)
            return
        const time = moment().utc().format()
        let events = []
        for (let i = 1; i <= Math.abs(numOfEvents); i++) {
            const id = `evt_${uuidv4()}`
            const evt = {
                id,
                uuid: id,
                isDeleted: false,
                isSuccess: isSuccess,
                isTasks: false,
                allDay,
                identity,
                task,
                title,
                project_uuid,
                backgroundColor,
                borderColor,
                start: moment(start).add(numOfEvents > 0 ? i : -i, "days").utc().format(),
                end: moment(end).add(numOfEvents > 0 ? i : -i, "days").utc().format(),
                duration: (moment(end).utc() - moment(start).utc()) / (60000 * 60),
                location: location || null,
                description: description || null,
                created_at: time,
                updated_at: time,
                isEventVote: Boolean(info.event.extendedProps.isEventVote),
                isAccepted: Boolean(info.event.extendedProps.isAccepted),
                meetingId: info.event.extendedProps?.meetingId || null,
            }
            events.push(evt)

        }
        if (googleEvent) {
            if (events.length === 0) {
                // refresh events : reset eventState
                const {ggEvents} = getGlobal()
                setGlobal({
                    ggEvents: {...ggEvents}
                })
                return
            }

            const batch = window.gapi.client.newBatch()
            events.forEach(evt => {
                const evtParsed = parseResource(evt)
                const request = window.gapi.client.calendar.events.insert({
                    'calendarId': project_uuid,
                    'resource': evtParsed
                })
                // await AddGoogleEvent(evt)
                batch.add(request)
            })
            try {
                const {result, status} = await batch.then()
                // local updated
                if (status !== 200) {
                    return toast.error("unable to copy right now, please try again later")
                }
                let success = []
                Object.keys(result).forEach(key => {
                    if (result[key]?.status === 200) {
                        success.push(result[key].result)
                    }
                })
                if (!success.length)
                    return

                const {ggEvents} = getGlobal()
                if (!ggEvents[project_uuid])
                    return
                success = GCalendarEventFormat(success, project_uuid, ggEvents[project_uuid].color, false)
                ggEvents[project_uuid].events = concat(ggEvents[project_uuid].events, success)
                setGlobal({
                    ggEvents: {...ggEvents}
                }, () => {
                    refetchOneSource("google", numberId)
                })
            } catch (e) {
                console.log(e);
                toast.error(e.toString())
            }

        } else {
            if (events.length === 0) {
                // refresh events : reset eventState
                const {geEvents} = getGlobal()
                setGlobal({
                    geEvents: {...geEvents}
                })
                return
            }
            events.forEach(evt => {
                const evtRef = db.collection(CALENDAR_COLLECTION)
                    .doc(user.user_id)
                    .collection("events")
                    .doc(evt.id)
                batch.set(evtRef, evt)
            })
            await batch.commit()
        }

    } catch (e) {
        console.log(e.toString())
        return null
    }

}

export const eventAltCopy = async (info, numberId) => {
    try {
        const {user, EventEditting} = getGlobal()
        let {
            start,
            end,
            title,
            allDay,
            backgroundColor,
            borderColor,
            extendedProps: {project_uuid, identity, isSuccess, task, googleEvent, location, description}
        } = info.event
        // let evt = {start, end, allDay, uuid, calendar_uuid, identity, isSuccess, task}
        if (!end) {
            if (!allDay) {
                end = moment(start).add(EventEditting.extendedProps.duration, "hour").utc().format()
            } else {
                end = moment(start).add(Math.ceil(EventEditting.extendedProps.duration / 24), "day").utc().format()
            }
        }
        const id = `evt_${uuidv4()}`
        const time = moment().utc().format()
        const newEvent = {
            id,
            uuid: id,
            isDeleted: false,
            isSuccess: isSuccess,
            isTasks: false,
            allDay,
            identity,
            task,
            title,
            project_uuid,
            backgroundColor,
            borderColor,
            start: moment(start).utc().format(),
            end: moment(end).utc().format(),
            duration: (moment(end).utc() - moment(start).utc()) / (60000 * 60),
            location: location || null,
            description: description || null,
            created_at: time,
            updated_at: time,
            isEventVote: Boolean(info.event.extendedProps.isEventVote),
            isAccepted: Boolean(info.event.extendedProps.isAccepted),
            meetingId: info.event.extendedProps?.meetingId || null,
        }
        if (googleEvent) {
            const data = await AddGoogleEvent(newEvent)
            if (data) {
                const {ggEvents, googleCalendarList} = getGlobal()
                const {project_uuid} = data
                let idx = findIndex(googleCalendarList, {id: project_uuid})
                if (idx === -1)
                    return;
                if (ggEvents[project_uuid]) {
                    ggEvents[project_uuid].events.push(data)
                } else {
                    ggEvents[project_uuid] = {
                        id: project_uuid,
                        events: [data],
                        name: googleCalendarList[idx].summary,
                        color: googleCalendarList[idx].backgroundColor
                    }
                }
                setGlobal({
                    ggEvents: {...ggEvents}
                }, () => {
                    refetchOneSource("google", numberId)
                })

            }
            return
        }
        await db.collection(CALENDAR_COLLECTION)
            .doc(user.user_id)
            .collection('events')
            .doc(id)
            .set(newEvent)
    } catch (e) {
        console.log(e.toString())
        return null
    }
}


export const onRestore = async () => {
    const {restoring, user, prevAction} = getGlobal()
    if (prevAction && prevAction.length !== 0) {
        if (!restoring) {
            let length = prevAction.length
            const {event, type} = prevAction[length - 1]
            if (!event || !type)
                return;
            document.body.style.cursor = 'progress';
            await setGlobal({restoring: true})
            const {is_google, conferenceData, googleTasksID} = event
            switch (type) {
                case CREATED_ACTION:
                    // just delete event created:

                    if (is_google) {
                        await deleteGoogleEvent(event)
                    } else {
                        await archiveGeniamEvent(event, user, true)
                    }
                    prevAction.splice(length - 1, 1);
                    await setGlobal([...prevAction])
                    toast.success(`archive event '${event.title}'`);
                    if (conferenceData) {
                        console.log('delete');
                        axios.post(API_ZOOM + '/delete', {
                            type: 'meetings',
                            meetingId: event?.zoomMeetingId,
                        }).catch(e => console.log(e))
                    }
                    if (googleTasksID) {
                        handleDeleteGoogleTasksSchedule(event.googleTasksID).catch(e => console.log(e))
                    }
                    break;
                case UPDATED_ACTION:
                    const {newEvent} = prevAction[length - 1]
                    //1 just up date google
                    if (is_google && newEvent.is_google) {
                        // update old State to event
                        await updateGoogleEvent(event, event.id)
                    }
                    //2. geniam event
                    if (!is_google && !newEvent.is_google) {
                        updateEvent(event);
                        // update zoom meeting if exists
                        updateZoomData(newEvent, event, user)
                    }
                    //3. geniam event to google event
                    if (!is_google && newEvent.is_google) {
                        //3.1 delete google event
                        await deleteGoogleEvent(newEvent);
                        //3.2 enable geniam event
                        await archiveGeniamEvent(event, user, false);
                        updateZoomData(newEvent, event, user)
                    }
                    //4. google event to geniam event
                    if (is_google && !newEvent.is_google) {
                        //4.1 delete geniam event
                        await archiveGeniamEvent(newEvent, user, true)
                        //4.2 enable google event
                        await unArchiveGoogleEvent(event)
                    }
                    prevAction.splice(length - 1, 1)
                    await setGlobal([...prevAction])
                    toast.success(`Restore update event '${event.title}'`)
                    break;
                case CHANGE_TIME_ACTION:
                    let newInfo = prevAction[length - 1].newEvent
                    let oldInfo = prevAction[length - 1].event
                    if (newInfo.googleEvent || newInfo.is_google) {
                        // update googleEvent
                        await changeTimeGoogleEvent({
                            allDay: oldInfo.allDay,
                            start: oldInfo.start,
                            end: oldInfo.end,
                            id: newInfo.id || newInfo.uuid,
                            project_uuid: newInfo.project_uuid
                        })
                    } else {
                        // updateGeniam event
                        await ChangeTimeEvent({
                            allDay: oldInfo.allDay,
                            start: oldInfo.start,
                            end: oldInfo.end,
                            id: newInfo.id || newInfo.uuid
                        });
                        updateZoomData(newInfo, oldInfo, user)
                    }
                    prevAction.splice(length - 1, 1)
                    await setGlobal([...prevAction])
                    toast.success(`Restore update event '${newInfo.title}'`)
                    break;
                case DELETED_ACTION:
                    if (is_google) {
                        await unArchiveGoogleEvent(event)
                    } else {
                        await archiveGeniamEvent(event, user, false)
                    }
                    prevAction.splice(length - 1, 1)
                    await setGlobal([...prevAction])
                    toast.success(` UnArchive event '${event.title}'`)
                    break
                default:
                    break
            }
            await setGlobal({restoring: false})
            document.body.style.cursor = ''
        }
    }
}

export const updateZoomData = (newEvent, oldEvent, user) => {
    let meetingZoom = handleGetValue(newEvent) || newEvent?.linkZoom;
    if (meetingZoom && checkTimeTitleEvent(oldEvent, newEvent)) {
        handleUpdateZoomMeeting(moment(oldEvent.start).format(), moment(oldEvent.end).format(), meetingZoom, user.user_id, oldEvent.title)
            .catch(e => console.log(e))
    }
}
const deleteGoogleEvent = async (event) => {
    try {
        await ggTokenChecked()
        const request = window.gapi.client.calendar.events.delete({
            "calendarId": event.project_uuid,
            "eventId": event.id
        })
        await request.then()
        updateWatchChannelMyself(event.project_uuid)
    } catch (e) {
        toast.error(e.toString())
    }
}
const unArchiveGoogleEvent = async (event) => {
    try {
        await ggTokenChecked()
        const request = window.gapi.client.calendar.events.patch({
            "calendarId": event.project_uuid,
            "eventId": event.id || event.uuid,
            "status": "confirmed"
        })
        await request.then()
        updateWatchChannelMyself(event.project_uuid)
    } catch (e) {
        toast.error(e.toString())
    }
}
const archiveGeniamEvent = async (event, user, archive) => {
    try {
        const COLL = event.isEventVote ? "eventBoxMeeting" : "events"
        await db.collection(CALENDAR_COLLECTION)
            .doc(user.user_id)
            .collection(COLL)
            .doc(event.uuid || event.id)
            .set({
                isDeleted: archive
            }, {merge: true})

    } catch (e) {
        console.log(e);
    }
}
export const ToggleSuccess = async (event) => {
    const {googleEvent} = event
    const {ggEvents, geEvents} = getGlobal()
    if (googleEvent) {
        try {
            let idx = findIndex(ggEvents[event.project_uuid]?.events || [], {id: event.id})
            if (idx !== -1) {
                ggEvents[event.project_uuid].events[idx].isSuccess = event.isSuccess
                setGlobal({
                    ggEvents: {...ggEvents}
                })
            }
            const request = window.gapi.client.calendar.events.patch({
                "calendarId": event.project_uuid,
                "eventId": event.id,
                "extendedProperties": {
                    "private": {
                        "is_success": event.isSuccess ? 1 : 0
                    }
                }
            })
            const data = await request.then()
            if (data.status !== 200) {
                toast.error('Update Error')
                return null
            }
            return event
        } catch (e) {
            toast.error('Update Error')
            console.log(e)
            return null
        }

    } else {
        try {
            const {user} = getGlobal()
            let idx = findIndex(geEvents[event.project_uuid]?.events || [], {id: event.id})
            if (idx !== -1) {
                geEvents[event.project_uuid].events[idx].isSuccess = event.isSuccess
                setGlobal({
                    geEvents: {...geEvents}
                })
            }
            await db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection("events")
                .doc(event.id)
                .set({
                    isSuccess: Boolean(event.isSuccess)
                }, {merge: true})

            return event
        } catch (e) {
            toast.error('Update Error')
            console.log(e)
            return null
        }

    }
}


