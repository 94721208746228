import jwt from "jsonwebtoken";
import { msgError } from "../../src/utils/msg"
import { getIntlId } from "../hooks/useIntlId"


export const getJWTTokenShare = (uid, userId, evt, verify, email) => {
    return jwt.sign({
        uid: rot47(uid),
        usr: rot47(userId),
        evt: rot47(evt),
        verify: rot47(verify),
        email: rot47(email),
    }, process.env.REACT_APP_JWT_PASS_SHARE, {expiresIn: '168h'});
}

export const verifyJWTTokenShare = (token, password) => {
    if (!token)
        return null
    try {
        const data = jwt.verify(token, password)
        return {
            uid: rot47(data?.uid),
            usr: rot47(data?.usr),
            evt: rot47(data?.evt),
            verify: rot47(data?.verify),
            email: rot47(data?.email)
        }
    }catch(err){
        if(err.name === 'TokenExpiredError') {
            return 'token_expired';
        }
        else return null;
    }
}

export const rot47 = (x) => {
    if (!x?.length)
        return null;
    const array = [];
    for (let i = 0; i < x.length; i++) {
        let j = x.charCodeAt(i);
        if ((j >= 33) && (j <= 126))
            array[i] = String.fromCharCode(33 + ((j + 14) % 94))
        else
            array[i] = String.fromCharCode(j)
    }
    return array.join('')
}
