import {get} from 'lodash';
import {getGlobal} from 'reactn';
import {CALENDAR_COLLECTION, USER_COLLECTION, ZOOM_COLLECTION} from "../config/constants";
import {db} from "../firebaseConfig";

export const bar = 'bar'

export const myZoomDocRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${ZOOM_COLLECTION}/${user_id}`)
}

export const myFreeTimeDocRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${CALENDAR_COLLECTION}/${user_id}/myFreeTime/myfreetime`)
}

export const myPrjGECollRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.collection(`${CALENDAR_COLLECTION}/${user_id}/projects`)
}

export const myPrjGEDocRef = (projectId) => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${CALENDAR_COLLECTION}/${user_id}/projects/${projectId}`)
}

export const myPrjGGCollRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.collection(`${USER_COLLECTION}/${user_id}/projects`)
}

export const myPrjGGDocRef = (projectId) => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${USER_COLLECTION}/${user_id}/projects/${projectId}`)
}

export const myUserDocRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${USER_COLLECTION}/${user_id}`)
}

export const barRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${CALENDAR_COLLECTION}/${user_id}/announcement/${bar}`)
}
