import React from 'react'
import {withStyles} from "@material-ui/core"
import {ReactComponent as IdentitySVG} from '../../../asSvg/hubman.svg'
import styled from "styled-components";

const styles = {
    circle: {
        borderRadius: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    arrow: {
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 0,
    }
}

const StyledIdentitySVG = styled(IdentitySVG)`
    fill: ${props => props.color};
    stroke: ${props => props.stroke};
    padding: ${props => props.padding};
`;

function IconIdentity(props) {

    const {color = '#F2685B', stroke = null, padding = null} = props

    return (
        <StyledIdentitySVG color={color} stroke={stroke} padding={padding}/>
    )
}

export default withStyles(styles)(IconIdentity)
