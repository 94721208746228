import {remove} from "lodash";

const removeHistorySync = (events, history = [], id) => {
    if (!history?.length)
        return events

    const syncId = history.map(h => h?.eventId).filter(x => x)
    if (!syncId?.length)
        return [...events]
    let nextEvents = [...events]
    remove(nextEvents, item => !item || syncId.includes(item.id) || (item?.scheduleId && item?.scheduleId === id))
    return [...nextEvents]
}

export default removeHistorySync;
