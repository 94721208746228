import moment from "moment";
import {CALENDAR_COLLECTION, USER_COLLECTION} from "../../config/constants";
import {db} from "../../firebaseConfig";
import {getEventsRecurrence} from "../../components/Meeting/Home/utils";
import {getIntlId} from "../../hooks/useIntlId";

const _ = require('lodash')

export const getGoogleEvents = async ({userId}) => {
    try {
        const userSnap = await db.collection(CALENDAR_COLLECTION).doc(userId).get()
        if (!userSnap.exists) return []
        const user = userSnap.data()
        if (!user.googleAuth)
            return []
        // const email = user.googleAuth.email
        const snap = await db.collection(USER_COLLECTION).doc(userId).collection('calendars')
            .get()
        const data = snap.docs.map(doc => ({...doc.data(), id: doc.id}))
        // _.remove(data, item => unselect.includes(item.id))
        if (!data.length) return []
        const result = await Promise.all(data.map(async item => {
            const snap = await db.collection(`${USER_COLLECTION}/${userId}/calendars/${item.id}/events`).get()
            if (!snap.docs) return {events: []}
            return {events: snap.docs.map(event => event.data()) || []}
        }))
        return result.map(item => formatGoogleEvents(item.events)).flat()
    } catch (e) {
        return []
    }
}

export const formatGoogleEvents = (events) => {
    if (!events || !events.length)
        return []
    const data = events.map(evt => {
        let recurrence = null
        if (_.isArray(evt?.recurrence)) {
            let idx = _.findIndex(evt.recurrence, r => r.includes("RRULE:"))
            if (idx !== -1)
                recurrence = evt.recurrence[idx]
        }
        if ((!evt?.start || !evt?.end) && evt.recurringEventId) return {...evt}
        else if (!evt?.start || !evt?.end) return null
        return ({
            id: evt.id,
            start: evt.start.date ? moment(evt.start.date).utc().format() : moment(evt.start.dateTime).utc().format(),
            end: evt.end.date ? moment(evt.end.date).utc().format() : moment(evt.end.dateTime).utc().format(),
            title: evt.summary || getIntlId('setting.identityNoTitle'),
            allDay: Boolean(evt.start.date),
            meetingId: _.get(evt, 'extendedProperties.private.meetingId', null),
            scheduleId: _.get(evt, 'extendedProperties.private.scheduleId', null),
            task: _.get(evt, 'extendedProperties.private.task', null),
            recurrence: recurrence || null,
            recurringEventId: evt.recurringEventId || null,
            timeZone: evt.start.timeZone || null,
            creator: evt.creator
        })
    }).filter(x => x)
    const recurrence = _.remove(data, item => !item || item.recurrence)
    const recurEdited = _.remove(data, e => !e || e.recurringEventId)
    const recurring = getEventsRecurrence(recurrence, recurEdited)
    return _.concat(data, recurring)
}


