import {useEffect} from 'react';

export function useDBKeyPress(targetKey, callbackFunction, deps = [], action = false) {
    const {priKey, key} = targetKey
    useEffect(() => {
        function downHandler(event) {
            if (event[priKey] && (event.key === key) && action) {
                event.preventDefault()
                callbackFunction()
            }
        }

        window.addEventListener('keydown', downHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
        // eslint-disable-next-line
    }, deps)
}

export function useKeyPress(targetKey, callbackFunction, deps = [], action = false) {
    useEffect(() => {
        function downHandler(event) {
            if (event.key === targetKey && action) {
                event.preventDefault()
                callbackFunction()
            }
        }

        window.addEventListener('keydown', downHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
        // eslint-disable-next-line
    }, deps)
}
