import {db} from '../../../firebaseConfig'
import {CALENDAR_COLLECTION, GOOGLE_REFRESH_TOKEN} from "../../../config/constants";
import axios from 'axios'

export const createEvent = async (projectId, userId, event) => {
    try {
        const userRef = db.doc(`${CALENDAR_COLLECTION}/${userId}`)
        const docSnap = await userRef.get()
        if (!docSnap.exists)
            return null

        const data = docSnap.data()
        const {googleAuth} = data
        if (!googleAuth)
            return null
        const {refreshToken} = googleAuth
        if (!refreshToken)
            return null
        let res = await axios.get(`${GOOGLE_REFRESH_TOKEN}?refreshToken=${refreshToken}`)
        const {access_token} = res.data
        res = await axios.post(`https://www.googleapis.com/calendar/v3/calendars/${projectId}/events`, event, {
            headers: {
                Authorization: "Bearer " + access_token
            }
        })
        return res.data
    } catch (e) {
        console.log(e);
        return null
    }

}

export const getAccessTokenUser = async (userId) => {
    const userRef = db.doc(`${CALENDAR_COLLECTION}/${userId}`)
    const docSnap = await userRef.get()
    if (!docSnap.exists)
        return null

    const data = docSnap.data()
    const {googleAuth} = data
    if (!googleAuth)
        return null
    const {refreshToken} = googleAuth
    if (!refreshToken)
        return null
    let res = await axios.get(`${GOOGLE_REFRESH_TOKEN}?refreshToken=${refreshToken}`)
    const {access_token = null} = res.data
    return access_token;
}

export const updateExtendedPropertiesEventGoogle = async (projectId, userId, event) => {
    if (!projectId || !userId || !event?.id) return null
    const access_token = await getAccessTokenUser(userId)
    // console.log({projectId, userId, event, access_token})
    return axios.patch(`https://www.googleapis.com/calendar/v3/calendars/${projectId}/events/${event.id}`, {
        extendedProperties: event.extendedProperties
    }, {
        headers: {
            Authorization: "Bearer " + access_token
        }
    })
}

export const deleteEvent = async (projectId, userId, eventId) => {
    try {
        const userRef = db.doc(`${CALENDAR_COLLECTION}/${userId}`)
        const docSnap = await userRef.get()
        if (!docSnap.exists)
            return null

        const data = docSnap.data()
        const {googleAuth} = data
        if (!googleAuth)
            return null
        const {refreshToken} = googleAuth
        if (!refreshToken)
            return null
        let res = await axios.get(`${GOOGLE_REFRESH_TOKEN}?refreshToken=${refreshToken}`)
        const {access_token} = res.data
        res = await axios.delete(`https://www.googleapis.com/calendar/v3/calendars/${projectId}/events/${eventId}`, {
            headers: {
                Authorization: "Bearer " + access_token
            }
        })
        return res.data
    } catch (e) {
        console.log(e);
        return null
    }

}


export const updateShareURLEvent = async (projectId, eventId, userId, event) => {
    try {
        const userRef = db.doc(`${CALENDAR_COLLECTION}/${userId}`)
        const docSnap = await userRef.get()
        if (!docSnap.exists)
            return null

        const data = docSnap.data()
        const {googleAuth} = data
        if (!googleAuth)
            return null
        const {refreshToken} = googleAuth
        if (!refreshToken)
            return null
        let res = await axios.get(`${GOOGLE_REFRESH_TOKEN}?refreshToken=${refreshToken}`)
        const {access_token} = res.data
        res = await axios.patch(`https://www.googleapis.com/calendar/v3/calendars/${projectId}/events/${eventId}?conferenceDataVersion=1`, event, {
            headers: {
                Authorization: "Bearer " + access_token
            }
        })
        return res.data
    } catch (e) {
        console.log(e);
        return null
    }

}
