import React, {useEffect, useState} from 'react';
import {getGlobal, useGlobal} from 'reactn';
import styled from 'styled-components';
import {Tooltip} from 'antd';
import moment from 'moment';
import SaveButton from '../../Custom/SaveButton';
import {getLinkZoom, handleCopy, handleCopyZoom} from '../../Meeting/common/getLinkZoom';
import useIntlId from '../../../hooks/useIntlId';
import {zoneCustomFormat, zoneDate, zoneHHmm} from '../../../common/moment/format';
import {toast} from 'react-toastify';
import {useAppointmentEvent} from '../../../context/AppointmentEventContext';

const TooltipCustom = styled(Tooltip)`

`

const DivTitleCustom = styled.div`
    width: 100%;
    min-width: 200px;
    height: auto;
    background: #FFFFFF;
`;

const DivTitle = styled.div`
    color: ${props => props.color};
    width: 100%;
    text-align: center;
    background: #F1F1F1;
    border-radius: 7px;
    font-weight: bold;
    padding: 3px;
    line-height: 1.4;
    //height: 28px;
`

const DivContent = styled.div`
    width: 100%;
    color: #000000;
    font-size: 1rem;
    font-weight: unset;
    text-align: center;
    padding: 10px;
`;

const DivText = styled.div`
    text-align: left;
`

function HoverEventCalendar({children, hoverTitle, placement, deleteEnable = true, isLocal = false}) {
    const [colors] = useGlobal('colors');
    const {meeting} = getGlobal();
    const [isSameDay, setIsSameDay] = useState(true)
    const [copiedIntl] = useIntlId(['toast.success.calendar.copied'])

    useEffect(() => {
        if (hoverTitle && hoverTitle.start && hoverTitle.end) {
            setIsSameDay(moment(hoverTitle.start).isSame(hoverTitle.end, 'day'))
        }
    }, [hoverTitle])

    if (!children)
        return null;

    return (
        <TooltipCustom
            title={<TitleChildren hoverTitle={hoverTitle} colors={colors} isSameDay={isSameDay}
                                  handleCopy={
                                      () => {
                                          handleCopy(hoverTitle)
                                          toast.success(`${copiedIntl}`)
                                      }

                                  }
                                  handleCopyZoom={async () => {
                                      await handleCopyZoom(hoverTitle, meeting)
                                      toast.success(`${copiedIntl}`)
                                  }}
                                  deleteEnable={deleteEnable}
                                  isLocal={isLocal}
            />}
            placement={placement}
            // visible={true}
            overlayClassName={'hover-show-tooltip'}
            destroyTooltipOnHide={{keepParent: false}}
        >
            {children}
        </TooltipCustom>
    )
}

export default HoverEventCalendar;

export const TitleChildren = ({hoverTitle, colors, handleCopy, loading = false, isLocal, handleCopyZoom}) => {
    const isSameDay = zoneDate(hoverTitle?.start, isLocal) === zoneDate(hoverTitle?.end, isLocal)
    const [eventByIntl] = useIntlId(['event.by'])
    const [zoomDisable, setZoomDisable] = useState(false);
    const {meeting} = useAppointmentEvent()

    useEffect(() => {
        if (!hoverTitle) return null
        const extendedProps = hoverTitle?.extendedProps
        const linkZoom = getLinkZoom(extendedProps, meeting)
        if (linkZoom) setZoomDisable(false)
        else setZoomDisable(true)
        // eslint-disable-next-line
    }, [hoverTitle])

    if (!hoverTitle)
        return ''

    return (
        <DivTitleCustom>
            <DivTitle color={colors?.primary}>
                {hoverTitle.title}
            </DivTitle>
            <DivContent>
                {
                    isSameDay ?
                        <DivText>{zoneCustomFormat(hoverTitle.start, 'MM/DD', isLocal)} ({zoneCustomFormat(hoverTitle.start, 'ddd', isLocal)}) </DivText>
                        :
                        <DivText>{zoneCustomFormat(hoverTitle.start, 'MM/DD', isLocal)} - {zoneCustomFormat(hoverTitle.end, 'MM/DD', isLocal)} ({zoneCustomFormat(hoverTitle.start, 'ddd', isLocal)})</DivText>

                }
                {
                    !hoverTitle?.allDay &&
                    <DivText>{zoneHHmm(hoverTitle?.start, isLocal)} - {zoneHHmm(hoverTitle?.end, isLocal)}</DivText>
                }
                {
                    hoverTitle?.extendedProps?.creator ?
                        <DivText>{eventByIntl}: {hoverTitle.extendedProps.creator.email ? hoverTitle.extendedProps.creator.email : hoverTitle.extendedProps.creator}</DivText>
                        :
                        hoverTitle?.extendedProps?.emailVoter ?
                            <DivText>{eventByIntl}: {hoverTitle.extendedProps.emailVoter}</DivText>
                            : null
                }
            </DivContent>
            <SaveButton
                // saveClick={saveClick}
                copyTime={handleCopy}
                copyZoom={handleCopyZoom}
                disable={loading}
                disableZoom={zoomDisable}
            />
        </DivTitleCustom>
    )
}
