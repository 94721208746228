import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn'
import TopBar from "./TopBar";
import ShareFreeTimeUrlCal from "./Cal";
import {Dialog, DialogContent, DialogContentText, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import DialogActions from "@material-ui/core/DialogActions";
import {toast} from "react-toastify";
import validator from "email-validator";
import {Button} from 'antd'
import {ReactComponent as ArrowDropDownWhiteSVG} from "../../../asSvg/icons/arrowDownWhite.svg";
import {useMyFreeTime} from "../../../context/MyFreeTimeContext";
import DivContent from "../common/DivContent";
import RequestDialog from "./RequestDialog";
import {useAdjustmentEvents} from "../../../context/AdjustmentEventsContext";
import useIntlId from "../../../hooks/useIntlId";
import MenuAppBarGlobal from "../Home/MenuAppBarGlobal";
import ProjectSelection from '../Home/ProjectSelection';

function ShareFreeTimeUrl() {
    const [user] = useGlobal("user")
    const [colors] = useGlobal("colors")
    const [eventConfirm, setEventConfirm] = useGlobal('eventConfirm')
    const classes = useStyles()
    const [fullHeight, setFullHeight] = useState('fullHeight')
    const {myFreeTime} = useMyFreeTime();
    const [viewEvent, setViewEvent] = useState(null);
    const [showExpired, setShowExpired] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [selectedColorId, setSelectedColorId] = useState(null)
    const {info, modify, loading, freeTime, settingUser, expired, setExpired} = useAdjustmentEvents();
    const [collapseIntl, fullHeightIntl, seender, reminder] = useIntlId(['view.collapse', 'view.fullHeight', 'appy.view.seender', 'appy.view.reminder'])
    const seenderId = seender ? seender.split("|") : ''
    useEffect(() => {
        if (myFreeTime?.viewEvent) {
            setViewEvent(myFreeTime?.viewEvent)
        }
    }, [myFreeTime])

    const handleFullHeight = () => {
        if (fullHeight)
            setFullHeight('')
        else
            setFullHeight('fullHeight')
    }


    return (
        <div>
            {
                user?.user_id ?
                    <MenuAppBarGlobal viewEvent={viewEvent} setViewEvent={setViewEvent}/>
                    :
                    <TopBar/>
            }
            {/*<NotificationTimeZone/>*/}
            <div className={user?.user_id && classes.root}>
                <div className={classes.memberTitle}>
                    <span>{seenderId[0] + (info?.name ? info?.name : '') + seenderId[1]} </span><br/>
                    <span>{reminder}</span>
                </div>
                <div className={`${classes.headerShare} headerShare`}>
                    {
                        user?.user_id ?
                            <ProjectSelection
                                callBack={setSelectedProjectId}
                                setIdentitySync={setSelectedColorId}
                                isShareUrl={true}
                            />
                            :
                            <div className={classes.title}/>
                    }
                <DivContent classes={classes} info={info}/>
                    <div>
                        <Button
                            className={classes.btnFullHeight}
                            onClick={handleFullHeight}
                            shape="round"
                        >{
                            fullHeight ? <span>{collapseIntl}<ArrowDropDownWhiteSVG className={"iconUp"}
                                                                              style={{fill: colors?.primary}}/></span>
                                : <span>{fullHeightIntl}<ArrowDropDownWhiteSVG className={"iconDown"}
                                                                          style={{fill: colors?.primary}}/></span>
                        }
                        </Button>
                    </div>
                </div>
            </div>
            <ShareFreeTimeUrlCal infoShare={info}
                                 freeTimeEvent={freeTime}
                                 modify={modify}
                                 eventConfirm={eventConfirm}
                                 fullHeight={fullHeight}
                                 setEventConfirm={setEventConfirm}
                                 loading={loading}
                                 expired={expired}
                                 setShowExpired={setShowExpired}
                                 settingUser={settingUser}
                                 selectedProjectId={selectedProjectId}
                                 selectedColorId={selectedColorId}
            />
            {
                showExpired &&
                <ExpiredDialog
                    open={showExpired}
                    setOpen={setShowExpired}
                    data={expired}
                    setData={setExpired}
                />
            }
        </div>
    );
}

function ExpiredDialog({open, setOpen, data, setData}) {
    const classes = useStyles()
    const [user] = useGlobal("user")
    const [emailRequest, setEmailRequest] = useState("")
    const [nameRequest, setNameRequest] = useState("")
    const [openForm, setOpenForm] = useState(false)
    const [emailNotValidIntl, nameIntl, emailIntl, pleaseContactOwnerURLIntl, pleaseContactURLIntl, pleaseReSettingURLIntl, urlNotExistIntl, requestTheOwnerBtnIntl, requestGoToSettingPageBtnIntl, requestGoToNameSettingPageBtnIntl] = useIntlId([
        'event.toast.emailNotValid',
        'app.profile.name',
        'event.dialog.request.email',
        'event.dialog.request.pleaseContactOwnerURL',
        'event.dialog.request.pleaseContactURL',
        'event.dialog.request.pleaseReSettingURL',
        'event.dialog.request.urlNotExist',
        'event.dialog.requestTheOwnerBtn',
        'event.dialog.requestGoToSettingPageBtn',
        'event.dialog.requestGoToNameSettingPageBtn'
    ])

    useEffect(() => {
        if (user?.user_id) {
            const fullName = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname
            setNameRequest(fullName)
            setEmailRequest(user?.email)
        }
    }, [user])

    const goToSetting = () => {
        setData(null)
        setOpen(false)
        window.location = `/shareUrl?idShare=${data?.id}`
    }

    const goToHomeShare = () => {
        setData(null)
        setOpen(false)
        window.location = `/shareUrl`
    }

    const handleRequest = () => {
        if (!validator.validate(emailRequest)) {
            return toast.error(`${emailNotValidIntl}`)
        }
        return setOpenForm(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Dialog
            open={Boolean(open)}
            onClose={handleClose}
        >
            <DialogContent>
                <DialogContentText>
                    {
                        !data?.isExpired ? `${urlNotExistIntl}`
                            :
                            (
                                data?.isMaxTimes ?
                                    data?.usr === user?.user_id ?
                                        `${pleaseReSettingURLIntl}`
                                        :
                                        `${pleaseContactURLIntl}`
                                    :
                                    `${pleaseContactOwnerURLIntl}`
                            )
                    }
                </DialogContentText>
                {
                    data?.usr !== user?.user_id &&
                    <div>
                        <TextField
                            type={'text'}
                            variant="outlined"
                            value={nameRequest}
                            onChange={e => setNameRequest(e.target.value)}
                            placeholder={nameIntl}
                            fullWidth={true}
                            style={{marginBottom: 10}}
                            id={"nameRequest"}
                        />
                        <TextField
                            type={'email'}
                            variant="outlined"
                            value={emailRequest}
                            onChange={e => setEmailRequest(e.target.value)}
                            placeholder={emailIntl}
                            fullWidth={true}
                            id={"emailRequest"}
                            InputProps={{
                                readOnly: Boolean(user?.user_id),
                            }}
                        />
                    </div>
                }

                <RequestDialog openForm={openForm}
                               setOpenForm={setOpenForm}
                               emailRequest={emailRequest}
                               nameRequest={nameRequest}
                               mailTo={data?.email}
                               usr={data?.usr}
                               scheduleId={data?.id}
                               setOpen={setOpen}
                               language={data?.language}
                />
            </DialogContent>
            <DialogActions>
                {
                    data?.usr !== user?.user_id &&
                    <Button className={classes.button} disabled={!emailRequest || !nameRequest}
                            onClick={handleRequest}>{requestTheOwnerBtnIntl}</Button>
                }
                {
                    data?.usr === user?.user_id && data?.nameSetting &&
                    <Button className={classes.button}
                            onClick={goToSetting}>{requestGoToNameSettingPageBtnIntl?.replace('name', `${data.nameSetting}`)}</Button>
                }
                {
                    data?.usr === user?.user_id && !data?.nameSetting &&
                    <Button className={classes.button} onClick={goToHomeShare}>{requestGoToSettingPageBtnIntl}</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    button: {
        margin: '10px auto',
        background: "#99C355",
        color: "#fff",
        fontWeight: 'bold',
        padding: "5px 10px",
        borderRadius: "15px",
        "&:hover": {
            background: "#99C355",
            color: "#fff",
        }
    },
    headerShare: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
        padding: 10,
        marginTop: 10
    },
    title: {
        color: `${theme.props.primaryColor}`,
        textTransform: "uppercase",
        fontSize: 14,
        fontWeight: "bold",
        minWidth: 120,
    },
    content: {
        fontSize: 12,
        fontWeight: "bold",
    },
    btnFullHeight: {
        color: '#fff',
        background: theme.props.primaryColor,
        margin: '8px 0 8px 5px',
        fontSize: 12,
        fontWeight: "bold",
        textTransform: "uppercase",
        border: "none",
        padding: "4px 8px",
        minWidth: 125,
        display: "flex",
        justifyContent: "flex-end",
        "&:hover": {
            background: theme.props.primaryColor,
            opacity: 0.8,
            color: '#fff',
            border: "none",
        },
        "&:focus": {
            background: theme.props.primaryColor,
            opacity: 0.8,
            color: '#fff',
            border: "none",
        },
        "& span": {
            display: "flex",
            alignItems: "center",
            lineHeight: "23px",
        },
        "& svg": {
            marginLeft: 4,
        },
        "& iconUp": {
            transform: "rotate(180deg)"
        }
    },
    root: {
        position: "static !important",
        marginTop: 64
    },
    red: {
        color: "#ff0000"
    },
    memberTitle: {
        position: 'absolute',
        display: "block",
        left: 10,
        top: 90,
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'left',
    }
}))

export default ShareFreeTimeUrl;
