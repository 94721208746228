import React, {createContext, useContext, useEffect, useState} from "react"
import moment from "moment";
import qs from "query-string";
import {chunk, concat, isFunction, remove} from "lodash";
import {db} from "../firebaseConfig";
import {CALENDAR_COLLECTION, USER_COLLECTION} from "../config/constants";
import {formatGoogleEvents} from "./common/getGoogleEvents";
import {eventSuccessRef, getDocTaskFreeRef} from "../common/firebaseRef/meetings";
import {getDateRange} from "../common/getDateRange";
import {getEventsRecurrence} from "../components/Meeting/Home/utils";
import {toast} from "react-toastify";
import {freeSlot} from "../components/Meeting/Fullcalendar/Actions/freeSlot";
import validEvent from "../common/validEvent";
import isHiddenDays from "../common/isHiddenDays";
import isDateBusy from "../common/isDateBusy";
import isEnoughTime from "../common/isEnoughTime";
import {autoFreeTimes} from "./common/autoFreeTimes";
import {verifyJWTTokenShare} from "../common/jwt";
import {useGlobal} from "reactn";

const EventAdjustmentContext = createContext({})

export const useAdjustmentEvents = () => {
    return useContext(EventAdjustmentContext);
}

export function EventAdjustmentProvider({children}) {
    const events = useEventsAdjustment()
    return (
        <EventAdjustmentContext.Provider value={events}>
            {children}
        </EventAdjustmentContext.Provider>
    )
}

const useEventsAdjustment = () => {
    const [user, setUser] = useState(null)
    const [info, setInfo] = useState(null)
    const [geniamEvents, setGeniamEvents] = useState([])
    const [geEventSelect, setGeEventSelect] = useState([])
    const [googleEvents, setGoogleEvents] = useState([])
    const [ggEventsSelect, setGGEventSelect] = useState([])
    const [eventsDontSync, setEventsDontSync] = useState([])
    const [totalAdjustmentEvents, setTotalEvents] = useState([])
    const [geCals, setGECals] = useState([])
    const [ggCals, setGGCals] = useState([]);
    const [validUrl, setValidUrl] = useState(null);
    const [eventConfirmed, setEventConfirmed] = useState(null)
    const [eventConfirm,] = useGlobal('eventConfirm')
    const [taskFree, setTaskFree] = useState([]);
    const [freeTime, setFreeTime] = useState([]);
    const [expired, setExpired] = useState(null)
    const [loading, setLoading] = useState(false)
    const [modify, setModify] = useState(false)
    const [changeHourCurrent, setChangeHourCurrent] = useState(0)
    const query = qs.parse(window.location.search)

    useEffect(() => {
        const runInterval = setInterval(function () {
            setChangeHourCurrent(changeHourCurrent + 1)
        }, 30 * 60 * 1000);
        return () => clearInterval(runInterval);
        // eslint-disable-next-line
    }, [changeHourCurrent])

    useEffect(() => {
        if (!freeTime?.length || changeHourCurrent === 0) return null
        const autoMyFreeSlot = autoFreeTimes(freeTime, user?.timeVote)
        setFreeTime(autoMyFreeSlot)
        // eslint-disable-next-line
    }, [changeHourCurrent])

    useEffect(() => {
        if (!user) return null
        // console.log("get list calendar")
        let proSub = db.collection(`${CALENDAR_COLLECTION}/${user?.id}/projects`)
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                    msSelected: doc.data().msSelected !== undefined ? doc.data().msSelected : true,
                    apSelected: doc.data().apSelected !== undefined ? doc.data().apSelected : true,
                }));
                remove(data, pro => pro?.is_sync || pro.is_deleted)
                setGECals(data)
            })

        let proGGSub = db.collection(`${USER_COLLECTION}/${user?.id}/projects`)
            .onSnapshot(snapshot => {
                if (!snapshot.docs) return setGECals([])
                const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                const ggCal = data.filter(pro => pro && pro.is_google)
                setGGCals(ggCal)
            })

        return () => {
            if (isFunction(proSub))
                proSub()
            if (isFunction(proGGSub))
                proGGSub()
        }
        // eslint-disable-next-line
    }, [user]);


    useEffect(() => {
        const {tokenShare} = query;
        const data = verifyJWTTokenShare(tokenShare, process.env.REACT_APP_JWT_PASS_SHARE);
        if (!data)
            return null;
        if (data === 'token_expired') {
            setExpired(true)
            return null;
        }
        const {uid, usr, evt, verify} = data;
        if (!uid || !usr)
            return null
        console.log("Get setting schedule and user setting")
        setLoading(true)
        let evtSub
        let scheduleSub = db.doc(`${CALENDAR_COLLECTION}/${usr}/scheduleAdjustment/${uid}`)
            .onSnapshot(doc => {
                if (!doc.exists || doc.data().isDeleted) {
                    setExpired({...doc.data(), isExpired: false, usr: usr})
                    setLoading(false)
                    return
                } else if (moment().isAfter(moment(doc.data()?.exp)) || moment().isAfter(moment(info?.end).endOf("day"))) {
                    setLoading(false)
                    setExpired({...doc.data(), isExpired: true, usr: usr})
                    return
                } else if (!doc.data()?.unlimited) {
                    const times = Number(doc.data().times || 1)
                    const usedTime = Number(doc.data().used || 0)
                    if (usedTime >= times && !evt && !verify) {
                        setExpired({...doc.data(), isExpired: true, usr: usr, isMaxTimes: true})
                        setLoading(false)
                        return
                    }
                }
                setValidUrl(doc.data())
                setLoading(false)
            })
        if (evt && verify) {
            setModify(true)
            const eventRef = db.doc(`${CALENDAR_COLLECTION}/${usr}/eventBoxMeeting/${evt}`)
            evtSub = eventRef.onSnapshot(snapshot => {
                if (!snapshot.exists) {
                    toast.error("schedule does not exists or deleted")
                    return
                }
                const data = snapshot.data()
                if (data.token === verify)
                    setEventConfirmed({
                        ...data,
                        id: snapshot.id,
                        borderColor: 'red',
                        backgroundColor: "#C7965BFF"
                    })
            })
        }
        return () => {
            if (evtSub) evtSub()
            if (scheduleSub) scheduleSub()
        }
        // eslint-disable-next-line
    }, [query?.tokenShare]);

    //get realtime shared info
    useEffect(() => {
        let sub
        if (validUrl) {
            const {tokenShare} = query;
            const data = verifyJWTTokenShare(tokenShare, process.env.REACT_APP_JWT_PASS_SHARE);
            if (!data)
                return null;
            const {uid, usr} = data;
            const time = getDateRange(validUrl?.user?.timeRange)
            setInfo({...validUrl, id: uid, usr, ...time})
            setUser({...validUrl?.user, id: usr})
        }
        return () => {
            if (sub) {
                sub()
            }
        }
        // eslint-disable-next-line
    }, [validUrl])


    useEffect(() => {
        // console.log("Geniam event select")
        const groupSelect = geCals.filter(cal => cal?.apSelected)
        if (!groupSelect?.length) return setGeEventSelect([])
        const calIds = groupSelect.map(cal => cal?.id).filter(x => x) || []
        const eventGe = geniamEvents.map(e => {
            if (e && calIds.includes(e?.project_uuid)) {
                return {...e}
            }
            return null;
        }).filter(x => x)
        // console.log({eventGe})
        setGeEventSelect(eventGe)
    }, [geCals, geniamEvents])

    useEffect(() => {
        // console.log("Google event select")
        const groupSelect = ggCals.filter(cal => cal?.apSelected)
        if (!groupSelect?.length) return setGGEventSelect([])
        const calIds = groupSelect.map(cal => cal?.id).filter(x => x) || []
        const eventGG = googleEvents.map(e => {
            if (e && calIds.includes(e?.project_uuid)) {
                return {...e}
            }
            return null;
        }).filter(x => x)
        // console.log({eventGG})
        setGGEventSelect(eventGG)
        // eslint-disable-next-line
    }, [ggCals, googleEvents])

    useEffect(() => {
        setTotalEvents(concat(geEventSelect, ggEventsSelect, eventsDontSync))
        // console.log({totalAdjustmentEvents})
        // eslint-disable-next-line
    }, [geEventSelect, ggEventsSelect, eventsDontSync])

    useEffect(() => {
        if (!geCals?.length || !user)
            return null;
        let isMapPro = true
        const chunksArr = chunk(geCals, 10)
        let events = {}
        const subs = chunksArr.map((items, index) => {
            return db.collection(CALENDAR_COLLECTION)
                .doc(user.id)
                .collection('events').where("isDeleted", '==', false)
                .where('project_uuid', 'in', items.map(i => i.id))
                .onSnapshot(snapshot => {
                    if (!isMapPro) setLoading(false)
                    else if (index === chunksArr?.length - 1) {
                        setLoading(false)
                        isMapPro = false
                    } else setLoading(true)

                    const data = snapshot.docs.map(doc => ({
                        ...doc.data(), id: doc.id, backgroundColor: "#808080",
                        borderColor: "transparent",
                        editable: false,
                    }))

                    const recurrence = remove(data, item => !item || item.recurrence)
                    const recurEdited = remove(data, e => !e || e.recurringEventId)
                    const recurring = getEventsRecurrence(recurrence, recurEdited)
                    let dataBusy = concat(data, recurring).filter(event => event && moment(event.start).isBefore(moment().add(57, 'day')))
                    events[`${index}`] = dataBusy
                    const total = Object.keys(events).map(key => {
                        return events[key] || []
                    }).flat()
                    setGeniamEvents(total)
                    //setLoading(false)
                })
        })
        return () => {
            if (subs?.length) {
                subs.forEach(u => {
                        if (u) u()
                    }
                )
            }
        }
        // eslint-disable-next-line
    }, [geCals?.length])

    useEffect(() => {
        if (!ggCals?.length || !user) {
            setGoogleEvents([])
            return null;
        }
        let events = {}
        let isMapPro = true
        const subs = ggCals.map((item, index) => {
            return db.collection(USER_COLLECTION)
                .doc(user.id)
                .collection('calendars')
                .doc(item.id)
                .collection('events')
                .onSnapshot(snapshot => {
                    if (!isMapPro) setLoading(false)
                    else if (index === ggCals?.length - 1) {
                        setLoading(false)
                        isMapPro = false
                    } else setLoading(true)

                    let data = formatGoogleEvents(snapshot.docs.map(doc => ({
                        ...doc.data(), id: doc.id,
                    }))).map(e => ({
                        ...e, backgroundColor: "#808080",
                        borderColor: "transparent",
                        editable: false,
                        project_uuid: item.id
                    }))
                    const dataBusy = data.filter(event => event && moment(event.end).isBefore(moment().add(57, 'day')))
                    events[`${item.id}`] = dataBusy || []
                    const total = Object.keys(events).map(key => {
                        return events[key] || []
                    }).flat()
                    // console.log(total)
                    setGoogleEvents(total)
                    //setLoading(false)
                })
        })
        return () => {
            if (subs?.length) {
                subs.forEach(u => {
                        if (u) u()
                    }
                )
            }
        }
        // eslint-disable-next-line
    }, [ggCals?.length])

    useEffect(() => {
        if (!user?.id) return null
        let subTask
        let subEvent
        getDocTaskFreeRef(user.id).onSnapshot((snapshot) => {
            if (!snapshot.exist) return setTaskFree([])
            const tasksFree = snapshot.data()?.taskFree || []
            setTaskFree(tasksFree)
        })
        eventSuccessRef(user.id).onSnapshot({
            includeMetadataChanges: true
        }, snap => {
            if (!snap.docs) return setEventsDontSync([])
            const events = []
            snap.docs.forEach(doc => {
                let data = doc.data()
                if (!data.isSync) // event khong sync project
                    events.push({
                        ...data,
                        id: doc.id,
                        backgroundColor: "#808080",
                        borderColor: "transparent",
                        editable: false,
                    })
            })
            setEventsDontSync(events)
        });

        return () => {
            if (isFunction(subTask))
                subTask()
            if (isFunction(subEvent))
                subEvent()
        }
        // eslint-disable-next-line
    }, [user?.id])

    useEffect(() => {
        if (!info || !user) return null
        // console.log({info, user})
        let events = [...totalAdjustmentEvents]
        if (user?.freeTime > 0)
            events = [...totalAdjustmentEvents].map(e => ({
                ...e,
                start: moment(e?.start).subtract(user?.freeTime, "minutes").format(),
                end: moment(e?.end).add(user?.freeTime, "minutes").format()
            }))
        let allBusy = concat(events, user?.busyBox)
        if (eventConfirm) allBusy = concat(allBusy, [eventConfirm])
        const eventFree = freeSlot(info?.start, info?.end, allBusy, '#009A4C', user, user?.exBoxFreeShare, false)
        remove(eventFree, e => !isEnoughTime(e, user?.timeVote) || !validEvent(e) || isHiddenDays(e, user?.hiddenDays) || isDateBusy(e, user?.dateSelections))
        setFreeTime(eventFree)
        // eslint-disable-next-line
    }, [totalAdjustmentEvents, user, eventConfirm])

    return {
        totalAdjustmentEvents,
        info,
        loading,
        eventConfirmed,
        taskFree,
        expired,
        setExpired,
        freeTime,
        settingUser: user,
        modify
    }
}
