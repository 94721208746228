import {IS_PRODUCTION} from '../../config/constants';


export const products = IS_PRODUCTION ?
    [
        {
            name: "HXプロフェッショナル",
            subName1: "ツールを使ってHX支援",
            subName2: "Live配信と録画で",
            subName3: "１人でいつでも学べる",
            id: "prod_Kp7AHLTSapUz5E",
            price: {
                month: "price_1K9SwUKtz9s4YUGtqrjodzI9",
                year: "price_1K9SwUKtz9s4YUGti4AR1yYh"
            },
            amount: {
                month: 4980,
                year: 49800,
                coinsPlus: 10000,
                saved: 9960,
                totalSaved: 19960,
            },
            image: "/images/geniamplan2.svg",
            background: "linear-gradient(91.53deg, #9F2AFB -0.82%, #DF2D6D 99.49%)",
            color: "#9F2AFB"
        },
        {
            name: "HXスペシャリスト",
            subName1: "ツールを使ってHX支援",
            subName2: "1on1の実践と対話で",
            subName3: "仲間と切磋琢磨できる",
            id: "prod_Kp7BMBp6j67aQC",
            price: {
                month: "price_1K9SwhKtz9s4YUGtJM8ro5ug",
                year: "price_1K9SwgKtz9s4YUGtfulWSaFc"
            },
            amount: {
                month: 9980,
                year: 99800,
                coinsPlus: 30000,
                saved: 19960,
                totalSaved: 49960
            },
            image: "/images/geniamplan3.svg",
            background: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%)",
            color: "#F29912"
        },
        {
            name: "HXエキスパート",
            subName1: "ソリューションを創ってHX支援",
            subName2: "経営者、法人向けの",
            subName3: "HX事業を伸ばす",
            id: "prod_Kp7BmTB7K1vSpO",
            price: {
                month: "price_1K9SwqKtz9s4YUGtp3VEGwGU",
                year: "price_1K9SwqKtz9s4YUGtbJ82izIC"
            },
            amount: {
                month: 14980,
                year: 149800,
                coinsPlus: 50000,
                saved: 29600,
                totalSaved: 79600,
            },
            image: "/images/geniamplan4.svg",
            background: "linear-gradient(91.53deg, rgb(238,118,59) -0.82%, rgb(235,51,36) 99.49%)",
            color: "rgb(235,51,36)"
        },
    ]
    :
    [
        {
            name: "HXプロフェッショナル",
            subName1: "ツールを使ってHX支援",
            subName2: "Live配信と録画で",
            subName3: "１人でいつでも学べる",
            id: "prod_Kp0lgoc1ypEZcs",
            price: {
                month: "price_1K9WorKtz9s4YUGtx3zi4OHE",
                year: "price_1K9MjWKtz9s4YUGt68qRQ4Y8"
            },
            amount: {
                month: 4980,
                year: 49800,
                coinsPlus: 10000,
                saved: 9960,
                totalSaved: 19960,
            },
            image: "/images/geniamplan2.svg",
            background: "linear-gradient(91.53deg, #9F2AFB -0.82%, #DF2D6D 99.49%)",
            color: "#9F2AFB"
        },
        {
            name: "HXスペシャリスト",
            subName1: "ツールを使ってHX支援",
            subName2: "1on1の実践と対話で",
            subName3: "仲間と切磋琢磨できる",
            id: "prod_Kp1doQSIKZVWsp",
            price: {
                month: "price_1K9NZuKtz9s4YUGtl3JBNAtX",
                year: "price_1K9NZuKtz9s4YUGtj5zdKc8E"
            },
            amount: {
                month: 9980,
                year: 99800,
                coinsPlus: 30000,
                saved: 19960,
                totalSaved: 49960
            },
            image: "/images/geniamplan3.svg",
            background: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%)",
            color: "#F29912"
        },
        {
            name: "HXエキスパート",
            subName1: "ソリューションを創ってHX支援",
            subName2: "経営者、法人向けの",
            subName3: "HX事業を伸ばす",
            id: "prod_Kp1esAcpRs85yb",
            price: {
                month: "price_1K9NatKtz9s4YUGthUokiJtb",
                year: "price_1K9NatKtz9s4YUGt0Zm00d8n"
            },
            amount: {
                month: 14980,
                year: 149800,
                coinsPlus: 50000,
                saved: 29600,
                totalSaved: 79600,
            },
            image: "/images/geniamplan4.svg",
            background: "linear-gradient(91.53deg, rgb(238,118,59) -0.82%, rgb(235,51,36) 99.49%)",
            color: "rgb(235,51,36)"
        },
    ]
//
// // step 2
// export const secondProducts = IS_PRODUCTION ?
//     [
//         {
//             name: "HXエキスパート",
//             subName1: "ツールを使ってHX支援",
//             subName2: "Live配信と録画で１人でいつでも学べる",
//             id: "prod_Kp7BmTB7K1vSpO",
//             price: {
//                 month: "price_1K9SwqKtz9s4YUGtp3VEGwGU",
//                 year: "price_1K9SwqKtz9s4YUGtbJ82izIC"
//             },
//             amount: {
//                 month: 14980,
//                 year: 149800,
//                 coinsPlus: 80000,
//                 saved: 29600,
//                 totalSaved: 119960,
//             },
//             image: "/images/plan/image4.png",
//             background: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%)",
//             color: "#F29912"
//         },
//         {
//             name: "HXエキスパートPlus",
//             subName1: "ツールを使ってHX支援",
//             subName2: "Live配信と録画で１人でいつでも学べる",
//             id: "prod_Kp7BBBSF9pc8mg",
//             price: {
//                 month: "price_1K9SwwKtz9s4YUGtAlcD3Ceh",
//                 year: "price_1K9SwwKtz9s4YUGtnaMe7lwd"
//             },
//             amount: {
//                 month: 19980,
//                 year: 199800,
//                 coinsPlus: 140000,
//                 saved: 39960,
//                 totalSaved: 199960,
//             },
//             image: "/images/plan/image3.png",
//             background: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%)",
//             color: "#F29912"
//         },
//     ]
//     :
//     [
//         {
//             name: "HXエキスパート",
//             subName1: "ツールを使ってHX支援",
//             subName2: "Live配信と録画で１人でいつでも学べる",
//             id: "prod_Kp1esAcpRs85yb",
//             price: {
//                 month: "price_1K9NatKtz9s4YUGthUokiJtb",
//                 year: "price_1K9NatKtz9s4YUGt0Zm00d8n"
//             },
//             amount: {
//                 month: 14980,
//                 year: 149800,
//                 coinsPlus: 80000,
//                 saved: 29600,
//                 totalSaved: 119960,
//             },
//             image: "/images/plan/image4.png",
//             background: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%)",
//             color: "#F29912"
//         },
//         {
//             name: "HXエキスパートPlus",
//             subName1: "ツールを使ってHX支援",
//             subName2: "Live配信と録画で１人でいつでも学べる",
//             id: "prod_Kp1fFdOMs0jgfj",
//             price: {
//                 month: "price_1K9Nc5Ktz9s4YUGtSVqHLdVh",
//                 year: "price_1K9Nc5Ktz9s4YUGtiE197R5I"
//             },
//             amount: {
//                 month: 19980,
//                 year: 199800,
//                 coinsPlus: 140000,
//                 saved: 39960,
//                 totalSaved: 199960,
//             },
//             image: "/images/plan/image3.png",
//             background: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%)",
//             color: "#F29912"
//         },
//     ]
