import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {MenuAppBar} from "../splitting";
import ContentsExpDate from "./ContentsExpDate";
import './styles.css'
import {useGlobal} from "reactn";


const useStyles = makeStyles(theme => ({
    // appBar: {
    //     position: 'relative',
    // },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    contents: {
        width: 900,
        height: 'auto',
        margin: 'auto',
    },
    divBody: {
        textAlign: "center",
        marginBottom: 30,
        marginTop: 20
    },
    typoStyle: {
        marginBottom: 10,
        fontWeight: "inherit"
    },
    divCouponStyle: {
        paddingTop: 20,
        width: '80%',
        margin: 'auto'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ExpDateCheckDialog(props) {
    const classes = useStyles();
    const [open] = useGlobal('openPackageDialog')


    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <div className='menu-dialog'>
                <MenuAppBar expired/>
            </div>

            <div className={classes.contents}>
                <ContentsExpDate classes={classes}/>
            </div>
        </Dialog>
    );
}

export default ExpDateCheckDialog;
