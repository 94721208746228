import {db} from '../../firebaseConfig';
import {CALENDAR_COLLECTION, MEETING_COLLECTION, USER_COLLECTION} from '../../config/constants';

export const getDocsAsync = async (collRef) => {
    return (await collRef.get())?.docs?.map(doc => ({...doc.data(), id: doc.id}))
}
export const getDataAsync = async (docRef) => {
    return (await docRef.get())?.data();
}

export const getMeetingDocRef = (meetingId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}`)
}

export const getMeetingCollRef = () => {
    return db.collection(`${MEETING_COLLECTION}`)
}

export const getUserVoteBoxRef = (userId, meetingId) => {
    return db.collection(CALENDAR_COLLECTION).doc(userId)
        .collection('eventBoxMeeting')
        .where('meetingId', '==', meetingId)
}

export const getVoteBoxCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl`)
}
export const getVoteBoxDocRef = (meetingId, voteBoxId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl/${voteBoxId}`)
}
export const getUserVoteCollRef = (meetingId, voteBoxId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl/${voteBoxId}/voteColl`)
}
export const getUserVoteDocRef = (meetingId, voteBoxId, userId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl/${voteBoxId}/voteColl/${userId}`)
}

export const getUsersCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/users`)
}

export const getBoxFreeRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/exBoxFree`)
}

export const getBusyBoxRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/busyColl`)
}

export const getBoxFreeShareRef = (user_id) => {
    return db.collection(`${CALENDAR_COLLECTION}/${user_id}/exBoxFreeShare`)
}

export const getBoxFreeShareDoc = (user_id, idEvt) => {
    return db.doc(`${CALENDAR_COLLECTION}/${user_id}/exBoxFreeShare/${idEvt}`)
}

export const getDocTaskFreeRef = (userID) => {
    return db.collection(`${CALENDAR_COLLECTION}/${userID}/taskFree`).doc("taskFree")
}

export const getUserDocRef = (meetingId, userId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/users/${userId}`)
}


export const getBusyCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/busyColl`)
}
export const getBusyDocRef = (meetingId, busyId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/busyColl/${busyId}`)
}


export const getAnonymousCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/anonymousColl`)
}
export const getAnonymousDocRef = (meetingId, email) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/anonymousColl/${email}`)
}


export const getMessagesCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/messages`)
}

export const getMessagesDocRef = (meetingId, messageId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/messages/${messageId}`)
}

export const getEventCalendarDocRef = (userId, voteId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting/${voteId}`)
}
export const getCalendarUserRef = (userId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}`)
}

export const getCalendarProjectsRef = (userId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}/projects`)
}

export const getCalendarProjectsDocRef = (userId, projectId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}/projects/${projectId}`)
}

export const getEventCalendarCollRef = (userId) => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting`)
}
export const getUserProjectsColl = userId => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/projects`)
}

export const getScheduleAdjustmentRef = (userId, eventId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting/${eventId}`)
}

export const settingSaveCollRef = (userId) => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/saveSettings`)
}
export const scheduleAdjustmentRef = (userId) => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/scheduleAdjustment`)
}

export const myFeetimeRef = (userID) => db.doc(`${CALENDAR_COLLECTION}/${userID}/myFreeTime/myfreetime`)

export const eventSuccessRef = (userId) => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting`)
        .where('isDeleted', '==', false)
}
export const eventAdjustmentDoc = (userId, queryIDShare) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('scheduleAdjustment')
        .doc(queryIDShare)
}

export const userRef = (userId) => {
    return db.collection(USER_COLLECTION).doc(userId)
}

export const getAllUsersProjectsColl = (userId) => {
    return db.collection(`${USER_COLLECTION}/${userId}/projects`)
}

export const getAllUsersProjectsDocRef = (userId, projectId) => {
    return db.doc(`${USER_COLLECTION}/${userId}/projects/${projectId}`)
}

export const eventSnap = (userId, proId) => db.collection(CALENDAR_COLLECTION)
    .doc(userId)
    .collection('events')
    .where("project_uuid", "==", proId)
    .where("isDeleted", "==", false)
    .where("isTasks", "==", false)
    .get()


export const getEventsDocRef = (userId, eventId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('events')
        .doc(eventId)
}
