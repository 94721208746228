import React, { useMemo } from 'react';
import moment from "moment";
import {makeStyles} from "@material-ui/styles";
import useIntlId from "../../../hooks/useIntlId";
import {useGlobal} from "reactn";
import getUserTimeZone from "../../../common/moment/getUserTimeZone"

function DivContent({classes, info}) {
    const clf = useStyles()
    const [preview] = useGlobal('preview')
    const [user] = useGlobal('user')
    const [descriptionIntl, descriptionTimeIntl, timezoneTextIntl, voteNumberIntl, expirationTimeIntl, formatDayIntl] = useIntlId(['appy.linkVote.description', 'appy.linkVote.descriptionTime', 'appy.linkVote.timezone',
        'appy.voteNumber', 'appy.linkVote.expirationTime', 'appbar.formatAppbar.day'])

    const voteNumberInfoText = useMemo(() => {
        if (info?.unlimited) return `${info?.used}`
        else return `${info?.used}/${info?.times}`
    },[info])

    return (
        <div className={classes.content}> <span>
                        {/* {descriptionIntl}<br/>
            {descriptionTimeIntl}<br/> */}
            {timezoneTextIntl}: {getUserTimeZone()} {moment().tz(getUserTimeZone()).format('Z')}<br/>
            {
                info &&
                <div>
                    {user?.user_id && <div className={clf.fontSizeNumberVote}>
                        {voteNumberIntl}: {voteNumberInfoText}
                    </div>
                    }
                    <div className={classes.red}>{expirationTimeIntl}
                        : {moment(info?.exp ? info?.exp : preview.data.exp).tz(getUserTimeZone()).format(`${formatDayIntl} HH:mm`)}
                    </div>
                </div>
            }
                            </span>

        </div>
    );
}

export default DivContent;
const useStyles = makeStyles(theme => ({
    fontSizeNumberVote: {
        fontSize: 20,
        // color: 'red',
        fontWeight: "bold"
    }
}))
