import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core'
import qs from 'query-string'
import Fire, {db} from '../../../firebaseConfig'
import {CALENDAR_COLLECTION} from "../../../config/constants";
import moment from "moment";
import {v4 as uuidv4} from 'uuid'
import {getChangeTimeScheduleAdjustmentEmail, getFormatDate} from "./common";
import {toast} from "react-toastify";
import {useGlobal} from "reactn";
import {handleUpdateZoomMeeting} from "../../../actions/zoom";
import {updateShareURLEvent} from "./syncCalendarEvent";
import {handleGetValue} from "../../ConferenceButton";
import {createGoogleTasksSchedule, handleDeleteGoogleTasksSchedule} from "../../../actions/googleTasks";
import momentTz from 'moment-timezone';
import {verifyJWTTokenShare} from "../../../common/jwt";
import useIntlId from "../../../hooks/useIntlId";
import {getScheduleAdjustmentRef} from "../../../common/firebaseRef/meetings";
import {useCalendarEvents} from "../../../context/CalendarEventsContext";
import {find} from "lodash";

function RequestChangeTime({open, setOpen, eventConfirm, info}) {
    const [loading, setLoading] = useState(false)
    const [user] = useGlobal("user");
    const {allEventGoogle} = useCalendarEvents()
    const [yesIntl, noIntl, changeTimeMeetingIntl, askChangeTimeIntl, changeTimeIntl, updateErrorIntl, anyFailGenerateIntl, userChangeTimeIntl, btnGotoEventIntl, dontReplyIntl, joinZoomIntl, beforeChangeIntl, afterChangeIntl, timeZoneIntl, zoomMeetingIntl] = useIntlId([
        'settings.yes',
        'settings.no',
        'event.dialog.request.changeTimeMeeting',
        'event.dialog.request.askChangeTime',
        'toast.success.change',
        'event.toast.updateError',
        'event.toast.anyFailGenerate',
        'email.userChangeTime',
        'email.message.btn.gotoEvent',
        'email.message.dontReply',
        'email.message.joinZoom',
        'email.beforeChange',
        'email.afterChange',
        'email.timeZone',
        'email.zoomMeeting'
    ])

    const handleClose = () => {
        open.revert()
        setOpen(null)
    }
    const formatDay = (language, time) => {
        let day
        if (language) {
            if (language === 'ja-jp') {
                day = `${moment(time).format("YYYY年MM月DD日")}`
            }
            if (language === 'en-us' || language === 'vi-vn') {
                day = `${moment(time).format("DD-MM-YYYY")}`
            }
        } else {
            day = `${moment(time).format("DD-MM-YYYY")}`
        }
        return day
    }

    const updateEventForPartner = async (newGoogleTasksID = null) => {
        if (!eventConfirm) return null

        // update google event
        const oldGGEvent = find(allEventGoogle, {scheduleId: eventConfirm.scheduleId})
        if (oldGGEvent) {
            const data = await updateShareURLEvent(oldGGEvent.project_uuid, oldGGEvent.id, user.user_id, {
                start: {
                    dateTime: moment(open.event.start).utc().format(),
                    date: null
                },
                end: {
                    dateTime: moment(open.event.end).utc().format(),
                    date: null
                },
                googleTasksID: newGoogleTasksID ?? eventConfirm.googleTasksID
            });
            if (!data) {
                // update fail
                handleClose()
                return toast.error(`${updateErrorIntl}`)
            }
        }

        const eventBoxUserRef = getScheduleAdjustmentRef(user?.user_id, eventConfirm?.eventFollowId)
        // `${CALENDAR_COLLECTION}/${user?.user_id}/eventBoxMeeting/${eventConfirm?.eventFollowId}`
        const dataDoc = await eventBoxUserRef.get()
        if (!dataDoc.exists) return null;
        await eventBoxUserRef.set({
            start: moment(open.event.start).format(),
            end: moment(open.event.end).format(),
        }, {merge: true})
        return null
    }

    const handleChange = async () => {
        try {
            setLoading(true)
            if (!user?.user_id) {
                // no user login -> login anonymous
                const data = await Fire.auth().signInAnonymously();
                if (!data.user?.uid)
                    return toast.error(`${anyFailGenerateIntl}`);
            }
            const query = qs.parse(window.location.search)
            const {tokenShare} = query;
            const data = verifyJWTTokenShare(tokenShare, process.env.REACT_APP_JWT_PASS_SHARE);
            if (!data)
                return null;
            const {email, evt, usr} = data
            // get info user
            // const userRef = db.doc(`${CALENDAR_COLLECTION}/${usr}`)
            // const snap = await userRef.get()
            // const userData = snap.data()
            const batch = db.batch()
            const evtRef = db.doc(`${CALENDAR_COLLECTION}/${usr}/eventBoxMeeting/${evt}`)
            let meetingLink = handleGetValue(eventConfirm) || eventConfirm?.linkZoom;
            // event calendar sync
            // update google Tasks ID
            let newGoogleTasksID = '';
            let oldGoogleTasksID = eventConfirm?.googleTasksID || '';
            const zoneUser = eventConfirm?.zoneUser || "Asia/Tokyo";
            const zoneUserShare = eventConfirm?.zoneUserShare || "Asia/Tokyo";

            const users = [
                {
                    id: email,
                    email: email,
                    name: eventConfirm.name,
                    timeZone: zoneUserShare
                },
                {
                    id: eventConfirm.email,
                    email: eventConfirm.email,
                    name: eventConfirm.name,
                    timeZone: zoneUser
                }
            ];
            newGoogleTasksID = await createGoogleTasksSchedule({
                ...eventConfirm,
                start: open.event.start,
                end: open.event.end
            }, users);

            await updateEventForPartner(newGoogleTasksID)

            if (info.project) {
                if (info.isGoogle) {
                    // update google event
                    const data = await updateShareURLEvent(info.project, eventConfirm.eventId, usr, {
                        start: {
                            dateTime: moment(open.event.start).utc().format(),
                            date: null
                        },
                        end: {
                            dateTime: moment(open.event.end).utc().format(),
                            date: null
                        },
                        googleTasksID: newGoogleTasksID
                    });
                    if (!data) {
                        // update fail
                        handleClose()
                        return toast.error(`${updateErrorIntl}`)
                    }
                } else {
                    // update geniam event
                    const eventUpdateRef = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${usr}/events/${eventConfirm.eventId}`)
                    batch.set(eventUpdateRef, {
                        start: moment(open.event.start).format(),
                        end: moment(open.event.end).format(),
                        googleTasksID: newGoogleTasksID
                    }, {merge: true});
                }
            }

            batch.set(evtRef, {
                start: moment(open.event.start).format(),
                end: moment(open.event.end).format(),
                googleTasksID: newGoogleTasksID
            }, {merge: true});
            // send email
            const emailId = 'meet_' + uuidv4()
            const emailRef = db.doc(`mails/${emailId}`)

            // send email to user shared time
            let btnInfoIntl
            let dontReplyInfoIntl
            let joinZoomInfoIntl
            let subjectInfoIntl
            let timeZoneInfoIntl
            let beforeChangeInfoIntl
            let afterChangeInfoIntl
            let zoomMeetingInfoIntl

            if (info.language) {
                let language = info?.language || 'ja-jp';

                const languageSnap = await db.doc(`intl/calendarIntl/langColl/${language}`).get();
                const languageData = languageSnap.exists ? languageSnap.data() : {};

                btnInfoIntl = `${languageData['email.message.btn.gotoEvent']}`;
                joinZoomInfoIntl = `${languageData['email.message.joinZoom']}`;
                dontReplyInfoIntl = `${languageData['email.message.dontReply']}`;
                subjectInfoIntl = `${languageData['email.userSharedTime']}`
                timeZoneInfoIntl = `${languageData['email.timeZone']}`
                beforeChangeInfoIntl = `${languageData['email.beforeChange']}`
                afterChangeInfoIntl = `${languageData['email.afterChange']}`
                zoomMeetingInfoIntl = `${languageData['email.zoomMeeting']}`
            } else {
                btnInfoIntl = 'Go to Event'
                joinZoomInfoIntl = 'Join zoom'
                dontReplyInfoIntl = 'This is an automated message, please do not reply.'
                subjectInfoIntl = `${eventConfirm.name} has changed meeting time`
                timeZoneInfoIntl = 'TimeZone'
                beforeChangeInfoIntl = 'Before change'
                afterChangeInfoIntl = 'After change'
                zoomMeetingInfoIntl = 'Meeting on Zoom'
            }
            let oldStart = getFormatDate(info.language, momentTz(eventConfirm.start).tz(zoneUser))
            let oldEnd = getFormatDate(info.language, momentTz(eventConfirm.end).tz(zoneUser))
            let newStart = getFormatDate(info.language, momentTz(open.event.start).tz(zoneUser))
            let newEnd = getFormatDate(info.language, momentTz(open.event.end).tz(zoneUser))
            let oldTimeStartInfo = momentTz(eventConfirm.start).tz(zoneUser).format("HH:mm")
            let oldTimeEndInfo = momentTz(eventConfirm.end).tz(zoneUser).format("HH:mm")
            let newTimeStartInfo = momentTz(open.event.start).tz(zoneUser).format("HH:mm")
            let newTimeEndInfo = momentTz(open.event.end).tz(zoneUser).format("HH:mm")
            let oldTimeInfo
            let newTimeInfo
            if (oldStart === oldEnd || newStart === newEnd) {
                oldTimeInfo = `${oldStart || oldEnd} (${oldTimeStartInfo} ~ ${oldTimeEndInfo})`
                newTimeInfo = `${newStart || newEnd} (${newTimeStartInfo} ~ ${newTimeEndInfo})`
            } else {
                oldTimeInfo = `${oldStart} (${oldTimeStartInfo} ~ ${oldTimeEndInfo}) - ${oldEnd} (${oldTimeStartInfo} ~ ${oldTimeEndInfo})`
                newTimeInfo = `${newStart} (${newTimeStartInfo} ~ ${newTimeEndInfo}) - ${newEnd} (${newTimeStartInfo} ~ ${newTimeEndInfo})`
            }


            batch.set(emailRef, {
                from: `${eventConfirm.name} <geniamevents@geniam.com>`,
                replyTo: eventConfirm.email,
                to: email,
                message: {
                    html: getChangeTimeScheduleAdjustmentEmail({
                        name: eventConfirm.name,
                        title: eventConfirm.title,
                        oldTime: oldTimeInfo,
                        newTime: newTimeInfo,
                        linkZoom: meetingLink,
                        timeZone: `${zoneUserShare}  ${momentTz().tz(zoneUserShare).format("Z")}`,
                        btnGotoEvent: btnInfoIntl,
                        dontRely: dontReplyInfoIntl,
                        linkEvent: eventConfirm.linkEvent || '',
                        btnJoinZoom: joinZoomInfoIntl,
                        content: subjectInfoIntl?.replace('name', `${eventConfirm.name}`) || '',
                        timeZoneIntl: timeZoneInfoIntl,
                        beforeChange: beforeChangeInfoIntl,
                        afterChange: afterChangeInfoIntl,
                        zoomMeetingIntl: zoomMeetingInfoIntl
                    }),
                    subject: subjectInfoIntl?.replace('name', `${eventConfirm.name}`) || ''
                }
            });

            // send email to user change time

            let oldStartUser = getFormatDate(user.language, momentTz(eventConfirm.start).tz(zoneUserShare))
            let oldEndUser = getFormatDate(user.language, momentTz(eventConfirm.end).tz(zoneUserShare))
            let newStartUser = getFormatDate(user.language, momentTz(open.event.start).tz(zoneUserShare))
            let newEndUser = getFormatDate(user.language, momentTz(open.event.end).tz(zoneUserShare))
            let oldTimeStartUser = momentTz(eventConfirm.start).tz(zoneUserShare).format("HH:mm")
            let oldTimeEndUser = momentTz(eventConfirm.end).tz(zoneUserShare).format("HH:mm")
            let newTimeStartUser = momentTz(open.event.start).tz(zoneUserShare).format("HH:mm")
            let newTimeEndUser = momentTz(open.event.end).tz(zoneUserShare).format("HH:mm")
            let oldTimeUser
            let newTimeUser
            if (oldStartUser === oldEndUser || newStartUser === newEndUser) {
                oldTimeUser = `${oldStartUser || oldEndUser} (${oldTimeStartUser} ~ ${oldTimeEndUser})`
                newTimeUser = `${newStartUser || newEndUser} (${newTimeStartUser} ~ ${newTimeEndUser})`
            } else {
                oldTimeUser = `${oldStartUser} (${oldTimeStartInfo} ~ ${oldTimeEndInfo}) - ${oldEnd} (${oldTimeStartInfo} ~ ${oldTimeEndInfo})`
                newTimeUser = `${newStartUser} (${newTimeStartUser} ~ ${newTimeEndUser}) - ${newEndUser} (${newTimeStartUser} ~ ${newTimeEndUser})`
            }

            const changedEmailId = 'meet_' + uuidv4()
            const ref = db.doc(`mails/${changedEmailId}`)
            batch.set(ref, {
                from: `Geniam <geniamevents@geniam.com>`,
                replyTo: email,
                to: eventConfirm.email,
                message: {
                    html: getChangeTimeScheduleAdjustmentEmail({
                        name: "You",
                        title: eventConfirm.title,
                        oldTime: oldTimeUser,
                        newTime: newTimeUser,
                        linkZoom: meetingLink,
                        timeZone: `${zoneUser}  ${momentTz().tz(zoneUser).format("Z")}`,
                        btnGotoEvent: btnGotoEventIntl,
                        dontRely: dontReplyIntl,
                        linkEvent: eventConfirm.linkEvent || '',
                        btnJoinZoom: joinZoomIntl || '',
                        content: userChangeTimeIntl || '',
                        timeZoneIntl: timeZoneIntl,
                        beforeChange: beforeChangeIntl,
                        afterChange: afterChangeIntl,
                        zoomMeetingIntl: zoomMeetingIntl
                    }),
                    subject: userChangeTimeIntl
                }
            });
            await batch.commit();
            toast.success(`${changeTimeIntl}`)
            if (meetingLink && !info.isGoogle) {
                handleUpdateZoomMeeting(moment(open.event.start).format(), moment(open.event.end).format(), handleGetValue(eventConfirm), usr, open.event.title)
                    .catch(e => console.log(e))
            }
            if (oldGoogleTasksID) {
                handleDeleteGoogleTasksSchedule(oldGoogleTasksID).catch(e => console.log(e))
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            setOpen(null)
        }
    }
    if (!open)
        return null
    return (
        <Dialog open={Boolean(open)} onClose={handleClose}>
            <DialogTitle>{changeTimeMeetingIntl}</DialogTitle>
            <DialogContent>
                <Typography>
                    {moment(open.event.start).format("YYYY-MM-DD HH:mm")} ~ {moment(open.event.end).format(" HH:mm YYYY-MM-DD")}
                </Typography>
                {askChangeTimeIntl}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    onClick={handleClose}
                    variant={"outlined"}
                    color={"inherit"}
                >
                    {noIntl}
                </Button>
                <Button
                    variant={"outlined"}
                    color={"secondary"}
                    onClick={handleChange}
                    disabled={loading}
                >
                    {yesIntl}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RequestChangeTime;
