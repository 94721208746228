import {getGlobal} from "reactn";


export const alertNotMeeting = (groupExists = "The group no longer exists") => {
    alert(groupExists)
    const {listMeetings} = getGlobal();
    if (listMeetings?.length && listMeetings[0]?.id)
        return window.history.replaceState({}, 'event', `/event?id=${listMeetings[0].id}`)
    else
        return window.history.replaceState({}, 'event', `/event`)

}


export const alertNotSetting = (groupExists = "The schedule id no longer exists") => {
    alert(groupExists)
    const {listMeetings} = getGlobal();
    if (listMeetings?.length && listMeetings[0]?.id)
        return window.history.replaceState({}, 'event', `/event?id=${listMeetings[0].id}`)
    else
        return window.history.replaceState({}, 'event', `/event`)

}
