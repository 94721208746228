import React, {memo} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh"

    },

}));

function LangSetting() {
    const classes = useStyles()
    return (
        <LoadingOverlay>
            <Loader loading={true} text={"Language Setting"}/>
            <div className={classes.root}/>
        </LoadingOverlay>
    );
}

export default memo(LangSetting);
