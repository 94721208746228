import {clone, forEach, isEqual, isPlainObject, transform} from 'lodash'

export const omitByDeep = (obj, shouldOmit) => {
    obj = clone(obj);
    forEach(obj, (value, key) => {
        if (shouldOmit(value, key)) {
            delete obj[key];
        }
        if (isPlainObject(value)) {
            obj[key] = omitByDeep(value, shouldOmit);
        }
    })
    return obj;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
    function changes(object, base) {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = (isPlainObject(value) && isPlainObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }

    return changes(object, base);
}
