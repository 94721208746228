import React from 'react';
import {makeStyles} from "@material-ui/styles";
import styled from "styled-components";

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.props.primaryColor,
        fontWeight: "bold",
        width: "100%"
    },
    img: {
        width: 25,
        height: 25,
        marginRight: 5,
        marginLeft: 5
    },
    divContentStyle: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '100%'
    },
}));

const DivTextInputs = styled.div(props => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: "center",
    fontSize: '1.2rem',
    borderRadius: 24,
    backgroundColor: '#F1F1F1',
    minHeight: 40,
    marginBottom: props.marginBottom
}))

const DivTitleIMG = styled.div(props => {
    return {
        color: props.color,
        fontWeight: "bold",
        width: "100%",
        maxWidth: props.titleMaxWidth
    }
})

const DivContent = styled.div(props => ({
    color: '#000',
    fontSize: '1.2rem',
    background: '#FFF',
    minHeight: '34px',
    borderRadius: '20px',
    textAlign: 'left',
    paddingLeft: '10px',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    width: `calc(100% - ${props.titleMaxWidth + 5}px)`
}))

const DivColor = styled.div(props => ({
    backgroundColor: props.backgroundColor,
    width: 25,
    minWidth: 25,
    height: 25,
    borderRadius: '50%',
    padding: 0,
    border: '1px solid #D5D5D5',
    margin: "auto"
}))

function TextInputCustom({
                             name,
                             contentName,
                             isColor,
                             urlImg,
                             isURL,
                             editComponent,
                             isEditComponent,
                             titleMaxWidth = 90,
                             marginBottom = null
                         }) {
    const classes = useStyles();

    return (
        <DivTextInputs marginBottom={marginBottom}>
            <DivTitleIMG titleMaxWidth={titleMaxWidth}>
                {
                    isURL && <img src={urlImg} alt='' className={classes.img}/>
                }
                <span className={classes.title}>
                    {name}
                </span>
            </DivTitleIMG>
            <DivContent titleMaxWidth={titleMaxWidth}>
                <div className={classes.divContentStyle}>
                    {
                        isEditComponent ?
                            editComponent
                            :
                            isColor ?
                                <DivColor backgroundColor={contentName}/>
                                :
                                contentName
                    }
                </div>
            </DivContent>
        </DivTextInputs>
    );
}

export default TextInputCustom;
