import React from 'react';
import {Popover} from "@material-ui/core";
import {CirclePicker} from "react-color";
import {makeStyles} from "@material-ui/styles";

const arrColor = [
    "#88304e", "#533546", "#311d3f",
    "#f44336", "#e91e63", "#9c27b0",
    "#673ab7", "#3f51b5", "#2196f3",
    "#03a9f4", "#00bcd4", "#009688",
    "#4caf50", "#8bc34a", "#cddc39",
    "#ffeb3b", "#ffc107", "#ff9800",
    "#ff5722", "#795548", "#607d8b",
    "#616161", "#424242", "#212121",
]

function SelectProjectColor({anchorEl, setAnchorEl, editInfo, setEditInfo}) {
    const classes = useStyles()
    const handleChangeColor = color => {
        setEditInfo({...editInfo, color: color.hex})
        setAnchorEl(null)
    }
    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            classes={{
                root: classes.popoverRoot
            }}
        >
            <div className={classes.root}>
                <CirclePicker
                    width={'100%'}
                    circleSize={19}
                    circleSpacing={7}
                    color={editInfo.color}
                    colors={arrColor}
                    onChangeComplete={(color) => handleChangeColor(color)}
                />
            </div>

        </Popover>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 10,
        width: 350,
    },
    popoverRoot: {
        top: 500,
        left: 840
    }
}))

export default SelectProjectColor
