import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Grid, TextField, Typography} from '@material-ui/core'
import SelectProjectColor from "./SelectColor";
import {useGlobal} from 'reactn'
import {CALENDAR_COLLECTION, GOOGLE_EMAIL_WATCH} from "../../../config/constants";
import {toast} from "react-toastify";
import {find, findIndex} from 'lodash'
import TextInputCustom from "../../Custom/TextInputCustom";
import TextInputURLCustom from "../../Custom/TextInputURLCustom";
import {Spin} from 'antd';
import {db} from "../../../firebaseConfig";
import {ggTokenChecked} from "../../Calendar/google/auth";
import axios from "axios";
import SaveButton from "../../Dialog/SaveButton";
import CancelButton from "../../Dialog/CancelButton";
import useIntlId from "../../../hooks/useIntlId";
import {v4 as uuidv4} from "uuid";
import {gerenateCategories, gerenatePriorities} from "../../TaskListApp/AddProjectPopup/constant";
import moment from "moment";
import isURL from 'validator/lib/isURL';

const linkItems = [{
    name: "Slack",
    logo: "/icons/slackIcon.svg",
    id: "slack"
}, {
    name: "Trello",
    logo: "/icons/trelloIcon.svg",
    id: "trello"
}
]

function EditProject({project, setProject, setIsEdit, totalProjects}) {

    const classes = useStyles()
    const [edited, setEdited] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [user] = useGlobal("user")
    const [calendarUser] = useGlobal("calendarUser")
    const [list, setList] = useGlobal("googleCalendarList")
    const [googleStatus] = useGlobal('googleStatus')
    // const {reLoadEvents} = useCalendarEvents()
    const [otherURL, setOtherURL] = useState([])
    const [colorIntl, updateSuccessIntl, invalidLinkIntl] = useIntlId(['view.color',
        'appy.project.updateSuccess', 'appy.noticeLink.invalid'])


    useEffect(() => {
        setEdited({...project})
        if (project?.otherURL) {
            setOtherURL([...project.otherURL])
        } else {
            setOtherURL(linkItems)
        }
    }, [project])


    const onCancel = () => {
        setIsEdit(false)
    }


    const onUpdate = async () => {
        // validate project name , code
        if (!edited.name) {
            return toast.warn("name cannot empty")
        }
        try {
            setLoading(true)
            //valid url
            let check = true;
            otherURL.map(async item => {
                if(item.url) {
                    if (isURL(item.url,{protocols: ['http','https'], require_protocol: true})) {
                    } else check = false;
                }
            })
            if (!check) {
                toast.error(invalidLinkIntl)
                return;
            }
            // valid icon
            await Promise.all(otherURL.map(async item => {
                let logo = '/icons/linkURL.svg'
                if (item.url) {
                    try {
                        const URL = new window.URL(item.url)
                        const origin = URL.origin
                        await axios.get(`${origin}/favicon.ico`)
                        logo = `${origin}/favicon.ico`

                    } catch (e) {

                    }
                    item.logo = logo
                }
            }))

            if (!edited.is_google) {
                // update geniam project
                if (edited.color !== project.color) {
                    const batch = db.batch()
                    const eventSnap = await db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/events`)
                        .where("project_uuid", "==", project.id)
                        .where("isDeleted", "==", false)
                        .get()
                    eventSnap.docs.forEach(doc => {
                        const eventRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${doc.id}`)
                        batch.set(eventRef, {
                            borderColor: edited.color
                        }, {merge: true})
                    })
                }

                const refProject = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/projects/${project.id}`)
                await refProject.set({
                        otherURL: otherURL || [],
                        color: edited.color || '',
                        name: edited.name || ''
                    },
                    {merge: true})
                edited.otherURL = otherURL
                return
            }

            // google Project update
            if (edited.name !== project.name) {
                // update data to google calendar
                const data = await ggTokenChecked()
                if (!data) {
                    return toast.error("google auth error. try again late")
                }
                const request = window.gapi.client.calendar.calendars.patch({
                    calendarId: project.id,
                    summary: edited.name,
                })
                const result = await request.then()
                if (result.status !== 200) {
                    return toast.error("update color google error")
                }
            }
            if (edited.color !== project.color) {
                // update data to google calendar
                const data = await ggTokenChecked()
                if (!data) {
                    return toast.error("google auth error. try again late")
                }
                const request = window.gapi.client.calendar.calendarList.update({
                    calendarId: project.id,
                    "colorRgbFormat": true,
                    // summary: edited.name,
                    backgroundColor: edited.color,
                    foregroundColor: "#000000",
                    selected: Boolean(edited.selected)
                })
                const result = await request.then()
                if (result.status !== 200) {
                    return toast.error("update color google error")
                }
            }

            // other info stored on firebase
            const googleSettings = calendarUser?.googleSettings || {}
            googleSettings[edited.id] = {
                otherURL: otherURL || []
            }
            edited.otherURL = otherURL
            const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
            await userRef.set({googleSettings}, {merge: true})
            // update local calendar List
            let idx = findIndex(list, {id: edited.id})
            if (idx !== -1) {
                list[idx].backgroundColor = edited.color
                list[idx].summary = edited.name
                setList([...list])
                // reLoadEvents()
            }
            // add project default
            if (totalProjects?.length === 1 && project?.id === totalProjects[0].id) {
                let checkExistDefault = find(totalProjects, (e) => {
                    return e.name === 'Project Default'
                })
                if (checkExistDefault)
                    return
                let _project
                const projectName = 'Project Default'
                const projectColor = '#e91e63'
                const uid = 'pro_' + uuidv4()
                const categories = gerenateCategories(uid)
                const priorities = gerenatePriorities(uid)
                const time = moment().utc().format()
                _project = {
                    id: uid,
                    uuid: uid,
                    name: projectName,
                    ja_name: "",
                    color: projectColor,
                    author: user.email,
                    selected: true,
                    categories,
                    priorities,
                    is_deleted: false,
                    created_at: time,
                    updated_at: time,
                    is_sync: false,
                    isDefault: true
                }
                await db.collection(CALENDAR_COLLECTION)
                    .doc(user.user_id)
                    .collection('projects')
                    .doc(_project.id)
                    .set(_project)
            }
            await db.collection(GOOGLE_EMAIL_WATCH)
                .doc(googleStatus.userProfile.email)
                .update({created_at: new Date().toISOString()})
            setIsEdit(false)
            return toast.success(updateSuccessIntl)
        } catch (e) {
            toast.error("Update error. try again late")
        } finally {
            setLoading(false)
            setProject({...edited})
        }

    }

    //
    // const onAddURL = async () => {
    //     const id = uuidv4()
    //     const input = {
    //         name: 'other',
    //         logo: '/icons/linkURL.svg',
    //         id: id
    //     }
    //     setOtherURL([...otherURL, input])
    //
    // }


    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={9}>
                    <div className={classes.content}>
                        <TextInputCustom
                            name={'Name'}
                            contentName={project.name}
                            isEditComponent={true}
                            editComponent={
                                <div className={`clf-flex-center ${classes.textEdit}`}>
                                    <TextField
                                        autoFocus
                                        value={edited.name || ''}
                                        onChange={e => {
                                            setEdited({...edited, name: e.target.value})
                                        }}
                                        disabled={edited?.accessRole === "reader"}
                                        fullWidth
                                    />
                                    {
                                        edited?.accessRole === "reader" ?
                                            <Typography color={"secondary"}>reader access role</Typography>
                                            :
                                            null
                                    }
                                </div>
                            }/>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div className={classes.content}>
                        <TextInputCustom
                            name={colorIntl}
                            contentName={project.color}
                            isEditComponent={true}
                            editComponent={
                                <div className={`clf-flex-center ${classes.textEdit}`}>
                                    <div className={classes.colorCircle}
                                         style={{backgroundColor: edited.color}}
                                         onClick={event => setAnchorEl(event.currentTarget)}
                                    />
                                    <SelectProjectColor anchorEl={anchorEl} setAnchorEl={setAnchorEl} editInfo={edited}
                                                        setEditInfo={setEdited}/>
                                </div>
                            }
                        />
                    </div>
                </Grid>

            </Grid>
            <div className={classes.listItems}>
                {
                    otherURL.map((item, index) => {
                        return (
                            <Grid container key={index} className={classes.divGrid}>
                                <TextInputURLCustom
                                    contentName={`${project[item.id] ? project[item.id] : ''}`}
                                    urlImg={item.logo}
                                    isURL={true}
                                    item={item}
                                    project={project}
                                    setProject={setProject}
                                    otherURL={otherURL}
                                    setOtherURL={setOtherURL}
                                    isEditComponent={true}
                                    editComponent={
                                        <div className={`clf-flex-center ${classes.textEdit}`}>
                                            <TextField
                                                value={item.url || ''}
                                                className={classes.textField}
                                                onChange={(e) => {
                                                    otherURL[index].url = e.target.value || ''
                                                    setOtherURL([...otherURL])
                                                }}
                                                fullWidth
                                            />
                                        </div>
                                    }
                                    titleMaxWidth={110}
                                />
                            </Grid>
                        )
                    })
                }

            </div>
            <div className={classes.action}>
                <CancelButton
                    onClick={onCancel}
                />
                <Spin spinning={loading}>
                    <SaveButton
                        onClick={onUpdate}
                        disable={loading}
                    />
                </Spin>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: '#FFF',
        marginTop: 40,
        padding: '30px 10px',
        borderRadius: 20,
        position: 'relative'
    },
    content: {
        textAlign: "center",
        fontSize: 14
    },
    divGrid: {
        marginBottom: 15
    },
    listItems: {
        marginTop: 20,
    },
    title: {
        fontWeight: "bold"
    },
    action: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center'
    },
    itemHeader: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        width: 30,
        height: 30,
        marginRight: 5
    },
    textField: {
        marginLeft: 10
    },
    colorCircle: {
        width: 25,
        minWidth: 25,
        height: 25,
        borderRadius: '50%',
        padding: 0,
        border: '1px solid #D5D5D5',
        margin: "auto"
    },
    projectColor: {
        borderRadius: 24,
        maxWidth: 150,
        minHeight: 33,
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#FFF',
        border: 'none',
        width: "calc(100% - 10px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        position: 'absolute',
        top: -15,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    saveIconSecondary: {
        fill: theme.props.secondaryColor,
        marginLeft: -24,
        marginRight: 10,
        marginTop: 1
    },
    textEdit: {
        width: '95%'
    },
    codeWidth: {
        width: '80%'
    },
    saveIcon: {
        fill: theme.props.primaryColor,
        marginLeft: -41,
        marginRight: 10,
        marginTop: 1
    },
    iconAddURL: {
        fill: theme.props.primaryColor,
        transform: 'scale(1.5)',
    },
    divIconAddURL: {
        marginLeft: '16%',
        textAlign: 'left'
    },
    iconBtnAddURL: {
        padding: 0
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
            marginTop: 1
        }
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            marginTop: 1
        }
    }
}))

export default EditProject;
