import {getDateRange} from "../Home/utils";
import {changeTz} from "../../../utils/time";

const calendarView = (mySetting) => {
    let start, end;

    if (mySetting.timeRange !== undefined) {
        const DateRangeCalendar = getDateRange(mySetting.timeRange)
        start = changeTz(DateRangeCalendar.start, mySetting.time_zone)
        end = changeTz(DateRangeCalendar.end, mySetting.time_zone)
    } else {
        const DateRangeCalendar = getDateRange([0, 1])
        start = changeTz(DateRangeCalendar.start, mySetting.time_zone)
        end = changeTz(DateRangeCalendar.end, mySetting.time_zone)
    }
    return {start, end}
}

export default calendarView;
