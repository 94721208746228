import React, {useEffect, useState} from 'react';
import {setGlobal} from 'reactn'
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Box} from "@material-ui/core";
import {CART_URL, GENIAM_API} from "../../config/apiUrls";
import axios from 'axios'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import moment from "moment";
import currencyFormat from "../../common/currencyFormat";
import ServicesAppExpansion from "./TableViewAppServices/ServicesAppExpansion";


const useStyles = makeStyles({
    containerBox: {
        width: "100%",
        padding: 10
    },
    card: {
        width: "98%",
        minWidth: 275,
        float: "left",
        margin: "10px",
        border: " 10px solid",
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
        marginBottom: 10,
    },
    pos: {
        marginBottom: 12,
        fontSize: 12,
        fontStyle: "italic"
    },
    packageName: {
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    displayFlex: {
        display: "flex"
    },
    iconShopping: {
        marginTop: 5,
        marginRight: 5,
        marginLeft: 5
    }
});

export const getServicesList = async () => {

    await axios.get(GENIAM_API + '/v1/services/list')
        .then(res => {
            setGlobal({servicesList: res.data})
        })
        .catch(e => {
            console.log(e.message)
        })
};


function PackagesInfomations() {
    const classes = useStyles();
    const [listPackages, setListPackages] = useState([])

    useEffect(() => {
        getListPackages()
        getServicesList()
    }, [])

    const getListPackages = async () => {
        const {data} = await axios.get(GENIAM_API + '/v1/services/get-packages')
        if (data) {
            let taskPackageList = []
            data.forEach(dt => {
                if (dt.appList.length !== 0) {
                    dt.appList.forEach(al => {
                        if (al.service_name === 'カレンダー') {
                            taskPackageList.push({...dt})
                            return null
                        }
                    })
                }
            })
            setListPackages([...taskPackageList])
        }
    }

    const handleBuyPackage = (data) => {
        // (API_CART + `/?type=packages&num=1&add=${data.id}&`)
        let urlCart = CART_URL + `/?type=packages&num=1&add=${data.id}&redirect_url=${window.location.href}`
        window.location.href = urlCart
        // localStorage.setItem('', true)
    }

    return (
        <Box className={classes.containerBox}>
            {
                listPackages && listPackages.length !== 0 ?
                    listPackages.map((lpk, index) => {
                        return (
                            <Card key={index} className={classes.card}>
                                <CardContent>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Package {index + 1} used for Calendar
                                    </Typography>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        time expired {lpk.planExpDate}
                                    </Typography>
                                    <Typography className={classes.packageName} variant="h5" component="h2">
                                        {lpk.packageName}
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        Created at {moment(lpk.createdAt).utc().format('lll')}
                                    </Typography>
                                    <Typography>
                                        This package prices: {currencyFormat.format(Math.floor(lpk.price))}
                                    </Typography>
                                    <div>
                                        <ServicesAppExpansion cart={lpk}/>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <div className={classes.displayFlex}>
                                        <ShoppingCartOutlinedIcon
                                            className={classes.iconShopping}/>
                                        <Button onClick={() => handleBuyPackage(lpk)} size="medium"> Buy package
                                            now </Button>
                                    </div>
                                </CardActions>
                            </Card>
                        )
                    })
                    :
                    <Typography>No packages found</Typography>
            }
        </Box>
    );
}

export default PackagesInfomations;
