import React from 'react';
import IconIdentity from '../../Calendar/google/identityIcon';
import {Modal} from 'antd';
import useIntlId from '../../../hooks/useIntlId';
import CancelButton from '../../Dialog/CancelButton';
import {CLFButtonSVG} from 'react-antd-button-svg-icons';

function UnArchiveIdentityDialog({identity, setIdentity, onSave, loading, classes}) {
    const [unarchiveIntl] = useIntlId(['setting.unarchive'])

    return (
        <Modal
            visible={Boolean(identity)}
            title={identity?.name}
            onCancel={() => setIdentity(null)}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CancelButton
                    onClick={() => setIdentity(null)}
                    disable={loading}
                />
                <CLFButtonSVG
                    name={unarchiveIntl}
                    loading={loading}
                    onClick={() => onSave({...identity, isArchive: false})}
                    disable={loading}
                    width={'180px'}
                    className={classes.primary}
                />
            </div>}
        >
            <IconIdentity color={identity?.color || null}/>
        </Modal>
    );
}

export default UnArchiveIdentityDialog;
