import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import React from 'react';
import useIntlId from '../../hooks/useIntlId';
import {ReactComponent as EditSVG} from '../../asSvg/icons/edit.svg';
import commonStyles from './commonStyles';

export default function EditButton({onClick, ...others}) {
    const classes = commonStyles()
    const [editIntl] = useIntlId(['chat.edit'])
    return (
        <CLFButtonSVG
            name={editIntl}
            iconComponent={<EditSVG/>}
            className={classes.primary}
            onClick={onClick}
            {...others}
        />
    )
}
