import {MenuAppBar} from "../splitting";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

export default function NotFound(props) {
    const classes = useStyles()
    return <div>
        <MenuAppBar/>
        <div className={classes.notFound}>
            NOT FOUND :((
        </div>
    </div>
}

const useStyles = makeStyles(theme => ({
    notFoundMeeting: {
        marginTop: 20,
        textAlight: 'center'
    },
    notFound: {
        marginTop: 80
    }
}))
