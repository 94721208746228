import {getDateRange} from "./getDateRange";
import moment from "moment";

export const getUserTimeRange = (u) => {
    let startDay, endDay
    if (u?.typeTimeSharing === "normal") {
        startDay = moment(u.startDay).startOf("day").format()
        endDay = moment(u.endDay).add(1, "day").startOf("day").format()
    } else {
        const DateRangeCalendar = getDateRange(u?.timeRange || [0, 1])
        startDay = DateRangeCalendar.start
        endDay = DateRangeCalendar.end
    }
    return {
        startDay, endDay
    }
}
