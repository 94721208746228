import moment from "moment";

const adjustmentFreetime = (events = [], freetime = 0) => {
    if (!freetime)
        return events
    if (!events?.length) return []
    return events.map(e => {
        if (e.isBusyBox)
            return e
        return ({
            ...e,
            start: moment(e.start).subtract(freetime, "minutes").format(),
            end: moment(e.end).add(freetime, "minutes").format()
        })
    })
}

export default adjustmentFreetime;
