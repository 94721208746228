import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Tooltip} from 'antd';
import SaveButton from '../../Custom/SaveButton';
import {getGlobal, useGlobal} from 'reactn';
import {getPosition} from '../../../common/element';
import {toast} from 'react-toastify';
import {findIndex, concat} from 'lodash';
import {db} from '../../../firebaseConfig';
import {getEventCalendarDocRef, getEventsDocRef, getVoteBoxDocRef} from '../../../common/firebaseRef/meetings';
import {CALENDAR_COLLECTION, MEETING_COLLECTION} from '../../../config/constants';
import {deleteEvent} from '../ShareFreeTimeUrl/syncCalendarEvent';
import {DialogContent, Typography} from '@material-ui/core';
import {getLinkZoom, handleCopy, handleCopyZoom} from '../common/getLinkZoom';
import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import DialogCustom from '../../Custom/DialogCustom';
import {makeStyles} from '@material-ui/core/styles';
import useIntlId from '../../../hooks/useIntlId';
import {sendMailDeleteEventVote} from "../../Mails/Actions";
import {useAppointmentEvent} from "../../../context/AppointmentEventContext";
import VoteDeleteButton from "./VoteDeleteButton";
import {zoneObj} from "../../../common/moment/format";
import qs from "query-string";
import moment from "moment";
import { handleDeleteZoomMeeting } from '../../../actions/zoom';

const TooltipCustom = styled(Tooltip)`

`

function HoverVote({children, placement}) {
    const [info] = useGlobal("voteBox");
    if (!children)
        return null;
    return (
        <TooltipCustom title={info ? <TitleChildren/> : ''}
                       placement={placement}
                       destroyTooltipOnHide={{keepParent: false}}
                       overlayClassName={'hover-show-tooltip'}

        >
            {children}
        </TooltipCustom>
    );
}

export default HoverVote;

export const TitleChildren = () => {
    const classes = useStyles()
    const [info, setInfo] = useGlobal("voteBox");
    const [colors] = useGlobal('colors');
    const [listHistory, setListHistory] = useGlobal('listHistory');
    const [listFuture, setListFuture] = useGlobal('listFuture');
    const [voteEvents] = useGlobal('voteEvents')
    const {user} = getGlobal();
    const {meeting, users, role, voteBox, setVoteBox} = useAppointmentEvent();
    const [pos, setPos] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [zoomDisable, setZoomDisable] = useState(false);
    const [time, setTime] = useState(null)
    const isSameDay = zoneObj(info?.event?.start).format('YYYY-MM-DD') === zoneObj(info?.event?.end).format('YYYY-MM-DD')
    const [eventByIntl, okIntl, cancelIntl, isDeleteVote, toastDeleteSS, toastDeleteErr, acceptInTimeIntl] = useIntlId(['event.by', 'dialog.action.ok', 'dialog.action.cancel', 'event.dialog.isDeleteVote', 'event.toast.deleteSuccess', 'event.toast.deleteError', 'event.acceptInTime'])
    const [copiedIntl] = useIntlId(['toast.success.calendar.copied'])
    const query = qs.parse(window.location.search);

    useEffect(() => {
        let timer
        if (info) {
            let {top, left} = getPosition(info.el)
            let newTop = top - 10
            if (top && left) {
                setPos({top: newTop, left})
            }
            timer = setInterval(() => {
                const time = new Date(zoneObj(info.event.extendedProps.createdAt).add(30, "seconds").format()).getTime()
                const now = new Date().getTime()
                const second = Math.floor((time - now) / 1000)
                setTime(second)
                if (second <= 0) {
                    clearInterval(timer)
                }

            }, 1000)
            const extendedProps = {...info?.event?.extendedProps, id: info.event.id}
            const linkZoom = getLinkZoom(extendedProps, meeting)
            if (linkZoom) setZoomDisable(false)
            else setZoomDisable(true)

            async function getEventBoxMeeting(id) {
                const eventBoxDoc = db.doc(`${CALENDAR_COLLECTION}/${user?.user_id}/eventBoxMeeting/${id}`)
                const data = (await eventBoxDoc.get())?.data()
                let index = listHistory.findIndex(e => e.id === id)
                if (index !== -1) {
                    listHistory[index] = {...listHistory[index], ...data}
                    setListHistory(listHistory).then()
                    // return data
                }
                let index1 = listFuture.findIndex(e => e.id === id)
                if (index1 !== -1) {
                    listFuture[index1] = {...listFuture[index1], ...data}
                    setListFuture(listFuture).then()
                    // return data
                }
                const linkZoom = getLinkZoom(data, meeting)
                if (linkZoom) setZoomDisable(false)
                else setZoomDisable(true)
                return data
            }

            if (!linkZoom && extendedProps.isAccepted && !extendedProps.isSync) {
                getEventBoxMeeting(info.event.id).then()
            }

        } else {
            setPos(null)
            setLoading(false)
            setTime(null)
        }
        return () => {
            if (timer) clearInterval(timer)
        }
        // eslint-disable-next-line
    }, [info])

    const getZoomMeetingId = (event) => {
        const index = findIndex(voteEvents, e => e.eventId === event.id)
        if (index > -1) {
            return voteEvents[index].zoomMeetingId
        }
        else return null
    }

    const handleClose = () => {
        setInfo(null)
        setPos(null)
        setIsDelete(false)
    }
    const handleDelete = async () => {
        // Delete event vote
        if (!meeting?.id || !info?.event || !info?.event?.id)
            return toast.error(`${toastDeleteErr}`)
        let oldEvents = [...voteBox];
        let newInfo = {...info}
        handleClose();
        try {
            let index = findIndex(voteBox, vote => vote?.id === newInfo?.event?.id)
            if (index !== -1) {
                let eventId = newInfo.event.id
                setLoading(true)
                const batch = db.batch()
                // const voteRef = getVoteBoxDocRef(meeting?.id, eventId)
                // batch.delete(voteRef)
                // remove(voteBox, vot => vot.id === eventId)
                // setVoteBox([...voteBox])
                let checkBatch = true;
                await Promise.all(users.map(async (usr) => {
                    const uId = usr?.id || usr?.user_id
                    let eventDocRef = getEventCalendarDocRef(uId, eventId)
                    const snap = await eventDocRef.get()
                    if (!snap?.exists) return;
                    await eventDocRef.delete()
                    let evt = snap?.data()
                    if (!evt || !evt?.isSync || !evt?.eventSyncId) return;
                    try {
                        await deleteEventSync(evt, uId, usr)
                        await sendMailDeleteEventVote(evt, uId, usr)
                    } catch (e) {
                        if (user.user_id === uId) checkBatch = false
                    }
                }))
                if (checkBatch) {
                    const voteRef = getVoteBoxDocRef(meeting?.id, eventId)
                    await voteRef.delete()
                    toast.success(`${toastDeleteSS}`)
                    await batch.commit()
                } else
                    toast.error(`${toastDeleteErr}`)
                const eventBackup = db.doc(`${MEETING_COLLECTION}/${query.id}`);
                await eventBackup.set({updatedAt: moment().utc().format()}, {merge: true});
                const zoomMeetingId = getZoomMeetingId(info.event)
                if (zoomMeetingId) await handleDeleteZoomMeeting(zoomMeetingId)
            }
        } catch (e) {
            toast.error(`${toastDeleteErr}`)
            setVoteBox({type: "updateAll", payload: {voteBox: [...oldEvents]}})
        } finally {
            setLoading(false)
        }
    }

    const deleteEventSync = async (evt, uID) => {
        if (evt.eventGoogle)
            return deleteEvent(evt.project_uuid, uID, evt.eventSyncId)
        const eventDocRef = getEventsDocRef(uID, evt?.eventSyncId)
        await eventDocRef.delete();
    }

    const isPublish = useMemo(() =>
            users.find(u => u.id === user.id || u.id === user.uuid)?.isPublish
        , [users, user])

    if (!info || !pos)
        return null

    return (
        <div className={classes.root} onMouseLeave={handleClose}>
            <div className={classes.title}>{meeting?.name || 'Event info'}</div>
            {/*{*/}
            {/*    !info.event?.extendedProps?.isAccepted &&*/}
            {/*    <IconButton  className={classes.closeIcon}*/}
            {/*                onClick={handleClose}>*/}
            {/*        <Close/>*/}
            {/*    </IconButton>*/}
            {/*}*/}
            <DialogContent className={classes.content}>
                {
                    isSameDay ?
                        <Typography>{zoneObj(info?.event.start).format('MM/DD')} ({zoneObj(info?.event.start).format('ddd')}) </Typography>

                        :

                        <Typography>
                            {zoneObj(info?.event.start).format('MM/DD')}
                            - {zoneObj(info?.event.end).format('MM/DD')}
                            ({zoneObj(info?.event.start).format('ddd')}
                            - {zoneObj(info?.event.end).format('ddd')})
                        </Typography>

                }
                {!info.event?.allDay &&
                    <Typography>
                        {zoneObj(info?.event?.start).format('HH:mm')} ~ {zoneObj(info?.event?.end).format('HH:mm')}
                    </Typography>
                }
                {
                    info.event?.extendedProps?.email ?
                        <Typography>{eventByIntl}: {info.event?.extendedProps?.email}</Typography>
                        :
                        info.event?.extendedProps?.creator ?
                            <Typography>{eventByIntl}: {info.event?.extendedProps?.creator.email ? info.event?.extendedProps?.creator.email : info.event?.extendedProps?.creator}</Typography>
                            :
                            info.event?.extendedProps?.emailVoter ?
                                <Typography>{eventByIntl}: {info.event?.extendedProps.emailVoter}</Typography>
                                :
                                null
                }

            </DialogContent>
            <div className={classes.blockCenter}>
                <SaveButton
                    copyTime={() => {
                        handleCopy(info.event, meeting)
                        toast.success(`${copiedIntl}`)
                    }}
                    copyZoom={() => {
                        handleCopyZoom(info.event, meeting)
                        toast.success(`${copiedIntl}`)
                    }}
                    disable={loading}
                    disableZoom={zoomDisable}
                />

                {
                    role !== 'viewer' && isPublish &&
                    <VoteDeleteButton
                        handleIsDelete={() => setIsDelete(true)}
                    />
                }

                {/*{*/}
                {/*    role !== 'viewer' &&*/}
                {/*    <CLFButtonSVG*/}
                {/*        name={deleteIntl}*/}
                {/*        background={'#690003'}*/}
                {/*        borderColor={'#690003'}*/}
                {/*        size={'default'}*/}
                {/*        onClick={handleIsDelete}*/}
                {/*        block*/}
                {/*        margin={"auto"}*/}
                {/*    />*/}
                {/*}*/}

                {/*{*/}
                {/*    role !== 'viewer' && !info.event.extendedProps.isAccepted &&*/}
                {/*    <CLFButtonSVG*/}
                {/*        name={'Accept'}*/}
                {/*        background={colors?.primary}*/}
                {/*        borderColor={colors?.primary}*/}
                {/*        size={'default'}*/}
                {/*        disable={loading}*/}
                {/*        onClick={handleClickAccept}*/}
                {/*    />*/}
                {/*}*/}
            </div>
            {
                time && time > 0 && time < 30 ?
                    <p className={classes.p}>{acceptInTimeIntl?.replace('time', `${time}`)}</p>
                    :
                    null
            }
            {
                isDelete &&
                <DialogCustom
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    title={isDeleteVote}
                    actions={<div className={'clf-flex-center'}>
                        <CLFButtonSVG name={cancelIntl}
                                      width={'100px'}
                                      background={'#690003'}
                                      borderColor={'#690003'}
                                      onClick={() => setIsDelete(false)}/>
                        <CLFButtonSVG name={okIntl}
                                      width={'100px'}
                                      background={colors?.primary}
                                      borderColor={colors?.primary}
                                      disable={loading}
                                      onClick={handleDelete}
                                      loading={loading}
                        />
                    </div>}
                />
            }
        </div>)
}
const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 250,
        borderRadius: 10,
        position: "relative",
        background: "#FFFFFF",
        color: "#000",
        maxWidth: 400,
    },
    content: {
        padding: "10px"
    },
    title: {
        width: "100%",
        borderRadius: "7px",
        background: "#F1F1F1",
        fontSize: "1.4rem",
        fontWeight: "bold",
        textAlign: "center",
        color: theme.props.primaryColor,
        padding: "3px 10px"
    },
    actions: {
        textAlign: "center",
        display: "block"
    },
    closeIcon: {
        position: "absolute",
        right: 0,
        top: 0,
    },
    p: {
        fontSize: "1.4rem",
        fontWeight: "bold",
        textAlign: "center"
    },
    blockCenter: {
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center'
    },
}))
