import {find, uniq} from "lodash";
import {products} from './hxProduct'

export const getHxPlan = (subs) => {
    const prices = uniq(subs.map(item => item?.plan?.id || '').filter(x => x))
    let plan = null
    let type = null
    let price = null
    let mySub = null
    products.forEach(item => {
        if (prices.includes(item.price.month) || prices.includes(item.price.year)) {
            plan = item
            type = prices.includes(item.price.year) ? "year" : "month"
            price = prices.includes(item.price.year) ? item.price.year : item.price.month
            mySub = find(subs, item => item?.plan?.id === price)
        }
    })

    return {plan, type, price, mySub}
}
