import {makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles(theme => ({
    meetingScreenFullMeeting1: {
        position: 'relative',
        margin: 10,
        backgroundColor: "#f1f1f1",
        '& .fc-time-grid-container': {},
        [theme.breakpoints.down('md')]: {
            '& .fc-time-grid-container': {
                height: 'auto !important'
            },
            "& .fc-time-grid .fc-slats tr": {
                height: "2.5rem !important",
            }
        },
    },
    meetingScreenFull: {
        position: 'relative',
        margin: 10,
        backgroundColor: "#f1f1f1",
        '& .fc-view-container': {
            height: 'calc(100vh - 133px) !important',
        },
        [theme.breakpoints.down('md')]: {
            '& .fc-time-grid-container': {
                height: 'auto !important'
            },
            "& .fc-time-grid .fc-slats tr": {
                height: "2.5rem !important",
            }
        },
    },
    headerHtml: {
        padding: 3,
        fontSize: '1em',
        [theme.breakpoints.down('md')]: {
            fontSize: '7px',
        },
    },
    headerHtmlDay: {
        // padding: 5,
        fontSize: '0.825rem',
    },
    headerHtmlSmallDay: {
        fontSize: '0.8rem',
        padding: 2,
    },
    headerHr: {
        height: 1,
        width: '100%',
        backgroundColor: '#ccc',
        position: 'absolute',
        left: -1,
        [theme.breakpoints.down('md')]: {
            width: '110%',
        },
    },
    divExpired: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(241, 241, 241, 0.6)',
        zIndex: 999,
    },
    divExpiredRoot: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    absoluteExpired: {
        position: 'absolute',
        top: '30%',
        left: 0,
        right: 0,
        transform: "translateY(-50%)",
        fontWeight: "bold"
    },
    absoluteExpiredButton: {
        position: 'absolute',
        top: '40%',
        left: 0,
        right: 0,
        transform: "translateY(-50%)",
        margin: 'auto'
    },
    events: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        minHeight: '1em',
        position: 'relative',
        textAlight: "center",
        zIndex: 9999,
        overflow: 'hidden'
    },
}))
