const checkHeight = (api, elementId, fullHeight, isPreview = false, isShareUrl = false, topMeeting = 0) => {
    const calendarEl = document.getElementById(elementId)
    const heightWindow = document.body?.offsetHeight
    if (!calendarEl)
        return
    const body = calendarEl.querySelector('.fc-timegrid-slots')
    if (!body)
        return;

    let headerHeight = document.getElementsByClassName("fc-col-header")[0]?.offsetHeight || 50
    let allDayHeight = document.getElementsByClassName("fc-timegrid-axis fc-scrollgrid-shrink")[0]?.offsetHeight || 35
    let headerShareHeight = document.getElementsByClassName('headerShare')[0]?.offsetHeight + 40 || 0
    let height = 0;
    if (elementId === "shareTime") {
        headerHeight = document.getElementsByClassName("fc-col-header")[0].offsetHeight + 20 || 79
        allDayHeight = document.getElementsByClassName("fc-timegrid-axis fc-scrollgrid-shrink")[0].offsetHeight || 56
        headerShareHeight = document.getElementsByClassName('headerShare')[0]?.offsetHeight + 40 || 0
        height = isPreview ? (heightWindow - headerHeight - allDayHeight - 300 - headerShareHeight) : (heightWindow - headerHeight - allDayHeight - 80 - headerShareHeight)
    }

    if (isPreview && elementId === 'previewPartner') {
        let login = document.getElementById('previewLogin').firstChild
        let freeTime = document.getElementById('ownFreeTime')
        let copyUrl = document.getElementById('copyUrl')
        let shareTime = document.getElementById('shareTime')
        let parentPreviewLogin = document.getElementById('previewLogin').parentElement
        height = heightWindow - login.offsetHeight - freeTime.offsetHeight - copyUrl.offsetHeight - headerHeight - allDayHeight - 64 - 20 - 50
        parentPreviewLogin.style.paddingBottom = '0'
        login.style.paddingTop = '0'
        login.style.paddingBottom = '0'
        freeTime.style.paddingTop = '0'
        freeTime.style.paddingBottom = '0'
        copyUrl.style.paddingTop = '0'
        shareTime.style.padding = '0'
        shareTime.style.maginTop = `${5}px`
        body.firstChild.style.height = `${height}px`
        body.firstChild.style.fontSize = `${9}px`
        return;
    }

    if (elementId === "meeting-editFreeTime")
        height = heightWindow - headerHeight - allDayHeight - 300

    if (elementId === "meeting")
        height = isShareUrl ? (heightWindow - headerHeight - allDayHeight - 170) : (heightWindow - headerHeight - allDayHeight - topMeeting - 180)

    if (fullHeight === "fullHeight") {
        body.firstChild.style.height = `${height}px`
    } else {
        body.firstChild.style.height = "100%"
        if (elementId === "meeting")
            body.firstChild.style.minHeight = "400px"
    }

    if (api) {
        api.updateSize()
    }
}

export default checkHeight;
