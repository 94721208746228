import React from 'react';
import IconIdentity from '../../Calendar/google/identityIcon';
import {Modal} from 'antd';
import CancelButton from '../../Dialog/CancelButton';
import DeleteButton from '../../Dialog/DeleteButton';
import useIntlId from "../../../hooks/useIntlId";

function DeletedIdentityDialog({identity, setIdentity, onSave, loading, classes}) {
    const [idIntl] = useIntlId(['app.profile.identities'])
    return (
        <Modal
            visible={Boolean(identity)}
            title={`${identity?.name} ${idIntl}`}
            onCancel={() => setIdentity(null)}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CancelButton
                    onClick={() => setIdentity(null)}
                    disable={loading}
                    // name={'Cancel'}
                    // className={classes.secondary}
                />
                <DeleteButton
                    // name={'Delete'}
                    loading={loading}
                    onClick={() => onSave({...identity, isDeleted: true})}
                    disable={loading}
                    // className={classes.primary}
                />
            </div>}
        >
            <IconIdentity color={identity?.color || null}/>
        </Modal>
        // <Dialog
        //     open={Boolean(identity)}
        //     onClose={() => setIdentity(null)}
        // >
        //     <DialogContent>
        //         <DialogContentText>Do you want delete this identity</DialogContentText>
        //         <IconIdentity color={identity?.color || null}/>
        //     </DialogContent>
        //     <DialogActions>
        //         <Button onClick={() => onSave({...identity, isDeleted: true})} disabled={loading}>
        //             OK
        //         </Button>
        //         <Button onClick={() => setIdentity(null)} disabled={loading}>
        //             Cancel
        //         </Button>
        //     </DialogActions>
        // </Dialog>
    );
}

export default DeletedIdentityDialog;
