import {findIndex} from "lodash";
import moment from "moment";
import copy from "clipboard-copy";
import {getIntlId} from "../../../hooks/useIntlId";
import {getGlobal} from 'reactn'

export const handleCopy = (hoverTitle, meeting = null) => {
    if (!hoverTitle)
        return null
    let text = getInfoEvent(hoverTitle, meeting)
    copy(text).then()
}

export const handleCopyZoom = async (event, meeting = null) => {
    if (!event)
        return null
    let text = getInfoEvent(event, meeting)
    const extendedProps = {...event.extendedProps, id: event.id};
    const linkZoom = getLinkZoom(extendedProps, meeting)
    if (linkZoom) {
        text += `Zoom: ${linkZoom}\n`
    }
    await copy(text)
}

export const getLinkZoom = (event, meeting) => {
    try {
        if (!event?.isEventVote || (event?.isEventVote && event.meetingId !== meeting?.id)) {
            // console.log("Event not is vote")
            if (event.conferenceData?.join_url) {
                return event?.conferenceData?.join_url
            }
            if (event?.conferenceData?.entryPoints) {
                let idx = findIndex(event?.conferenceData?.entryPoints || [], {entryPointType: "video"})
                if (idx === -1)
                    return null
                return event?.conferenceData.entryPoints[idx]?.uri || null
            }
            return null
        }
        // console.log("Event....Vote...")
        const {listHistory, listFuture} = getGlobal()
        let eventCheck = null
        const eventInHistory = listHistory.find(e => e.id === event.id)
        const eventInFuture = listFuture.find(e => e.id === event.id)
        if (eventInHistory) eventCheck = {...eventInHistory}
        if (eventInFuture) eventCheck = {...eventInFuture}
        if (!eventCheck) return event?.conferenceData?.join_url || null
        // console.log({eventCheck})
        if (eventCheck?.conferenceData?.join_url) {
            return eventCheck?.conferenceData?.join_url
        }
        if (eventCheck?.conferenceData?.entryPoints) {
            let idx = findIndex(eventCheck?.conferenceData?.entryPoints || [], {entryPointType: "video"})
            if (idx === -1)
                return null
            return eventCheck?.conferenceData?.entryPoints[idx]?.uri || ''
        }
        return null
    } catch (e) {
        return null
    }
}

const getInfoEvent = (event, meeting) => {
    const eventByIntl = getIntlId('event.by')
    const start = event.start
    const end = event.end
    const allDay = event.allDay
    const byUser = event?.extendedProps?.creator?.email ? event.extendedProps.creator.email : event?.extendedProps?.email
    const title = meeting ? meeting?.name : event?.title || ""
    const isSameDay = moment(start).format('YYYY-MM-DD') === moment(end).format('YYYY-MM-DD')
    let text = "";
    text += `${title}\n`
    if (isSameDay) {
        text += `${moment(start).format('MM/DD')} （${moment(start).format('ddd')})`
        text += "\n"
        text += `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`
        text += "\n"
    } else {
        text += `${moment(start).format('MM/DD')} `
        text += `- ${allDay ? moment(end).subtract(1, "day").format('MM/DD') : moment(end).format('MM/DD')} `
        text += `(${moment(start).format('ddd')} - `
        text += `${allDay ? moment(end).subtract(1, "day").format('ddd') : moment(end).format('ddd')} ）`
        text += "\n"
        if (!allDay) {
            text += `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`
            text += "\n"
        }
    }
    if (byUser)
        text += `${eventByIntl}: ${byUser}\n`
    return text

}
