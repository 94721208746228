import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import React from 'react';
import useIntlId from '../../hooks/useIntlId';
import commonStyles from "./commonStyles";

export default function ArchiveButton({onClick, ...others}) {
    const classes = commonStyles()
    const [archiveIntl] = useIntlId(['app.setting.archive'])
    return (
        <CLFButtonSVG
            name={archiveIntl}
            onClick={onClick}
            className={classes.primary}
            {...others}
        />
    )
}
