import {db} from '../../firebaseConfig'
import {getGlobal} from 'reactn'
import moment from "moment";
import {v4 as uuidv4} from 'uuid'
import {
    CALENDAR_COLLECTION,
    MAIL_TEMPLATES_INVITE_EVENT_DOC,
    MAIL_TEMPLATES_SEND_MAIL_DELETE_EVENT_VOTE_DOC,
    MAIL_TEMPLATES_SEND_MAIL_GRANT_ROLE_DOC,
    MAIL_TEMPLATES_SEND_MAIL_NOTIFICATION_AUTO_SHARE,
    SUBDOMAIN_APPOINTMENT
} from "../../config/constants";
import {getAnonymousCollRef, getMeetingDocRef, getUsersCollRef} from "../../common/firebaseRef/meetings";
import {concat, isEmpty, remove} from 'lodash'
import {notificationsDoc} from "../../common/firebaseRef/calendar";
import {getFormatDate} from "../Meeting/ShareFreeTimeUrl/common";

const axios = require('axios')
const jwt = require('jsonwebtoken');

export const sentEmailInviteUsers = async (batch, meeting) => {
    const {user} = getGlobal();
    let userInvites = meeting?.userInvites || [];
    const messageId = "email.message.invite";

    await Promise.all(userInvites.map(async usr => {
        let language = usr?.language || 'ja-jp';

        const languageSnap = await db.doc(`intl/calendarIntl/langColl/${language}`).get();
        const languageData = languageSnap.exists ? languageSnap.data() : {};
        let fullName = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname

        const content = `${fullName} ${languageData['email.message.invite']}`;
        const startIntl = `${languageData['email.message.startDay']}`;
        const endIntl = `${languageData['email.message.endDay']}`;
        const btnIntl = `${languageData['email.message.btnGotoEvent']}`;
        const dontRely = `${languageData['email.message.dontReply']}`;
        const subjectInvite = `${languageData['email.message.subject.invite']}`.split("|");

        // sent email to user

        const token = jwt.sign({
            id: meeting?.id,
            from: user.user_id
        }, 'geniamMeeting', {expiresIn: '1h'});
        const linkAnonymous = `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/event?id=${meeting?.id}&from=${user.user_id}&tokenMeeting=${token}`

        const link = usr.isAnonymous ?
            linkAnonymous
            :
            `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/event?id=${meeting.id}&by=${meeting.owner}&to=${usr.email}`;

        // convert to dynamic url
        const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                "domainUriPrefix": "https://appygeniam.page.link",
                "link": link,
                "androidPackageName": null,
                "iosBundleId": null
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )

        // for add email to emails collections
        const uuidEmail = `email_evt_meeting_${uuidv4()}`;

        const mailDocRef = db.doc(`mails/${uuidEmail}`);
        const title = meeting.name !== undefined ? meeting.name : meeting.title
        let subject

        if (usr?.language) {
            subject = subjectInvite[0] + fullName + subjectInvite[1] + title + subjectInvite[2]
        } else {
            subject = `${fullName} invites you to the group「${title}」.`
        }
        batch.set(mailDocRef, {
            to: usr.email,
            template: {
                name: MAIL_TEMPLATES_INVITE_EVENT_DOC,
                data: {
                    startDay: `${moment(meeting.startDay).utc().format("MM/DD")} ${moment(meeting.startDay).utc().format("dd")}`,
                    endDay: `${moment(meeting.endDay).utc().format("MM/DD")} ${moment(meeting.endDay).utc().format("dd")}`,
                    author: meeting.owner,
                    title: title || '',
                    subject: subject || '',
                    subDomain: SUBDOMAIN_APPOINTMENT,
                    link: shortLink || '',
                    content: subject || '',
                    btnIntl: btnIntl,
                    startIntl: startIntl,
                    endIntl: endIntl,
                    dontRely: dontRely
                }
            },
            meetingId: meeting.id,
            createdAt: moment().utc().format()
        });

        if (!usr.isAnonymous) {
            // add notifications
            let dataNotice = {
                id: 'meeting_notice_' + uuidv4(),
                isRead: false,
                isClick: false,
                createdAt: moment().utc().format(),
                updatedAt: moment().utc().format(),
                meetingId: meeting.id,
                name: meeting.name !== undefined ? meeting.name : meeting.title,
                content,
                role: usr.role || (meeting.userInvites.length > 1 ? "viewer" : "admin"),
                from: user.user_id,
                by: meeting.owner,
                to: usr.email,
                messageId,
                nickname: user.nickname,
                first_name: user?.first_name || "",
                last_name: user?.last_name || "",
                avatar: user.avatar
            };

            let noticeDocRef = db.collection(CALENDAR_COLLECTION)
                .doc(usr.id)
                .collection('notifications')
                .doc(dataNotice.id);

            batch.set(noticeDocRef, dataNotice);
        }
        return usr
    }));

    return await batch.commit()
}

export const handleSentEmailSuccess = async (batch, meeting, url, messages, isRefer, isRequest, isCancel, isEmailOnly) => {
    try {
        const {user} = getGlobal()
        let nickname = "", avatar = "https://calendar.geniam.com/favicon.ico"
        if (!isEmpty(user)) {
            nickname = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname
            avatar = user.avatar
        }
        let users = [];
        const messageId = isCancel ?
            'email.message.canceled'
            :
            isRefer ?
                'email.message.referEvent'
                :
                isRequest ?
                    'email.message.requestChangeVoteBox'
                    :
                    'email.message.accepted';
        const eventDocRef = getMeetingDocRef(meeting.id)
        const eventSnapshot = await eventDocRef.get();

        if (!eventSnapshot.exists)
            return await batch.commit()

        const userCollRef = getUsersCollRef(meeting.id)
        const snapshots = await userCollRef.get();

        const anonymousCollRef = getAnonymousCollRef(meeting.id)
        const anonymousSnapshots = await anonymousCollRef.get()

        if (!snapshots.size && !anonymousSnapshots.size)
            return await batch.commit()

        const usersData = snapshots.docs.map(doc => ({...doc.data(), id: doc.id}));
        const anonymousUsers = anonymousSnapshots.docs.map(doc => ({...doc.data(), id: doc.id}));

        concat(usersData, anonymousUsers).forEach(snap => {
            if (isRequest) {
                if (snap.joined && (snap.role === 'admin' || snap.role === 'owner'))
                    users.push({...snap, id: snap.id});
            } else {
                if (snap.joined)
                    users.push({...snap, id: snap.id});
            }
        });

        remove(users, u => u.id === user.user_id);

        await Promise.all(users.map(async usr => {
            let language = usr?.language || 'ja-jp';
            if (!usr.joined) return;

            const fullName = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname
            const _fullName = (usr.first_name || usr.last_name) ? `${usr.last_name || ''} ${usr.first_name || ''}` : usr.nickname
            const languageSnap = await db.doc(
                `intl/calendarIntl/langColl/${language}`).get();
            const languageData = languageSnap.exists ? languageSnap.data() : {};
            let content = isRequest ?
                `${_fullName}, ${fullName} ${languageData[messageId]} \
          Vote box: ${moment(meeting?.start).format('YYYY-MM-DD HH:mm')} ~ ${moment(
                    meeting?.end).format('YYYY-MM-DD HH:mm')}`
                :
                `${nickname} ${languageData[messageId]}`;

            const token = jwt.sign({
                id: meeting?.id,
                from: user.user_id
            }, 'geniamMeeting', {expiresIn: '1h'});

            const linkAnonymous = `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/event?id=${meeting?.id}&from=${user.user_id}&tokenMeeting=${token}`

            const link = usr.isAnonymous ?
                linkAnonymous
                : `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/event?id=${meeting.id}`;

            // convert to dynamic url
            // const appId = 'com.classfunc.appointment'
            // const {data: {shortLink}} = await axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCXTwJn_Qiz6pl3JhcHatcRp3dBXsciMsE', {
            //     longDynamicLink: `https://geniam.page.link?link=${encodeURIComponent(link)}&apn=${appId}&ibi=${appId}`
            // }, {
            //     headers: {
            //         "authorization": null
            //     }
            // })
            const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                    "domainUriPrefix": "https://appygeniam.page.link",
                    "link": link,
                    "androidPackageName": null,
                    "iosBundleId": null
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )

            // for add email to emails collections
            const uuidEmail = `email_evt_meeting_${uuidv4()}`

            const mailDocRef = db.doc(`mails/${uuidEmail}`)

            batch.set(mailDocRef, {
                // cc: isEmailOnly ? user.email : '',
                to: usr.email,
                template: {
                    name: 'sendMailSuccess',
                    data: {
                        title: eventSnapshot.data().name || '',
                        subDomain: SUBDOMAIN_APPOINTMENT,
                        link: shortLink || '',
                        content: content || '',
                        messages: messages,
                        url: url,
                    }
                },
                meetingId: meeting.id,
                createdAt: moment().utc().format()
            });

            if (isEmailOnly) {
                return null;
            }

            if (!usr.isAnonymous) {
                // add notifications
                let dataNotice = {
                    id: 'meeting_notice_' + uuidv4(),
                    isRead: false,
                    isClick: false,
                    createdAt: moment().utc().format(),
                    updatedAt: moment().utc().format(),
                    meetingId: meeting.id,
                    name: eventSnapshot.data().name,
                    content,
                    messageId: isRequest ? null : messageId,
                    nickname,
                    last_name: usr?.last_name || "",
                    first_name: usr?.first_name || "",
                    avatar
                }
                let noticeDocRef = db.collection(CALENDAR_COLLECTION)
                    .doc(usr.id)
                    .collection('notifications')
                    .doc(dataNotice.id)

                batch.set(noticeDocRef, dataNotice)
            }
            return usr
        }));

        await batch.commit()
    } catch (e) {
        console.log(e.toString())
    }
}

export const handleSharedEmail = async (batch, meetingId, messageId) => {
    try {
        const {user} = getGlobal();
        const {nickname, avatar, first_name, last_name} = user;
        const meetingDocRef = getMeetingDocRef(meetingId);
        const snapshot = await meetingDocRef.get();

        if (!snapshot.exists)
            return await batch.commit();

        const userCollRef = getUsersCollRef(meetingId)
        const snapshots = await userCollRef.get();
        const anonymousCollRef = getAnonymousCollRef(meetingId)
        const anonymousSnapshots = await anonymousCollRef.get()

        if (!snapshots.size && !anonymousSnapshots.size)
            return await batch.commit();

        const eventData = snapshot.data();

        const users = snapshots.docs.map(doc => ({...doc.data(), id: doc.id}));
        const anonymousUsers = anonymousSnapshots.docs.map(doc => ({...doc.data(), id: doc.id}));

        const allUsers = concat(users, anonymousUsers)

        remove(allUsers, us => us.id === user.user_id);

        if (users.length === 0)
            return await batch.commit();

        await Promise.all(allUsers.map(async usr => {
            if (!usr.joined)
                return null;
            let language = usr?.language || 'ja-jp';
            const languageSnap = await db.doc(
                `intl/calendarIntl/langColl/${language}`).get();
            const languageData = languageSnap.exists ? languageSnap.data() : {};
            const fullName = (first_name || last_name) ? `${last_name || ''} ${first_name || ''}` : nickname

            let content = messageId ?
                `${fullName} ${languageData[messageId]}` :
                'There has been a change from admin or owner, please check';

            // const subDomain = process.env.REACT_APP_ENV === 'production' ? 'appointment' : 'appointment-stg';

            // const token = jwt.sign({
            //     id: snapshot.data()?.id,
            //     from: user.user_id
            // }, 'geniamMeeting', {expiresIn: '1h'});
            // const linkAnonymous = `https://${subDomain}.geniam.com/event?id=${snapshot.data()?.id}&from=${user.user_id}&tokenMeeting=${token}`

            // const link = usr.isAnonymous ?
            //     linkAnonymous
            //     :
            //     `https://${subDomain}.geniam.com/event?id=${meetingId}`;

            // for add email to emails collections
            // const uuidEmail = `email_evt_meeting_${uuidv4()}`

            // const mailDocRef = db.doc(`mails/${uuidEmail}`)

            // batch.set(mailDocRef, {
            //     to: usr.email,
            //     template: {
            //         name: 'sharedMail',
            //         data: {
            //             title: eventData.name,
            //             subDomain: subDomain,
            //             link: link,
            //             content: content,
            //         }
            //     },
            //     meetingId,
            //     createdAt: moment().utc().format()
            // });

            if (!usr.isAnonymous) {
                let dataNotice = {
                    id: 'meeting_notice_' + uuidv4(),
                    isRead: false,
                    isClick: false,
                    createdAt: moment().utc().format(),
                    updatedAt: moment().utc().format(),
                    meetingId: meetingId,
                    name: eventData.name,
                    content: content || '',
                    messageId,
                    nickname: nickname || '',
                    last_name: last_name || '',
                    first_name: first_name || '',
                    avatar
                };

                let noticeDocRef = db.collection(CALENDAR_COLLECTION)
                    .doc(usr.id)
                    .collection('notifications')
                    .doc(dataNotice.id)

                batch.set(noticeDocRef, dataNotice)
            }
        }));

        await batch.commit()
        return true
    } catch (e) {
        console.log(e.toString())
        return false
    }
}

export const sendMailDeleteEventVote = async (evt, uId, usr) => {
    const {user} = getGlobal()

    try {
        if (!user?.user_id || !usr || !evt || !usr.joined) return;
        let language = usr?.language || 'en-us';
        const languageSnap = await db.doc(
            `intl/calendarIntl/langColl/${language}`).get();
        const languageData = languageSnap.exists ? languageSnap.data() : {};
        const btnViewGroup = `${languageData['email.message.btnViewGroup']}`;
        const dontRely = `${languageData['email.message.dontReply']}`;
        // const dayId = `${languageData['appbar.formatAppbar.day']}`;
        const subjectDeleteVote = `${languageData['email.message.subject.deleteVote']}`.split("|");
        const subjectDeleteVoteToUser = `${languageData['email.message.subject.deleteVoteToUser']}`.split("|");
        const fullName = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname

        const link = `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/event?id=${evt.meetingId}`;
        // convert to dynamic url
        // const appId = 'com.classfunc.appointment'
        // const {data: {shortLink}} = await axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCXTwJn_Qiz6pl3JhcHatcRp3dBXsciMsE', {
        //     longDynamicLink: `https://geniam.page.link?link=${encodeURIComponent(link)}&apn=${appId}&ibi=${appId}`
        // }, {
        //     headers: {
        //         "authorization": null
        //     }
        // })

        const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                "domainUriPrefix": "https://appygeniam.page.link",
                "link": link,
                "androidPackageName": null,
                "iosBundleId": null
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )

        const groupName = `${evt.title}`
        const timeStart = `${moment(evt.start).tz(usr.time_zone).format('HH:mm')}`
        const timeEnd = `${moment(evt.end).tz(usr.time_zone).format('HH:mm')}`
        const time = `${timeStart} ~ ${timeEnd}`

        let startDay = getFormatDate(usr?.language, evt.start, usr.time_zone)

        let subject
        if (usr?.language) {
            if (user?.user_id === uId) {
                if (usr.language === 'ja-jp') {
                    subject = subjectDeleteVoteToUser[0] + groupName + subjectDeleteVoteToUser[1] + startDay + subjectDeleteVoteToUser[2] + timeStart + subjectDeleteVoteToUser[3] + timeEnd + subjectDeleteVoteToUser[4]
                }
                if (usr.language === 'en-us') {
                    subject = subjectDeleteVoteToUser[0] + startDay + subjectDeleteVoteToUser[1] + timeStart + subjectDeleteVoteToUser[2] + timeEnd + subjectDeleteVoteToUser[3] + groupName + subjectDeleteVoteToUser[4]
                }
                if (usr.language === 'vi-vn') {
                    subject = subjectDeleteVoteToUser[0] + timeStart + subjectDeleteVoteToUser[1] + timeEnd + subjectDeleteVoteToUser[2] + startDay + subjectDeleteVoteToUser[3] + groupName + subjectDeleteVoteToUser[4]
                }
            } else {
                if (usr.language === 'ja-jp') {
                    subject = subjectDeleteVote[0] + fullName + subjectDeleteVote[1] + groupName + subjectDeleteVote[2] + startDay + subjectDeleteVote[3] + timeStart + subjectDeleteVote[4] + timeEnd + subjectDeleteVote[5]
                }
                if (usr.language === 'en-us') {
                    subject = subjectDeleteVote[0] + fullName + subjectDeleteVote[1] + startDay + subjectDeleteVote[2] + timeStart + subjectDeleteVote[3] + timeEnd + subjectDeleteVote[4] + groupName + subjectDeleteVote[5]
                }
                if (usr.language === 'vi-vn') {
                    subject = subjectDeleteVote[0] + fullName + subjectDeleteVote[1] + timeStart + subjectDeleteVote[2] + timeEnd + subjectDeleteVote[3] + startDay + subjectDeleteVote[4] + groupName + subjectDeleteVote[5]
                }
            }
        } else {
            subject = `${fullName} deleted the event in「${groupName}」group on ${startDay} at [${timeStart} ~ ${timeEnd}]`
        }

        const batch = db.batch()

        const uuidEmail = `email_delete_${uuidv4()}`
        const mailDocRef = db.doc(`mails/${uuidEmail}`)
        // const mailTemplates = process.env.REACT_APP_ENV === 'production' ? 'sendMailDeleteEventVote' : 'sendMailDeleteEventVote-stg'
        batch.set(mailDocRef, {
            to: usr.email,
            template: {
                name: MAIL_TEMPLATES_SEND_MAIL_DELETE_EVENT_VOTE_DOC,
                data: {
                    link: shortLink || '',
                    subject: subject || '',
                    content: subject || '',
                    time: time,
                    startDay: startDay,
                    groupName: groupName || '',
                    btnViewGroup: btnViewGroup,
                    dontRely: dontRely
                }
            },
        })
        await batch.commit()

    } catch (e) {
        console.log(e);
    }

}

export const sendMailGrantRole = async (meeting, userRole, nextRole) => {
    const {user} = getGlobal()
    try {
        if (!user?.user_id || !meeting.id || !userRole.id || nextRole === 'viewer') return;
        let language = userRole?.language || 'en-us';
        const messageId = `email.message.grantRole.notification`
        const languageSnap = await db.doc(
            `intl/calendarIntl/langColl/${language}`).get();
        const languageData = languageSnap.exists ? languageSnap.data() : {};
        const btnViewGroup = `${languageData['email.message.btnViewGroup']}`;
        const dontReply = `${languageData['email.message.dontReply']}`;
        // const contentNotificationId = `${languageData['email.message.grantRole.contentNotification']}`.split("|");
        const subjectId = `${languageData['email.message.grantRole.subject']}`.split("|");
        const contentMailId = `${languageData['email.message.grantRole.contentMail']}`.split("|");

        const groupName = `${meeting.name}`
        const link = `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/event?id=${meeting.id}`;
        const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                "domainUriPrefix": "https://appygeniam.page.link",
                "link": link,
                "androidPackageName": null,
                "iosBundleId": null
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        const batch = db.batch()
        // for add email to emails collections
        const uuidEmail = `email_evt_meeting_${uuidv4()}`;
        const mailDocRef = db.doc(`mails/${uuidEmail}`);
        const ownerName = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname
        // let contentNotification
        let subject
        let contentMail

        if (language) {
            subject = subjectId[0] + groupName + subjectId[1]
            contentMail = contentMailId[0] + ownerName + contentMailId[1]
        } else {
            // contentNotification = `${ownerName} (group owner) has changed your role as Group Admin (administration) in group「${groupName}」`
            subject = `You have some new activities in「${groupName}」`
            contentMail = `${ownerName} (group owner) has changed your role as Admin user (administration). By becoming an Admin, now you are able to "enter general message" and "add new member',..`
        }

        if (!userRole.isAnonymous) {

            batch.set(mailDocRef, {
                to: userRole.email,
                template: {
                    name: MAIL_TEMPLATES_SEND_MAIL_GRANT_ROLE_DOC,
                    data: {
                        link: shortLink || '',
                        subject: subject || '',
                        content: contentMail || '',
                        groupName: groupName || '',
                        btnViewGroup: btnViewGroup,
                        dontReply: dontReply || ''
                    }
                },
            })

            let dataNotice = {
                id: 'meeting_notice_' + uuidv4(),
                isRead: false,
                isClick: false,
                createdAt: moment().utc().format(),
                updatedAt: moment().utc().format(),
                meetingId: meeting?.id,
                name: groupName,
                // content: contentNotification || '',
                nickname: user?.nickname || '',
                last_name: user?.last_name || '',
                first_name: user?.first_name || '',
                avatar: user?.avatar || '',
                messageId: messageId
            };
            const noticeDocRef = notificationsDoc(userRole.id, dataNotice.id)
            batch.set(noticeDocRef, dataNotice)
        }
        return await batch.commit()
    } catch (e) {
        console.log(e);
    }
}

export const sendMailNotificationAutoShare = async (meeting, userNotice) => {
    const {user} = getGlobal()
    try {
        if (!meeting || !userNotice?.id) return null;
        let language = userNotice?.language || 'en-us';
        const languageSnap = await db.doc(
            `intl/calendarIntl/langColl/${language}`).get();
        const languageData = languageSnap.exists ? languageSnap.data() : {};
        const btnViewGroup = `${languageData['email.message.btnViewGroup']}`;
        const dontReply = `${languageData['email.message.dontReply']}`;
        const subjectId = `${languageData['event.mail.userNotifi.subject']}`.split("|");

        const batch = db.batch()
        const uuidEmail = `email_evt_meeting_${uuidv4()}`;
        const mailDocRef = db.doc(`mails/${uuidEmail}`);
        const link = `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/event?id=${meeting.id}`;
        // convert to dynamic url
        const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                "domainUriPrefix": "https://appygeniam.page.link",
                "link": link,
                "androidPackageName": null,
                "iosBundleId": null
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        const senderName = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname
        const groupName = meeting.name || ''
        //sent mail

        let subject

        if (language) {
            subject = subjectId[0] + senderName + subjectId[1] + groupName + subjectId[2]
        } else {
            subject = `${senderName} prompted you to adjust free time sharing as "auto" in the ${groupName} group`
        }

        batch.set(mailDocRef, {
            to: userNotice.email,
            template: {
                name: MAIL_TEMPLATES_SEND_MAIL_NOTIFICATION_AUTO_SHARE,
                data: {
                    link: shortLink || '',
                    subject: subject || '',
                    content: subject || '',
                    groupName: groupName || '',
                    btnViewGroup: btnViewGroup || '',
                    dontReply: dontReply || ''
                }
            },
        })
        await batch.commit()

    } catch (e) {
        console.log(e);
    }
}
