import React, {useEffect, useState} from 'react';
import {Grid, List, ListItem, makeStyles, Typography} from "@material-ui/core";
import {getGlobal, useGlobal} from "reactn";
import {cloneDeep, findIndex, orderBy} from "lodash";
import {withRouter} from 'react-router-dom'
import {onLogOut} from '../../common/googleCal/logOutGoogle'
import {Modal} from "antd";
import MenuItemContainer from "./MenuItemContainer";
import {ReactComponent as SaveSvg} from '../../asSvg/copy.svg';
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {ItemCheckedCustom} from "item-checked-custom";
import useIntlId from "../../hooks/useIntlId";
import CancelButton from "../Dialog/CancelButton";
import OkButton from "../Dialog/OkButton";
import {updateProjectSelected, updateUsersProjects} from "../Meeting/Home/utils";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '40px 0px'
    },
    list: {
        maxWidth: 360
    },
    archiveIcon: {
        fontSize: 20
    },
    title: {
        fontSize: "1.3rem",
        color: '#000',
        textAlign: 'center',
        marginBottom: 10
    },
    titleItem: {
        '& span': {
            fontSize: "1.2rem !important",
            fontWeight: 700
        }
    },
    moveText: {
        "&:hover": {
            cursor: "move"
        }
    },
    email: {
        color: theme.props.primaryColor,
        fontSize: '1.3rem',
        fontWeight: 'bold',
        marginLeft: 10
    },
    userEmail: {
        background: '#F1F1F1',
        borderRadius: 25,
        fontWeight: 700,
        textTransform: 'lowercase',
        fontSize: '1.3rem',
        color: '#000',
        width: '350px',
        height: '35px'
    },
    gridRoot: {
        width: '100%',
        padding: 10
    },
    gridItem: {
        background: '#FFF',
        borderRadius: 15,
        padding: '10px 0',
        margin: '0 10px',
        height: '100%'
    },
    box: {
        position: "relative",
        borderRadius: "5px",
        width: 20,
        height: 20,
        marginRight: 7,
        "& img": {
            position: "absolute",
            left: "50%",
            right: 0,
            bottom: 0,
            top: "50%",
            maxWidth: "100%",
            transform: "translate(-50%,-50%)",
        }
    },
    listItem: {
        display: 'block',
        padding: 15
    },
    reloadIcon: {
        color: theme.props.primaryColor,
        fontSize: '1.8rem',
    },
    listItemIcon: {
        justifyContent: 'flex-end'
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
        }
    },
    iconDisplay: {
        display: "flex"
    },
}));

function GoogleList(props) {
    const [user] = useGlobal('user');
    const [googleStatus] = useGlobal('googleStatus')
    const {ggAllProject} = getGlobal();
    const [listGg, setListGg] = useState([])
    const [, setOpenSetting] = useGlobal('openSetting')
    const [openLogOut, setOpenLogOut] = useState(false)
    const [loading, setLoading] = useState(false)
    const [groups,] = useGlobal('groups')
    const [listGe, setListGe] = useState([])
    const [arrListUpdate, setArrListUpdate] = useState([])
    const [logout, select, ok] = useIntlId(['settings.logout', "settings.googleCalendarSelect", 'settings.ok'
    ])

    const classes = useStyles()

    const onSave = async () => {
        setLoading(true)
        await Promise.all(arrListUpdate.map(async pro => {
            if (pro.isListGg) {
                await updateUsersProjects(pro.id, user.user_id, pro.apSelected)
            } else {
                await updateProjectSelected(pro.id, user.user_id, pro.apSelected)
            }
        }))
        await setOpenSetting(false)
        await setLoading(false)
    }

    // useEffect(() => {
    //     if (!googleStatus.is_login || ggAllProject.length === 0)
    //         return
    //     let nextArchive = []
    //     projects.forEach((pro) => {
    //         if (pro.is_sync && pro.sync_id && pro.sync_email === googleStatus?.userProfile?.email) {
    //             let idx = findIndex(ggAllProject, {id: pro.sync_id})
    //             if (idx === -1) {
    //                 nextArchive.push(pro)
    //             }
    //         }
    //     })
    //     setArchive(nextArchive)
    //     // eslint-disable-next-line
    // }, [projects, ggAllProject])

    // const onUnArchive = async () => {
    //
    //     if (!unArchive || !unArchive.sync_id) {
    //         toast.error('error load calendar try late')
    //         return
    //     }
    //     try {
    //         const project = {...unArchive}
    //         setLoading(true)
    //         await ggTokenChecked();
    //         const request = window.gapi.client.calendar.calendarList.insert({
    //             "id": project.sync_id
    //         })
    //         await request.then()
    //
    //         await GCalendarList()
    //         remove(archive, s => s.sync_id === project.sync_id)
    //         setArchive([...archive])
    //         toast.success("saved")
    //     } catch (e) {
    //         console.log(e)
    //         toast.error(e.toString())
    //     } finally {
    //         setUnArchive(null)
    //         setLoading(false)
    //     }
    // }
    //
    // const onDelete = async () => {
    //     if (!deleting || !deleting.sync_id || !deleting.uuid)
    //         return
    //     try {
    //         const pro = {...deleting}
    //         setLoading(true)
    //         // Is_Delete firestore true
    //         const idx = projects.findIndex((p) => p.uuid === pro.uuid);
    //         await db.collection(CALENDAR_COLLECTION)
    //             .doc(user.user_id)
    //             .collection("projects")
    //             .doc(projects[idx].uuid)
    //             .set({
    //                 is_deleted: true
    //             }, {merge: true})
    //
    //         projects.splice(idx, 1);
    //         setProjects([...projects])
    //         toast.success("Delete successful")
    //     } catch (e) {
    //         toast.error("Delete fail !!!")
    //     } finally {
    //         setDeleting(null)
    //         setLoading(false)
    //     }
    // }

    const SortGoogleCalendar = () => {
        let sortProjects;
        sortProjects = orderBy(listGg, g => !g?.apSelected);
        return sortProjects
    };

    const SortProjectCalendar = () => {
        let sortProjects;
        sortProjects = orderBy(listGe, p => !p?.apSelected);
        return sortProjects
    };
    const Toggle = (source) => {
        let sourceIndex = cloneDeep(source)
        let index = findIndex(listGe, p => p.id === sourceIndex.id)
        if (index !== -1) {
            let nextValue = !Boolean(sourceIndex.apSelected)
            sourceIndex.apSelected = nextValue
            let _listGe = cloneDeep(listGe)
            _listGe[index] = sourceIndex
            setListGe(_listGe)
            listArrUpdate(sourceIndex.id, index, nextValue)
        }
    };

    const toggleGgCalendar = (id) => {
        let _listGg = listGg
        let index = findIndex(_listGg, {'id': id})
        if (index !== -1) {
            let nextValue = !_listGg[index].apSelected
            _listGg[index].apSelected = nextValue
            setListGg([..._listGg])
            listArrUpdate(id, index, nextValue, 'gg')
        }
    }

    const listArrUpdate = (id, index, nextValue, list = false) => {
        let ind = findIndex(arrListUpdate, g => g.id === id);
        if (ind > -1) {
            arrListUpdate.splice(ind, 1)
        } else {
            arrListUpdate.push({
                id: list ? listGg[index].id : listGe[index].id,
                apSelected: nextValue,
                isListGg: list

            })
        }
        setArrListUpdate(arrListUpdate)
    }

    useEffect(() => {
        let _ggAllProject = cloneDeep([...ggAllProject])
        setListGg(_ggAllProject)
        setListGe(groups)
    }, [])

    return (
        <div className={classes.root}>
            <MenuItemContainer width={555} height={39} justifyContent={'space-between'} alignItems={'center'}
                               marginBottom={20} margin={'auto'}>
                <div className={classes.email}>E-Mail</div>
                <div className={`clf-flex-center ${classes.userEmail}`}>{googleStatus.userProfile.email}</div>
                <CLFButtonSVG
                    onClick={() => setOpenLogOut(true)}
                    name={logout}
                    size={'default'}
                    className={classes.primary}
                />
                {
                    openLogOut &&
                    <LogoutDialog open={openLogOut} setOpen={setOpenLogOut} loading={false}/>
                }
            </MenuItemContainer>

            <Grid container className={classes.gridRoot}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        {select}
                    </Typography>
                    <div className={`${classes.gridItem}`}>
                        <List className={classes.listItem}>
                            {
                                SortProjectCalendar().map((source, index) => {
                                    if (!source?.id)
                                        return null;
                                    if (!source.is_sync)
                                        return (
                                            <ItemCheckedCustom
                                                id={index}
                                                key={`${index}`}
                                                onClick={() => Toggle(source)}
                                                name={source.name}
                                                color={source.color}
                                                checked={source?.apSelected}
                                                isRevert={true}
                                                hiddenAvatar={true}
                                                isBold={true}
                                                isTextColor={true}
                                                height={'30px'}
                                                margin={'unset'}
                                                maxWidth={'100%'}
                                                className={"ItemCheckedCustom"}
                                            />
                                        )
                                    return null
                                })}
                            {
                                SortGoogleCalendar().map((calendar, index) => {
                                    return (
                                        <ItemCheckedCustom
                                            id={index}
                                            key={`${index}`}
                                            onClick={() => toggleGgCalendar(calendar.id)}
                                            name={calendar.summary}
                                            color={calendar.backgroundColor}
                                            checked={calendar?.apSelected}
                                            isRevert={true}
                                            hiddenAvatar={true}
                                            isBold={true}
                                            isTextColor={true}
                                            height={'30px'}
                                            margin={'unset'}
                                            maxWidth={'100%'}
                                            className={"ItemCheckedCustom"}
                                        />
                                    )
                                })}
                            <ListItem className={`clf-flex-center`}>
                                <CLFButtonSVG
                                    loading={loading}
                                    onClick={onSave}
                                    iconComponent={<SaveSvg/>}
                                    name={ok}
                                    size={'default'}
                                    className={classes.primary}
                                />
                            </ListItem>
                        </List>
                    </div>
                </Grid>
                {/*<Grid item xs={6}>*/}
                {/*    <Typography className={classes.title}>*/}
                {/*        {listSync}*/}
                {/*    </Typography>*/}
                {/*    <div className={`${classes.gridItem}`}>*/}
                {/*        <List className={classes.listItem}>*/}
                {/*            {projects.map((p, index) => {*/}
                {/*                if (p?.is_sync) {*/}
                {/*                    let idx = findIndex(ggAllProject, {id: p.sync_id})*/}
                {/*                    if (idx === -1) return null*/}
                {/*                    return (*/}
                {/*                        <ListItem key={index} style={{padding: 0}}>*/}
                {/*                            <ListItemText className={classes.titleItem} style={{color: `${p.color}`}}*/}
                {/*                                          primary={p.name}/>*/}
                {/*                            <ListItemIcon className={classes.listItemIcon}>*/}
                {/*                                <CheckCircleFilled*/}
                {/*                                    style={{color: `${p.color}`, padding: 0, fontSize: '1.8rem'}}/>*/}
                {/*                            </ListItemIcon>*/}
                {/*                        </ListItem>*/}
                {/*                    )*/}
                {/*                }*/}
                {/*                return (*/}
                {/*                    <ListItem key={index} style={{padding: 0}}>*/}
                {/*                        <ListItemText className={classes.titleItem} style={{color: `${p.color}`}}*/}
                {/*                                      primary={p.name}/>*/}
                {/*                        {*/}
                {/*                            inSync && p.uuid === sync_uuid &&*/}
                {/*                            <ListItemText secondary={syncProcess}/>*/}
                {/*                        }*/}
                {/*                        <ListItemIcon className={classes.listItemIcon}>*/}
                {/*                            {!p?.isDefault && <IconButton*/}
                {/*                                disabled={inSync}*/}
                {/*                                onClick={async () => {*/}
                {/*                                    setSync_uuid(p.uuid)*/}
                {/*                                    Sync(p.uuid)*/}
                {/*                                }}*/}
                {/*                                style={{padding: 0}}*/}

                {/*                            >*/}
                {/*                                {*/}
                {/*                                    inSync && p.uuid === sync_uuid ?*/}
                {/*                                        <CircularProgress*/}
                {/*                                            color={"primary"}*/}
                {/*                                            size={30}*/}
                {/*                                        /> :*/}
                {/*                                        <Cached className={classes.reloadIcon}/>*/}
                {/*                                }*/}

                {/*                            </IconButton>}*/}
                {/*                        </ListItemIcon>*/}
                {/*                    </ListItem>*/}
                {/*                )*/}
                {/*            })}*/}
                {/*        </List>*/}
                {/*        <Typography className={classes.title}>*/}
                {/*            {archiveProject}*/}
                {/*        </Typography>*/}
                {/*        <List className={classes.list}>*/}
                {/*            {*/}
                {/*                archive.map(p => (*/}
                {/*                    <ListItem key={p.sync_id} style={{padding: '5px 15px'}}>*/}
                {/*                        <ListItemText style={{color: `${p.color}`}} primary={p.name}/>*/}
                {/*                        <ListItemIcon>*/}
                {/*                            <IconButton*/}
                {/*                                onClick={() => setUnArchive(p)}*/}
                {/*                                disabled={loading}*/}

                {/*                            >*/}
                {/*                                <Unarchive/>*/}
                {/*                            </IconButton>*/}
                {/*                            <IconButton*/}
                {/*                                disabled={loading}*/}
                {/*                                onClick={() => setDeleting(p)}*/}

                {/*                            >*/}
                {/*                                <Delete/>*/}
                {/*                            </IconButton>*/}
                {/*                        </ListItemIcon>*/}
                {/*                    </ListItem>*/}
                {/*                ))*/}
                {/*            }*/}
                {/*            {*/}
                {/*                Boolean(unArchive) &&*/}
                {/*                <ArchiveDialog unArchive={unArchive} setUnArchive={setUnArchive} onDone={onUnArchive}*/}
                {/*                               loading={loading}/>*/}
                {/*            }*/}
                {/*            {*/}
                {/*                Boolean(deleting) &&*/}
                {/*                <DeleteProjectDialog project={deleting} setProject={setDeleting} onDone={onDelete}*/}
                {/*                                     loading={loading}/>*/}
                {/*            }*/}
                {/*        </List>*/}

                {/*    </div>*/}
                {/*</Grid>*/}
            </Grid>
        </div>
    );
}

//
// function ArchiveDialog({unArchive, setUnArchive, onDone, loading}) {
//     const [archiveDialogDeleteArchive, archiveDialogCancel, archiveDialogOk, archiveDialogTitle] = useIntlId(['settings.archiveDialogDeleteArchive', 'settings.archiveDialogCancel', 'settings.archiveDialogOk', 'settings.archiveDialogTitle'])
//     const handleClose = () => {
//         setUnArchive(null)
//     }
//     const onOk = () => {
//         onDone()
//     }
//     return (
//         <Modal
//             visible={Boolean(unArchive)}
//             title={archiveDialogDeleteArchive}
//             onCancel={handleClose}
//             zIndex={9000}
//             footer={[
//                 <ButtonAntdComponent
//                     onClick={handleClose}
//                     disable={loading}
//                     name={archiveDialogCancel}
//                     typeClass={"secondary"}
//                     key={'back'}
//                 />
//                 ,
//                 <ButtonAntdComponent
//                     name={archiveDialogOk}
//                     key={"submit"}
//                     loading={loading}
//                     onClick={onOk}
//                     disable={loading}
//                     typeClass={"primary"}
//                 />,
//             ]}
//         >
//             {archiveDialogTitle}
//         </Modal>
//     )
// }
//
// function DeleteProjectDialog({project, setProject, onDone, loading}) {
//     const [archiveDialogDeleteProject, archiveDialogCancel, archiveDialogOk, archiveDialogDelete] = useIntlId(['settings.archiveDialogDeleteProject', 'settings.archiveDialogCancel', 'settings.archiveDialogOk', 'settings.archiveDialogDelete'])
//     const handleClose = () => {
//         setProject(null)
//     }
//     const onOk = () => {
//         onDone()
//     }
//     return (
//         <Modal
//             visible={Boolean(project)}
//             title={archiveDialogDeleteProject}
//             onCancel={handleClose}
//             zIndex={9000}
//             footer={[
//                 <ButtonAntdComponent
//                     onClick={handleClose}
//                     disable={loading}
//                     name={archiveDialogCancel}
//                     typeClass={"secondary"}
//                 />
//                 ,
//                 <ButtonAntdComponent
//                     name={archiveDialogOk}
//                     key={"submit"}
//                     loading={loading}
//                     onClick={onOk}
//                     disable={loading}
//                     typeClass={"primary"}
//                 />,
//             ]}
//         >
//             {archiveDialogDelete}
//         </Modal>
//     )
// }

function LogoutDialog({open, setOpen, loading}) {
    const [googleStatus] = useGlobal('googleStatus')
    const [logoutGoogle, question, text] = useIntlId(['settingGoogleSync.title', 'settingGoogleSync.question', 'settingGoogleSync.text'])

    const handleClose = () => {
        setOpen(false)
    }
    const onOk = () => {
        onLogOut()
        handleClose()
    }
    return (
        <Modal
            visible={Boolean(open)}
            title={logoutGoogle}
            onCancel={handleClose}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CancelButton
                    onClick={handleClose}
                    disable={loading}
                />
                <OkButton
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                />
            </div>}
        >
            {question}
            <Typography color={"secondary"}>
                ( {text} {googleStatus?.userProfile?.email || ''})
            </Typography>
        </Modal>
    )
}


export default withRouter(GoogleList)
