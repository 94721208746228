import React, {createContext, useEffect} from 'react'
import {getGlobal, setGlobal, useGlobal} from 'reactn'
import {auth} from "../firebaseConfig";
import {omitByDeep} from "../common/exLodash";
import {findIndex, isEmpty, isFunction, isPlainObject, isUndefined, orderBy} from 'lodash'
import {COOKIE_GENIAM_REFRESH_TOKEN_KEY, setCookie} from "../common/cookies";
import qs from "query-string";
import {
    getUserData,
    signInFirebase,
    useMyCalSettings,
    useMyFreeTimeData,
    useMyGEProjects,
    useMyGGProjects,
    useMyThemeList,
} from "../actions/user";
import {useHistory} from 'react-router-dom'
import {scheduleAdjustmentRef, settingSaveCollRef} from "../common/firebaseRef/meetings";
import {useZoomData} from "../actions/zoom";
import store from "store";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {myCal} from "../common/myCal";
import {mergeGlobalUser} from "../setGlobal";
import {msgError, msgSuccess} from "../utils/msg";
import {getIntlId} from "../hooks/useIntlId";
import {getGlobalUrls} from '../common/firebaseRef/globalnavi';
import {fetchGlobalApps} from '../common/fetchGlobalApps';
import {APP_GLOBAL_ID} from "../config/constants";
import {removeStorageGoogleUser} from "../common/googleCal/logOutGoogle";


export const CalendarUserContext = createContext({})

export const CalendarUserProvider = ({children}) => {
    const calendarUser = useCalendarUser()
    const [user] = useGlobal('user');
    const query = qs.parse(window.location.search);
    const path = window.location.pathname;
    const history = useHistory();
    /*const [, setList] = useGlobal('navigationsApps')
    const [, setAddLinkApp] = useGlobal('addLinkApp')*/
    const [globalApps] = useGlobal('globalApps')
    /*useEffect(() => {
        if (!user?.user_id)
            return null
        const sub = getNaviCollRef(user.user_id)
            .onSnapshot(snapshot => {
                if (!snapshot.exists)
                    return;
                const list = snapshot.data()
                setList(list.data || [])
            })
        const sub1 = getURLCollRef(user.user_id)
            .orderBy('createAt', 'asc')
            .onSnapshot(snapshot => {
                let allUrl = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                setAddLinkApp(allUrl)
            })
        return () => {
            if (sub)
                sub()
            if (sub1)
                sub1()
        }
    }, [user?.user_id])*/

    useEffect(() => {
        if (!user?.user_id || !globalApps?.length)
            return null
        const urlSub = getGlobalUrls(user.user_id)
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                const userGeniamApps = globalApps.map(item => {
                    let idx = findIndex(data, {id: item.id})
                    if (idx === -1) {
                        return {
                            ...item,
                            global: true,
                            shortLink: {[APP_GLOBAL_ID]: false},
                            geniamApp: true,
                            favorite: {[APP_GLOBAL_ID]: false}
                        };

                    }
                    return {
                        ...item, ...data[idx],
                        geniamApp: true,
                        global: !isUndefined(data[idx].global) ? data[idx].global
                            : (isUndefined(data[idx].checked) ? true : data[idx].checked),

                        shortLink: !isUndefined(data[idx].shortLink) ? data[idx].shortLink
                            : {[APP_GLOBAL_ID]: Boolean(data[idx][APP_GLOBAL_ID])}
                        ,

                        favorite: !isUndefined(data[idx].favorite) ? data[idx].favorite
                            : {[APP_GLOBAL_ID]: Boolean(data[idx].show)}
                    }
                })
                let orderGeniamApps = orderBy(userGeniamApps, "index", "asc")

                const userLinks = orderBy(data.filter(item => !item.geniamApp))
                let orderUserLinks = orderBy(userLinks, "index", "asc")
                setGlobal({
                    userGeniamApps: orderGeniamApps,
                    userLinks: orderUserLinks
                })
            })
        return () => {
            if (urlSub)
                urlSub()
        }
    }, [user?.user_id, globalApps])

    useEffect(() => {
        fetchGlobalApps()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const {token, refreshToken} = query;

        if (token || refreshToken) {
            const opts = {expires: 1826};
            if (process.env.NODE_ENV === 'production') opts.domain = '.geniam.com'
            // localStorage.setItem('accessToken', token)
            setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, refreshToken, opts);
            setGlobal({
                accessToken: token,
                refreshToken
            }, () => {
                getUserData().then(data => {
                        if (!data)
                            setGlobal({langDefault: "ja-jp"})
                    }
                ).catch(e => {
                    console.log(e);
                });
            });
            // localStorage.setItem('refreshToken', refreshToken)

            delete query.token;
            delete query.refreshToken;
            history.push(`${path}?${qs.stringify(query)}`)
        } else {
            getUserData().then(data => {
                    if (!data)
                        setGlobal({langDefault: "ja-jp"})
                }
            ).catch(e => {
                console.log(e);
            });
        }

        auth.onAuthStateChanged(user => {
            if (!user) {
                signInFirebase()
            }
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!user?.user_id)
            return
        const sub = settingSaveCollRef(user.user_id)
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                let arr = []
                data.forEach(item => {
                    if (!item?.isDelete) {
                        arr.push(item)
                    }
                })
                setGlobal({listCustomEvent: arr})

            })
        const sub2 = scheduleAdjustmentRef(user.user_id)
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                let arr = []
                data.forEach(item => {
                    if (item?.nameSetting && item?.nameSetting?.replace(/\s+/g, '') !== "" && !item?.isDelete) {
                        arr.push(item)
                    }
                })
                setGlobal({listSettingShareUrl: arr})

            })
        return () => {
            if (sub)
                sub()
            if (sub2)
                sub2()
        }
        // eslint-disable-next-line
    }, [user?.user_id])

    return (
        <CalendarUserContext.Provider value={calendarUser}>
            {children}
        </CalendarUserContext.Provider>
    )
}

export const useCalendarUser = () => {
    const [calendarUser, setCalendarUser] = useGlobal('calendarUser')
    const [user] = useGlobal('user')
    const [colors, setColors] = useGlobal('colors')
    const [googleStatus, setGGStatus] = useGlobal('googleStatus');
    const [calUserData] = useDocumentData(myCal())
    useMyThemeList()
    useZoomData()
    useMyFreeTimeData()
    useMyGEProjects()
    useMyGGProjects()
    // useMyUserData()
    const [displaySettings] = useMyCalSettings()


    useEffect(() => {
        if (isEmpty(calUserData))
            return;
        const data = calUserData
        setCalendarUser(data)
        mergeGlobalUser(data)

        setColors(data?.colors || colors)
        store.set('colors', data?.colors || colors)
        // check user is sync with google @PhiNV Do you need?
        const {googleStatus} = getGlobal()
        if (data.googleAuth) {
            if ((!googleStatus.is_login || googleStatus.userProfile?.email !== data.googleAuth.email)) {
                googleStatus.is_login = true
                googleStatus.userProfile = data.googleAuth
                setGGStatus({...googleStatus}, () => {
                    // GCalendarList()
                })
            }
        } else {
            setGlobal({
                googleStatus: {
                    is_login: false,
                    userProfile: {},
                    initialed: true
                },
                googleCalendarList: [],
                ggEventLoaded: true
            },)
            removeStorageGoogleUser()
        }
        // eslint-disable-next-line
    }, [calUserData, googleStatus.initialed])


    const mergeCalendarUser = (data, {onOk, onFailed}) => {
        if (!user || !user.user_id) return;
        if (!isPlainObject(data)) return;
        data = omitByDeep(data, isUndefined);

        myCal()
            .set(data, {merge: true})
            .then((val) => {
                msgSuccess(getIntlId('common.saved'))
                if (isFunction(onOk)) onOk(val)
            })
            .catch(e => {
                msgError(e.message)
                if (isFunction(onFailed)) onFailed(e)
            })
    }

    return {calendarUser, mergeCalendarUser, displaySettings}
}
