import React, {useState} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    makeStyles
} from '@material-ui/core';
import {useGlobal} from 'reactn'
import {APP_GLOBAL_ID} from '../../../config/constants';
import {getNaviCollRef} from '../../../common/firebaseRef/globalnavi';
import {msgError, msgSuccess} from '../../../utils/msg';
import {uniqBy} from 'lodash'

function AddAppDialog({open, setOpen}) {
    const classes = useStyles()
    const [globalApps] = useGlobal('globalApps')
    const [list] = useGlobal('navigationsApps')
    const [user] = useGlobal('user')

    const [selected, setSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const toggleApp = id => {
        let idx = selected.indexOf(id)
        if (idx !== -1) {
            selected.splice(idx, 1)
        } else {
            selected.push(id)
        }
        setSelected([...selected])
    }
    const onAdd = async () => {
        if (!selected?.length)
            return;
        try {
            setLoading(true)
            const newApps = globalApps.filter(app => selected.includes(app.id))
            const ref = getNaviCollRef(user.user_id)
            await ref.set({
                data: uniqBy([...list, ...newApps], 'id')
            }, {merge: true})
            msgSuccess('successfully')
            setOpen(false)
        } catch (e) {
            console.log(e);
            msgError(e.toString())
        } finally {
            setLoading(false)
        }
    }
    if (!open)
        return null
    return (
        <div>
            <Dialog open={open}
                    onClose={() => setOpen(false)}
                    maxWidth={"xs"}
                    fullWidth={true}
            >
                <DialogTitle>
                    カレンビューにアプリリンクを追加
                </DialogTitle>
                <DialogContent>
                    <div>
                        {
                            globalApps.map((item) => {
                                // this app
                                if (item.id === APP_GLOBAL_ID)
                                    return null
                                // added app
                                if (list.find(s => s.id === item.id))
                                    return null
                                return (
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selected.includes(item.id)}
                                                    onClick={() => toggleApp(item.id)}
                                                />
                                            } label={item.name}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpen(false)}
                        disabled={loading}
                    >Cancel</Button>
                    <Button
                        disabled={loading || !selected.length}
                        onClick={onAdd}
                    >Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {}
}))

export default AddAppDialog;
