import React, {useEffect, useState} from 'react';
import MaterialTable from "material-table";
import {createMuiTheme, createStyles, MuiThemeProvider, withStyles} from "@material-ui/core";


function TableAppDetails(props) {
    const {cartPackage, classes} = props
    const [data, setData] = useState([])
    const theme = createMuiTheme({
        overrides: {
            MuiToolbar: {
                regular: {
                    width: "100%",
                    minHeight: "0px",
                    '@media (min-width: 600px)': {
                        minHeight: "0px"
                    }
                }
            },
        }
    })

    useEffect(
        () => {
            if (!cartPackage.appList) return null
            try {
                let data = cartPackage.appList.map(pro => {
                    let datas = {}
                    datas.service_id = pro.service_id
                    datas.service_name = pro.service_name
                    datas.home_url = pro.home_url
                    return datas
                })
                setData(data)
            } catch (e) {
                console.log(e)
            }
        },
        [cartPackage.appList]
    )

    let columns = [
        {
            title: 'APP NAME', field: 'service_name', type: 'text',
        },
    ]

    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    options={{
                        paging: false,
                        search: false,
                        showTitle: false
                    }}
                    columns={columns}
                    data={data}
                />
            </MuiThemeProvider>
        </div>
    );
}

const styles = (theme) => createStyles({
    root: {
        width: "100%",
        pading: "5px",
        margin: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.07)",
        marginTop: 0,
        marginBottom: 0,
        // textAlign:"center"
    },
});
export default withStyles(styles)(TableAppDetails);
