import React from 'react';
import {Modal} from 'antd';
import useIntlId from '../../../hooks/useIntlId';
import CancelButton from '../../Dialog/CancelButton';
import DeleteButton from '../../Dialog/DeleteButton';


function DialogDeleteZoomMeeting(props) {
    const {open, setOpen, onDelete, loading} = props;
    const [delConfirmIntl] = useIntlId(['milestone.isDelete'])

    const onClose = () => {
        setOpen(null)
    };

    const onDone = () => {
        onClose();
        if (onDelete) {
            onDelete()
            // msgSuccess(`Deleted`)
        }
    };

    return (
        <Modal
            visible={Boolean(open)}
            title={open?.topic}
            onCancel={onClose}
            zIndex={9000}
            footer={[
                <div className={'clf-flex-center'}>
                    <CancelButton
                        onClick={onClose}
                    />
                    <DeleteButton
                        onClick={onDone}
                        loading={loading}
                        disable={loading}
                    />
                </div>
            ]}
        >
            {delConfirmIntl}
        </Modal>
        // <div>
        //     <Dialog
        //         open={Boolean(open)}
        //         onClose={onClose}
        //     >
        //         <DialogContent>
        //             <DialogContentText id="alert-dialog-description">
        //                 Do you want to remove it?
        //             </DialogContentText>
        //         </DialogContent>
        //         <DialogActions>
        //             <Button onClick={onClose} color="primary">
        //                 Cancel
        //             </Button>
        //             {
        //                 loading ? <CircularProgress color={"primary"} size={25}/>
        //                     :
        //                     <Button onClick={onDone} color="primary">
        //                         Delete
        //                     </Button>
        //             }
        //         </DialogActions>
        //     </Dialog>
        // </div>
    );
}

export default DialogDeleteZoomMeeting;
