import React, {memo, Suspense, useEffect} from 'react';
import './App.css';
import {onRestore,} from "./actions/calendar";
import {getGlobal, setGlobal, useGlobal} from 'reactn'
import {Route, Switch} from 'react-router-dom'
import {Meeting, MeetingUnAuthentication, ShareUrl} from './components/splitting'
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import ExpDateCheckDialog from "./components/PackageDialog/ExpDateCheckDialog";
import {getCalendarInfo} from "./common/calendarInfo";
import {getVoteBoxList} from "./components/Calendar/actions/syncEventMeeting";
import usePushNotifications from "./hooks/usePushNotifications";
import {MessageContextProvider} from "./context/MessagesContext";
import {swapProjectByOrder} from "./components/GroupCalendar/common";
import ShareFreeTimeUrl from "./components/Meeting/ShareFreeTimeUrl";
import {concat, filter, isEmpty, isString, remove} from 'lodash'
import ClfLinearProgress from "./components/Custom/CLFLinearProgress";
import LinkMeetingError from "./components/LinkMeetingError/LinkMeetingError";
import NotFound from "./components/NotFound/NotFound";
import {MyFreeTimeContextProvider} from "./context/MyFreeTimeContext";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import store from 'store'
import {EventAdjustmentProvider} from "./context/AdjustmentEventsContext";
import {AppointmentEventProvider} from "./context/AppointmentEventContext";
import {ggTokenChecked} from "./components/Calendar/google/auth";
import {IS_PRODUCTION} from "./config/constants";
import {GCalendarList} from "./actions/googleCalendar";

function App() {
    const [user] = useGlobal('user');
    const [openEditingEvent] = useGlobal('openEditingEvent');
    const [openNewEvent] = useGlobal('openNewEvent');
    const [isRestored] = useKeyboardJs('ctrl + z');
    const [groups, setGroups] = useGlobal('groups')
    const [projects] = useGlobal('projects');
    const [allProject] = useGlobal('allProject')
    const [calendarUser] = useGlobal("calendarUser");
    const [projectsSync] = useGlobal("projectsSync");
    const [, setOpenChat] = useGlobal("openChat")

    usePushNotifications(user);

    useEffect(() => {
        store.set("editFreeBox", [])
        document.title = IS_PRODUCTION ? 'Appy by Geniam' : `[${process.env.REACT_APP_ENV}] Appy by Geniam`;
    }, [])

    useEffect(() => {
        const checkFocusMessage = window.location.search
        if (checkFocusMessage.includes('is_Open_Message'))
            setOpenChat(true)

        // eslint-disable-next-line
    }, [])

    function handleMousemove (evt) {
        if (window.location.pathname === '/') return null
        const {hoverPos} = getGlobal()
        if (!evt?.target || !isString(evt.target?.className))
            return null

        if (!isEmpty(hoverPos) && (evt.target.className?.includes('fc-timegrid-slot') ||
            evt.target.className?.includes('meeting-app') ||
            evt.target.className?.includes('fc-scrollgrid-sync-inner') ||
            evt.target.className?.includes('shareTime') ||
            evt.target.className?.includes('event-hover') ||
            !evt.target.className
        )) {
            return setGlobal({
                hoverPos: null
            })
        }

        if (evt.target.className?.includes('fc-event-title') || evt.target.className?.includes('fc-event-title-container') || evt.target.id === 'hover-create-event') {
            return null
        }

        if (!isEmpty(hoverPos) && evt.target.id === 'hover-create-event' && !evt.target.className?.includes('fc-event-title-container')) {
            return setGlobal({
                hoverPos: null
            })
        }
    }

    function handleScroll(e) {
        const {hoverPos} = getGlobal()
        if (hoverPos) {
            setGlobal({
                hoverPos: null
            })
        }
    }

    useEffect(() => {
        document.body.addEventListener('mousemove', handleMousemove, false)
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.body.removeEventListener("mousemove", handleMousemove, false)
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    useEffect(() => {
        if (isRestored && !openNewEvent && !openEditingEvent.open)
            onRestore()
        // eslint-disable-next-line
    }, [isRestored])

    useEffect(() => {
        let votesub
        if (user.user_id) {
            getCalendarInfo(user.user_id)
            // getIdentity()
            votesub = getVoteBoxList(user.user_id)
        }

        return () => {
            if (votesub && typeof votesub === "function") {
                votesub()
            }
        }
        // eslint-disable-next-line
    }, [user])

    useEffect(() => {
        let group = [];
        projects.forEach(project => {
            if (!project.is_sync) {
                group.push({...project})
            }
        })
        setGroups([...group])
        // eslint-disable-next-line
    }, [projects])

    useEffect(() => {
        // async function getGoogleApi() {
        //     try {
        //         setGlobal({
        //             googleListing: true
        //         })
        //         if (!window?.gapi?.client?.calendar?.calendarList)
        //             return
        //         await ggTokenChecked();
        //         const request = window.gapi.client.calendar.calendarList.list()
        //         const {status, result} = await request.then()
        //         if (status !== 200)
        //             return
        //         let groups = result.items
        //         remove(groups, g => {
        //             return g.id.includes('#contacts@group.v.calendar.google.com')
        //         })
        //         setGoogleCalendarApi(groups)
        //     } catch (e) {
        //         // console.log(e);
        //     } finally {
        //         setGlobal({
        //             googleListing: false
        //         })
        //     }
        // }
        // getGoogleApi()
        GCalendarList()

        // eslint-disable-next-line
    }, [user, allProject])

    //
    // const getSelected = (id) => {
    //     let index = findIndex(projectsSync, {projectUuid: id})
    //     return projectsSync[index]?.selected || false
    // }

    useEffect(() => {
        const googleSettings = calendarUser?.googleSettings || {};
        const googleList = calendarUser?.googleList || [];
        const googleAllList = filter(projectsSync, g => !g.id.includes("#holiday")).map(gg => {
            const linkInfo = googleSettings[gg.id] || {};
            return {
                ...gg, ...linkInfo,
                is_google: true,
                uuid: gg.id,
                color: gg.backgroundColor,
                name: gg.summary,
                apSelected: gg.apSelected
            }
        })
        const nextAllList = swapProjectByOrder(concat(groups, googleAllList), googleList);
        const nextAllListgg = swapProjectByOrder(googleAllList, googleList);
        setGlobal({
            allProject: nextAllList,
            ggAllProject: nextAllListgg
        })
        // eslint-disable-next-line
    }, [groups, calendarUser, projectsSync])

    return (
        <Suspense fallback={<SuspenseLoader/>}>
            <div className="App">
                <Switch>
                    <AppointmentEventProvider>
                        <MessageContextProvider>
                            <Route exact path={'/'} component={Meeting}/>
                            <Route path={'/event'} component={Meeting}/>
                        </MessageContextProvider>
                        <Route path={'/eventAnonymous'} component={MeetingUnAuthentication}/>
                        <MyFreeTimeContextProvider>
                            <Route path={'/shareUrl'} component={ShareUrl}/>
                            <EventAdjustmentProvider>
                                <Route path={'/scheduleAdjustment'} component={ShareFreeTimeUrl}/>
                            </EventAdjustmentProvider>
                        </MyFreeTimeContextProvider>
                    </AppointmentEventProvider>
                    <Route path={'/notFound'} component={NotFound}/>
                    <Route path={'/eventNotFound'} component={LinkMeetingError}/>
                </Switch>
                <ExpDateCheckDialog/>
            </div>
        </Suspense>
    )
}

export default App

const SuspenseLoader = memo(() => {
    return (<ClfLinearProgress/>)
})
