import React, {useEffect, useState} from 'react';
import MaterialTable from "material-table";
import {useGlobal} from 'reactn'
import {findIndex} from "lodash";


import {createMuiTheme, createStyles, MuiThemeProvider, Typography, withStyles} from "@material-ui/core";

function TableServiceDetails(props) {
    const {cartPackage, classes} = props
    const [data, setData] = useState([])
    const [servicesList] = useGlobal('servicesList')

    const theme = createMuiTheme({
        overrides: {
            MuiToolbar: {
                regular: {
                    width: "100%",
                    minHeight: "0px",
                    '@media (min-width: 600px)': {
                        minHeight: "0px"
                    }
                }
            },
        }
    })
    useEffect(() => {
        if (!cartPackage.addedServiceList) return null
        try {
            let data = cartPackage.addedServiceList.map(pro => {
                let datas = {}
                datas.serviceId = pro.serviceId
                datas.planType = pro.planType
                datas.ticketable = pro.ticketable
                datas.numOfTicket = pro.numOfTicket
                datas.ticketExpDate = pro.ticketExpDate
                return datas
            })
            setData(data)
        } catch (e) {
            console.log(e)
        }
    }, [cartPackage.addedServiceList])

    let columns = [
        {
            title: 'Service Name', field: 'serviceId', type: 'numeric',
            render: (row) => {
                let idx = findIndex(servicesList, {'service_id': row.serviceId})
                if (idx !== -1) {
                    return (
                        <Typography>{servicesList[idx].service_name}</Typography>
                    )
                }
            }
        },
        {
            title: 'Plan Type', field: 'planType', type: 'text',
            render: (row) => {
                return (
                    row.planType === 'byMonth' ?
                        <Typography> 月々払い </Typography>
                        :
                        <Typography> 1年一括 </Typography>
                )
            }
        },
        // {
        //   title: 'Times Use Service', field: 'planTimes', type: 'text',
        //   render: (row) => {
        //     return (
        //       <Typography>{row.planTimes} times</Typography>
        //     )
        //   }
        // },
        // {
        //   title: 'Metamor Ranks Used', field: 'ranks', type: 'text',
        //   render: (row) => {
        //     return (
        //       <Typography> {row.ranks.join(' , ')} </Typography>
        //     )
        //   }
        // },
        {
            title: 'Ticket can be used', field: 'numOfTicket', type: 'numeric',
            render: (row) => {
                return (
                    row.ticketable === 1 ?
                        <Typography>{row.numOfTicket} チケット</Typography>
                        :
                        <Typography> - </Typography>
                )
            }
        },
        {
            title: 'Ticket ExpDate', field: 'ticketExpDate', type: 'text',
            render: (row) => {
                return (
                    <Typography>first of month</Typography>
                )
            }
        },
    ]

    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    options={{
                        paging: false,
                        search: false,
                        showTitle: false
                    }}
                    columns={columns}
                    data={data}
                />
            </MuiThemeProvider>
        </div>
    );
}

const styles = (theme) => createStyles({
    root: {
        width: "100%",
        pading: "5px",
        margin: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.07)",
        marginTop: 0,
        marginBottom: 0,
        // textAlign:"center"
    },
});
export default withStyles(styles)(TableServiceDetails);
