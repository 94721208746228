const oldIcons = {
    "AccountCircle": "account_circle",
    "Group": "group",
    "AssignmentInd": "assignment_ind",
    "AssignmentTurnedIn": "assignment_turned_in"
}

export const getIcon = (name) => {
    return oldIcons[name] || name
}
