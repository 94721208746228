import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {useGlobal} from "reactn";
import DialogEdit from "./DialogEdit";
import TabSettingCustom from "./TabSettingCustom";
import useIntlId from "../../../hooks/useIntlId";

function MeetingSettingList(props) {
    const classes = useStyles()
    const [user] = useGlobal('user')
    const [settingSaveTab,] = useGlobal('settingSaveTab')
    const [itemCustom, setItemCustom] = useState(null)
    const [title, setTitle] = useState('')
    const [dialogEdit, setDialogEdit] = useState(false)
    const [listCustomEvent] = useGlobal('listCustomEvent')
    const [listSettingShareUrl] = useGlobal('listSettingShareUrl')
    const [settingList] = useIntlId(['event.settingList'])
    //const [groupSettings] = useIntlId(['settings.groupSettings'])
    return (
        <div className={classes.container}>

            {/*<MenuItemContainer width={'100%'} margin={'0px 0px'}>*/}
            {/*    <TabsCustom value={settingSaveTab}*/}
            {/*                onChange={(event, newValue) => setSettingSaveTab(newValue)}*/}
            {/*                className={classes.width100}*/}
            {/*    >*/}
            {/*        <Tab label={groupSettings}*/}
            {/*             className={classes.tab}*/}
            {/*        />*/}
            {/*        <Tab label={shareSettings}*/}
            {/*             className={classes.tab}*/}
            {/*        />*/}
            {/*    </TabsCustom>*/}
            {/*</MenuItemContainer>    */}

            <div className={classes.groupSettingName}>{settingList}</div>

            <TabSettingCustom
                settingList={window.location.pathname === '/shareUrl' ? listSettingShareUrl : listCustomEvent}
                setDialogEdit={setDialogEdit}
                setTitle={setTitle}
                setItemCustom={setItemCustom}
                user={user}
                settingSaveTab={settingSaveTab}
            />

            {/*<TabSettingCustom*/}
            {/*    settingList={listSettingShareUrl}*/}
            {/*    setDialogEdit={setDialogEdit}*/}
            {/*    setTitle={setTitle}*/}
            {/*    setItemCustom={setItemCustom}*/}
            {/*    user={user}*/}
            {/*    settingSaveTab={settingSaveTab}*/}
            {/*/>*/}


            <DialogEdit
                itemCustom={itemCustom}
                dialogEdit={dialogEdit}
                setDialogEdit={setDialogEdit}
                setItemCustom={setItemCustom}
                settingSaveTab={window.location.pathname === '/shareUrl' ? 1 : 0}
                title={title}
                setTitle={setTitle}
            />

        </div>
    );
}

export default MeetingSettingList;

const useStyles = makeStyles(theme => ({
    container: {
        padding: '20px 10px'
    },
    tab: {
        width: "50%",
    },
    width100: {
        width: '100%'
    },
    groupSettingName: {
        width: "100%",
        textAlign: "center",
        color: theme.props.primaryColor,
        fontWeight: "bold"
    }
}))

