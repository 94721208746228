import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Spin} from "antd";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 10,
        "& .ant-spin-dot-item": {
            backgroundColor: theme.props.primaryColor
        }
    }
}))

function SpinCustom({size = 'default'}) {
    const classes = useStyles()
    return (
        <Spin size={size} className={classes.root}/>
    );
}

export default SpinCustom;
