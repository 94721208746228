import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Col, Popconfirm, Row} from "antd";
import moment from "moment";
import {ReactComponent as EditGroupSVG} from "../../../asSvg/groupEdit.svg";
import {ReactComponent as DeleteGroupSVG} from "../../../asSvg/groupDelete.svg";
import {getMeetingDocRef, scheduleAdjustmentRef, settingSaveCollRef} from "../../../common/firebaseRef/meetings";
import {toast} from "react-toastify";
import useIntlId from "../../../hooks/useIntlId";
import qs from "query-string";
import {db} from "../../../firebaseConfig";
import {MEETING_COLLECTION} from "../../../config/constants";

function TabSettingCustom({
                              settingList = [],
                              setDialogEdit,
                              setTitle,
                              setItemCustom,
                              user,
                              settingSaveTab
                          }) {

    const classes = useStyles();
    const meetingId = qs.parse(window.location.search).id;
    const [deleteText, cancelText, titleDelete, deleteSuccessIntl] = useIntlId([
        'dialog.action.delete',
        'dialog.action.cancel',
        'dialog.action.titleDelete',
        'event.toast.deleteSuccess'
    ]);
    // const {myFreeTime} = useMyFreeTime(user)
    const onSelectItem = (item) => {
        setItemCustom(item)
        setDialogEdit(true)
        setTitle(item?.nameSetting)
    }

    const confirmDelete = async (item) => {
        try {
            if (!user?.user_id || !item?.id)
                return null

            // await settingRef.delete()
            const timeUpdated = moment().utc().format()
            if (settingSaveTab === 0) {
                const settingRef = settingSaveCollRef(user.user_id).doc(item?.id)
                await settingRef.set({isDelete: true, updatedAt: timeUpdated}, {merge: true})
                const eventUserRef = db.collection(MEETING_COLLECTION)
                    .doc(meetingId)
                    .collection('users')
                    .doc(user.user_id)
                await eventUserRef.set({
                    freeTime: 0,
                    timeRange: [0, 1],
                    typeTimeSharing: "auto",
                    idSetting: null,
                    updatedAt: timeUpdated
                }, {merge: true})
                const meetingRef = getMeetingDocRef(meetingId)
                await meetingRef.set({timeVote: 0.5}, {merge: true})
                toast.success(`${deleteSuccessIntl}`)
                return
            }
            if (settingSaveTab === 1) {
                const schedule = scheduleAdjustmentRef(user.user_id).doc(item?.id)
                await schedule.set({isDelete: true, updatedAt: timeUpdated}, {merge: true})
                toast.success(`${deleteSuccessIntl}`)
            }

        } catch (e) {
            console.log(e.toString())
        } finally {
            setItemCustom(null)
        }
    }


    function cancel(e) {
        console.log('click no');
    }

    if (!settingList.length) return null;
    return (
        <div className={classes.tabContainer}>
            <Row gutter={[16, 16]}>
                {
                    settingList.map((item) => {
                        const startDay = moment(item?.startDay || item?.start).format('YYYY-MM-DD')
                        const endDay = moment(item?.endDay || item?.end).format('YYYY-MM-DD')

                        return (
                            <Col span={12} key={item.id} className={classes.inputStyle}>
                                <div className={classes.divInfo}>
                                    <div className={classes.divTitle}>{item.title || item?.nameSetting}</div>
                                    <div className={classes.divDate}>{startDay} ~ {endDay}</div>
                                    <div className={classes.btnAction}>
                                        <EditGroupSVG
                                            onClick={() => onSelectItem(item)}
                                            id={'list-setting-identity-edit' + item.id}
                                            className={classes.iconAntdStyle}
                                        />
                                        <Popconfirm
                                            zIndex={9999}
                                            title={titleDelete}
                                            onConfirm={() => confirmDelete(item)}
                                            onCancel={cancel}
                                            okText={deleteText}
                                            cancelText={cancelText}
                                        >
                                            <DeleteGroupSVG
                                                id={'list-setting-identity-delete' + item.id}
                                                className={classes.iconAntdStyle}
                                            />
                                        </Popconfirm>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    );
}

export default TabSettingCustom;

const useStyles = makeStyles(theme => ({
    tabContainer: {
        padding: '10px',
        background: '#FFF',
        margin: '20px 0px',
        borderRadius: '20px'
    },
    inputStyle: {
        "& .ant-input": {
            borderRadius: 11,
            background: '#F1F1F1',
            padding: '8px 75px 8px 8px',
            color: '#1790FF',
            // fontFamily: 'Montserrat',
            fontWeight: 'bold',
            fontSize: 14,
            lineHeight: '17px',
            /* identical to box height */
            textTransform: 'uppercase',
        }
    },
    divInfo: {
        backgroundColor: "#F1F1F1",
        borderRadius: 11,
        padding: '5px 10px',
        fontSize: 14,
        // fontFamily: 'Montserrat',
        fontWeight: 'bold',
        lineHeight: '17px',
        textTransform: 'uppercase',
    },
    divTitle: {
        color: '#1790FF',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '75%',
        overflow: 'hidden'
    },

    divDate: {
        color: '#7B7B7B',
    },
    btnAction: {
        position: "absolute",
        right: 15,
        top: "50%",
        marginTop: -13,
        "& button": {
            boxShadow: "none"
        }
    },
    iconAntdStyle: {
        fontSize: '1.5rem',
        fill: theme.props.primaryColor,
        cursor: 'pointer',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 5
    },

}));
