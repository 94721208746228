import React, {useEffect, useReducer, useState} from 'react';
import {Col, Input, Row, Spin, Typography} from 'antd';
import ButtonSelectColor from "./ButtonSelectColor";
import IconIdentity from "../../Calendar/google/identityIcon";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import {useGlobal} from "reactn";
import {filter, findIndex} from 'lodash'
import {makeStyles} from "@material-ui/core/styles";
import {db} from "../../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import useIntlId from "../../../hooks/useIntlId";
import CancelButton from "../../Dialog/CancelButton";
import SaveButton from "../../Dialog/SaveButton";
import {msgError} from "../../../utils/msg";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const reducerIdentity = (state, action) => {
    switch (action.type) {
        case 'upDated': {
            return {
                ...state,
                id: action.data.id,
                color: action.data.color,
                name: action.data.name,
                isArchive: action.data.isArchive,
                isDeleted: action.data.isDeleted,
                isGoogle: action.data.isGoogle,
                err: action.data.err
            };
        }
        case 'color': {
            return {...state, color: action.color};
        }
        case 'name': {
            return {...state, name: action.name};
        }
        case 'isArchive': {
            return {...state, isArchive: action.isArchive};
        }
        case 'isDeleted': {
            return {...state, isDeleted: action.isDeleted};
        }
        case 'isGoogle': {
            return {...state, isGoogle: action.isGoogle};
        }
        case 'err': {
            return {...state, err: action.err};
        }
        case 'GoogleIdsList': {
            return {...state, GoogleIdsList: action.GoogleIdsList};
        }
        case 'reset': {
            return {
                id: null,
                color: '#000000',
                name: '',
                isArchive: false,
                isDeleted: false,
                isGoogle: true,
                err: '',
                GoogleIdsList: []
            };
        }
        default:
            return state;
    }
};

const useStyles = makeStyles(theme => ({
    dialogAntd: {
        textAlign: 'center',
        "& .MuiPaper-rounded": {
            borderRadius: 24
        }
    },
    titleDialog: {
        background: "#F1F1F1",
        borderRadius: 24,
        maxWidth: 181,
        minHeight: 35,
        fontWeight: 'bold',
        color: theme.props.primaryColor,
        fontSize: "1.2rem",
        margin: 'auto'
    },
    addInfo: {
        background: "#F1F1F1",
        borderRadius: 24,
        margin: '20px 3px 3px 3px !important',
        width: '100%',
    },
    icon: {
        margin: 'auto',
        paddingLeft: 'unset !important',
        paddingRight: 'unset !important',
        marginTop: '5px'
    },
    textError: {
        margin: "auto",
        paddingBottom: 5
    },
    actions: {
        margin: 'auto',
        padding: '8px 8px 16px 8px'
    },
    tabs: {
        width: '100%',
        background: '#F1F1F1'
    },
    tab: {
        width: '50%'
    },
    inputText: {
        border: 'none',
        borderRadius: 24
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor
        }
    }
}));

function DialogAddIdentity({open, setOpen, classes}) {
    const clx = useStyles()
    const [loading, setLoading] = useState(false);
    const [user] = useGlobal('user')
    const [calendarUser, setCalendarUser] = useGlobal("calendarUser")
    const {Text} = Typography;

    const [state, dispatch] = useReducer(reducerIdentity, {
        id: null,
        color: '#000000',
        name: '',
        isArchive: false,
        isDeleted: false,
        isGoogle: true,
        err: '',
        GoogleIdsList: []
    });
    const [titleAddIdIntl, IdIntl,identityNameIntl] = useIntlId(['title.add.identity', 'app.profile.identities','setting.identityName'])

    useEffect(() => {
        if (open)
            getColorGooglesList()
        // eslint-disable-next-line
    }, [state.isGoogle, open])

    const getColorGooglesList = () => {
        const {identitySettings = []} = calendarUser
        let identityData = [...identitySettings]
        const deleted = filter(identityData, {isDeleted: true})
        if (!deleted?.length) {
            // no avaiable identity to add
            setOpen(false)
            return
        }
        const canAddColor = deleted.map(item => item.color)
        dispatch({type: 'GoogleIdsList', GoogleIdsList: canAddColor})
        dispatch({type: 'color', color: canAddColor[0]})
    }

    const handleOk = async () => {
        let {identitySettings = []} = calendarUser
        setLoading(true)
        try {
            let idx = findIndex(identitySettings, {"color": state.color.toUpperCase()})
            if (idx === -1) {
                return;
            }
            identitySettings[idx].isArchive = false
            identitySettings[idx].isDeleted = false
            identitySettings[idx].name = state.name || ''
            setCalendarUser({
                ...calendarUser,
                identitySettings
            })
            const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
            await userRef.set({
                identitySettings: identitySettings
            }, {merge: true})
        } catch (e) {
            console.log(e);
            msgError('Add error')
        } finally {
            setLoading(false)
            setOpen(false)
            dispatch({type: 'reset'})
        }
    };

    const handleCancel = () => {
        dispatch({type: 'reset'})
        setOpen(false)
    };

    const handleChange = (event) => {
        dispatch({type: 'name', name: event.target.value})
    };


    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            onClose={handleCancel}
            fullWidth
            className={clx.dialogAntd}
        >
            <div>
                <Spin tip={'Loading...'} spinning={loading}>
                    <DialogTitle>
                        <div className={`${clx.titleDialog} clf-flex-center`}>{titleAddIdIntl}</div>
                    </DialogTitle>
                    <DialogContent>
                        {
                            state.err && <Text type="danger" className={classes.textError}>{state.err}</Text>
                        }
                        <Row gutter={[16, 16]} align={'middle'} className={clx.addInfo}>
                            <Col span={1} className={clx.icon}>
                                <IconIdentity color={state.color}/>
                            </Col>
                            <Col span={14}>
                                <Input
                                    className={clx.inputText}
                                    placeholder={identityNameIntl}
                                    value={state.name}
                                    onChange={handleChange}
                                    autoFocus
                                />
                            </Col>
                            <Col span={7}>
                                <ButtonSelectColor state={state} dispatch={dispatch}/>
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions className={clx.actions}>
                        <CancelButton onClick={handleCancel}/>
                        <SaveButton onClick={handleOk}
                                    disable={loading}
                        />
                    </DialogActions>
                </Spin>
            </div>
        </Dialog>
    );
}

export default DialogAddIdentity;
