import React, {useEffect, useState} from 'react';
import {Input, Spin} from 'antd';
import {Grid} from '@material-ui/core';
import MenuItemContainer from '../MenuItemContainer';
import DialogCustom from '../../Custom/DialogCustom';
import {makeStyles} from '@material-ui/styles';
import {toast} from 'react-toastify';
import moment from 'moment';
import {scheduleAdjustmentRef, settingSaveCollRef} from '../../../common/firebaseRef/meetings';
import {useGlobal} from 'reactn';
import useIntlId from '../../../hooks/useIntlId';
import SaveButton from '../../Dialog/SaveButton';
import CancelButton from '../../Dialog/CancelButton';
import {useEnter} from '../../Calendar/common/keyboard/useHotKey';

function DialogEdit({
                        itemCustom,
                        dialogEdit,
                        setDialogEdit,
                        setItemCustom,
                        settingSaveTab = 0,
                        title = '',
                        setTitle
                    }) {

    const classes = useStyles()
    const [user] = useGlobal('user')
    const [loading, setLoading] = useState(false)
    const [labelModalDelete, savedIntl] = useIntlId([
        'dialog.action.editDelete',
        'common.saved'
    ])

    const handleChange = (e) => {
        setTitle(e.target.value)
    }

    const handleUpdate = async () => {
        if (!title) return;
        if (title === itemCustom?.nameSetting) {
            setTitle("")
            setItemCustom({})
            return

        }
        setLoading(true)
        try {
            const timeUpdated = moment().utc().format()
            if (settingSaveTab === 0) {
                const settingRef = settingSaveCollRef(user.user_id).doc(itemCustom?.id)
                await settingRef.set({nameSetting: title, updatedAt: timeUpdated}, {merge: true})
                toast.success(savedIntl)
                return
            }
            if (settingSaveTab === 1) {
                const schedule = scheduleAdjustmentRef(user.user_id).doc(itemCustom?.id)
                await schedule.set({nameSetting: title, updatedAt: timeUpdated}, {merge: true})
                toast.success(savedIntl)
            }

        } catch (e) {
            console.log(e)
            toast.error(e.toString())
            setTitle("")
            setItemCustom({})
            setDialogEdit(false)
        } finally {
            setLoading(false)
            setTitle("")
            setItemCustom({})
            setDialogEdit(false)
        }

    }

    const [isHKEnter] = useEnter('#name-input')
    useEffect(() => {
        if (isHKEnter) handleUpdate()
        // eslint-disable-next-line
    }, [isHKEnter])

    return (
        <DialogCustom open={dialogEdit} onClose={() => setDialogEdit(false)}
                      className={classes.dialogRoot}

        >
            <div>
                <Spin spinning={loading} tip={'Loading...'} delay={500}>
                    <Grid container justify={"center"} className={classes.root}>
                        <MenuItemContainer width={165} height={40} justifyContent={'center'}
                                           alignItems={'center'}
                                           margin={'0px 0px 20px 0px'} backgroundColor={'#F1F1F1'}>
                            <div className={classes.titleText}>{labelModalDelete}</div>
                        </MenuItemContainer>
                        <div className={classes.divInput}>
                            <Input
                                id={'name-input'}
                                className={classes.styleInput}
                                defaultValue={itemCustom?.title || itemCustom?.nameSetting}
                                onChange={(e) => handleChange(e)}
                                autoFocus={true}
                            />

                        </div>

                        <div className={'clf-flex-center'}>
                            <CancelButton
                                onClick={() => {
                                    setDialogEdit(false)
                                }}
                            />

                            <SaveButton onClick={() => handleUpdate()}
                                        loading={loading}
                            />
                        </div>
                    </Grid>
                </Spin>
            </div>
        </DialogCustom>
    );
}

export default DialogEdit;


const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
    },
    dialogRoot: {
        "& .MuiPaper-rounded": {
            borderRadius: 14,
            padding: 20,
            backgroundColor: '#FFF'
        },
        '& .MuiPaper-root': {
            paddingBottom: "15px",
            boxSizing: "content-box",
            overflow: "unset",
        },
        '& .MuiDialogTitle-root': {
            padding: 3,
            textAlign: "right",
        },
        "& .MuiDialog-paperWidthSm": {
            minWidth: 400,
            maxWidth: "auto",
            borderRadius: 15,
        },
        [theme.breakpoints.down('sm')]: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "auto",
            }
        }
    },
    content: {
        padding: "40px 40px 20px 40px",
    },
    formControl: {
        width: "100%",
        marginBottom: 10,
    },
    grow: {
        flexGrow: 1
    },
    margin: {
        margin: '0 5px',
        width: 130,
        color: '#3A3A3A',
        border: '1px solid #3A3A3A',
        backgroundColor: 'transparent',
    },
    error: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    selectColor: {
        width: 25,
        minWidth: 25,
        height: 25,
        borderRadius: '50%',
        padding: 0,
        border: '1px solid #D5D5D5',
    },
    btnClose: {
        margin: 4,
        width: 25,
        height: 25,
        minHeight: 0,
        backgroundColor: "transparent",
        boxShadow: "none",
        border: '1px solid #3A3A3A',
        position: "absolute",
        top: 5,
        right: 5,
        '&:hover': {
            backgroundColor: '#e91e63',
        },
    },
    btnAntd: {
        marginRight: 20,
        position: 'relative'
    },
    titleTooltip: {
        fontSize: '1rem',
        whiteSpace: 'nowrap'
    },
    titleText: {
        fontSize: "1.2rem",
        fontWeight: 'bold',
        color: theme.props.primaryColor,
        textTransform: 'uppercase',
    },
    textEdit: {
        width: '90%'
    },
    inputAdornment: {
        position: 'relative'
    },
    iconAdornment: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "-48px"
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor
        }
    },
    iconClose: {
        position: "absolute",
        right: -10,
        top: -10,
        background: "#fff",
        "&:hover": {
            background: "#fff",
        },
        "&:focus": {
            background: "#fff",
        },
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important'
    },
    textNotice: {
        width: '100%',
        display: "block",
        margin: "5px 0",
        fontSize: '1rem',
        padding: '5px 0px',
        textTransform: 'uppercase',
        color: theme.props.primaryColor
    },
    textNoticeError: {
        width: '100%',
        display: "block",
        margin: "5px 0",
        fontSize: '1rem',
        padding: '5px 0px',
        color: "red",
        textTransform: 'uppercase',
    },
    divInput: {
        width: '100%',
        margin: '20px 60px'
    },
    styleInput: {
        color: '#1790FF',
        backgroundColor: "#F1F1F1",
        borderRadius: 11,
        padding: '5px 10px',
        fontSize: 14,
        // fontFamily: 'Montserrat',
        fontWeight: 'bold',
        lineHeight: '17px',
    }

}))
