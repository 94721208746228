import moment from "moment";
import {concat, orderBy,} from "lodash";

const isEnoughTime = (e, time = 0.5) => {
    return moment(e.end).diff(e.start, "hours", true) >= time
}

const getNewEventFree = (eventVote, eventFree, timeVote = 0.5) => {
    const result = []
    if (moment(eventVote.start).isSame(eventFree.start)) {
        if (isEnoughTime({
            ...eventFree,
            start: moment(eventVote.end).format(),
            end: moment(eventFree.end).format(),
        }, timeVote))
            result.push({
                ...eventFree,
                start: moment(eventVote.end).format(),
                end: moment(eventFree.end).format(),
            })

    } else if (moment(eventVote.end).isSame(eventFree.end)) {
        if (isEnoughTime({
            ...eventFree,
            start: moment(eventFree.start).format(),
            end: moment(eventVote.start).format(),
        }, timeVote))
            result.push({
                ...eventFree,
                start: moment(eventFree.start).format(),
                end: moment(eventVote.start).format(),
            })
    } else if (moment(eventVote.start).isBetween(eventFree.start, eventFree.end)) {
        if (isEnoughTime({
            ...eventFree,
            start: moment(eventFree.start).format(),
            end: moment(eventVote.start).format(),
        }, timeVote))
            result.push({
                ...eventFree,
                start: moment(eventFree.start).format(),
                end: moment(eventVote.start).format(),
            })
        if (isEnoughTime({
            ...eventFree,
            start: moment(eventVote.end).format(),
            end: moment(eventFree.end).format(),
        }, timeVote))
            result.push({
                ...eventFree,
                start: moment(eventVote.end).format(),
                end: moment(eventFree.end).format(),
            })
    } else return false
    return result
}

export const freeTimesSimulator = (events = [], voteBox = [], timeVote) => {
    if (!events.length) return []
    if (!voteBox.length) return events
    const arrFree = orderBy(events, 'start', 'asc')
    const arrVote = orderBy(voteBox, 'start', 'asc').filter(e => moment(e.end).isAfter(moment()))
    let newGeneral = []
    for (let i = 0; i < arrFree.length; i++) {
        const free = arrFree[i]
        let count = 0;
        for (let j = 0; j < arrVote.length; j++) {
            const vote = arrVote[j]
            // if (moment(vote.end).isSameOrBefore(free.start)) continue
            // if (moment(vote.start).isSameOrAfter(free.end)) break
            const result = getNewEventFree(vote, free, timeVote)
            if (result) {
                count++;
                newGeneral = concat(newGeneral, result)
            }
        }
        if (count === 0) newGeneral.push(free)
    }
    return newGeneral || []
}
