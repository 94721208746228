import {REDIRECT_URL_KEY, USER_INFO_KEY} from "../config/constants";
import {removeCookie} from "./cookies";

const removeLoginOrRegisterKeys = () => {
    localStorage.removeItem(REDIRECT_URL_KEY);
    localStorage.removeItem('course_id');
    localStorage.removeItem('service_id');
    removeCookie(`geniam_${USER_INFO_KEY}`)
}

export default removeLoginOrRegisterKeys;
