import {toast} from 'react-toastify';
import {css} from 'glamor';

const toastDefault = {
    // Keep the signature of the original toast object
    // Doing so you can pass additionnal options
    success(msg, options = {}) {
        return toast.success(msg, {
            // Merge additionals options
            ...options,
            close: 2000,
            className: css({
                color: '#FFF !important',
                fontWeight: '400px !important',
                fontSize: '0.875rem !important',
                minHeight: '50px !important',
                borderRadius: '4px !important',
                background: `#43a047 !important`,
                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important'
            }),
        });
    },
    error(msg, options = {}) {
        return toast.error(msg, {
            ...options,
            close: 2000,
            className: css({
                color: '#fff',
                minHeight: '50px !important',
                fontWeight: '400px !important',
                fontSize: '0.875rem !important',
                borderRadius: '4px !important',
                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important'
            })
        });
    }
}

export default toastDefault;
