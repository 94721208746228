import {getGlobal} from 'reactn'
import {barRef} from "./firebaseRef";
import {db} from "../firebaseConfig";

export const barDefault = {
    displayBar: true,
    announcementBar: true,
    highLightBar: false,
    remindBar: false,
    rotationInterval: 1,
    displayTips: true,
    reminds: [],
    announcementSets: {
        times: 3,
        data: [],
        startDate: null,
    },
    milestone: {
        checked: false,
        column: 1,
    },
    highlight: "",
    geniamNews: true,
    isShowHighLight: false,
    isShowTodayHighLight: false,
    indexHighLight: 0,
    indexDayTarget: 1,
    indexWeeksTarget: 2,
    indexMonthTarget: 3,
    indexRemind: 4,
    daysAgo: 0,
    daysLater: 0,
    weeksAgo: 0,
    weeksLater: 0,
    monthsAgo: 0,
    monthsLater: 0,
    daysCheck: [true],
    weeksCheck: [true],
    monthsCheck: [true],
    defaultRotation: true,
}
export default async () => {
    try {
        const {user} = getGlobal()
        if (!user?.user_id)
            return;
        const batch = db.batch()
        const myBarRef = barRef(user.user_id)
        const barSnap = await myBarRef.get()
        if (!barSnap.exists) {
            batch.set(myBarRef, barDefault)
        }
        await batch.commit()
    } catch (e) {

    }

}
