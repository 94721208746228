import moment from 'moment';

export const isSameDate = (date1, date2) => {
    return moment(date1).isSame(date2, 'date')
}
export const mddd = date => {
    return moment(date).format("ddd")
}
export const mMMDD = date => {
    return moment(date).format("MM/DD")
}
export const mHHmm = date => {
    return moment(date).format("HH:mm")
}

//change time zone but keep local time
export const changeTz = (time, zone) => {
    return zone ? moment(time).tz(zone,true).format() : time
}
