import React, {createContext, useContext, useEffect, useState} from "react"
import {useGlobal} from 'reactn'
import {formatGoogleEvents} from "./common/getGoogleEvents";
import {myEvents, myGoogleEvents} from "../common/firebaseRef/calendar";
//import usePrevious from "react-use/lib/usePrevious";
import {chunk, concat, isFunction, remove, uniqBy} from 'lodash'
import {checkUserTaskFree, getEventsRecurrence} from "../components/Meeting/Home/utils";
import {eventSuccessRef, getDocTaskFreeRef} from "../common/firebaseRef/meetings";
import qs from "query-string";
import {useParams} from "react-router-dom";
import moment from "moment";
import {MEETING_COLLECTION} from "../config/constants";
import {db} from "../firebaseConfig";

const CalendarEventsContext = createContext({})

export const useCalendarEvents = () => {
    return useContext(CalendarEventsContext);
}

export function CalendarEventsProvider({children}) {
    const events = useEventsUser()
    return (
        <CalendarEventsContext.Provider value={events}>
            {children}
        </CalendarEventsContext.Provider>
    )
}


const useEventsUser = () => {
    const [groups] = useGlobal("groups")
    const [user] = useGlobal("user")
    // const [userInfo] = useGlobal("userInfo")
    const [ggAllProject] = useGlobal('ggAllProject');
    const [taskFree, setTaskFree] = useGlobal("taskFree")
    //const prevGroups = usePrevious(groups)
    //const prevGoogle = usePrevious(ggAllProject)
    const [geniamEvents, setGeniamEvents] = useState([])
    const [geEventSelect, setGeEventSelect] = useState([])
    const [googleEvents, setGoogleEvents] = useState([])
    const [ggEventsSelect, setGGEventSelect] = useState([])
    const [eventsDontSync, setEventsDontSync] = useState([])
    const [totalEvents, setTotalEvents] = useState([])
    const query = qs.parse(window.location.search);
    const [voteUrl, setVoteUrl] = useState([]);
    const [voteOthers, setVoteOthers] = useState([]);
    useParams();

    useEffect(() => {
        // console.log({groups})
        const groupSelect = groups.filter(cal => cal?.apSelected)
        if (!groupSelect?.length) return setGeEventSelect([])
        const calIds = groupSelect.map(cal => cal?.id).filter(x => x) || []
        const eventGe = geniamEvents.map(e => {
            if (e && calIds.includes(e?.project_uuid)) {
                return {...e}
            }
            return null;
        }).filter(x => x)
        // console.log({eventGe})
        setGeEventSelect(eventGe)
        // eslint-disable-next-line
    }, [groups, geniamEvents])

    useEffect(() => {
        // console.log({googleEvents})
        const groupSelect = ggAllProject.filter(cal => cal?.apSelected)
        if (!groupSelect?.length) return setGGEventSelect([])
        const calIds = groupSelect.map(cal => cal?.uuid).filter(x => x) || []
        const eventGG = googleEvents.map(e => {
            if (e && calIds.includes(e?.project_uuid)) {
                return {...e}
            }
            return null;
        }).filter(x => x)
        // console.log({eventGG})
        setGGEventSelect(eventGG)
        // eslint-disable-next-line
    }, [ggAllProject, googleEvents])

    useEffect(() => {
        const eventSelected = concat(geEventSelect, ggEventsSelect, eventsDontSync)
        // if (!taskFree?.length) return setTotalEvents(eventSelected)
        const events = checkUserTaskFree(eventSelected, taskFree)
        setTotalEvents(events)
        // eslint-disable-next-line
    }, [geEventSelect, ggEventsSelect, eventsDontSync, taskFree])

    useEffect(() => {
        if (!groups?.length)
            return null;
        const chunksArr = chunk(uniqBy(groups, 'id'), 10);
        let events = {};
        const subs = chunksArr.map((items, index) => {
            return myEvents().where("isDeleted", '==', false)
                .where('project_uuid', 'in', items.map(i => i.id))
                .onSnapshot(async snapshot => {
                    const data = snapshot.docs.map(doc => ({
                        ...doc.data(), id: doc.id, backgroundColor: "#808080",
                        borderColor: "transparent",
                        editable: false,
                    }))
                    const recurrence = remove(data, item => item?.recurrence)
                    const recurEdited = remove(data, e => e?.recurringEventId)
                    const recurring = getEventsRecurrence(recurrence, recurEdited)
                    let eventCal = concat(data, recurring).filter(event => event && moment(event.end).isBefore(moment().add(57, 'day')))
                    events[`${index}`] = eventCal
                    const eventBackup = db.doc(`${MEETING_COLLECTION}/${query.id}`);
                    await eventBackup.set({updatedAt: moment().utc().format()}, {merge: true});
                    if (Object.keys(events).length === chunksArr.length) {
                        const total = Object.keys(events).map(key => {
                            return events[key] || []
                        }).flat()
                        setGeniamEvents(total)
                    }
                })
        })
        return () => {
            if (subs?.length) {
                subs.forEach(u => {
                        if (u) u()
                    }
                )
            }
        }
        // eslint-disable-next-line
    }, [groups?.length])

    useEffect(() => {
        if (!ggAllProject?.length) {
            setGoogleEvents([])
            return null;
        }

        let events = {}
        const subs = uniqBy(ggAllProject, 'id').map((item, index) => {
            return myGoogleEvents(item.id)
                .onSnapshot(snapshot => {
                    let data = formatGoogleEvents(snapshot.docs.map(doc => ({
                        ...doc.data(), id: doc.id,
                    }))).map(e => ({
                        ...e, backgroundColor: "#808080",
                        borderColor: "transparent",
                        editable: false,
                        project_uuid: item.id
                    }))
                    let eventCal = data.filter(event => event && moment(event.end).isBefore(moment().add(57, 'day')))
                    events[`${item.id}`] = eventCal || []
                    if (Object.keys(events).length === ggAllProject.length) {
                        const total = Object.keys(events).map(key => {
                            return events[key] || []
                        }).flat()
                        setGoogleEvents(total)
                    }

                })
        })
        return () => {
            if (subs?.length) {
                subs.forEach(u => {
                        if (u) u()
                    }
                )
            }
        }
        // eslint-disable-next-line
    }, [ggAllProject?.length])

    useEffect(() => {
        let subTask
        if (!user?.user_id || !Array.isArray(user?.tasks?.data))
            return;
        subTask = getDocTaskFreeRef(user.user_id).onSnapshot((snapshot) => {
            if (!snapshot.exists) {
                setTaskFree([])
                return
            }
            const avaiableTask = user.tasks.data.map(item => item.type === "file" ? item.file?.id : item.icon)
            const value = snapshot.data()
            const tasksFree = value?.taskFree?.length ? value.taskFree.filter(x => x && avaiableTask.includes(x)) : []
            setTaskFree(tasksFree).then()
        })
        return () => {
            if (isFunction(subTask)) {
                subTask()
            }
        }

    }, [user?.user_id, user?.tasks])

    useEffect(() => {
        if (!user?.user_id) return null
        let subEvent
        setVoteUrl([])
        subEvent = eventSuccessRef(user.user_id).onSnapshot({
            includeMetadataChanges: true
        }, snap => {
            if (!snap.docs) setEventsDontSync([])
            const events = []
            let evtUrlSchedules = []
            let evtUrlVoteBox = []
            snap.docs.forEach(doc => {
                let data = doc.data()
                if (!data.isSync) // event khong sync project
                    events.push({
                        ...data,
                        id: doc.id,
                        backgroundColor: "#808080",
                        borderColor: "transparent",
                        editable: false,
                    })
                if (!data?.isSync && data.scheduleId && data.scheduleId !== query?.uid) // vote nhung k thuoc vao uid
                    evtUrlSchedules.push(data)
                else if (data.scheduleId && data.scheduleId === query?.uid) // vote mau cam
                    evtUrlVoteBox.push({
                        ...data,
                        backgroundColor: "#F89320FF"
                    })
            })
            setEventsDontSync(events)
            setVoteUrl([...evtUrlVoteBox])
            setVoteOthers([...evtUrlSchedules])
        });
        return () => {
            if (isFunction(subEvent)) subEvent()
        }
        // eslint-disable-next-line
    }, [user?.user_id, query?.id])

    return {
        totalEvents,
        voteUrl,
        voteOthers,
        geEvents: geEventSelect,
        ggEvents: ggEventsSelect,
        loadingData: false,
        allEventGoogle: googleEvents,
        allEventGeniam: geniamEvents,
        eventsDontSync,
    }
}
