import React from 'react';
import {CirclePicker, SketchPicker} from "react-color";
import {Button, Popover} from "antd";
import {ReactComponent as EditSVG} from "../../../asSvg/edit.svg";
import useIntlId from "../../../hooks/useIntlId";

function ButtonSelectColor(props) {
    const {state, dispatch} = props
    const handleChangeComplete = (event) => {
        dispatch({type: 'color', color: event.hex.toUpperCase()})
    }
    const [colorIntl, selectColor] = useIntlId(['view.color', 'app.notification.selectColor'])

    return (
        <Popover
            zIndex={9900}
            placement="bottom"
            title={selectColor}
            content={
                state.isGoogle ?
                    <CirclePicker
                        colors={state.GoogleIdsList}
                        onChange={handleChangeComplete}
                    />
                    :
                    <SketchPicker color={state.color} onChangeComplete={handleChangeComplete}/>
            }
            trigger="click"
        >
            <Button
                icon={<EditSVG fill={state.color} style={{marginLeft: -13, marginRight: 10}}/>}
                size={'default'}
                className={`clf-flex-center`}
                style={{background: state.color, color: "#FFF", borderRadius: 24, height: 35}}
            >
                {colorIntl}
            </Button>
        </Popover>
    );
}

export default ButtonSelectColor;
