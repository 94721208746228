import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import {checkedCalendar, GCalendarList, Sync, updateListGgCalenviewShow} from "../../actions/googleCalendar";
import {getGlobal, useGlobal} from "reactn";
import {findIndex, orderBy, remove} from "lodash";
import {withRouter} from 'react-router-dom'
import {onLogOut} from '../../common/googleCal/logOutGoogle'
import {toast} from "react-toastify";
import {Modal} from "antd";
import MenuItemContainer from "./MenuItemContainer";
import {ReactComponent as SaveSvg} from '../../asSvg/copy.svg';
import ButtonAntdComponent from "../Custom/ButtonAntdComponent";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {ItemCheckedCustom} from "item-checked-custom";
import {db} from "../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../config/constants";
import useIntlId from "../../hooks/useIntlId";
import CancelButton from "../Dialog/CancelButton";
import OkButton from "../Dialog/OkButton";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {Cached, Delete, Unarchive} from "@material-ui/icons";
import {ggTokenChecked} from "../Calendar/google/auth";
import * as PropTypes from "prop-types";
import SpinCustom from "../Custom/CLFSpinCustom";
import {msgError, msgSuccess} from "../../utils/msg";
import {publicIcon} from "../Calendar/common/infoEvent";
import {projectRef} from "../../common/firebaseRef/calendar";
import {refetchSource} from "../../actions/refetchSourcce";
import {useAnnouncement} from "../../context/AnnouncementContext";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '40px 0px'
    },
    list: {
        maxWidth: 360
    },
    archiveIcon: {
        fontSize: 20
    },
    title: {
        fontSize: "1.3rem",
        color: '#000',
        textAlign: 'center',
        marginBottom: 10
    },
    titleItem: {
        '& span': {
            fontSize: "1.2rem !important",
            fontWeight: 700
        }
    },
    moveText: {
        "&:hover": {
            cursor: "move"
        }
    },
    email: {
        color: theme.props.primaryColor,
        fontSize: '1.3rem',
        fontWeight: 'bold',
        marginLeft: 10
    },
    userEmail: {
        background: '#F1F1F1',
        borderRadius: 25,
        fontWeight: 700,
        textTransform: 'lowercase',
        fontSize: '1.3rem',
        color: '#000',
        width: '350px',
        height: '35px'
    },
    gridRoot: {
        width: '100%',
        padding: 10
    },
    gridItem: {
        background: '#FFF',
        borderRadius: 15,
        padding: '10px 0',
        margin: '0 10px',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: '#ccc',
        },
    },
    box: {
        position: "relative",
        borderRadius: "5px",
        width: 20,
        height: 20,
        marginRight: 7,
        "& img": {
            position: "absolute",
            left: "50%",
            right: 0,
            bottom: 0,
            top: "50%",
            maxWidth: "100%",
            transform: "translate(-50%,-50%)",
        }
    },
    listItem: {
        display: 'block',
        padding: 15
    },
    reloadIcon: {
        color: theme.props.primaryColor,
        fontSize: '1.8rem',
    },
    listItemIcon: {
        justifyContent: 'flex-end',
        minWidth: 'unset',
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            maxWidth: "25px !important"
        }
    },
    spin: {
        marginTop: 20
    },
    spinDiv: {
        position: 'absolute',
        width: 'auto',
        alignItems: 'center',
        display: 'contents',
    },
    iconDisplay: {
        display: "flex"
    },
    icon: {
        margin: 'auto 5px'
    },
    iconGgCalendar: {
        width: 15,
        height: 15,
        alignSelf: 'center',
    },
    gridItem2: {
        background: '#FFF',
        borderRadius: 15,
        paddingTop: 10,
        margin: '0 10px',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: '#ccc',
        },
    },
    freeLimit: {
        color: '#FFF',
        background: 'black',
        cursor: "pointer"
    },
}));

function FormattedMessage(props) {
    return null;
}

FormattedMessage.propTypes = {id: PropTypes.string};

function GoogleList() {
    const {plan} = useAnnouncement()
    const [user] = useGlobal('user');
    const [googleStatus] = useGlobal('googleStatus')
    const [projects, setProjects] = useGlobal('projects')
    const [syncProcess] = useGlobal('syncProcess')
    const [inSync] = useGlobal('inSync')
    const [sync_uuid, setSync_uuid] = useGlobal('sync_uuid')
    const [, setOpenSetting] = useGlobal('openSetting')
    const [archives, setArchives] = useState([])
    const [loading, setLoading] = useState(false)
    const [unArchive, setUnArchive] = useState(null)
    const [deleting, setDeleting] = useState(null)
    const [openLogOut, setOpenLogOut] = useState(false)
    const [googleCalendar, setGoogleCaledar] = useGlobal('googleCalendarList')
    const [googleListing] = useGlobal('googleListing')
    const [canRestoreArchive, setCanReStoreArchive] = useState([])
    const [loadingList, setLoadingList] = useState(false)
    const [calendarUser] = useGlobal("calendarUser")
    let heightDiv = window.innerHeight - 350
    const isMilestone = window.location.pathname === '/mileStone';
    const [savedIntl, deletedIntl, listSync, archiveProject, select, logout, ok, displayOnCalendar, hiddenOnCalendar, productIsHidden, btnRemoveProductDisplay, btnShowAgainProduct, noticePlan] = useIntlId(['common.saved', 'common.deleted', 'settings.listProjectSync',
        'settings.archiveProject', "settings.googleCalendarSelect", 'settings.logout', 'settings.ok', 'calendar.setting.displayOnCalendar', 'calendar.setting.hiddenOnCalendar', 'calendar.setting.productIsHidden', 'calendar.setting.btnRemoveProductDisplay', 'calendar.setting.btnShowAgainProduct', 'settings.noticePlan'])
    const [idCalenderHidden, setIdCalenderHidden] = useGlobal('idCalenderHidden')
    const [freeLimit, setFreeLimit] = useState({max: 0, index: 0, isMax: false})
    const [numberCal, setNumberCal] = useGlobal('numberCal')
    const classes = useStyles()

    const onSave = () => {
        setOpenSetting(false)
    }

    useEffect(() => {
        if (user?.idCalenderHidden?.length)
            setIdCalenderHidden(user?.idCalenderHidden || [])


// eslint-disable-next-line
    }, [user?.idCalenderHidden]);

    useEffect(() => {
        if (!googleStatus.is_login || googleCalendar.length === 0)
            return
        let nextArchives = []
        projects.forEach((pro) => {
            if (pro.is_sync && pro.id && pro.sync_email === googleStatus?.userProfile?.email) {
                let idx = findIndex(googleCalendar, {id: pro.sync_id})
                if (idx === -1) {
                    nextArchives.push(pro)
                }
            }
        })
        setArchives(nextArchives)
        // eslint-disable-next-line
    }, [projects, googleCalendar])

    useEffect(() => {
        if (!archives.length)
            return;
        checkRestoreValid(archives)

    }, [archives])

    const checkRestoreValid = async list => {
        try {
            await ggTokenChecked()
            const result = await Promise.all(list.map(async pro => {
                try {
                    const request = window.gapi.client.calendar.calendars.get({
                        "calendarId": pro.sync_id
                    })
                    await request.then()
                    return pro
                } catch (e) {
                    return null
                }
            }))
            setCanReStoreArchive(result.filter(x => x))
        } catch (e) {
            console.log("gg get archive error", e);
            setCanReStoreArchive([])
        }
    }

    const toggleCalendar = async (id) => {
        let index = findIndex(googleCalendar, {'id': id})
        if (index !== -1) {
            let nextValue = !googleCalendar[index].selected
            googleCalendar[index].selected = nextValue
            setGoogleCaledar([...googleCalendar])
            checkedCalendar(googleCalendar[index].id, {selected: nextValue, summary: googleCalendar[index].summary})
            const listUpdate = calendarUser?.listGgCalenviewShow
            let ind = findIndex(listUpdate, g => g.id === id);
            if (ind > -1) {
                listUpdate[ind].check = nextValue
            } else {
                listUpdate.push({
                    id: googleCalendar[index].id,
                    checkbox: true,
                    check: true
                })
                GCalendarList()
            }
            await updateListGgCalenviewShow(listUpdate)
            refetchSource('google')
        }
    }

    const toggle = (source) => {
        setLoadingList(true)
        let index = findIndex(projects, p => p.id === source.id)
        if (index !== -1) {
            if (isMilestone) {
                projects[index].isMilestoneSelected = !projects[index].isMilestoneSelected;
                setProjects([...projects]);
                return updateSelected(projects[index].id, projects[index].isMilestoneSelected)
            } else {
                projects[index].selected = !projects[index].selected;
                setProjects([...projects]);
                return updateSelected(projects[index].id, projects[index].selected)
            }
        }
    };

    const updateSelected = async (id, check) => {
        if (!id || !user?.user_id)
            return
        try {
            const proRef = projectRef(id);
            if (isMilestone) {
                await proRef.set({isMilestoneSelected: check}, {merge: true})
            } else {
                await proRef.set({selected: check}, {merge: true})
            }
            await setLoadingList(false)
        } catch (e) {
            console.log(e);
            setLoadingList(false)
        }
    };

    const onUnArchive = async () => {

        if (!unArchive || !unArchive.sync_id) {
            toast.error('error load calendar try late')
            return
        }
        try {
            const project = {...unArchive}
            setLoading(true)
            await ggTokenChecked();
            const request = window.gapi.client.calendar.calendarList.insert({
                "id": project.sync_id
            })
            await request.then()

            await GCalendarList()
            remove(archives, s => s.sync_id === project.sync_id)
            setArchives([...archives])
            msgSuccess(savedIntl)
        } catch (e) {
            console.log(e)
            toast.error('project has been completely deleted from google account. Unable to restore')
        } finally {
            setUnArchive(null)
            setLoading(false)
        }
    }

    const onDelete = async () => {
        if (!deleting || !deleting.sync_id || !deleting.uuid)
            return
        try {
            const pro = {...deleting}
            setLoading(true)
            // Is_Delete firestore true
            const idx = projects.findIndex((p) => p.uuid === pro.uuid);
            await db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection("projects")
                .doc(projects[idx].uuid)
                .set({
                    is_deleted: true
                }, {merge: true})

            projects.splice(idx, 1);
            await setProjects([...projects])
            msgSuccess(`${deletedIntl}`)
        } catch (e) {
            console.log(e);
            msgError(e.toString())
        } finally {
            setDeleting(null)
            setLoading(false)
        }
    }

    const SortGoogleCalendar = () => {
        return orderBy(googleCalendar, g => g.index);
    };

    const updateIdCalenderHidden = async (listId) => {
        try {
            const {user} = getGlobal()
            await db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
                .set({
                    idCalenderHidden: listId
                }, {merge: true})
        } catch (e) {

        } finally {
            setLoadingList(false)
        }
    }

    const displayCalendar = (id) => {
        setLoadingList(true)
        let arrIdCalenderHidden = [...idCalenderHidden];
        const index = arrIdCalenderHidden.indexOf(id);
        if (index > -1) {
            arrIdCalenderHidden.splice(index, 1);
            if (freeLimit.isMax) {
                googleCalendar[index].selected = false
                setGoogleCaledar(orderBy(googleCalendar, "selected"))
                checkedCalendar(googleCalendar[index].id, {selected: false})
            }
        }
        setIdCalenderHidden(arrIdCalenderHidden);
        updateIdCalenderHidden(arrIdCalenderHidden)
    }
    const hiddenCalendar = (id) => {
        setLoadingList(true)
        let arrIdCalenderHidden = [...idCalenderHidden];
        if (arrIdCalenderHidden.indexOf(id) === -1) arrIdCalenderHidden.push(id);
        setIdCalenderHidden(arrIdCalenderHidden);
        updateIdCalenderHidden(arrIdCalenderHidden)
    }

    useEffect(() => {
        let ind = ''
        let calPlan = 0
        SortGoogleCalendar().forEach((source, index) => {
            if (!source?.id || idCalenderHidden.indexOf(source.id) > -1 || typeof source.index === 'undefined')
                return null
            ind = source.id
            calPlan++
        })
        setFreeLimit(ind)
        setNumberCal(calPlan)
        // eslint-disable-next-line
    }, [googleCalendar, plan.numOfCal, numberCal])

    return (
        <div className={classes.root}>
            <MenuItemContainer width={555} height={39} justifyContent={'space-between'} alignItems={'center'}
                               marginBottom={20} margin={'auto'}>
                <div className={classes.email}>E-Mail</div>
                <div className={`clf-flex-center ${classes.userEmail}`}>{googleStatus.userProfile.email}</div>
                <CLFButtonSVG
                    onClick={() => setOpenLogOut(true)}
                    name={logout}
                    size={'default'}
                    className={classes.primary}
                />

                {
                    openLogOut &&
                    <LogoutDialog open={openLogOut} setOpen={setOpenLogOut} loading={loading}/>
                }
            </MenuItemContainer>
            <div className={classes.spin}>
                {
                    googleListing ? <SpinCustom/> : null
                }
            </div>
            <Grid container className={classes.gridRoot}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>
                        {select}
                    </Typography>
                    <div className={`${classes.gridItem}`} style={{height: `${heightDiv}px`}}>
                        <List className={classes.listItem}>
                            <>
                                <Typography className={classes.title}>
                                    {displayOnCalendar}
                                </Typography>
                                {projects.map((p, index) => {
                                    if (p.is_sync) {
                                        return null
                                    }
                                    return (
                                        <div className={classes.iconDisplay} key={index}>
                                            <img src={'favicon.ico'} alt=""
                                                 className={classes.iconGgCalendar}/>
                                            {!p.isDefault ? <ListItemIcon className={classes.listItemIcon}>
                                                <IconButton
                                                    disableRipple={true}
                                                    disabled={inSync}
                                                    onClick={async () => {
                                                        setSync_uuid(p.uuid)
                                                        Sync(p.uuid)
                                                    }}
                                                    style={{padding: 0}}
                                                >
                                                    {
                                                        inSync && p.uuid === sync_uuid ?
                                                            <CircularProgress
                                                                color={"primary"}
                                                                size={30}
                                                            /> :
                                                            <Cached className={classes.reloadIcon}/>
                                                    }

                                                </IconButton>
                                            </ListItemIcon> : <div style={{width: 21}}></div>}
                                            <ItemCheckedCustom
                                                id={index}
                                                key={`${index}`}
                                                onClick={() => toggle(p)}
                                                name={p.name}
                                                color={p.color}
                                                checked={p.selected}
                                                isRevert={true}
                                                hiddenAvatar={true}
                                                isBold={true}
                                                isTextColor={true}
                                                height={'30px'}
                                                margin={'unset'}
                                                className={"ItemCheckedCustom"}
                                            />
                                        </div>
                                    )
                                })}
                                {SortGoogleCalendar().map((calendar, index) => {
                                    if (!calendar?.id || idCalenderHidden.indexOf(calendar.id) > -1)
                                        return null
                                    return (
                                        <div key={index}>
                                            <div className={classes.iconDisplay} key={index}>
                                                <img src={publicIcon('iconGgCalendar.png')} alt=""
                                                     className={classes.iconGgCalendar}/>
                                                <EyeOutlined
                                                    onClick={() => typeof calendar.checkbox === 'undefined' ? '' : hiddenCalendar(calendar.id)}
                                                    className={classes.icon}
                                                    style={{color: `${typeof calendar.checkbox === 'undefined' ? '#ccc' : calendar.backgroundColor}`}}
                                                    title={btnRemoveProductDisplay}
                                                />
                                                <ItemCheckedCustom
                                                    id={index}
                                                    key={`${index}`}
                                                    onClick={() => toggleCalendar(calendar.id)}
                                                    name={calendar.summary}
                                                    isRevert={true}
                                                    hiddenAvatar={true}
                                                    isBold={true}
                                                    isTextColor={true}
                                                    height={'30px'}
                                                    margin={'unset'}
                                                    color={typeof calendar.checkbox === 'undefined' ? '#ccc !important' : calendar.backgroundColor}
                                                    checked={Boolean(calendar.selected)}
                                                    disabled={typeof calendar.checkbox === 'undefined'}
                                                    offTooltip={true}
                                                    className={"ItemCheckedCustom"}
                                                />
                                            </div>
                                            {calendar.id === freeLimit &&
                                                <Grid className={classes.freeLimit}
                                                      align="center">{noticePlan ? noticePlan.replace('**', plan.numOfCal) : ''}</Grid>}
                                        </div>
                                    )
                                })}
                            </>
                        </List>
                    </div>
                    <ListItem className={`clf-flex-center`}>
                        <CLFButtonSVG
                            onClick={onSave}
                            iconComponent={<SaveSvg/>}
                            name={ok}
                            size={'default'}
                            className={classes.primary}
                            loading={Boolean(googleListing) || loadingList}
                            minHeight={"31px"}
                        />
                    </ListItem>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.title}>
                        &nbsp;
                    </Typography>
                    <div className={`${classes.gridItem2}`} style={{height: `${heightDiv / 2 - 2}px`}}>
                        <List className={classes.listItem}>
                            <Typography className={classes.title}>
                                {productIsHidden}
                            </Typography>
                            {googleCalendar.map((calendar, index) => {
                                return (
                                    idCalenderHidden.indexOf(calendar.id) !== -1 &&
                                    <div className={classes.iconDisplay} key={index}>
                                        <img src={publicIcon('iconGgCalendar.png')} alt=""
                                             className={classes.iconGgCalendar}/>
                                        <EyeInvisibleOutlined
                                            onClick={() => freeLimit.isMax ? '' : displayCalendar(calendar.id)}
                                            className={classes.icon}
                                            style={{color: `${freeLimit.isMax ? '#ccc' : calendar.backgroundColor}`}}
                                            title={btnRemoveProductDisplay}
                                        />
                                        <ItemCheckedCustom
                                            id={index}
                                            key={`${index}`}
                                            onClick={() => toggleCalendar(calendar.id)}
                                            name={calendar.summary}
                                            color={freeLimit.isMax ? '#ccc' : calendar.backgroundColor}
                                            disabled={freeLimit.isMax}
                                            checked={calendar.selected}
                                            isRevert={true}
                                            hiddenAvatar={true}
                                            isBold={true}
                                            isTextColor={true}
                                            height={'30px'}
                                            margin={'unset'}
                                            className={"ItemCheckedCustom"}
                                        />
                                    </div>
                                )
                            })}
                        </List>
                    </div>
                    <div className={`${classes.gridItem2}`}
                         style={{marginTop: '4px', height: `${heightDiv / 2 - 2}px`}}>
                        <Typography className={classes.title}>
                            {archiveProject}
                        </Typography>
                        <List className={classes.list}>
                            {
                                canRestoreArchive.map(p => (
                                    <ListItem key={p.sync_id} style={{padding: '5px 15px'}}>
                                        <ListItemText style={{color: `${p.color}`}} primary={p.name}/>
                                        <ListItemIcon>
                                            <IconButton
                                                disableRipple={true}
                                                onClick={() => setUnArchive(p)}
                                                disabled={loading}
                                            >
                                                <Unarchive/>
                                            </IconButton>
                                            <IconButton
                                                disableRipple={true}
                                                disabled={loading}
                                                onClick={() => setDeleting(p)}
                                            >
                                                <Delete/>
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                ))
                            }
                            {Boolean(unArchive) &&
                                <ArchiveDialog unArchive={unArchive} setUnArchive={setUnArchive} onDone={onUnArchive}
                                               loading={loading}/>
                            }
                            {Boolean(deleting) &&
                                <DeleteProjectDialog project={deleting} setProject={setDeleting} onDone={onDelete}
                                                     loading={loading}/>
                            }
                        </List>

                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

function ArchiveDialog({unArchive, setUnArchive, onDone, loading}) {
    const [archiveDialogDeleteArchive, archiveDialogCancel, archiveDialogOk, archiveDialogTitle] = useIntlId(['settings.archiveDialogDeleteArchive', 'settings.archiveDialogCancel', 'settings.archiveDialogOk', 'settings.archiveDialogTitle'])
    const handleClose = () => {
        setUnArchive(null)
    }
    const onOk = () => {
        onDone()
    }
    return (
        <Modal
            visible={Boolean(unArchive)}
            title={archiveDialogDeleteArchive}
            onCancel={handleClose}
            zIndex={9000}
            footer={[
                <ButtonAntdComponent
                    onClick={handleClose}
                    disable={loading}
                    name={archiveDialogCancel}
                    typeClass={"secondary"}
                    key={'back'}
                />
                ,
                <ButtonAntdComponent
                    name={archiveDialogOk}
                    key={"submit"}
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                    typeClass={"primary"}
                />,
            ]}
        >
            {archiveDialogTitle}
        </Modal>
    )
}

function DeleteProjectDialog({project, setProject, onDone, loading}) {
    const [archiveDialogDeleteProject, archiveDialogCancel, archiveDialogOk, archiveDialogDelete] = useIntlId(['settings.archiveDialogDeleteProject', 'settings.archiveDialogCancel', 'settings.archiveDialogOk', 'settings.archiveDialogDelete'])
    const handleClose = () => {
        setProject(null)
    }
    const onOk = () => {
        onDone()
    }
    return (
        <Modal
            visible={Boolean(project)}
            title={archiveDialogDeleteProject}
            onCancel={handleClose}
            zIndex={9000}
            footer={[
                <ButtonAntdComponent
                    onClick={handleClose}
                    disable={loading}
                    name={archiveDialogCancel}
                    typeClass={"secondary"}
                />
                ,
                <ButtonAntdComponent
                    name={archiveDialogOk}
                    key={"submit"}
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                    typeClass={"primary"}
                />,
            ]}
        >
            {archiveDialogDelete}
        </Modal>
    )
}

function LogoutDialog({open, setOpen, loading}) {
    const [googleStatus] = useGlobal('googleStatus')
    const [logoutGoogle, question, text] = useIntlId(['settingGoogleSync.title', 'settingGoogleSync.question', 'settingGoogleSync.text'])

    const handleClose = () => {
        setOpen(false)
    }
    const onOk = () => {
        onLogOut()
        handleClose()
    }
    return (
        <Modal
            visible={Boolean(open)}
            title={logoutGoogle}
            onCancel={handleClose}
            zIndex={9000}
            // footer={[
            //     <ButtonAntdComponent
            //         onClick={handleClose}
            //         disable={loading}
            //         name={'Cancel'}
            //         typeClass={"secondary"}
            //     />
            //
            //     ,
            //     <ButtonAntdComponent
            //         name={'Ok'}
            //         key={"submit"}
            //         loading={loading}
            //         onClick={onOk}
            //         disable={loading}
            //         typeClass={"primary"}
            //     />,
            // ]}
            footer={<div className={'clf-flex-center'}>
                <CancelButton
                    onClick={handleClose}
                    disable={loading}
                />
                <OkButton
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                />
            </div>}
        >
            {question}
            <Typography color={"secondary"}>
                ( {text} {googleStatus?.userProfile?.email || ''})
            </Typography>
        </Modal>
    )
};


export default withRouter(GoogleList)
