import React, {createContext, useContext, useEffect, useState} from "react"
import {setGlobal, useGlobal} from 'reactn'
import qs from 'query-string'
import {useHistory, useParams} from 'react-router-dom'
import {
    getAnonymousCollRef,
    getBoxFreeRef,
    getBusyBoxRef,
    getMeetingDocRef,
    getUserDocRef,
    getUsersCollRef,
    getVoteBoxCollRef
} from "../common/firebaseRef/meetings";
import {alertNotMeeting} from "./common/alertNotMeeting";
import useIntlId from "../hooks/useIntlId";
import {concat, findIndex, isEmpty, isFunction, remove} from 'lodash'
import {isUserExists} from "../components/Meeting/Home/actions";
import moment from "moment";
import {checkIdInBoxFree, freeSlot, inDayEvent} from "../components/Meeting/Fullcalendar/Actions/freeSlot";
import {useCalendarEvents} from "./CalendarEventsContext";
import getMySetting from "../common/getMySetting";
import validEvent from "../common/validEvent";
import isHiddenDays from "../common/isHiddenDays";
import isDateBusy from "../common/isDateBusy";
import adjustmentFreetime from "../common/adjustmentFreetime";
import store from 'store'
import {freeTimesSimulator} from "./common/freeTimesSimulator";
import {autoFreeTimes} from "./common/autoFreeTimes";
import {zoneObj} from "../common/moment/format";

const AppointmentEventContext = createContext({})

export const useAppointmentEvent = () => {
    return useContext(AppointmentEventContext);
}

export function AppointmentEventProvider({children}) {
    const event = useAppointment();
    // console.log(event)
    return (
        <AppointmentEventContext.Provider value={event}>
            {children}
        </AppointmentEventContext.Provider>
    )
}


const useAppointment = () => {
    const [changeHourCurrent, setChangeHourCurrent] = useState(0)
    const [user] = useGlobal('user')
    const [projects] = useGlobal('allProject')
    const [eventCancelJoin] = useGlobal('eventCancelJoin');
    const [generalFreeTimes, setGeneralFreeTimes] = useGlobal('generalFreeTimes')
    const [meeting, setMeeting] = useState(null)
    const [users, setUsers] = useState([])
    const [anonymous, setAnonymous] = useState([])
    const [loading, setLoading] = useState(false)
    const [mySetting, setMySetting] = useState(null)
    const [userLoading, setUserLoading] = useState(false)
    const [role, setRole] = useState('viewer')
    const [voteBox, setVoteBox] = useState([])
    const [boxFree, setBoxFree] = useState([])
    const [busyBox, setBusyBox] = useState([])
    const [totalEventsInMeet, setTotalEventsInMeet] = useState([])
    const [myFreeTimes, setMyFreeTimes] = useState([])
    const query = qs.parse(window.location.search)
    const [groupExists] = useIntlId(['alert.groupNotExists'])
    const history = useHistory()
    const {
        totalEvents,
    } = useCalendarEvents()
    useParams()

    const clearState = () => {
        setMeeting(null)
        setUsers([])
        setAnonymous([])
        setMySetting(null)
        setRole('viewer')
        setVoteBox([])
        setBoxFree([])
        setBusyBox([])
        setTotalEventsInMeet([])
    }

    useEffect(() => {
        let subMeeting
        let subUsers
        let subAnonymous
        let voteSub
        let subBoxFree
        let subBusyBox
        if (!query?.id) {
            if (window.location.pathname === "/shareUrl") {
                clearState()
                return null;
            }
            // else if ((window.location.pathname === "/" || window.location.pathname === "/event") && listMeetings?.length){
            //     history.push(`/event?id=${listMeetings[0].id}`)
            //     return window.location.reload()
            // }
            return null
        }
        setLoading(true)
        setUserLoading(true)
        const meetingRef = getMeetingDocRef(query.id)
        const userCollRef = getUsersCollRef(query.id)
        const anonymousCollRef = getAnonymousCollRef(query.id)
        const voteCollRef = getVoteBoxCollRef(query.id)
        const boxFreeRef = getBoxFreeRef(query.id)
        const busyBoxRef = getBusyBoxRef(query.id)
        subMeeting = meetingRef.onSnapshot(snapshot => {
            if (!snapshot.exists || snapshot.data()?.isDeleted) {
                clearState()
                alertNotMeeting(groupExists);
                return;
            }
            const generalFreeData = snapshot.data()?.generalFreeTimes ? [...snapshot.data()?.generalFreeTimes] : []
            // console.log("generalFreeData:", generalFreeData.length)
            let generalSubInDay = []
            generalFreeData.forEach((event) => {
                if (!event) return;
                event.start = zoneObj(event.start).format();
                event.end = zoneObj(event.end).format();
                generalSubInDay = concat(generalSubInDay, inDayEvent(event))
            })
            setMeeting({...snapshot.data(), id: snapshot.id})
            remove(generalSubInDay, e => moment(e.end).diff(e.start, "hours", true) < (snapshot.data().timeVote || 1))
            const generalFreeTimeAuto = autoFreeTimes(generalSubInDay, snapshot?.data()?.timeVote)
            setGlobal({
                meetingPrimary: Boolean(snapshot.data()?.automaticTimes?.includes(user.user_id)),
                generalFreeTimes: generalFreeTimeAuto
            })
            setLoading(false)
        })
        subUsers = userCollRef.onSnapshot(async (snapshot) => {
            const meetingDoc = await meetingRef.get();
            if (!meetingDoc.exists || meetingDoc.data()?.isDeleted) {
                return;
            }
            setUsers(snapshot.docs.map(doc => ({...doc.data(), id: doc.id, meetingId: query.id})))
            setUserLoading(false)
        })
        subAnonymous = anonymousCollRef.onSnapshot(snapshot => {
            setAnonymous(snapshot.docs.map(doc => ({...doc.data(), id: doc.id, isAnonymous: true})))
        })
        voteSub = voteCollRef
            .where('isDeleted', '==', false)
            .onSnapshot(snapshot => {
                if (!snapshot.docs) return setVoteBox([])
                setVoteBox(snapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                    meetingId: query.id,
                    isEventVote: true,
                    backgroundColor: "#F89320FF"
                })))
            })
        subBoxFree = boxFreeRef
            .onSnapshot({
                includeMetadataChanges: true
            }, snapshot => {
                if (!snapshot.docs) setBoxFree([])
                const boxFreeData = snapshot.docs.map(doc => ({...doc.data(), id: doc.id, meetingId: query.id}))
                setBoxFree(boxFreeData)
            })
        subBusyBox = busyBoxRef
            .onSnapshot({
                includeMetadataChanges: true
            }, snapshot => {
                if (!snapshot.docs) setBusyBox([])
                const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id, meetingId: query.id}))
                setBusyBox([...data])
            })

        return () => {
            if (isFunction(subMeeting))
                subMeeting();
            if (isFunction(subUsers))
                subUsers();
            if (isFunction(subAnonymous))
                subAnonymous();
            if (isFunction(voteSub))
                voteSub();
            if (isFunction(subBoxFree))
                subBoxFree();
            if (isFunction(subBusyBox))
                subBusyBox();
        }
        // eslint-disable-next-line
    }, [query?.id])

    useEffect(() => {
        if (!user.user_id || !meeting?.id || !users?.length) return
        if (users[0]?.meetingId === meeting.id ) {
            isUserExists(meeting, users, anonymous, history, groupExists).then()
        }
        // eslint-disable-next-line
    }, [meeting?.id, users, user?.user_id, eventCancelJoin]);

    useEffect(() => {
        if (!user?.user_id)
            return;
        if (!users.length) {
            setRole('viewer')
            return setMySetting(null)
        }
        let idx = findIndex(users, {id: user.user_id})
        if (idx === -1) {
            setRole('viewer')
            return setMySetting(null)
        }
        setRole(users[idx].role || 'viewer')
        setMySetting(users[idx])
        // eslint-disable-next-line
    }, [user?.user_id, users])

    useEffect(() => {
        if (!mySetting || !projects.length || !user?.user_id) return;
        let idx = -1
        if (mySetting.project) {
            idx = findIndex(projects, {uuid: mySetting.project.uuid})
        }
        if (idx === -1) {
            getUserDocRef(meeting.id, user.user_id).update({
                project: {
                    uuid: projects[0].uuid,
                    is_google: Boolean(projects[0].is_google)
                }
            }).then()
        }
    }, [mySetting?.project, projects, user?.user_id])

    useEffect(() => {
        if (window.location.pathname !== '/eventAnonymous')
            return;
        const email = store.get("anonymousEmail")
        if (!email)
            return
        let idx = findIndex(anonymous, {email})
        if (idx !== -1)
            setRole(anonymous[idx]?.role || 'viewer')
        // eslint-disable-next-line
    }, [anonymous])

    useEffect(() => {
        const runInterval = setInterval(function () {
            setChangeHourCurrent(changeHourCurrent + 1)
        }, 15 * 60 * 1000);
        return () => clearInterval(runInterval);
        // eslint-disable-next-line
    }, [changeHourCurrent])

    useEffect(() => {
        if (!generalFreeTimes?.length || changeHourCurrent === 0) return null
        const autoGeneral = autoFreeTimes(generalFreeTimes, meeting?.timeVote)
        setGeneralFreeTimes(autoGeneral).then()
        // eslint-disable-next-line
    }, [changeHourCurrent])

    useEffect(() => {
        if (!voteBox?.length || !generalFreeTimes?.length) return null;
        const generalSimulator = freeTimesSimulator(generalFreeTimes, [...voteBox], meeting?.timeVote)
        setGeneralFreeTimes(generalSimulator).then("Simulator event voteBox local")
        // eslint-disable-next-line
    }, [voteBox])

    useEffect(() => {
        if (!meeting?.id || isEmpty(mySetting)) return null;
        if (!totalEvents?.length)
            setTotalEventsInMeet([])

        const totalMeeting = totalEvents.filter(event => event && event?.meetingId !== meeting.id)
        let eventMeets = [...totalMeeting];
        if (boxFree.length) {
            let totalCheckedBoxFree = totalMeeting.map((event) => ({
                ...event,
                checkFree: !!checkIdInBoxFree(event?.id, boxFree)
            }))
            eventMeets = [...totalCheckedBoxFree]
        }

        setTotalEventsInMeet(eventMeets);

        const settingDate = getMySetting(mySetting)
        const freeTime = freeSlot(settingDate.start, settingDate.end, concat(adjustmentFreetime(eventMeets, mySetting.freeTime || 0), voteBox, busyBox), "#00CC66FF", mySetting, boxFree)

        remove(freeTime, e => !validEvent(e) || isHiddenDays(e, mySetting?.hiddenDays) || isDateBusy(e, mySetting?.dateBusy))
        setMyFreeTimes(freeTime)
        // eslint-disable-next-line
    }, [totalEvents, boxFree, mySetting, busyBox, voteBox])

    const updateField = React.useCallback(async (field, value) => {
        if (!meeting?.id) {
            return null;
        }
        try {
            const eventGroupRef = getMeetingDocRef(meeting.id)
            await eventGroupRef.set({
                [field]: value
            }, {merge: true})
        } catch (e) {
            console.log(e);
        }
        // eslint-disable-next-line
    }, [meeting?.id])

    return {
        meeting,
        users,
        loading,
        userLoading,
        anonymous,
        setMeeting,
        setUsers,
        setLoading,
        mySetting,
        setMySetting,
        role,
        setRole,
        voteBox,
        updateField,
        boxFree,
        setVoteBox,
        busyBox,
        totalEventsInMeet,
        myFreeTimes
    }
}
