import moment from "moment";


const validEvent = (event) => {
    if (!event || !event?.start || !event?.end)
        return false
    return moment(event.start).isBefore(event.end)
}

export default validEvent;
