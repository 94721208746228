const projectSelectData = (calendarUser, projects, identities) => {
    let project;
    let identity;
    let isGoogle;

    if (Boolean(calendarUser?.lastProjectSelect)) {
        project = calendarUser.lastProjectSelect.project_uuid
        isGoogle = Boolean(calendarUser.lastProjectSelect.isGoogle) || false
        identity = calendarUser.lastProjectSelect.colorId || 1
    } else {
        project = projects[0]?.uuid
        isGoogle = Boolean(projects[0]?.is_google)
        identity = identities[0]?.id || 1;
    }

    return {project, isGoogle, identity}
}

export default projectSelectData;
