import qs from 'query-string';
import Button from '@material-ui/core/Button';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

export default function LinkMeetingError(props) {
    const classes = useStyles()
    const query = qs.parse(window.location.search)
    return (
        <div className={classes.notFoundMeeting}>
            NOT FOUND :((
            {
                query && query?.error === 'maxUserJoin' ?
                    <div>
                        You have participated in an event that exceeds the number of participants allowed, please
                        contact the administrator or owner again
                    </div>
                    :
                    <div>
                        The link is currently not available or has expired! Please try the new link again
                    </div>
            }
            <div>
                <Button onClick={() => window.location.assign('/')}>Back to calendar</Button>
                <Button onClick={() => window.location.assign('/event')}>Back to event group</Button>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    notFoundMeeting: {
        marginTop: 20,
        textAlight: 'center'
    },
    notFound: {
        marginTop: 80
    }
}))
