import React from "react";
import {Button, Typography, withStyles} from "@material-ui/core";
import {IS_PRODUCTION} from "../../config/constants";

const styles = theme => ({
    root: {
        marginTop: 20,
        width: "90%",
        margin: "auto",
        maxWidth: 1000,
        padding: 20,
        border: "1px solid black"
    },
    reloadBtn: {
        borderRadius: 5
    }

});

class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // prod just reload
        if (IS_PRODUCTION) {
            return window.location.reload()
        }
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div className={this.props.classes.root}>
                    <Typography color={"secondary"} variant={"h2"}>Something went wrong.</Typography>
                    <Button className={this.props.classes.reloadBtn} variant={"contained"} color={"primary"}
                            onClick={() => window.location.reload()}>Reload page</Button>
                    <br/>

                    <details style={{whiteSpace: 'pre-wrap'}} open={true}>
                        {this.state.error && this.state.error.toString()}
                        <br/>
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default withStyles(styles)(ErrorBoundary)
