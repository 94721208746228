import React, {useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as AddSVG} from "../../../asSvg/icons/add.svg";
import {makeStyles} from "@material-ui/core/styles";
import {Button, TextField} from "@material-ui/core";
import DialogCustom from "../../Custom/DialogCustom";
import {v4 as uuidv4} from "uuid";
import {useGlobal} from "reactn";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {findIndex} from 'lodash'
import {db} from "../../../firebaseConfig";
import axios from "axios";
import useIntlId from "../../../hooks/useIntlId";
import {toast} from "react-toastify";
import isURL from 'validator/lib/isURL';
import { msgError } from '../../../utils/msg';

const urls = [
    {
        id: "slack",
        name: "slack",
        url: "",
        logo: "/icons/slackIcon.svg"
    },
    {
        id: "trello",
        name: "trello",
        url: "",
        logo: "/icons/trelloIcon.svg"
    },
]

function AddProjectUrl({project, setProject}) {
    const classes = useStyles()
    const [openAdd, setOpenAdd] = useState(false)
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [projects] = useGlobal("projects")
    const [user] = useGlobal("user")
    const [calendarUser] = useGlobal("calendarUser")
    const [loading, setLoading] = useState(false)
    const [saveIntl, nameIntl, addUrlIntl, invalidLinkIntl] = useIntlId(['dialog.action.done', 'app.profile.name', 'create.addUrl', 'appy.noticeLink.invalid'])
    const openAddURL = () => {
        setOpenAdd(!openAdd)
    }
    const onAdd = async () => {
        try {
            setLoading(true)
            const id = uuidv4()
            if (!url || !user?.user_id)
                return;
            if (!isURL(url,{protocols: ['http','https'], require_protocol: true})) {
                return  toast.error(invalidLinkIntl)
            }
            let logo = '/icons/linkURL.svg'
            try {
                const URL = new window.URL(url)
                const origin = URL.origin
                await axios.get(`${origin}/favicon.ico`)
                logo = `${origin}/favicon.ico`
            } catch (e) {

            }

            if (project.is_google) {
                // google calendar
                let googleSettings = calendarUser?.googleSettings || {}
                let projectInfo = googleSettings[project.id] || {}
                let {otherURL = []} = projectInfo
                if (!otherURL.length) {
                    otherURL = [...urls, {
                        id,
                        name: name || "",
                        url: url || "",
                        logo
                    }]
                } else {
                    otherURL = [...otherURL, {
                        id,
                        name: name || "",
                        url: url || "",
                        logo
                    }]
                }
                projectInfo.otherURL = otherURL
                googleSettings[project.id] = projectInfo
                const ref = db.collection(CALENDAR_COLLECTION)
                    .doc(user.user_id)
                await ref.set({
                    googleSettings
                }, {merge: true})
                setProject({...project, otherURL})
                return
            }
            let idx = findIndex(projects, {id: project.id})
            if (idx === -1)
                return

            let projectInfo = projects[idx]
            let {otherURL = []} = projectInfo
            if (!otherURL.length) {
                otherURL = [...urls, {
                    id,
                    name: name || "",
                    url: url || "",
                    logo
                }]
            } else {
                otherURL = [...otherURL, {
                    id,
                    name: name || "",
                    url: url || "",
                    logo
                }]
            }
            const ref = db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection('projects')
                .doc(project.id)
            await ref.set({otherURL}, {merge: true})
            setProject({...project, otherURL})
            setName("")
            setUrl('')
            setOpenAdd(false)
        } catch (e) {
            msgError(e.toString())
        } finally {
            setLoading(false)
            setName('')
            setUrl('')
            setOpenAdd(false)
        }

    }

    return (
        <div className={classes.divIconAddURL}>

            <IconButton className={classes.iconBtnAddURL}>
                <AddSVG
                    onClick={openAddURL}
                    className={classes.iconAddURL}/>
            </IconButton>
            <DialogCustom open={openAdd}
                          onClose={() => {
                              setName("")
                              setUrl('')
                              setOpenAdd(false)
                          }}
                          title={addUrlIntl}
                          maxWidth={'xs'}
                          fullWidth={true}
                          actions={<Button
                              variant={"outline"}
                              className={classes.button}
                              disabled={!name || !url || loading}
                              onClick={onAdd}
                          >
                              {saveIntl}
                          </Button>}
            >
                <div className={classes.item}>
                    <TextField
                        value={name}
                        label={nameIntl}
                        fullWidth={true}
                        onChange={e => setName(e.target.value)}
                    />
                    <TextField
                        value={url}
                        label={"URL"}
                        fullWidth={true}
                        onChange={e => setUrl(e.target.value)}

                    />
                </div>
            </DialogCustom>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    divIconAddURL: {
        marginLeft: '16%',
        textAlign: 'left'
    },
    iconBtnAddURL: {
        padding: 0
    },
    iconAddURL: {
        fill: theme.props.primaryColor,
        transform: 'scale(1.5)',
    },
    listItems: {
        marginTop: 20,
    },
    item: {
        width: "100%",
        padding: 20
    },
    button: {
        background: theme.props.primaryColor,
        color: "#fff",
        fontWeight: 700,
        "&:hover": {
            background: theme.props.primaryColor,
            opacity: 0.7,
            color: "#fff",
        }
    }
}))

export default AddProjectUrl;
