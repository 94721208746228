import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';
import TextInputCustom from '../../Custom/TextInputCustom';
import {CALENDAR_COLLECTION} from '../../../config/constants';
import {getGlobal, setGlobal, useGlobal} from 'reactn';
import {Popconfirm} from 'antd';
import useIntlId from '../../../hooks/useIntlId';
import TextInputURLCustom from '../../Custom/TextInputURLCustom';
import {db} from '../../../firebaseConfig';
import AddProjectUrl from './AddUrl';
import DeleteButton from '../../Dialog/DeleteButton';
import EditButton from '../../Dialog/EditButton';
import {v4 as uuidv4} from "uuid";
import moment from "moment";
import store from "store";
import {gerenateCategories, gerenatePriorities} from "../../TaskListApp/AddProjectPopup/constant";
import {
    getAllUsersProjectsDocRef,
    getCalendarProjectsDocRef,
    getEventCalendarCollRef,
    getScheduleAdjustmentRef
} from "../../../common/firebaseRef/meetings";
import {remove} from "lodash";
import {msgError, msgSuccess} from "../../../utils/msg"
import {ggTokenChecked} from '../../Calendar/google/auth'

const linkItems = [{
    name: "Slack",
    logo: "/icons/slackIcon.svg",
    id: "slack"
}, {
    name: "Trello",
    logo: "/icons/trelloIcon.svg",
    id: "trello"
}
]

function ProjectInfo({project, setEdit, setProject, totalProjects}) {
    const classes = useStyles()
    const [user] = useGlobal("user")
    const [loading, setLoading] = useState(false)
    const [otherURL, setOtherURL] = useState([])
    const [askDelete, yes, no, nameIntl] = useIntlId([
        'settings.askDelete',
        'settings.yes',
        'settings.no',
        'app.profile.name'
    ])
    const [colorIntl, deletedIntl] = useIntlId(['view.color', 'common.deleted'])
    //const [calendarUser] = useGlobal("calendarUser")
    //const [allProject] = useGlobal("allProject")
    const [googleCalendarList] = useGlobal('googleCalendarList')
    //const [projectsId, setProjectId] = useState('')
    // useEffect(() => {
    //     if (!allProject.length || !project) return null;
    //     let idx = findIndex(allProject, p => p?.id === project?.id)
    //     if (idx === -1) {
    //         setProjectId(allProject[0]?.id)
    //     }
    //
    //     // eslint-disable-next-line
    // }, [allProject])
    //
    //
    // useEffect(() => {
    //     async function updateTimeExpUrl() {
    //         if (!allProject.length || !project) return null;
    //         const batch = db.batch()
    //         if (project?.id === calendarUser?.timeExpUrl?.project && loading) {
    //             const calendarDocRef = getCalendarUserRef(user?.user_id)
    //             const timeExpUrl = calendarUser?.timeExpUrl
    //             const _timeExpUrl = {
    //                 ...timeExpUrl,
    //                 project: projectsId || ''
    //             }
    //             batch.set(calendarDocRef, {timeExpUrl: _timeExpUrl}, {merge: true})
    //         }
    //         await batch.commit()
    //
    //     }
    //
    //     updateTimeExpUrl().then();
    //
    //     // eslint-disable-next-line
    // }, [projectsId])

    useEffect(() => {
        if (project?.otherURL) {
            setOtherURL([...project.otherURL])
        } else {
            setOtherURL(linkItems)
        }

        // eslint-disable-next-line
    }, [project])

    function cancel(e) {
        setLoading(false)
    }

    const onDeleteProjects = async () => {
        setLoading(true)
        if (!project?.id) return;
        try {
            const batch = db.batch()
            if (!project?.is_google) {
                const projectsRef = getCalendarProjectsDocRef(user?.user_id, project?.id)
                const eventVoteBoxMeeting = await getEventCalendarCollRef(user?.user_id).where('project', '==', project?.id).get()
                eventVoteBoxMeeting.forEach(doc => {
                    const eventVoteBox = getScheduleAdjustmentRef(user?.user_id, doc.id)
                    batch.set(eventVoteBox, {isDeleted: true}, {merge: true})
                })
                batch.set(projectsRef, {is_deleted: true}, {merge: true})
                await batch.commit()
            }
            // add project default
            if (totalProjects?.length === 1) {
                let _project
                const projectName = 'Project Default'
                const projectColor = '#e91e63'
                const uid = 'pro_' + uuidv4()
                const categories = gerenateCategories(uid)
                const priorities = gerenatePriorities(uid)
                const time = moment().utc().format()
                _project = {
                    id: uid,
                    uuid: uid,
                    name: projectName,
                    ja_name: "",
                    color: projectColor,
                    author: user.email,
                    selected: true,
                    categories,
                    priorities,
                    is_deleted: false,
                    isDefault: true,
                    created_at: time,
                    updated_at: time,
                    is_sync: false
                }
                await db.collection(CALENDAR_COLLECTION)
                    .doc(user.user_id)
                    .collection('projects')
                    .doc(_project.id)
                    .set(_project)
            }
            //delete project sync gg
            if (project?.is_google && totalProjects?.length !== 1) {
                const projectsRef = getAllUsersProjectsDocRef(user?.user_id, project?.id)
                const eventVoteProjectGG = await getEventCalendarCollRef(user?.user_id).where('project', '==', project?.id).get()
                eventVoteProjectGG.forEach(doc => {
                    const eventVoteBox = getScheduleAdjustmentRef(user?.user_id, doc.id)
                    batch.set(eventVoteBox, {isDeleted: true}, {merge: true})
                })
                batch.delete(projectsRef)
                await batch.commit()
                await ggTokenChecked()
                const request = window.gapi.client.calendar.calendarList.delete({
                    calendarId: project.id
                })
                const result = await request.then()
                if (result.status !== 204) {
                    // error
                    msgError('delete fail')
                    return
                }
            }
            //remove local project
            const {googleCalendar} = getGlobal()
            remove(googleCalendar, {id: project.id})
            setGlobal({
                googleCalendarList: [...googleCalendarList]
            })
            store.set("GGList", [...googleCalendarList])
            msgSuccess(`${deletedIntl}`)
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            setProject({})
            setEdit(false)
        }
    }

    return (
        <div className={classes.root}>
            <div className={`${classes.projectColor} clf-flex-center`}
                 style={{backgroundColor: `${project.color || project.backgroundColor}`}}>
                <div className={classes.wrapText}>
                    {project.name || project.summary}
                </div>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <TextInputCustom
                        name={nameIntl}
                        contentName={<div className={classes.wrapText}>{project.name}</div>}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextInputCustom name={colorIntl} contentName={project.color} isColor={true}/>
                </Grid>
            </Grid>
            <div className={classes.listItems}>
                <div>
                    {
                        otherURL.map((item, index) => {
                            return (
                                <Grid container key={index} className={classes.divGrid}>
                                    <TextInputURLCustom
                                        contentName={` ${project[item.id] ? project[item.id] : ''}`}
                                        urlImg={item.logo}
                                        isURL={true}
                                        item={item}
                                        project={project}
                                        setProject={setProject}
                                        otherURL={otherURL}
                                        setOtherURL={setOtherURL}
                                        isEditComponent={false}
                                        titleMaxWidth={110}
                                    />
                                </Grid>
                            )
                        })
                    }
                </div>

            </div>
            <AddProjectUrl project={project} setProject={setProject}/>
            <div className={`${classes.action} clf-flex-center`}>
                <EditButton
                    onClick={() => setEdit(true)}
                />
                {
                    !project.isDefault &&
                    <Popconfirm
                        title={askDelete}
                        onConfirm={onDeleteProjects}
                        onCancel={cancel}
                        okText={yes}
                        cancelText={no}
                        overlayClassName={classes.confirmStyle}
                    >
                        <DeleteButton
                            loading={loading}
                        />
                    </Popconfirm>
                }
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: '#FFF',
        marginTop: 40,
        padding: '30px 10px',
        borderRadius: 20,
        position: 'relative'
    },
    content: {
        textAlign: "center",
        fontSize: 14
    },
    divGrid: {
        marginBottom: 15
    },
    listItems: {
        marginTop: 20,
    },
    title: {
        fontWeight: "bold"
    },
    action: {
        marginTop: 20
    },
    itemHeader: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        width: 30,
        height: 30,
        marginRight: 5
    },
    textField: {
        marginLeft: 10
    },
    colorCircle: {
        width: 25,
        minWidth: 25,
        height: 25,
        borderRadius: '50%',
        padding: 0,
        border: '1px solid #D5D5D5',
        margin: "auto"
    },
    projectColor: {
        borderRadius: 24,
        maxWidth: 150,
        minHeight: 33,
        color: '#FFF',
        border: 'none',
        width: "calc(100% - 10px)",
        position: 'absolute',
        top: -15,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    wrapText: {
        width: '95%',
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: '1.2rem',
        fontWeight: 'bold',
        whiteSpace: "nowrap",
    },
    saveIcon: {
        fill: theme.props.primaryColor,
        marginLeft: -45,
        marginRight: 10,
        marginTop: 1
    },
    deleteIcon: {
        fill: theme.props.primaryColor,
        marginLeft: -25,
        marginRight: 10,
        marginTop: 1
    },
    confirmStyle: {
        zIndex: '9999 !important'
    },
    primaryColor: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            marginTop: 1
        }
    },
    secondaryColor: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
            marginTop: 1
        }
    }
}))

export default ProjectInfo;
