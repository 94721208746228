import React from 'react';
import {Typography} from "@material-ui/core";
import PackagesInfomations from "./PackagesInfomations";
import Coupon from "./Coupon";

function ContentsExpDate(props) {
    const {classes} = props
    return (
        <div>
            <div className={classes.project}>
                <h1
                    className={classes.title}
                    style={{
                        // backgroundColor: "blue",
                    }}
                >
                    Calendar
                </h1>
            </div>
            <div className={classes.divBody}>
                <Typography className={classes.typoStyle}>
                    Notifications Error: Your account has expired to use this app
                </Typography>
                <Typography>
                    Choose to buy additional packages to be used more services
                </Typography>
            </div>
            <div className={classes.divCouponStyle}>
                <Coupon/>
            </div>
            <PackagesInfomations/>
        </div>

    );
}

export default ContentsExpDate;
