import React, {createContext, useContext, useEffect, useState} from 'react';
import {setGlobal, useGlobal} from 'reactn';
import moment from "moment";
import qs from "query-string";
import {isOffDay,} from "../components/Meeting/Home/utils";
import {concat, remove} from "lodash";
import {updateMyFreeTime,} from "../components/Meeting/Home/actions";
import {eventAdjustmentDoc, eventSuccessRef, myFeetimeRef} from "../common/firebaseRef/meetings";
import {freeSlot} from "../components/Meeting/Fullcalendar/Actions/freeSlot";
import useIntlId from "../hooks/useIntlId";
import {useParams} from "react-router-dom";
import {createDefaultMyFreeTime} from "../common/calendarInfo";
import calendarView from "../components/Meeting/common/calendarView";
import {useCalendarEvents} from "./CalendarEventsContext";
import isEnoughTime from "../common/isEnoughTime";
import adjustmentFreetime from "../common/adjustmentFreetime";
import {autoFreeTimes} from "./common/autoFreeTimes";

export const MyFreeTimeContext = createContext({})

export const useMyFreeTime = () => {
    return useContext(MyFreeTimeContext)
}

export const MyFreeTimeContextProvider = ({children}) => {
    const value = useMyFreeTimes()
    return (
        <MyFreeTimeContext.Provider value={value}>
            {children}
        </MyFreeTimeContext.Provider>
    )
}

const useMyFreeTimes = () => {
    const [user] = useGlobal('user');
    const query = qs.parse(window.location.search);
    const language = user.language;
    const {
        totalEvents,
    } = useCalendarEvents()
    const [expressBoxMsg, expressTitleMsg, expressPlaceMsg, scheduleIdNotExists] = useIntlId(['event.express.box', 'event.express.title', 'event.express.place', 'alert.scheduleIdNotExists']);
    const [myFreeTime, setMyFreeTime] = useState(null);
    const [loadingData, setLoadingData] = useState(false)
    const [editFreeTime] = useGlobal("editShare")
    const [taskFree,] = useGlobal("taskFree")
    const [myFreeSlot, setMyFreeSlot] = useState([])
    const [shareUrlHistory, setShareUrlHistory] = useState([])
    const [voteUrl, setVoteUrl] = useState([])
    const [calendarTime, setCalendarTime] = useState(null);
    const [changeVote, setChangeVoteEvent] = useState(false)
    const [changeHourCurrent, setChangeHourCurrent] = useState(0)
    // const [projectLoading, setProjectLoading] = useState(false);
    const params = useParams() // no delete

    useEffect(() => {
        const runInterval = setInterval(function () {
            setChangeHourCurrent(changeHourCurrent + 1)
        }, 30 * 60 * 1000);
        return () => clearInterval(runInterval);
        // eslint-disable-next-line
    }, [changeHourCurrent])

    useEffect(() => {
        if (!myFreeSlot?.length || changeHourCurrent === 0) return null
        const autoMyFreeSlot = autoFreeTimes(myFreeSlot, myFreeTime?.timeVote)
        setMyFreeSlot(autoMyFreeSlot)
        // eslint-disable-next-line
    }, [changeHourCurrent])

    const viewBox = {
        [language]: [
            {label: expressBoxMsg, checked: true},
            {label: expressTitleMsg, checked: true},
            {label: expressPlaceMsg, checked: true}
        ],
    }

    useEffect(() => {
        if (!user?.user_id)
            return;
        console.log("change share...", params)
        setVoteUrl([])
        const userID = user.user_id;
        let subEventSuccess
        let subAdjustment
        let subMyShareSetting
        let subMyShareSetting2
        let subTaskFree
        if (window.location.pathname !== "/shareUrl") {
            const settingShare = myFeetimeRef(userID);
            subMyShareSetting2 = settingShare.onSnapshot(snapshot => {
                if (snapshot.exists) {
                    const data = {...snapshot.data(), time_zone: user.time_zone};
                    setMyFreeTime(data)
                } else {
                    createDefaultMyFreeTime(userID, viewBox[language])
                }
            })
            setVoteUrl([])
            setMyFreeTime(null)
            setShareUrlHistory([])

            setLoadingData(false)
            return
        }
        if (window.location.pathname === "/shareUrl") {
            setLoadingData(true)
            const evtSuccessRef = eventSuccessRef(userID)
            subEventSuccess = evtSuccessRef
                .onSnapshot(snap => {
                    let evtUrlSchedules = []
                    let evtUrlVoteBox = []
                    snap.docs.forEach(doc => {
                        let data = doc.data()
                        if (!data.isSync && data.scheduleId && data.scheduleId !== query?.idShare) // vote nhung k thuoc vao idShare
                            evtUrlSchedules.push({...data, id: doc.id})
                        else if (data.scheduleId && data.scheduleId === query?.idShare) // vote mau cam
                            evtUrlVoteBox.push({
                                ...data,
                                backgroundColor: "#F89320FF"
                            })
                        else if (!query?.idShare && data?.scheduleId) // vote mau cam
                            evtUrlSchedules.push({
                                ...data,
                                id: doc.id
                            })
                    })
                    setChangeVoteEvent(!changeVote)
                    setVoteUrl(evtUrlVoteBox)
                    setShareUrlHistory(evtUrlSchedules)
                    setLoadingData(false)
                })
        }

        if (window.location.pathname === "/shareUrl" && !query?.idShare) {
            const settingShare = myFeetimeRef(userID);
            subMyShareSetting = settingShare.onSnapshot(snapshot => {
                if (snapshot.data()) {
                    const data = {...snapshot.data(), time_zone: user?.time_zone};
                    setMyFreeTime(data);
                    if (!data?.workTime) return;
                    let {start, end} = calendarView(data);
                    setGlobal({
                        dateSelections: data?.dateSelections || [],
                        meetingTimes: {
                            maxTime: {
                                value: data?.workTime?.end,
                                label: `${(data?.workTime?.end < 10) ? "0" + data?.workTime?.end + ":00" : data?.workTime?.end + ":00"}`,
                            },
                            minTime: {
                                value: data?.workTime?.start,
                                label: `${(data?.workTime?.start < 10) ? "0" + data?.workTime?.start + ":00" : data?.workTime?.start + ":00"}`,
                            },
                            durationDay: moment(end).diff(start, "days")
                        }
                    }).then()
                } else {
                    createDefaultMyFreeTime(userID, viewBox[language])
                }
            })
        }

        if (window.location.pathname === "/shareUrl" && query?.idShare) {
            const adjustmentDoc = eventAdjustmentDoc(userID, query.idShare);
            subAdjustment = adjustmentDoc.onSnapshot(snapshot => {
                if (!snapshot.exists) {
                    alert(scheduleIdNotExists)
                    window.location.assign(`/shareUrl`)
                    return;
                }
                if (snapshot?.exists) {
                    let data = {
                        ...snapshot.data()?.user,
                        nameSetting: snapshot?.data()?.nameSetting || '',
                        time_zone: user?.time_zone
                    }
                    setMyFreeTime(data)
                    if (!data?.workTime) return;
                    let {start, end} = calendarView(data);
                    setGlobal({
                        dateSelections: data?.dateSelections || [],
                        meetingTimes: {
                            maxTime: {
                                value: data?.workTime?.end || 24,
                                label: `${(data?.workTime?.end < 10) ? "0" + data?.workTime?.end + ":00" : data?.workTime?.end + ":00"}`,
                            },
                            minTime: {
                                value: data?.workTime?.start || 0,
                                label: `${(data?.workTime?.start < 10) ? "0" + data?.workTime?.start + ":00" : data?.workTime?.start + ":00"}`,
                            },
                            durationDay: moment(end).diff(start, "days")
                        },
                    }).then()
                }
            })
        }


        return () => {
            if (subEventSuccess) {
                subEventSuccess()
            }
            if (subAdjustment) {
                subAdjustment()
            }
            if (subMyShareSetting) {
                subMyShareSetting()
            }
            if (subMyShareSetting2) {
                subMyShareSetting2()
            }
            if (subTaskFree) {
                subTaskFree()
            }
        }
        // eslint-disable-next-line
    }, [query?.idShare, user?.user_id, user?.time_zone])

    const specialDay = (days, e) => {
        return (days.includes(moment(e.start).format('YYYY-MM-DD')))
    }


    useEffect(() => {
        if (!myFreeTime)
            return;
        // myEvents = checkUserTaskFree(myEvents, taskFree);
        const hiddenDays = myFreeTime.hiddenDays || []
        const dateSelections = myFreeTime.dateSelections || []
        if (myFreeTime.typeTimeSharing === "normal")
            return updateMyFreeTime('typeTimeSharing', 'auto')
        const boxFreeShare = myFreeTime?.exBoxFreeShare || []
        const setting = calendarView(myFreeTime);
        let events = [...totalEvents]
        if (query?.idShare)
            events = [...totalEvents].filter(event => event && event?.scheduleId !== query.idShare)
        const eventsBusy = concat(adjustmentFreetime([...events], myFreeTime.freeTime), voteUrl, myFreeTime.busyBox || [])
        const freeTimes = freeSlot(setting?.start, setting?.end, eventsBusy, "#00CC66", myFreeTime || null, boxFreeShare).map(evt => ({
            ...evt,
            editable: false
        }))
        remove(freeTimes, e => !e || isOffDay(hiddenDays, e) || specialDay(dateSelections, e) || !isEnoughTime(e, myFreeTime?.timeVote))
        setMyFreeSlot(freeTimes)
        setCalendarTime(setting)
        setLoadingData(false)
        // eslint-disable-next-line
    }, [query?.idShare, totalEvents, changeHourCurrent, shareUrlHistory, voteUrl, myFreeTime, editFreeTime, taskFree])

    return {
        voteUrl,
        myFreeTime,
        setMyFreeTime,
        myFreeSlot,
        shareUrlHistory,
        calendarTime,
        loading: loadingData,
        setLoading: setLoadingData,
        taskFree,
    }
}
