import React, {useEffect, useState} from 'react';
import {getGlobal, useGlobal} from 'reactn'
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {handleConnectZoomOAuth} from "../../actions/zoom";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import {IconButton} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {toast} from "react-toastify";
import axios from "axios"
import {API_ZOOM} from "../../config/constants";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {findIndex} from 'lodash'
import {Button} from 'antd';
import useIntlId from "../../hooks/useIntlId";

export const handleGetValue = (event, callBack) => {
    if (!event)
        return null;
    if ((event?.is_google || event?.isGoogle || event?.googleEvent) && event?.conferenceData) {
        if (event.conferenceData.conferenceId) {
            let entryData = event.conferenceData.entryPoints
            let idx = findIndex(entryData, e => e.entryPointType === 'video')
            if (idx !== -1) {
                if (callBack)
                    callBack(entryData[idx].uri)
                return entryData[idx].uri
            }
        } else if (event?.conferenceData?.join_url) {
            if (callBack)
                callBack(event.conferenceData.join_url)
            return event.conferenceData.join_url
        } else {
            if (callBack)
                callBack(event?.linkZoom)
            return event?.linkZoom
        }
    } else {
        if (event?.conferenceData?.conferenceId) {
            let entryData = event.conferenceData.entryPoints
            let idx = findIndex(entryData, e => e.entryPointType === 'video')
            if (idx !== -1) {
                if (callBack)
                    callBack(entryData[idx].uri)
                return entryData[idx].uri
            }
        }
        if (callBack)
            callBack(event?.linkZoom || event?.conferenceData?.join_url)
        return event?.linkZoom || event?.conferenceData?.join_url
    }

}

const copy = require('clipboard-copy');

function ZoomButton(props) {
    const {classes, event, setEvent, onUpdate = null} = props;
    const [zoomOAuth] = useGlobal('zoomOAuth');
    const [loading, setLoading] = useState(false);
    const [linkZoom, setLinkZoom] = useState(null);
    const [shareLinkCopied] = useIntlId(['event.toast.shareLinkCopy'])

    useEffect(() => {
        handleGetValue(event, setLinkZoom)
    }, [event])

    const handleCopy = async () => {
        try {
            if (!linkZoom)
                return null;
            const link = linkZoom || '';
            await copy(link)
            toast.success(`${shareLinkCopied}`)
        } catch (e) {
            console.log(e.toString())
        }
    };

    const handleCreateZoomMeeting = async () => {
        const {user} = getGlobal();
        try {
            setLoading(true);
            const {data} = await axios.post(API_ZOOM + '/create', {
                type: 'meetings',
                timeStart: moment(event.start).format(),
                timeEnd: moment(event.end).format(),
                schedule: {
                    ...event,
                    service_name: 'Calendar Meetings',
                    title: event.title,
                },
                timeZone: user?.time_zone || 'Asia/Tokyo'
            });
            if (data) {
                event.zoomMeetingId = data?.id || '';
                event.conferenceData = data;
                setEvent({...event});
                if (onUpdate && typeof onUpdate === 'function')
                    onUpdate(event, true)
            }
        } catch (e) {
            toast.error(e?.response?.data || "Create zoom event error! Please try again")
            console.log(e?.response?.data || "Create zoom event error! Please try again");
        } finally {
            setLoading(false)
        }
    };

    const handleDeleteZoomMeeting = async () => {
        try {
            let zoomId = event?.zoomMeetingId || '';
            event.linkZoom = '';
            event.zoomMeetingId = '';
            event.conferenceData = '';
            setEvent({...event});
            if (onUpdate && typeof onUpdate === 'function')
                onUpdate(event, true);

            if (zoomId) {
                axios.post(API_ZOOM + '/delete', {
                    type: 'meetings',
                    meetingId: zoomId,
                }).catch(e => console.log(e))
            }
        } catch (e) {
            console.log(e?.response?.data || "Delete zoom event error! Please try again");
        }
    };

    const handleOpenZoomMeeting = () => {
        window.open(linkZoom)
    };

    if (event && !event.is_holiday && !moment(event.end).isBefore(moment()) && !event.isMileStone)
        return (
            <div className={classes.zoomMeeting}>
                {
                    event?.conferenceData || event?.linkZoom ?
                        <TextField
                            className={classes.copyField}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            <Tooltip title={'Share this Zoom Meeting'}>
                                                <IconButton onClick={handleCopy}>
                                                    <FileCopyIcon/></IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                        <InputAdornment position="start">
                                            <Tooltip title={'Join Zoom meeting'}>
                                                <VideocamOutlinedIcon
                                                    className={classes.zoomJoin}
                                                    onClick={handleOpenZoomMeeting}
                                                    fontSize={'large'}
                                                />
                                            </Tooltip>
                                        </InputAdornment>
                                        <InputAdornment position="start">
                                            <Tooltip title={'Remove Zoom Meeting'}>
                                                <DeleteOutlineIcon
                                                    className={classes.zoomJoin}
                                                    onClick={handleDeleteZoomMeeting}
                                                    fontSize={'large'}
                                                />
                                            </Tooltip>
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                            label={'Share this Zoom Meeting'}
                            InputLabelProps={{shrink: true}}
                            value={linkZoom ? linkZoom : ''}
                            // disabled={disabled}
                        />
                        :
                        Boolean(zoomOAuth?.zoomOAuth) ?
                            loading ? <CircularProgress size={25}/>
                                :
                                <>
                                    {/*<VideocamOutlinedIcon className={classes.iconZoom} fontSize={'large'}/>*/}
                                    <IconButton className={classes.iconZoom}>
                                        <img src={process.env.PUBLIC_URL + '/icons/zoom.svg'} alt=""/>
                                    </IconButton>
                                    <Button
                                        type="primary"
                                        shape="round"
                                        onClick={handleCreateZoomMeeting}
                                        disabled={!Boolean(event?.title)}
                                    >
                                        Add Zoom Meeting
                                    </Button>
                                </>
                            :
                            <>
                                <IconButton className={classes.iconZoom}>
                                    <img src={process.env.PUBLIC_URL + '/icons/zoom.svg'} alt=""/>
                                </IconButton>
                                <Button
                                    shape="round"
                                    type="primary"
                                    onClick={handleConnectZoomOAuth}
                                >
                                    Login Zoom To Create Meeting
                                </Button>
                            </>
                }

            </div>
        );
    else
        return null;
}

export default ZoomButton;
