import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableAppDetails from "./TableAppDetails";
import TableServiceDetails from "./TableServiceDetails";
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import NfcOutlinedIcon from '@material-ui/icons/NfcOutlined';
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        marginLeft: theme.spacing(3),
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    tooltip: {
        fontWeight: "bold",
        fontSize: "12px"
    },
    flex: {
        display: "flex"
    },
    marginRight10: {
        marginRight: 10
    }
}));

function ServicesAppExpansion(props) {
    const classes = useStyles();
    const {cart} = props
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            {
                cart.appList && cart.appList.length !== 0 &&
                <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Tooltip className={classes.tooltip} title={'Expanded to view more App information will used'}>
                            <div className={classes.flex}>
                                <AppsOutlinedIcon className={classes.marginRight10}/>
                                <Typography className={classes.heading}>App details</Typography>
                            </div>
                        </Tooltip>
                        <Typography className={classes.secondaryHeading}>
                            Information app details for package
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <TableAppDetails cartPackage={cart}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            }
            {
                cart.addedServiceList && cart.addedServiceList.length !== 0 &&
                <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Tooltip className={classes.tooltip} title={'Expanded to view more App information will used'}>
                            <div className={classes.flex}>
                                <NfcOutlinedIcon className={classes.marginRight10}/>
                                <Typography className={classes.heading}>Services details</Typography>
                            </div>
                        </Tooltip>
                        <Typography className={classes.secondaryHeading}>
                            Information services details for package
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <TableServiceDetails cartPackage={cart}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            }
        </div>
    );
}

export default ServicesAppExpansion;
