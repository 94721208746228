import React, {createContext, useContext, useEffect, useState} from "react";
import {useGlobal} from "reactn";
import {barDefault} from "../common/initAnnoucement";
import {barRef} from "../common/firebaseRef";
import axios from "axios";
import {API_GENIAM} from "../config/constants";
import getCalendarPlan, {plans} from "../common/getCalendarPlan";
import {uniq} from "lodash";
import {getHxPlan} from "../common/hxPlan/getHxPlan";

const AnnouncementContext = createContext({})

export const useAnnouncement = () => {
    return useContext(AnnouncementContext)
}

export const AnnouncementProvider = ({children}) => {
    const value = useAnnouncementData()
    return (
        <AnnouncementContext.Provider value={value}>
            {children}
        </AnnouncementContext.Provider>
    )
}

const useAnnouncementData = () => {
    const [user] = useGlobal('user')
    const [bar, setBar] = useState(barDefault)
    const [lock, setLock] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [plan, setPlan] = useState(plans[0])
    const [data, setData] = useState([])

    const getSubs = async () => {
        try {
            const {data} = await axios.get(`${API_GENIAM}/subscriptions/list`)
            if (data?.data?.length) {
                setLock(false)
                const prices = uniq(data.data.map(item => item?.plan?.id || '').filter(x => x))
                setData(data.data)
                const {type} = getHxPlan(data.data)
                if (type === 'year') {
                    setPlan({...plans[3], hxPlan: true, type})
                    return;
                }
                setPlan(getCalendarPlan(prices))
            } else {
                setLock(true)
                setPlan(getCalendarPlan([]))
            }
        } catch (e) {
            console.log(e);
            setLock(true)
            setPlan(getCalendarPlan([]))
        } finally {
            setLoaded(true)
        }
    }


    useEffect(() => {
        if (!user.user_id) return
        getSubs()
        const barSub = barRef(user.user_id)
            .onSnapshot(snapshot => {
                if (!snapshot.exists) return
                const barData = snapshot.data()
                if (!barData.highlight) {
                    barData.highlight = barDefault.highlight
                }
                if (barData.geniamNews === undefined) {
                    barData.geniamNews = true
                }
                setBar(barData)
            })

        return () => {
            if (barSub) barSub()
        }
    }, [user?.user_id])
    return {data, loaded, lock, bar, plan}
}
