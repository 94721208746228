import {concat, filter, orderBy, remove} from 'lodash'
import {getGlobal} from 'reactn'
import {v4 as uuidv4} from 'uuid'
import {zoneObj} from "../../../../common/moment/format";
import moment from "moment";
import {getBusyFromWorkTime} from "../../Home/utils";

export const freeSlot = (start, end, events, color = '#009A4C', user, boxFree = [], isSchedule = false) => {
    let eventsBusy = [...events]
    if (!start || !end) return []
    if (!eventsBusy?.length) {
        const now = moment().format()
        const minutes = moment().minutes()
        const result = inDayEvent({
            backgroundColor: color,
            freeSlot: true,
            start: roundDateTimeSTART(minutes, now),
            end: zoneObj(end).format()
        })
        return result
    }
    const {workTime} = user
    if (workTime?.start > 0 || workTime?.end < 24) {
        const eventsWorkTime = getBusyFromWorkTime(start, end, workTime, user?.time_zone)
        eventsBusy = concat(eventsBusy, eventsWorkTime)
        // console.log("eventsBusy: ", eventsBusy.filter(e => moment(e.start).format("MM-DD") === "11-14"))
        // console.log("user?.time_zone: ", user?.time_zone);
    }
    let startDate = zoneObj(start).startOf("day").format()
    if (zoneObj().isAfter(startDate)) {
        let minutes = zoneObj().minutes()
        if (minutes % 30 === 0) {
            startDate = zoneObj().format()
        } else {
            startDate = roundDateTimeSTART(minutes,);
        }
    }
    let endDate = end
    let freeSlots = []
    let result = []
    let onTimeEvent = filter(eventsBusy, e => zoneObj(e?.start).isBetween(startDate, endDate, null, "[]") ||
        zoneObj(e?.end).isBetween(startDate, endDate, null, "[]")
    )
    let orderEvents = orderBy(onTimeEvent, (a) => zoneObj(a?.start).format(), 'asc')
    remove(orderEvents, evt => Boolean(evt?.allDay || evt?.isDeleted))

    const itemFreeSlot = {
        backgroundColor: color,
        freeSlot: true,
    };

    if (orderEvents.length === 0) {
        freeSlots = [{
            ...itemFreeSlot,
            id: `free_${uuidv4()}`,
            start: zoneObj(startDate).format(),
            end: zoneObj(endDate).format(),
        }]
    } else {
        let endLast = -1;
        orderEvents.forEach(function (event, index) {
            const evtCheck = checkIdInBoxFree(event?.id, boxFree);
            let endFreeBox = zoneObj(event?.start).format()
            if (event?.isTaskFree || (evtCheck && !evtCheck?.deleted)) {
                if (index !== orderEvents?.length - 1)
                    endFreeBox = zoneObj(orderEvents[index + 1]?.start).format()
                else endFreeBox = zoneObj(event?.end).format()
                endLast = index;
            }
            if (endLast !== index) {
                freeSlots.push({
                    ...itemFreeSlot,
                    id: `free_${uuidv4()}`,
                    start: zoneObj(startDate).format(),
                    end: endFreeBox,
                });
                if (zoneObj(startDate).isBefore(event?.end))
                    startDate = zoneObj(event?.end).format();
            }
        });
        if (zoneObj(startDate).isBefore(endDate)) {
            freeSlots.push({
                ...itemFreeSlot,
                id: `free_${uuidv4()}`,
                start: zoneObj(startDate).format(),
                end: zoneObj(endDate).format(),
            });
        }
    }
    freeSlots.forEach(free => {
        if (zoneObj(free?.start).isBefore(free?.end)) {
            // valid range
            result = concat(result, inDayEvent(free, isSchedule))
        }
    })

    return validStartFreeTime(adjustableMinMaxTime(result, workTime?.start, workTime?.end))
}

export const inDayEvent = (free, isLocal = false) => {
    let dayStart = zoneObj(free?.start, isLocal).format("YYYY-MM-DD");
    let dayEnd = zoneObj(free?.end, isLocal).format("YYYY-MM-DD");
    if (moment(dayEnd).isBefore(dayStart))
        return []
    if (dayStart === dayEnd)
        return [free]
    let result = []
    let start = free?.start
    while (zoneObj(start, isLocal).format("YYYY-MM-DD") !== dayEnd) {
        result.push({
            ...free,
            start,
            end: zoneObj(start, isLocal).add(1, "day").startOf("day").format(),
            id: `free_${uuidv4()}`,
        })
        start = zoneObj(start, isLocal).add(1, "day").startOf("day").format()
    }
    result.push({...free, start, id: `free_${uuidv4()}`,})
    return result
}
const checkHourEnd = (hourEnd, end) => {
    if (hourEnd === zoneObj(end).hours()) return 0;
    return zoneObj(end).minutes();
}
export const adjustableMinMaxTime = (events, start = 7, end = 24) => {
    let result = []
    events.forEach(event => {
        let startTime = zoneObj(event.start).hours() >= start ? event.start : zoneObj(event.start).hours(start).minutes(0).format()
        let endHour = zoneObj(event.end).hours() === 0 ? 24 : zoneObj(event.end).hours()
        let endTime = endHour < end ? event.end : zoneObj(event.end).subtract(endHour === 24 ? 1 : 0, "day").set({
            hour: end,
            minute: checkHourEnd(end, event.end)
        }).format()
        if (zoneObj(startTime).isBefore(endTime)) {
            result.push({...event, start: startTime, end: endTime})
        }
    })
    return result
}

export const isFreeSelect = (freeSlots = [], event) => {
    let result = false
    freeSlots.forEach(free => {
        if (zoneObj(event.start).isSameOrAfter(free.start) && zoneObj(event.end).isSameOrBefore(free.end)) {
            result = true
        }
    })
    return result
}

export const isConflictMoveTime = (event) => {
    // user move vote has another.
    const {user} = getGlobal()
    let result = false
    const {voteInfo} = event.extendedProps
    if (!voteInfo || voteInfo.length === 0) return result
    voteInfo.forEach(usr => {
        if (usr.voted && (usr.userId !== user.user_id)) {
            result = true
        }
    })
    return result
}

export const checkedUserEventTime = (eventsVote, freeTime) => {
    let result = false
    let events = []
    eventsVote.forEach(ev => {
        if (!isFreeSelect(freeTime, ev)) {
            events.push({...ev})
            result = true
        }
    })
    return {result, events}
}
export const checkIdInBoxFree = (id, boxFree = []) => {
    if (!id || !boxFree.length) return false;
    for (let i = 0; i < boxFree.length; i++) {
        if (id === boxFree[i]?.checkFreeID && !boxFree[i]?.deleted) return boxFree[i]
    }
    return false
}
// check again end of meeting
const validStartFreeTime = (times = []) => {
    return times.map((time, index) => {
        if (time?.checkFreeID) return time
        let start = time.start
        let end = time.end
        if (zoneObj(start).minutes() % 30 !== 0) {
            start = roundDateTimeSTART(zoneObj(time.start).minutes(), time.start);
        }
        if (zoneObj(end).minutes() % 30 !== 0) {
            end = roundDateTimeEND(zoneObj(time.end).minutes(), time.end)
        }
        return {
            ...time,
            start: start,
            end: end,
        }
    })
}
export const roundDateTimeSTART = (min, START) => {
    return zoneObj(START).set({
        minutes: (min < 30) ? 30 : 60,
        seconds: 0
    }).format()
}

export const roundDateTimeEND = (min, END) => {
    return zoneObj(END).set({
        minutes: (min < 30) ? 0 : 30,
        seconds: 0
    }).format()
}
//
// export const onSaveChecked = async (meeting, users, checkedUser, setCheckedUser) => {
//     if (!checkedUser)
//         return null
//     const {apiCalendar} = getGlobal()
//     await apiCalendar.unselect()
//     try {
//         const meetingRef = db.doc(`${MEETING_COLLECTION}/${meeting.id}/users/${checkedUser.user_id}`)
//
//         const update = await meetingRef.set({
//             isChecked: checkedUser.isChecked
//         }, {merge: true})
//
//         setCheckedUser(null)
//         return update
//     } catch (e) {
//         console.log(e.toString())
//     } finally {
//         setCheckedUser(null)
//     }
// }
