import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as CopySVG} from '../../asSvg/copy2.svg';
import useIntlId from '../../hooks/useIntlId';

function SaveButton({copyTime, disable = false, copyZoom, disableZoom = false}) {
    const classes = useStyles()
    const [hoverZoom] = useState(false)
    const [copyIntl] = useIntlId(['app.setting.copyTex'])

    return (
        <div className={classes.root}>
            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    className={`${classes.button} ${hoverZoom ? classes.copyHover : classes.copy} copyButon`}*/}
            {/*    endIcon={<CopySVG/>}*/}
            {/*    onClick={copyTime}*/}
            {/*    disabled={disable}*/}
            {/*>*/}
            {/*    {copyIntl}*/}
            {/*</Button>*/}
            <Button
                variant="contained"
                className={`${classes.button} ${classes.saveHover} saveButon`}
                endIcon={<CopySVG/>}
                onClick={copyTime}
                disabled={disable}
            >
                {copyIntl}
            </Button>
            <Button
                variant="contained"
                className={`${classes.button} ${hoverZoom ? classes.copyHover : classes.copy} copyButon`}
                endIcon={<CopySVG/>}
                onClick={copyZoom}
                disabled={disableZoom}
            >
                zoom
            </Button>
        </div>
    );
}

export default SaveButton;
const useStyles = makeStyles(theme => ({
    // button: {
    //     borderRadius: "25px",
    //     border: "solid 2px #fff",
    //     width: "calc(100% + 25px)",
    //     boxShadow: "none",
    //     marginLeft: "-25px",
    //     color: theme.props.primaryColor,
    //     fontWeight: "bold",
    //     position: "relative",
    //     "& .MuiButton-endIcon": {
    //         position: "absolute",
    //         right: 7,
    //         top: "50%",
    //         transform: "translateY(-50%)"
    //     },
    //     "&:hover": {
    //         boxShadow: "none",
    //         backgroundColor: theme.props.primaryColor,
    //         color: "#fff"
    //     },
    //     "& svg": {
    //         fill: theme.props.primaryColor,
    //     }
    // },
    // save: {
    //     zIndex: 3,
    // },
    // saveHover: {
    //     zIndex: 3,
    //     boxShadow: "none",
    //     backgroundColor: theme.props.primaryColor,
    //     color: "#fff"
    // },
    // copy: {
    //     zIndex: 2,
    // },
    // copyHover: {
    //     zIndex: 2,
    //     boxShadow: "none",
    //     backgroundColor: theme.props.primaryColor,
    //     color: "#fff"
    // },
    // root: {
    //     marginLeft: "25px",
    //     marginBottom: 5
    // },

    button: {
        borderRadius: "25px",
        border: "solid 2px #fff",
        width: "calc(50% + 25px)",
        boxShadow: "none",
        marginLeft: "-25px",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        position: "relative",
        "& .MuiButton-endIcon": {
            position: "absolute",
            right: 7,
            top: "50%",
            transform: "translateY(-50%)"
        },
        "&:hover": {
            boxShadow: "none",
            backgroundColor: theme.props.primaryColor,
            color: "#fff"
        },
        "& svg": {
            fill: theme.props.primaryColor,
        }
    },
    save: {
        zIndex: 3,
    },
    saveHover: {
        zIndex: 3,
        boxShadow: "none",
        backgroundColor: theme.props.primaryColor,
        color: "#fff"
    },
    copy: {
        zIndex: 2,
    },
    copyHover: {
        zIndex: 2,
        boxShadow: "none",
        backgroundColor: theme.props.primaryColor,
        color: "#fff"
    },
    root: {
        marginLeft: "25px",
    },
}))
