import React, {useEffect, useState} from 'react';
import {Typography, withStyles} from '@material-ui/core';
import {useGlobal} from 'reactn';
import {toast} from 'react-toastify';
import {getIcon} from '../Calendar/handlers/getIcon';
import {myCal} from "../../common/myCal";
import MenuItemContainer from './MenuItemContainer';
import {ReactComponent as AddLargeSVG} from '../../asSvg/icons/addLarge.svg';
import IconButton from '@material-ui/core/IconButton';
import SelectIconsDialog from './SelectIcons/SelectIconsDialog';
import {Input, Popover, Spin} from 'antd';
import DialogCustom from '../Custom/DialogCustom';
import {remove} from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import useIntlId from '../../hooks/useIntlId';
import {db} from '../../firebaseConfig';
import {getDocTaskFreeRef} from '../../common/firebaseRef/meetings';
import CancelButton from "../Dialog/CancelButton";
import DeleteButton from "../Dialog/DeleteButton";
import SaveButton from "../Dialog/SaveButton";

function CalendarEventSetting({classes}) {
    const [user] = useGlobal("user")
    const [tasks, setTasks] = useGlobal('tasks')
    const [colors] = useGlobal('colors')
    const [values, setValues] = useState([])
    const [openAddTask, setOpenAddTask] = useGlobal('openAddTask')
    const [loading, setLoading] = useState(false)
    const [loadingTask, setLoadingTask] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [item, setItem] = useState({})
    const [editing, setEditing] = useState(false)
    const [name, setName] = useState("")
    const [taskFree,] = useGlobal("taskFree")
    const [taskOrder, tagEvent, tagFunciton, titleTagsFree, changeNameIntl, removeAuthIntl] = useIntlId(['settings.taskOrder', 'event.tagEvent', 'event.tagFunciton', 'settings.tagsFree', 'settings.tag.changeName', 'settings.tag.removeAuth'])
    const [, setOpenSetting] = useGlobal('openSetting')
    const [toastChangeSuccessIntl, toastDeleteSuccessIntl, nameNullIntl] = useIntlId(['toast.success.change', 'toast.deleteUser', 'event.toast.nameEmpty'])
    useEffect(() => {
        if (!tasks?.data || !tasks?.data.length)
            return
        setValues(tasks?.data)

    }, [tasks, colors])

    const handleTaskFree = async (item) => {
        try {
            setLoadingTask(true)
            const icon = item.file ? item.file.id : item.icon;
            if (taskFree.includes(icon))
                remove(taskFree, t => t === icon)
            else taskFree.push(icon)
            const batch = db.batch()
            const freeTaskRef = getDocTaskFreeRef(user?.user_id)
            batch.set(freeTaskRef, {
                taskFree: [...taskFree]
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e)
        } finally {
            setLoadingTask(false)
        }
    }
    const handleAdd = async ({icon = '', name, type = '', file = null}) => {
        if (loading || !name || !type || (type === "file" && !file) || (type === "icon" && !icon))
            return
        const oldValue = {...tasks}
        setLoading(true)
        try {
            // let index = tasks.data.length
            tasks.data.push({
                // is_active: true,
                // is_default: false,
                icon,
                name,
                // index,
                file,
                type
            })
            await myCal().set({
                tasks: {...tasks}
            }, {merge: true})
        } catch (e) {
            console.log(e);
            toast.error(e.toString())
            setTasks(oldValue)
        } finally {
            setLoading(false)
            setOpenAddTask(false)
            setOpenSetting(false)

        }
    }

    const handleUpdate = async () => {
        if (!name || loading || name === "" || name?.replace(/\s+/g, '') === "") {
            setEditing(false)
            setItem({})
            setName("")
            toast.error(`${nameNullIntl}`)
            return
        }
        const oldValue = {...tasks}
        setLoading(true)
        try {
            tasks.data.forEach((task, idx) => {
                if (task.icon === item.icon && task?.file?.id === item?.file?.id) {
                    tasks.data[idx].name = name
                }
            });
            await myCal().set({
                tasks: tasks
            }, {merge: true})
            toast.success(`${toastChangeSuccessIntl}`)

        } catch (e) {
            console.log(e);
            toast.error(e.toString())
            setTasks(oldValue)
        } finally {
            setLoading(false)
            setEditing(false)
            setItem({})
            setName("")
        }
    }


    const onCloseDelete = () => {
        setOpenDelete(false)
        setItem({})

    }
    const onOpenDelete = (item) => {
        if (tasks?.data.length > 1) {
            setOpenDelete(true)
            setItem(item)
        }

    }
    const onCloseUpdate = () => {
        setEditing(false)
        setItem({})
        setName("")
    }
    const openUpdate = (item) => {
        // setOpenAddTask(true)
        setName(item.name)
        setEditing(true)
        setItem(item)

    }
    const handleDelete = async () => {
        const oldValue = {...tasks}
        try {
            if (item?.type === "file") {
                remove(tasks.data, e => e.file?.id === item?.file?.id)
            } else {
                remove(tasks.data, {"icon": item.icon})
            }
            await myCal().set({
                tasks: tasks
            }, {merge: true})
            toast.success(`${toastDeleteSuccessIntl}`)

        } catch (e) {
            console.log(e);
            toast.error(e.toString())
            setTasks(oldValue)
        } finally {
            setOpenDelete(false)
            setItem({})
        }

    }
    const onChangeName = (e) => {
        setName(e.target.value)
    }
    const Content = ({item, onDelete}) => {
        return (
            <div className={classes.contentPopover}>
                <div className={classes.name}>
                    {item.name}
                </div>
                <div>
                    <IconButton
                        onClick={() => openUpdate(item)}

                    >
                        <EditIcon/>
                    </IconButton>
                    {
                        onDelete && <IconButton

                            onClick={() => onOpenDelete(item)}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    }
                </div>
            </div>
        )
    }
    if (values.length === 0) return null

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>
                {taskOrder}
            </Typography>
            <MenuItemContainer className={classes.listIconTask}>
                <div className={classes.label}>{tagEvent}</div>
                <div className={classes.listTag}>
                    {
                        values.map((item, index) => {
                            if (item.icon === "AssignmentTurnedIn")
                                return null
                            return (
                                <Popover content={<Content item={item} onDelete={true}/>}
                                         overlayStyle={{minWidth: 220, zIndex: 9990}}
                                         onMouseLeave={() => {
                                             setEditing(false)
                                             setName("")
                                         }}
                                         placement="bottom"
                                         key={index}
                                >
                                    <div key={index} className={classes.flex}>
                                        {/*<Typography className={classes.numText}>{index + 1}</Typography>*/}
                                        <div>
                                            <div className={classes.iconSvg}>
                                                {
                                                    item?.type === "file" ?
                                                        <div>
                                                            <img src={item.file?.url}
                                                                 className={classes.taskImg}
                                                                 alt=""/>
                                                        </div>
                                                        :
                                                        <span
                                                            className={'material-icons md-24'}>
                                        {getIcon(item.icon)}
                                                </span>
                                                }
                                            </div>
                                            <div
                                                className={classes.boxItemName}>{item.name}</div>

                                        </div>
                                    </div>
                                </Popover>

                            )
                        })
                    }

                    <IconButton
                        className={classes.addIconSVG}
                        onClick={() => setOpenAddTask(true)}


                    >
                        <AddLargeSVG/>
                    </IconButton>
                </div>
                <div className={classes.label}>{tagFunciton}</div>
                <div className={classes.listTag}>

                    {
                        values.map((item, index) => {
                            if (item.icon !== "AssignmentTurnedIn")
                                return null
                            return (
                                <Popover content={<Content item={item} onDelete={false}/>}
                                         overlayStyle={{minWidth: 220, zIndex: 9990}}
                                         onMouseLeave={() => {
                                             setEditing(false)
                                             setName("")
                                         }}
                                         placement="bottom"
                                         key={index}
                                >
                                    <div key={index} className={classes.flex}>
                                        {/*<Typography className={classes.numText}>{index + 1}</Typography>*/}
                                        <div>
                                            <div className={classes.iconSvg}>
                                                {
                                                    item?.type === "file" ?
                                                        <div>
                                                            <img alt={''} src={item.file?.url}
                                                                 className={classes.taskImg}/>
                                                        </div>
                                                        :
                                                        <span
                                                            className={`material-icons md-24`}>
                                        {getIcon(item.icon)}
                                                </span>
                                                }
                                            </div>
                                            <div
                                                className={classes.boxItemName}>{item.name}</div>
                                        </div>
                                    </div>
                                </Popover>

                            )
                        })
                    }
                </div>
            </MenuItemContainer>
            {
                openAddTask &&
                <SelectIconsDialog open={openAddTask} setOpen={setOpenAddTask} onAdd={handleAdd}
                                   loading={loading} item={item} setItem={setItem}/>
            }

            <Typography className={classes.title}>
                {titleTagsFree}
            </Typography>

            <Spin spinning={loadingTask}>
                <MenuItemContainer className={classes.listIconTaskFree}>
                    {
                        values.map((item, index) => {
                            return (
                                <Popover>
                                    <div key={index} className={classes.flex} onClick={() => handleTaskFree(item)}>
                                        <div>
                                            <div className={classes.iconSvg}>
                                                {
                                                    item?.type === "file" ?
                                                        <div>
                                                            <img src={item.file?.url} alt={""}
                                                                 className={taskFree.includes(item.file ? item.file.id : item.icon) ? classes.taskImgActive : classes.taskImg}/>
                                                        </div>
                                                        :
                                                        <span
                                                            className={`material-icons md-24 ${taskFree.includes(item.file ? item.file.id : item.icon) ? classes.iconActive : ''}`}>
                                                    {getIcon(item.icon)}
                                                </span>
                                                }
                                            </div>
                                            <div
                                                className={taskFree.includes(item.file ? item.file.id : item.icon) ? classes.boxItemNameActive : classes.boxItemName}>{item.name}</div>

                                        </div>
                                    </div>
                                </Popover>

                            )
                        })
                    }
                </MenuItemContainer>
            </Spin>
            {
                editing &&
                <DialogCustom
                    open={editing}
                    onClose={onCloseUpdate}
                    title={changeNameIntl}

                >
                    <div className={classes.textField}>
                        <Input value={name}
                               onChange={(e) => onChangeName(e)}
                               onPressEnter={() => handleUpdate()}
                               autoFocus={true}
                        />
                        <div className={classes.groupBtn}>
                            {/*<CLFButtonSVG*/}
                            {/*    onClick={onCloseUpdate}*/}
                            {/*    name={cancelIntl}*/}
                            {/*    margin={'5px'}*/}
                            {/*    minWidth={"100px"}*/}
                            {/*    className={classes.secondary}*/}
                            {/*/>*/}
                            <CancelButton
                                onClick={onCloseUpdate}
                                // margin={'5px'}
                                // minWidth={"100px"}
                                className={classes.secondary}
                            />
                            {/*<CLFButtonSVG*/}
                            {/*    onClick={() => handleUpdate()}*/}
                            {/*    name={'Update'}*/}
                            {/*    margin={'5px'}*/}
                            {/*    minWidth={"100px"}*/}
                            {/*    className={classes.primary}*/}
                            {/*/>*/}
                            <SaveButton
                                onClick={() => handleUpdate()}
                                className={classes.primary}

                            />
                        </div>
                    </div>
                </DialogCustom>
            }
            {
                openDelete &&
                <DialogCustom open={openDelete}
                              title={removeAuthIntl}
                              onClose={onCloseDelete}
                              handleDelete={handleDelete}
                >
                    <div className={'clf-flex-center'}>
                        {/*<CLFButtonSVG*/}
                        {/*    onClick={onCloseDelete}*/}
                        {/*    name={'Cancel'}*/}
                        {/*    margin={'5px'}*/}
                        {/*    minWidth={"100px"}*/}
                        {/*    className={classes.secondary}*/}
                        {/*/>*/}
                        <CancelButton
                            onClick={onCloseDelete}
                            margin={'5px'}
                            className={classes.secondary}
                        />
                        {/*<CLFButtonSVG*/}
                        {/*    onClick={handleDelete}*/}
                        {/*    name={'Ok'}*/}
                        {/*    margin={'5px'}*/}
                        {/*    minWidth={"100px"}*/}
                        {/*    className={classes.primary}*/}
                        {/*/>*/}
                        <DeleteButton
                            onClick={handleDelete}
                            margin={'5px'}
                            className={classes.primary}
                        />
                    </div>
                </DialogCustom>
            }
        </div>
    )
}

const styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: "column",
        padding: 10
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minWidth: "95px",
        paddingTop: 5,
        paddingBottom: 5,
    },
    center: {
        textAlign: "center"
    },
    title: {
        padding: 20,
        fontSize: '1.4rem',
        color: '#000'
    },
    numText: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        color: '#000',
        padding: "0 8px",
    },
    iconSvg: {
        display: 'flex',
        alignItems: 'center',
        background: "#f1f1f1",
        justifyContent: "center",
        borderRadius: "50%",
        cursor: "pointer",
        width: "38px",
        height: "38px",
    },
    addIconSVG: {
        padding: 0,
        "& svg": {
            fill: theme.props.primaryColor,
        },
        "&:hover": {
            backgroundColor: 'unset'
        },
        "&:focus": {
            backgroundColor: 'unset'
        },
        margin: "0 8px"
    },
    iconActive: {
        color: theme.props.primaryColor
    },
    listIconTask: {
        flexWrap: "wrap",
        height: "unset",
        flexDirection: "column",
    },
    listIconTaskFree: {
        flexWrap: "wrap",
        height: "unset",
    },
    button: {
        margin: "5px 0",
        background: theme.props.primaryColor,
        color: "#fff",
        border: "none",
        "&:hover": {
            background: theme.props.primaryColor,
            color: "#fff",
            border: "none",
            opacity: 0.8
        },
        "&:focus": {
            background: theme.props.primaryColor,
            color: "#fff",
            border: "none",
            opacity: 0.8
        },

    },
    boxItemName: {
        maxWidth: "50px",
        overflow: "hidden",
        margin: 0,
        textOverflow: "ellipsis",
        fontWeight: "bold",
        fontSize: "12px",
        whiteSpace: "nowrap"
    },
    boxItemNameActive: {
        maxWidth: "50px",
        overflow: "hidden",
        margin: 0,
        textOverflow: "ellipsis",
        fontWeight: "bold",
        fontSize: "12px",
        whiteSpace: "nowrap",
        color: theme.props.primaryColor,
    },
    titleChange: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingRight: 30,
        position: "relative",
    },
    IconEdit: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: 0
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    contentPopover: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    taskImg: {
        width: "38px",
        height: "38px",
        borderRadius: "50%",
        border: "solid 3px #f1f1f1"
    },
    taskImgActive: {
        width: "38px",
        height: "38px",
        borderRadius: "50%",
        border: `solid 3px ${theme.props.primaryColor}`
    },
    textField: {
        padding: "0 15px 15px 15px",
        "& input": {
            width: 300,
            marginBottom: 15,
        }
    },
    name: {
        marginRight: 15,
        maxWidth: 400,
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    groupBtn: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    listTag: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: 10,
    },
    label: {
        padding: 10
    }
})

export default withStyles(styles)(CalendarEventSetting)
