import moment from "moment";

const getEventsInRange = (events, start, end) => {
    if (!start || !end || !events?.length)
        return events

    let result = []
    events.forEach(event => {
        if (moment(event?.start).isBetween(start, end, null, "[]") ||
            moment(event?.end).isBetween(start, end, null, "[]") ||
            (moment(event?.start).isBefore(start) && moment(event?.end).isAfter(end))) {
            result.push({...event, editable: false})
        }
    })
    return result
}

export default getEventsInRange;
