import React from 'react';
import IconIdentity from '../../Calendar/google/identityIcon';
import {Modal} from 'antd';
import ArchiveButton from '../../Dialog/ArchiveButton';
import CancelButton from '../../Dialog/CancelButton';

function ArchivedIdentityDialog({identity, setIdentity, onSave, loading, classes}) {
    return (
        <Modal
            visible={Boolean(identity)}
            title={identity.name}
            onCancel={() => setIdentity(null)}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CancelButton
                    onClick={() => setIdentity(null)}
                    disable={loading}
                />
                <ArchiveButton
                    loading={loading}
                    onClick={() => onSave({...identity, isArchive: true})}
                    disable={loading}
                />
            </div>
            }
        >
            <IconIdentity color={identity?.color || null}/>
        </Modal>
    );
}

export default ArchivedIdentityDialog;
