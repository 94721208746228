import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useIntlId from "../../../hooks/useIntlId";


export default function GoogleSyncPopup({open, setOpen, values, onAdd}) {
    const [syncNotif, cancelIntl] = useIntlId(['calendar.create.syncNotif', 'create.cancel'])

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {syncNotif}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false)
                        onAdd(values, false)
                    }} color="primary">
                        {cancelIntl}
                    </Button>
                    <Button onClick={() => {
                        console.log('sync for google calendar')
                        setOpen(false)
                        onAdd(values, true)
                    }} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
