import React, {useEffect, useMemo, useState} from 'react';
import {useGlobal} from 'reactn'
import moment from "moment";
import {makeStyles} from '@material-ui/core/styles'
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import {Close} from '@material-ui/icons'
import {CALENDAR_COLLECTION, REGISTER_URL, SUBDOMAIN_APPOINTMENT} from "../../../config/constants";
import Fire, {db} from "../../../firebaseConfig";
import {toast} from "react-toastify";
import {v4 as uuidv4} from 'uuid'
import validator from "email-validator";
import {getEmailHtml, getFormatDate} from "./common";
import {handleCreateZoomMeeting} from "../../../actions/zoom";
import {createEvent, updateShareURLEvent} from "./syncCalendarEvent";
import {createGoogleTasksSchedule} from "../../../actions/googleTasks";
import CircularProgress from "@material-ui/core/CircularProgress";
import momentTz from 'moment-timezone';
import useIntlId from "../../../hooks/useIntlId";
import {getJWTTokenShare} from "../../../common/jwt";
import {zoneCustomDateFormat, zoneHHmm, zoneObj} from "../../../common/moment/format"
import axios from "axios";
import {find} from "lodash";

const useStyles = makeStyles(theme => ({
    root: {
        "&:hover": {
            cursor: "pointer"
        }
    }
}))

function SelectEvent({api, info, setEventConfirm, selectedProjectId, selectedColorId}) {
    const [hoverPos] = useGlobal("hoverPos")
    const [user] = useGlobal("user")
    const [openSubmit, setOpenSubmit] = useState(null)
    const [openConfirm, setOpenConfirm] = useState(null)
    const [disabled, setDisable] = useState(false)
    const classes = useStyles()

    const timeText = useMemo(() => {
        if (!hoverPos) return '';
        const hourMinute = `${zoneHHmm(hoverPos.timeStart)} ~ ${zoneHHmm(hoverPos.timeEnd)}`
        const dayText = zoneCustomDateFormat(hoverPos.timeStart, user.language)
        return `${dayText} ${hourMinute}`
    }, [hoverPos, user.language])

    const handleClick = async () => {
        const event = {
            start: hoverPos.timeStart, end: hoverPos.timeEnd,
            user: hoverPos.event.user,
            id: hoverPos.event.id,
            email: hoverPos.event.email,
            name: hoverPos.event.name,
        }
        setOpenSubmit(event)
        if (api) {
            setEventConfirm({...event, backgroundColor: "#f89321"})
            api.addEvent({...event, backgroundColor: "#f89321"})
        }
    }

    return (
        <div>
            {(hoverPos && !disabled) ?
                <div
                    id={"hover-create-event"}
                    className={classes.root}
                    style={{
                        position: "fixed",
                        top: hoverPos.top + hoverPos.timeDuration * hoverPos.heightDuration,
                        left: hoverPos.left,
                        width: hoverPos.width,
                        height: hoverPos.height,
                        backgroundColor: "#f89321",
                        zIndex: 2,
                        color: "white",
                        fontSize: 9
                    }}
                    onClick={handleClick}
                >
                    {zoneObj(hoverPos.timeStart).format("HH:mm")} ~
                    {zoneObj(hoverPos.timeEnd).format("HH:mm")}
                </div>
                :
                null
            }
            <DialogSubmit open={openSubmit} setOpen={setOpenSubmit} setOpenConfirm={setOpenConfirm}
                          setDisable={setDisable} api={api} info={info}
                          setEventConfirm={setEventConfirm}
                          selectedProjectId={selectedProjectId}
                          selectedColorId={selectedColorId}
                          timeText={timeText}
            />
            <DialogConfirm open={openConfirm} setOpen={setOpenConfirm} timeText={timeText}/>
        </div>
    )
}

const getIDEvent = (schId, start, minAdd = 0) => {
    return `evt_${schId}_${moment(start).add(minAdd, "minutes").utc().format("YYYYMMDD_HHmm")}`
}


const transactionEvent = async (schId, eventData, ref) => {
    try {
        const diffMin = moment(eventData.end).diff(moment(eventData.start), "minutes");
        if (diffMin <= 0)
            return false
        return Fire.firestore().runTransaction(async (transaction) => {
            try {
                let refs = []
                let timeRage = -diffMin
                while (timeRage <= diffMin) {
                    const id = getIDEvent(schId, eventData.start, timeRage)
                    // console.log("id:",id)
                    const subRef = ref.doc(id)
                    refs.push(subRef)
                    timeRage += 30
                }

                let valid = true
                await Promise.all(refs.map(async ref => {
                    const doc = await transaction.get(ref)
                    if (doc.exists) {
                        const dataDoc = doc.data()
                        if (dataDoc?.isDeleted) return null;
                        else if (moment(dataDoc.start).isBetween(eventData.start, eventData.end) ||
                            moment(dataDoc.end).isBetween(eventData.start, eventData.end) ||
                            moment(dataDoc.start).isSame(eventData.start)
                        ) {
                            valid = false
                        }
                    }
                }))

                if (!valid)
                    return Promise.reject(false)
                const docRef = ref.doc(eventData.docId);
                transaction.set(docRef, eventData)
                return true
            } catch (e) {
                return Promise.reject(false)
            }
        }).then(() => true)
            .catch(() => false)
    } catch (e) {
        console.log(e)
        return Promise.reject(false)
    }
}

function DialogSubmit({
                          open,
                          setOpen,
                          setOpenConfirm,
                          setDisable,
                          api,
                          info,
                          setEventConfirm,
                          selectedProjectId,
                          selectedColorId,
                          timeText
                      }) {
    const classes = useSubmitStyles()
    const [user] = useGlobal("user")
    const [allProject] = useGlobal('allProject')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [language, setLanguage] = useState('ja-jp')
    const [loading, setLoading] = useState(false)

    // object of selected project
    const pro = useMemo(() => {
        return find(allProject, p => p.uuid === selectedProjectId)
    },[allProject, selectedProjectId])

    const [enableCookiesIntl, nameRequiredIntl, emailRequiredIntl, emailNotValidIntl, anyFailGenerateIntl, eventExistsIntl, createEventFailIntl, createScheduleFailIntl,nameIntl, emailIntl, projectIntl, confirmTextIntl] = useIntlId([
        'event.toast.enableCookies',
        'event.toast.nameRequired',
        'event.toast.emailRequired',
        'event.toast.emailNotValid',
        'event.toast.anyFailGenerate',
        'event.toast.eventExists',
        'event.toast.createEventFail',
        'event.toast.createScheduleFail',
        'app.profile.name',
        'event.dialog.request.email',
        'appy.tooltip',
        'appy.vote.confirmTime'
    ])

    useEffect(() => {
        if (user?.user_id) {
            let fullName = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname
            setName(fullName)
            setEmail(user.email)
            setLanguage(user?.language)
        }
        // eslint-disable-next-line
    }, [user]);

    function checkCookie() {
        let cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled) {
            document.cookie = "checkCookie";
            cookieEnabled = document.cookie.indexOf("checkCookie") !== -1;
        }
        return cookieEnabled
    }

    const handleSubmit = async () => {
        const userLanguage = language
        try {
            if (!checkCookie()) {
                return toast.error(`${enableCookiesIntl}`)
            }
            if (!name) {
                document.getElementById("name").focus()
                return toast.error(`${nameRequiredIntl}`)
            }
            if (!email) {
                document.getElementById("email").focus()
                return toast.error(`${emailRequiredIntl}`)
            }
            if (!validator.validate(email)) {
                return toast.error(`${emailNotValidIntl}`)
            }
            setLoading(true);

            if (!user?.user_id) {
                // no user login -> login anonymous
                const data = await Fire.auth().signInAnonymously();
                if (!data.user?.uid)
                    return toast.error(`${anyFailGenerateIntl}`);
            }

            // handle create zoom link if user authorized with zoom geniam apps
            let conferenceData = '';
            let zoomMeetingId = '';
            // check parseZone user invited
            // let zoneUser = momentTz.tz.guess() || 'Asia/Tokyo';
            let zoneUser = user?.time_zone || 'Asia/Tokyo';
            let zoneUserShare = info?.timeZone || 'Asia/Tokyo';

            if (info?.isCreateZoom) {
                const dataInfo = await handleCreateZoomMeeting('meetings', open, `[MTG] ${name}`, zoneUserShare);
                if (dataInfo) {
                    conferenceData = dataInfo;
                    zoomMeetingId = dataInfo.id;
                    // linkZoom = dataInfo.join_url;
                }
            }
            let tokenShare = getJWTTokenShare(info.id, info.usr);
            const linkEvent = `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/scheduleAdjustment?tokenShare=${tokenShare}`
            // convert to dynamic url
            const {data} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                    "domainUriPrefix": "https://appygeniam.page.link",
                    "link": linkEvent,
                    "androidPackageName": null,
                    "iosBundleId": null
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )

            const token = "token_" + uuidv4();

            const batch = Fire.firestore().batch()

            const eventData = {
                start: open.start,
                end: open.end,
                title: `[MTG] ${name}`,
                allDay: false,
                isDeleted: false,
                isEventVote: true,
                email,
                name,
                conferenceData,
                zoomMeetingId,
                token,
                zoneUser,
                zoneUserShare,
                project: info.project || '',
                isGoogle: Boolean(info.isGoogle),
                task: info.task || '',
                description: info.description || '',
                colorId: info.colorId || null,
                scheduleId: info.id,
                usr: info.usr || '',
                linkEvent: data?.shortLink || '',
                user: open.user,
                docId: getIDEvent(open.user, open.start, 0),
                createdAt: moment().format(),
                emailVoter: email || '',
                language: info?.language || 'ja-jp'
            };

            // const eventRef = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${open.user}/eventBoxMeeting/${eventData.docId}`);

            const users = [
                {
                    id: info.email,
                    email: info.email,
                    name: info.name,
                    timeZone: zoneUserShare,
                    language: info?.language || 'ja-jp'
                },
                {
                    id: email,
                    email: email,
                    name: name,
                    timeZone: zoneUser,
                    language: userLanguage
                }
            ];

            const googleTasksID = await createGoogleTasksSchedule(eventData, users);
            eventData.googleTasksID = googleTasksID;

            const conferenceDataGoogle = conferenceData? {
                "entryPoints": [
                    {
                        "entryPointType": "video",
                        "uri": conferenceData.join_url,
                        "label": conferenceData.join_url,
                        "meetingCode": conferenceData.id,
                        "password": conferenceData.password
                    }
                ],
                "conferenceSolution": {
                    "key": {
                        "type": "addOn"
                    },
                    "name": "Zoom Meeting",
                    "iconUri": "https://lh3.googleusercontent.com/ugWKRyPiOCwjn5jfaoVgC-O80F3nhKH1dKMGsibXvGV1tc6pGXLOJk9WO7dwhw8-Xl9IwkKZEFDbeMDgnx-kf8YGJZ9uhrJMK9KP8-ISybmbgg1LK121obq2o5ML0YugbWh-JevWMu4FxxTKzM2r68bfDG_NY-BNnHSG7NcOKxo-RE7dfObk3VkycbRZk_GUK_1UUI0KitNg7HBfyqFyxIPOmds0l-h2Q1atWtDWLi29n_2-s5uw_kV4l2KeeaSGws_x8h0zsYWLDP5wdKWwYMYiQD2AFM32SHJ4wLAcAKnwoZxUSyT_lWFTP0PHJ6PwETDGNZjmwh3hD6Drn7Z3mnX662S6tkoPD92LtMDA0eNLr6lg-ypI2fhaSGKOeWFwA5eFjds7YcH-axp6cleuiEp05iyPO8uqtRDRMEqQhPaiRTcw7pY5UAVbz2yXbMLVofojrGTOhdvlYvIdDgBOSUkqCshBDV4A2TJyDXxFjpSYaRvwwWIT0JgrIxLpBhnyd3_w6m5My5QtgBJEt_S2Dq4bXwCAA7VcRiD61WmDyHfU3dBiWQUNjcH39IKT9V1fbUcUkfDPM_AGjp7pwgG3w9yDemGi1OGlRXS4pU7UwF24c2dozrmaK17iPaExn0cmIgtBnFUXReY48NI8h2MNd_QysNMWYNYbufoPD7trSu6nS39wlUDQer2V"
                },
                "conferenceId": conferenceData.id,
                "notes": "Passcode: " + conferenceData.password,
                "parameters": {
                    "addOnParameters": {
                        "parameters": {
                            "realMeetingId": conferenceData.id,
                            "meetingCreatedBy": conferenceData.host_email,
                            "meetingUuid": conferenceData.uuid,
                            "meetingType": conferenceData.type
                        }
                    }
                }
            } : null

            if (!info.project) console.log("Event vote not project")

            const eventBoxRef = Fire.firestore().collection(`${CALENDAR_COLLECTION}/${open.user}/eventBoxMeeting`)
            const checkSuccess = await transactionEvent(info.id, eventData, eventBoxRef);
            if (!checkSuccess) {
                onClose();
                return toast.warn(`${eventExistsIntl}`);
            }
            if (info.project) {
                // sync event to calendar
                if (info.isGoogle) {
                    // google calendar
                    const data = await createEvent(info.project, info.usr, {
                        colorId: info.colorId || null,
                        summary: `[MTG] ${name}`,
                        start: {
                            dateTime: moment(open.start).utc().format(),
                            date: null
                        },
                        end: {
                            dateTime: moment(open.end).utc().format(),
                            date: null
                        },
                        extendedProperties: {
                            "private": {
                                "task": info.task || '',
                                "is_success": 0,
                                "meetingId": "",
                                "zoomMeetingId": zoomMeetingId,
                                "googleTasksID": googleTasksID,
                                "shareUrl": 1,
                                "scheduleId": info.id,
                                "emailVoter": email || '',
                                "email": email || '',
                                "name": name || '',
                            }
                        },
                        description: info.description || ''
                    });
                    if (data) {
                        eventData.isSync = true;
                        eventData.eventId = data.id;
                        if (conferenceData)
                            updateShareURLEvent(info.project, data.id, info.usr, {
                                conferenceData: conferenceDataGoogle
                            }).catch(e => console.log(e))

                    } else {
                        eventData.isSync = false
                    }

                } else {
                    // geniam calendar
                    const evtId = 'evt_' + uuidv4();
                    let data = {
                        ...eventData,
                        project_uuid: info.project,
                        id: evtId,
                        isTasks: false,
                        googleTasksID: googleTasksID || ''
                    }

                    const eventsRef = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${open.user}/events/${evtId}`)
                    batch.set(eventsRef, {...data})

                    eventData.isSync = true;
                    eventData.eventId = evtId;

                    if (!checkSuccess) {
                        onClose();
                        return toast.error(`${createEventFailIntl}`);
                    }

                }
            }


            const eventBoxDoc = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${open.user}/eventBoxMeeting/${eventData.docId}`)
            const eventIdPartner = "evt_" + uuidv4();
            const eventBoxMeetingIdPartner = "meet_1on1_" + uuidv4()
            eventData.eventFollowId = eventIdPartner
            batch.set(eventBoxDoc, {...eventData})

            const emailId = `email_meet_${uuidv4()}`
            const emailRef = Fire.firestore().doc(`mails/${emailId}`)
            const time = `${momentTz(open.start).tz(zoneUserShare).format("HH:mm")} ~ ${momentTz(open.end).tz(zoneUserShare).format("HH:mm")}`

            let language = info?.language || 'ja-jp';
            const languageSnap = await db.doc(
                `intl/calendarIntl/langColl/${language}`).get();
            const languageData = languageSnap.exists ? languageSnap.data() : {};
            const dontRely = `${languageData['email.message.dontReply']}`;
            const btnGotoEvent = `${languageData['email.message.btn.gotoEvent']}`;
            const btnJoinZoom = `${languageData['email.message.joinZoom']}`;
            const subjectVoteToUser = `${languageData['email.message.subject.voteToUser']}`.split("|");

            let subject
            if (info?.language) {
                subject = subjectVoteToUser[0] + name + subjectVoteToUser[1]
            } else {
                subject = `You have received an appointment vote from「${name}」`
            }

            let modifyUrls
            if (info.nameSetting) {
                modifyUrls = `${window.location.origin}/shareUrl?idShare=${info.id}`
            } else {
                modifyUrls = `${window.location.origin}/shareUrl`
            }

            // convert to dynamic url

            const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                    "domainUriPrefix": "https://appygeniam.page.link",
                    "link": modifyUrls,
                    "androidPackageName": null,
                    "iosBundleId": null
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )

            batch.set(emailRef, {
                from: `${name} <geniamevents@geniam.com>`,
                replyTo: email,
                to: open.email,
                message: {
                    html: getEmailHtml({
                        title: `[MTG] ${name} - [${email}]`,
                        start: `${getFormatDate(language, open.start, zoneUserShare)}`,
                        end: `${getFormatDate(language, open.end, zoneUserShare)}`,
                        time: time,
                        modifyUrl: shortLink || '',
                        linkZoom: conferenceData.join_url,
                        timeZone: `${zoneUserShare}  ${momentTz().tz(zoneUserShare).format("Z")}`,
                        dontRely: dontRely,
                        btnGotoEvent: btnGotoEvent,
                        btnJoinZoom: btnJoinZoom,
                    }),
                    subject: subject
                }
            });

            const notiUid = `noti_${uuidv4()}`;
            const notificationRef = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${open.user}/notifications/${notiUid}`)
            const notificationData = {
                id: notiUid,
                avatar: "https://calendar.geniam.com/favicon.ico",
                content: `${name} has made an appointment with you`,
                scheduleAjustment: true,
                messageId: "schedule.adjustment.receive",
                nickname: name,
                first_name: user?.first_name || '',
                last_name: user?.last_name || '',
                createdAt: moment().format(),
                start: open.start,
                end: open.end,
            }

            batch.set(notificationRef, notificationData)

            if (user?.user_id) {
                // send email to create event too
                const myEventRef = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${eventIdPartner}`);
                const myEventBoxMeetingRef = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${user.user_id}/eventBoxMeeting/${eventBoxMeetingIdPartner}`);
                if (Boolean(pro.is_google)) {
                    // google calendar
                    const data = await createEvent(selectedProjectId, user.user_id, {
                        colorId: selectedColorId,
                        summary: `[MTG] ${open.name}`,
                        start: {
                            dateTime: moment(open.start).utc().format(),
                            date: null
                        },
                        end: {
                            dateTime: moment(open.end).utc().format(),
                            date: null
                        },
                        extendedProperties: {
                            "private": {
                                "task": info.task || '',
                                "is_success": 0,
                                "meetingId": "",
                                "zoomMeetingId": zoomMeetingId,
                                "googleTasksID": googleTasksID,
                                "shareUrl": 1,
                                "scheduleId": info.id,
                                "emailVoter": email || '',
                                "email": email || '',
                                "name": name || '',
                            }
                        },
                        description: info.description || ''
                    });
                    if (conferenceData)
                            updateShareURLEvent(selectedProjectId, data.id, user.user_id, {
                                conferenceData: conferenceDataGoogle
                            }).catch(e => console.log(e))
                } else {
                    const _eventData = {
                        start: open.start,
                        end: open.end,
                        title: `[MTG] ${open.name}`,
                        allDay: false,
                        isDeleted: false,
                        isEventVote: true,
                        isTasks: false,
                        emailVoter: email,
                        email: open.email,
                        name: open.name,
                        project_uuid: selectedProjectId,
                        colorId: selectedColorId,
                        conferenceData,
                        zoomMeetingId,
                        zoneUser,
                        zoneUserShare,
                        scheduleId: info.id,
                        language: info.language || 'ja-jp',
                        linkEvent: shortLink || '',
                    }
                    batch.set(myEventRef, {..._eventData, eventId: eventBoxMeetingIdPartner});
                    batch.set(myEventBoxMeetingRef, {..._eventData, eventSyncId: eventIdPartner, isSync: true})
                }

                const emailId = `email_meet_${uuidv4()}`
                const emailRef = Fire.firestore().doc(`mails/${emailId}`)
                const timeUser = `${momentTz(open.start).tz(zoneUser).format("HH:mm")} ~ ${momentTz(open.end).tz(zoneUser).format("HH:mm")}`

                let tokenShare = getJWTTokenShare(info.id, info.usr, eventData.docId, token, open.email);
                const modifyUrl = `${window.location.origin}/scheduleAdjustment?tokenShare=${tokenShare}`
                // convert to dynamic url

                const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                        "domainUriPrefix": "https://appygeniam.page.link",
                        "link": modifyUrl,
                        "androidPackageName": null,
                        "iosBundleId": null
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                let language = user?.language || 'ja-jp';
                const languageSnap = await db.doc(
                    `intl/calendarIntl/langColl/${language}`).get();
                const languageData = languageSnap.exists ? languageSnap.data() : {};
                const dontRely = `${languageData['email.message.dontReply']}`;
                const btnGotoEvent = `${languageData['email.message.btn.gotoEvent']}`;
                const btnJoinZoom = `${languageData['email.message.joinZoom']}`;
                const subjectVoteToVoter = `${languageData['email.message.subject.voteToVoter']}`.split("|");

                let subject
                if (user?.language) {
                    subject = subjectVoteToVoter[0] + open.name + subjectVoteToVoter[1]
                } else {
                    subject = `You have just created an appointment with ${open.name}`
                }

                batch.set(emailRef, {
                    from: `${open.name} <geniamevents@geniam.com>`,
                    replyTo: open.email,
                    to: user.email,
                    message: {
                        html: getEmailHtml({
                            title: `[MTG] ${open.name} - [${open.email}]`,
                            start: `${getFormatDate(language, open.start, zoneUser)}`,
                            end: `${getFormatDate(language, open.end, zoneUser)}`,
                            time: timeUser,
                            modifyUrl: shortLink || '',
                            linkZoom: conferenceData.join_url,
                            timeZone: `${zoneUser}  ${momentTz().tz(zoneUser).format("Z")}`,
                            dontRely: dontRely,
                            btnGotoEvent: btnGotoEvent,
                            btnJoinZoom: btnJoinZoom,
                        }),
                        subject: subject
                    }
                })
            } else {
                const emailId = `email_meet_${uuidv4()}`
                const emailRef = Fire.firestore().doc(`mails/${emailId}`)
                const time = `${momentTz(open.start).tz(zoneUser).format("HH:mm")} ~ ${momentTz(open.end).tz(zoneUser).format("HH:mm")}`

                // const query = qs.parse(window.location.search)
                // query.verify = token
                // query.evt = eventData.docId
                // query.email = open.email
                // const queryString = qs.stringify(query, {sort: false})
                let tokenShare = getJWTTokenShare(info.id, info.usr, eventData.docId, token, open.email);
                const modifyUrl = `${window.location.origin}/scheduleAdjustment?tokenShare=${tokenShare}`
                // convert to dynamic url
                const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                        "domainUriPrefix": "https://appygeniam.page.link",
                        "link": modifyUrl,
                        "androidPackageName": null,
                        "iosBundleId": null
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                let language = open?.language || 'ja-jp';
                const languageSnap = await db.doc(
                    `intl/calendarIntl/langColl/${language}`).get();
                const languageData = languageSnap.exists ? languageSnap.data() : {};
                const dontRely = `${languageData['email.message.dontReply']}`;
                const btnGotoEvent = `${languageData['email.message.btn.gotoEvent']}`;
                const btnJoinZoom = `${languageData['email.message.joinZoom']}`;
                const subjectVoteToVoter = `${languageData['email.message.subject.voteToVoter']}`.split("|");

                let subject
                if (open?.language) {
                    subject = subjectVoteToVoter[0] + open.name + subjectVoteToVoter[1]
                } else {
                    subject = `You have just created an appointment with「${open.name}」`
                }


                batch.set(emailRef, {
                    from: `${open.name} <geniamevents@geniam.com>`,
                    replyTo: open.email,
                    to: email,
                    message: {
                        html: getEmailHtml({
                            title: `[MTG] ${open.name} - [${open.email}]`,
                            start: `${getFormatDate(language, open.start, zoneUserShare)}`,
                            end: `${getFormatDate(language, open.end, zoneUserShare)}`,
                            time: time,
                            modifyUrl: shortLink || '',
                            linkZoom: conferenceData.join_url,
                            timeZone: `${zoneUser}  ${momentTz().tz(zoneUser).format("Z")}`,
                            dontRely: dontRely,
                            btnGotoEvent: btnGotoEvent,
                            btnJoinZoom: btnJoinZoom,
                        }),
                        subject: subject
                    }
                })
            }
            // disable url
            const used = info.used || 0
            const urlRef = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${open.user}/scheduleAdjustment/${open.id}`)
            batch.set(urlRef, {used: used + 1}, {merge: true})
            await batch.commit()
            info.used = used + 1
            setDisable(true)
            setLoading(false)
            setOpenConfirm(open)
            setOpen(null)
        } catch (e) {
            console.log(e);
            toast.error(`${createScheduleFailIntl}`)
        } finally {
            setLoading(false);
        }
    }
    const onClose = () => {
        setOpen(null)
        setEventConfirm(null)
        if (api) {
            const event = api.getEventById(open.id)
            if (event) {
                event.remove()
            }
        }
    }

    const handleKeyDown = name => event => {
        if (event.key === "Enter") {
            if (name === "name") {
                // console.log(emailRef);
                // emailRef.current.focus()
                document.getElementById("email").focus()
            }
            if (name === "email") {
                handleSubmit()
            }
        }
    }
    if (!open)
        return null
    return (
        <Dialog open={Boolean(open)} onClose={onClose} className={classes.root}>
            <DialogContent className={classes.content}>
                <IconButton onClick={onClose} className={classes.closeBtn}>
                    <Close/>
                </IconButton>
                <DialogContentText>
                    {timeText}
                </DialogContentText>
                <Grid container className={'mt-2.5'}>
                    <Grid item xs={3}>
                        <Typography>{nameIntl}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField fullWidth
                                   autoFocus={true}
                                   id={"name"}
                                   value={name}
                                   onChange={e => setName(e.target.value)}
                                   onKeyDown={handleKeyDown("name")}
                                   disabled={Boolean(user?.user_id)}
                        />
                    </Grid>

                </Grid>
                <Grid container className={'mt-2.5'}>
                    <Grid item xs={3}>
                        <Typography>{emailIntl}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField fullWidth
                                   id={"email"}
                                   value={email}
                                   onChange={e => setEmail(e.target.value)}
                                   onKeyDown={handleKeyDown("email")}
                                   disabled={Boolean(user?.user_id)}
                        />
                    </Grid>

                </Grid>
                {user?.user_id &&
                    <Grid container className={'mt-2.5'}>
                        <Grid item xs={3}>
                            <Typography>{projectIntl}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                                <div className={'flex items-center'}>
                                    <div className={`rounded-full min-w-[15px] h-[15px]`} style={{backgroundColor: pro?.color}}>
                                    </div>
                                    <div className={'ml-2.5 font-bold truncate'}>
                                        {pro?.name || pro?.summary}
                                    </div>

                                </div>
                        </Grid>

                    </Grid>
                }
                {
                    loading ? <CircularProgress className={classes.Progress} color={"primary"} size={30}/>
                        :
                        <Button className={classes.button}
                                disabled={!name || !email || loading}
                                onClick={handleSubmit}
                                onKeyDown={handleKeyDown("email")}
                        >
                            {confirmTextIntl}
                        </Button>
                }
            </DialogContent>

        </Dialog>
    )
}

function DialogConfirm({open, setOpen, timeText}) {
    const classes = useSubmitStyles()
    const [user] = useGlobal("user")
    const onRegister = () => {
        window.location.assign(REGISTER_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
    }
    const [confirmDialogIntl] = useIntlId(['appy.vote.confirmDialog.text'])
    if (!open)
        return null
    return (
        <Dialog open={Boolean(open)} onClose={() => setOpen(null)} className={classes.root}>
            <DialogContent>
                <IconButton onClick={() => setOpen(null)} className={classes.closeBtn}>
                    <Close/>
                </IconButton>
                <DialogContentText>
                    {timeText}
                </DialogContentText>
                <Typography className={classes.confirmText}>
                    {confirmDialogIntl.toString().replace('~',open.name || '')}
                </Typography>

                {
                    user?.user_id ?
                        <Button className={classes.button} onClick={() => setOpen(null)}>
                            Ok
                        </Button>
                        :
                        <Button className={classes.button} onClick={onRegister}>
                            Geniam カレンダーを無料で使う。
                        </Button>
                }

            </DialogContent>
        </Dialog>
    )
}

const useSubmitStyles = makeStyles(theme => ({
    root: {
        textAlign: "center",
        "& .MuiDialogContent-root": {
            minWidth: 300,
        }
    },
    button: {
        margin: '10px auto',
        background: "#99C355",
        color: "#fff",
        fontWeight: 'bold',
        padding: "5px 10px",
        borderRadius: "15px",
        "&:hover": {
            background: "#99C355",
            color: "#fff",
        }
    },
    confirmText: {},
    closeBtn: {
        position: "absolute",
        top: 0,
        right: 0
    },
    Progress: {
        marginTop: 10
    },
}))


export default SelectEvent;
