import {getPosition} from "../../../common/element";
import moment from "moment";

const getDataHover = (info, data) => {
    let {top, left} = getPosition(info.el)
    const width = info.el.clientWidth
    left = left - width - 2
    const duration = moment(info.event.end).diff(info.event.start, "minutes") / 30
    const heightDuration = info.el.clientHeight / duration
    const height = heightDuration * 2 * Number(data.timeVote)
    let timeDuration = Math.floor(info.jsEvent.layerY / heightDuration)
    let timeStart = moment(info.event.start).add((timeDuration) * 30, "minutes").format()
    let timeEnd = moment(timeStart).add(Number(data.timeVote), "hours").format()
    if (moment(timeEnd).isAfter(info.event.end)) {
        const minutes = moment(timeEnd).diff(info.event.end, "minutes")
        timeDuration = timeDuration - minutes / 30
        timeStart = moment(timeStart).subtract(minutes, "minutes").format()
        timeEnd = moment(timeEnd).subtract(minutes, "minutes").format()
    }
    return {timeStart, timeEnd, top, left, width, height, heightDuration, timeDuration}
}

export default getDataHover;
