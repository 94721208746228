import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {LOGIN_URL, REGISTER_URL} from "../../../config/constants";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {ReactComponent as LogInICon} from "../../../asSvg/icons/login.svg"
import {ReactComponent as RegisterICon} from "../../../asSvg/icons/register.svg"
import useIntlId from "../../../hooks/useIntlId";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        background: '#F1F1F1',
        // marginBottom: 20,
        padding: "7px 10px",
        alignItems: "center",
        justifyContent: "space-between"
    },
    logo: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& span": {
            fontSize: 16,
            textTransform: "uppercase",
            fontWeight: "bold",
            color: "#000",
        }
    },
    buttonGroup: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    },

    buttonLoginRegister: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        textTransform: "uppercase",
        "&:hover": {
            border: "none",
        },
        "& svg": {
            fill: theme.props.primaryColor,
        },
    },
}))

function TopBar({isPreview = false}) {
    const classes = useStyles()
    const [registerIntl, loginIntl] = useIntlId(['app.auth.register', 'app.auth.login'])

    const onLogin = () => {
        if (isPreview)
            return null;

        window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
    }
    const onReg = () => {
        if (isPreview)
            return null;

        window.location.assign(REGISTER_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
    }
    return (
        <div className={classes.root}>
            <div className={classes.logo}>
                <img src="/imgs/logo.svg" alt=""/>
                <span>Geniam</span>
            </div>
            <div className={classes.buttonGroup}>
                <CLFButtonSVG
                    className={classes.buttonLoginRegister}
                    onClick={onLogin}
                    iconComponent={<LogInICon/>}
                    name={loginIntl}
                    minWidth={'130px'}
                />


                <CLFButtonSVG
                    className={classes.buttonLoginRegister}
                    onClick={onReg}
                    iconComponent={<RegisterICon/>}
                    name={registerIntl}
                    minWidth={'130px'}
                />

            </div>

        </div>
    );
}

export default TopBar;
