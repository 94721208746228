import React, {useEffect, useMemo, useState} from 'react';
import {useGlobal} from 'reactn';
import {makeStyles} from "@material-ui/styles";
import DialogCustom from "../../Custom/DialogCustom";
import ListIconSearch from "./ListIconSearch";
import Tab from "@material-ui/core/Tab";
import TabsCustom from "../../Custom/TabsCustom";
import {Button, Upload,} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {storage} from '../../../firebaseConfig'
import {toast} from "react-toastify";
import useIntlId from "../../../hooks/useIntlId";
import CancelButton from "../../Dialog/CancelButton";
import SaveButton from "../../Dialog/SaveButton";

function SelectIconsDialog({onAdd, loading, item, setItem}) {
    const classes = useStyles()
    const [name, setName] = useState('')
    const [icon, setIcon] = useState(null)
    const [, setIndex] = useState(null)
    const [tab, setTab] = useState(0)
    const [openAddTask, setOpenAddTask] = useGlobal('openAddTask')
    const [user] = useGlobal('user')
    const [file, setFile] = useState(null)
    const [isTitleChanged, setIsTitleChanged] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [, setOpenSetting] = useGlobal('openSetting')
    const [updateIconIntl, addiconIntl, selectIconIntl, upLoadImageIntl, nameIntl, clickToUploadIntl, titleRequiredIntl] = useIntlId(['event.setting.uploadTaskIcon', 'event.setting.addTaskIcon', 'icon.select', 'icon.Upload', 'icon.name', 'icon.clickToUpload', 'event.toast.titleNull'])

    useEffect(() => {
        if (item?.name) setName(item?.name)
        if (item?.icon) setIcon(item?.icon)
        setIndex(item?.index)
        if (item?.type === "file") {
            setTab(1)
            setFile(item?.file || null)
        }
    }, [item])
    const handleClose = () => {
        setOpenAddTask(false)
        setItem({})
        setOpenSetting(false)
    }

    const tabMemo = useMemo(() => {
        return tab
    }, [tab])

    const handleChangeTab = (event, newValue) => {
        event.preventDefault()
        setTab(newValue)
    }
    const handleUpload = async file => {
        try {

            if (!user.user_id)
                return
            if (!file.type || !file.type?.includes("image/"))
                return toast.error("not image file")

            setUploading(true)
            const fileName = `${new Date().getTime()}_${file.name}`
            const ref = storage.ref().child(`images/${user.user_id}/tasks/${fileName}`)
            const snap = await ref.put(file)
            const url = await snap.ref.getDownloadURL()
            setFile({
                id: file.uid,
                status: "done",
                url,
                name: fileName
            })
        } catch (e) {

        } finally {
            setUploading(false)
        }
    }
    const fileList = file ? [file] : []
    return (
        <DialogCustom
            open={openAddTask}
            onClose={handleClose}
            title={item?.name !== undefined ? updateIconIntl : addiconIntl}
            fullWidth={true}
            maxWidth={'xl'}
            disablePortal={true}
            actions={<React.Fragment>
                <CancelButton
                    onClick={handleClose}
                />
                <SaveButton
                    onClick={() => {
                        if (onAdd && typeof onAdd === "function" && item?.name === undefined) {
                            onAdd({name, icon})
                            if (tab === 1)
                                return onAdd({name, type: "file", file})
                            onAdd({name, icon, type: "icon"})
                        }
                    }}
                    loading={loading}
                    disable={!(name.length > 0) || (tabMemo === 0 && icon === null) || (tabMemo === 1 && file === null) || loading}
                />
            </React.Fragment>}
        >
            <TabsCustom value={tab}
                        onChange={handleChangeTab}
                        className={classes.tabsBox}
            >
                <Tab label={selectIconIntl} className={classes.tab}/>
                <Tab label={upLoadImageIntl} className={classes.tab}/>
            </TabsCustom>
            <div className={classes.root}>
                {
                    tabMemo === 0 ?
                        <div>
                            <div className={classes.fieldName}>
                                <input type="text"
                                       onChange={e => {
                                           setName(e.target.value)
                                           setIsTitleChanged(true)
                                       }}
                                       value={name}
                                       autoFocus={true}
                                       placeholder={nameIntl}
                                />
                                <div
                                    className={classes.requiredText}>{isTitleChanged && !(name.length > 0) ? titleRequiredIntl : null} </div>
                            </div>
                            <ListIconSearch icon={icon} setIcon={setIcon}/>
                        </div>
                        :
                        <div>
                            <div className={classes.uploadBox}>

                                <Upload
                                    name={"file"}
                                    action={handleUpload}
                                    listType="picture-card"
                                    fileList={fileList}
                                    maxCount={1}
                                    onRemove={file => {
                                        setFile(null)
                                    }}
                                    onChange={info => {
                                        if (info.file.type || !info.file.type?.includes("image/")) {
                                            info.fileList = []
                                        }
                                    }}
                                >
                                    {!file && !uploading ?
                                        <Button icon={<UploadOutlined/>}>{clickToUploadIntl}</Button>
                                        : null}

                                </Upload>

                            </div>
                            <div className={classes.fieldName}>
                                <input type="text"
                                       onChange={e => {
                                           setName(e.target.value)
                                           setIsTitleChanged(true)
                                       }}
                                       value={name}
                                       autoFocus={true}
                                       placeholder={nameIntl}
                                />
                                <div
                                    className={classes.requiredText}>{isTitleChanged && !(name.length > 0) ? titleRequiredIntl : null} </div>
                            </div>
                        </div>
                }

            </div>
        </DialogCustom>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: "10px 20px 20px 20px",
        minHeight: 455,
    },
    tabsBox: {
        margin: "0 20px",
        background: "#f1f1f1"
    },
    tab: {
        // background: "#fff",
        width: "50%"
    },
    fieldName: {
        backgroundColor: "#F1F1F1",
        borderRadius: "50px",
        padding: "5px",
        marginBottom: 10,
        "& input": {
            border: "none",
            outline: "none !important",
            background: "#fff",
            height: "32px",
            width: "100%",
            borderRadius: "50px",
            padding: "0 10px",
            color: "#000",
            fontSize: 14,
            // fontFamily: 'Montserrat'
        }
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`
    },
    uploadBox: {
        width: 150,
        margin: "auto"
    },
    requiredText: {
        fontSize: 12,
        color: "#ff0000",
        paddingLeft: 10,
        paddingTop: 5
    }
}))
export default SelectIconsDialog;
