import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Col, Row, Space, Tooltip} from "antd";
import IconIdentity from "../../Calendar/google/identityIcon";
import {WalletOutlined} from "@ant-design/icons";
import {ReactComponent as RemoveSVG} from "../../../asSvg/removeBGPrimary.svg";
import List from "@material-ui/core/List";

function UnArchiveIdentities({
                                 archives,
                                 classes,
                                 setUnArchive,
                                 loading,
                                 setDeleted
                             }) {
    return (
        <List className={classes.ListItem}>
            <Grid container>
                {archives.map((identity, index) => {
                    return (
                        <Grid item xs={6} key={index} className={classes.divGrid}>
                            <div className={classes.divRowAntd}>
                                <Row gutter={[16, 16]} align={'middle'} key={index}>
                                    <Col span={3}>
                                        <IconIdentity padding={'5px 0px 0px 0px'} color={identity.color}/>
                                    </Col>
                                    <Col span={15} className={classes.colInputArchive}>
                                        <span>{identity.name || "(not set)"}</span>
                                        <span className={"groupIdentiti"}>{
                                            identity.id < 12 ? "Google" : "Geiam"
                                        }</span>
                                    </Col>
                                    <Col span={6}>
                                        <Space size={'small'} className={classes.spaceAntd}>
                                            <Tooltip
                                                title="UnArchive"
                                                placement="bottom"
                                                getPopupContainer={() => document.getElementById('list-setting-identity-unArchive' + index)}
                                                destroyTooltipOnHide={{keepParent: false}}
                                            >
                                                <WalletOutlined
                                                    id={'list-setting-identity-unArchive' + index}
                                                    className={classes.iconAntd2}
                                                    onClick={() => {
                                                        setUnArchive(identity)
                                                    }}
                                                    disabled={loading}/>
                                            </Tooltip>
                                            <Tooltip
                                                title="Delete"
                                                placement="bottom"
                                                getPopupContainer={() => document.getElementById('list-setting-identity-delete' + index)}
                                                destroyTooltipOnHide={{keepParent: false}}
                                            >
                                                <RemoveSVG
                                                    id={'list-setting-identity-delete' + index}
                                                    className={classes.iconAntd}
                                                    onClick={() => {
                                                        setDeleted(identity)
                                                    }}
                                                    disabled={loading}
                                                />
                                            </Tooltip>
                                        </Space>
                                    </Col>
                                </Row>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        </List>
    );
}

export default UnArchiveIdentities;
