import {lazy} from 'react';

export const MenuAppBar = lazy(() => import('./MenuAppBar/MenuAppBar'))
export const NavApp = lazy(() => import('./Navigation/NavApp'))
export const Setting = lazy(() => import('./MenuAppBar/Setting'))
export const AccountPopup = lazy(() => import('./MenuAppBar/AccountPopup'))
export const Meeting = lazy(() => import('./Meeting/Home/Home'))
export const ShareUrl = lazy(() => import('./Meeting/Home/HomeShareUrl'))
export const MeetingList = lazy(() => import('./Meeting/MeetingList/index'))

//setting
export const CalendarEventSetting = lazy(() => import('./MenuAppBar/CalendarEventSetting'))
export const SyncSetting = lazy(() => import('./MenuAppBar/AppointmentGoogleSync'))
export const TextSelectFrom = lazy(() => import('./MenuAppBar/TextSelectFrom'))
export const TextSelectTo = lazy(() => import('./MenuAppBar/TextSelectTo'))
export const ListSetting = lazy(() => import('./MenuAppBar/ListSetting'))
export const ProjectSettings = lazy(() => import('./MenuAppBar/ProjectSettings/index'))
export const IdentitySettings = lazy(() => import('./MenuAppBar/IdentitySettings/index'))
export const ZoomSettings = lazy(() => import('./MenuAppBar/VideoConferencing'))

// project
export const AddProjectPopup = lazy(() => import('./TaskListApp/AddProjectPopup/AddProjectPopup'))

// MeetingUnAuth Meeting

export const MeetingUnAuthentication = lazy(() => import('./Meeting/UnAuthentication/Home'))
// Search

