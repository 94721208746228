import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, TextField} from '@material-ui/core';
import {setGlobal, useGlobal} from 'reactn';
import AddAppDialog from './AddAppDialog';
import {Button} from 'antd';
import RemoveAppDialog from './RemoveAPPDialog';
import {ReactComponent as AddLargeSVG} from '../../../asSvg/icons/addLarge.svg';
import {v4 as uuidv4} from 'uuid';
import {getGlobalUrls,} from '../../../common/firebaseRef/globalnavi';
import moment from 'moment';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import isURL from 'validator/es/lib/isURL';
import useIntlId from '../../../hooks/useIntlId';
import {APP_GLOBAL_ID} from '../../../config/constants';
import {trim} from 'lodash';
import {useAnnouncement} from '../../../context/AnnouncementContext';
//import PaymentList from '../../Cart/PaymentList';
import DragGeniamUrl from '../../GlobalNavi/DragGeniamUrl';

function NavigationSettingTab() {
    const [addURL, save, edit, confirmDelete, ok, cancel, unlockIntl, geniamService, otherUrl]
        = useIntlId(['create.addUrl', 'dialog.action.done', 'chat.edit',
        'setting.confirmDelete', 'create.ok', 'settings.archiveDialogCancel', 'settings.announcement.unlock', 'setting.navi.geniamService', 'calenview.otherUrl'])
    const [user,] = useGlobal('user')
    const [openAdd, setOpenAdd] = useState(false)
    const [deleteApp, setDeleteApp] = useState(null)
    const classes = useStyles()
    const {lock,} = useAnnouncement()
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [isSaveUrl] = useKeyboardJs(['ctrl+enter', 'command+enter'])
    const [openEdit,setOpenEdit] = useState(null)
    const [openDelete,setOpenDelete] = useGlobal('openDelete');
    const [openAddURL, setOpenAddURL ] = React.useState(false);
    //const [openPurcharse, setOpenPurcharse] = useState(false);

    const handleCancelConfirm = () => {
        setOpenDelete(null)
    };

    const handleCloseEdit = () => {
        setOpenEdit(null);
    };
    const handleClickOpen = () => {
        setOpenAddURL(true);
    };
    const handleClose = () => {
        setOpenAddURL(false);
    };
    const onSave = async () => {
        try {
            const id = uuidv4()
            if (!user?.user_id)
                return;
            let data = {
                id: id,
                name: name,
                url: url,
                createAt: moment().utc().format(),
                [APP_GLOBAL_ID]: false,
                checked: false,
                show: false,
            }
            const ref = getGlobalUrls(user.user_id)
            await ref.doc(id).set(data, {merge: true})
        } catch (e) {
            console.log(e)
        } finally {
            setName(null)
            setUrl(null)
            setOpenAddURL(false);
        }
    }

    const onEditURL = async () => {
        try {
            const id = openEdit.id
            if (!user?.user_id || !openEdit?.url)
                return;
            const ref = getGlobalUrls(user?.user_id)
            await ref.doc(id).set({
                name: openEdit.name,
                url: openEdit.url
            }, {merge: true})
        } catch (e) {
            console.log(e)
        } finally {
            setOpenEdit(null)
        }
    }

    const onDeleteURL = async () => {
        try {
            const id = openDelete.id
            if (!user?.user_id || !openDelete?.url)
                return;
            const ref = getGlobalUrls((user?.user_id))
            await ref.doc(id).delete()
        } catch (e) {
            console.log(e)
        } finally {
            setOpenDelete(null)
        }
    }

    useEffect(() => {
        if (isSaveUrl) {
            if (url) {
                onSave()
            }
        }
    }, [isSaveUrl])

    return (
        <div className={classes.root}>
            <AddAppDialog open={openAdd} setOpen={setOpenAdd}/>
            <RemoveAppDialog app={deleteApp} setApp={setDeleteApp}/>
            <div className={classes.moreServices}>
                <div className={classes.elements}>
                    <div className={classes.titleServices}>
                        {geniamService}
                    </div>
                    <div>
                        <div className={'flex justify-end pr-[110px]'}>
                            <img
                                className={classes.showApp}
                                src={"icons/moveIcon.svg"} alt={"showApp"}
                            />
                            <img
                                className={classes.blackIcon}
                                src={"./imgs/linkIcon.svg"} alt={"linkIcon"}/>
                            <img
                                src={"./imgs/iconApp.svg"} alt={"appIcon"}/>
                        </div>
                    </div>
                    <DragGeniamUrl setOpenEdit={setOpenEdit}/>

                    <Dialog open={openEdit} onClose={handleCloseEdit}>
                        <DialogTitle>{edit}</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="name"
                                type="text"
                                fullWidth
                                value={openEdit?.name}
                                variant="standard"
                                onChange={(e) => setOpenEdit({...openEdit, name: e.target.value})}
                            />
                            <TextField
                                margin="dense"
                                label="URL"
                                type="text"
                                fullWidth
                                value={openEdit?.url}
                                variant="standard"
                                onChange={(e) => setOpenEdit({...openEdit, url: e.target.value})}
                            />
                        </DialogContent>
                        <DialogActions>
                            {
                                openEdit?.url === "" || !isURL(trim(`${openEdit?.url}`), {
                                    protocols: ['http', 'https'],
                                    require_protocol: true
                                }) ?
                                    <Button disabled>{edit}</Button>
                                    :
                                    <Button onClick={onEditURL}>{edit}</Button>
                            }
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        className={classes.dialogConfirm}
                        open={Boolean(openDelete)}
                        onClose={handleCancelConfirm}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {confirmDelete}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleCancelConfirm}>{cancel}</Button>
                            <Button onClick={onDeleteURL} autoFocus>{ok}</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {!lock ?
                    <div className={classes.addBtn}>
                        <IconButton
                            variant="outlined"
                            onClick={handleClickOpen}>
                            <AddLargeSVG className={classes.addIconSVGTimeSetting}/>
                        </IconButton>
                    </div>
                    :
                    <IconButton
                        variant="outlined"
                        className={classes.disableAddBtn}>
                        <AddLargeSVG/>
                    </IconButton>
                }

                <div style={{paddingTop: "10px"}}>
                    {
                        lock &&
                        <Button
                            onClick={() => setGlobal({
                                openSetting: true,
                                settingTab: 12
                            })}
                            className={classes.btnUnlock}>
                            <img className={classes.iconButton} src="/icons/keyWhite.svg" alt=""/>
                            {unlockIntl}</Button>
                    }
                </div>


                {/*<PaymentList open={openPurcharse} setOpen={setOpenPurcharse}/>*/}

            </div>
            {
                openAddURL &&
                <Dialog open={openAddURL} onClose={handleClose}>
                    <DialogTitle>{addURL}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            label="URL"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions className={classes.dialogFooter}>
                        {
                            url === "" || !isURL(trim(`${url}`), {
                                protocols: ['http', 'https'],
                                require_protocol: true
                            }) ?
                                <Button className={classes.saveBtn} disabled>{save}</Button>
                                :
                                <Button className={classes.saveBtn} onClick={onSave}>{save}</Button>
                        }
                    </DialogActions>
                </Dialog>
            }
        </div>
    )
        ;
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 15px',
        background: '#fff',
        borderRadius: 15,
        padding: 15
    },
    left: {
        display: "flex",
        alignItems: 'center',

    },
    elements: {
        display: "flex",
        flexDirection: "column",
        rowGap: 5
    },
    saveBtn: {
        border: 0,
        borderRadius: 4,
        background: theme.props.primaryColor,
        textTransform: "uppercase",
        cursor: "pointer",
        color: "#FFF",
    },
    dialogFooter: {
        justifyContent: "center"
    },
    addIconSVGTimeSetting: {
        fill: theme.props.primaryColor,
    },
    disableAddBtn: {
        backgroundColor: "#b3b3b3",
        borderRadius: 50,
        cursor: "not-allowed",
        display: "block",
        margin: "auto",
        padding: "0px !important",
        "&:hover": {
            backgroundColor: "#b3b3b3"
        }
    },
    addBtn: {
        textAlign: "center"
    },
    moreServices: {
        columnGap: 15,
        fontWeight: "bold",
        background: "#FFFFFF",
        borderRadius: 15,
        padding: "10px 0",
        display: "flex",
        flexDirection: "column"
    },
    titleServices: {
        textAlign: "center",
    },
    allApps: {
        fontSize: 25,
        marginLeft: 15
    },
    blackApp: {
        paddingLeft: 18,
        paddingRight: 153
    },
    title: {
        color: '#444444',
        fontSize: 14,
        fontWeight: 700,
        textAlign: "center"
    },
    content: {
        justifyContent: "space-between",
        borderBottom: "1px solid #f1f1f1",
        alignItems: "center"
    },
    iconBtn: {
        fontSize: 30
    },
    leftSpace: {
        flexGrow: 1
    },
    appList: {
        overflow: "hidden",
        transition: 'all 0.5s',
        width: 'auto',
        display: "flex",
        alignItems: 'center',
        justifyContent: "space-around"
    },
    btnUnlock: {
        margin: "auto",
        border: "none",
        display: "flex",
        backgroundColor: `${theme.props.primaryColor} !important`,
        fontWeight: 700,
        color: '#fff',
        textTransform: "uppercase",
        borderRadius: "13px",
        padding: "8px 20px",
        height: "unset",
        "&:hover": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        },
        "&:focus": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        }
    },
    iconButton: {
        marginRight: 10,
    },
    blackIcon: {
        padding: "0 20px"
    }

}))
export default NavigationSettingTab;
