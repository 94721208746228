const convertIdEvtHistory = (evtHis) => {
    if (evtHis?.length === 0) return []
    const evtHisConveted = []
    for (let i = 0; i < evtHis?.length; i++) {
        const e = evtHis[i];
        if (e && !e.id) {
            evtHisConveted.push({...e, id: e.eventId})
        } else {
            evtHisConveted.push(e)
        }
    }
    return evtHisConveted;
}

export default convertIdEvtHistory;
