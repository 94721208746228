import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {ReactComponent as RemoveSVG} from "../../../asSvg/icons/remove.svg";
import useIntlId from "../../../hooks/useIntlId";

function VoteDeleteButton({handleIsDelete}) {
    const classes = useStyles()
    const [deleteIntl] = useIntlId(['dialog.action.delete'])

    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                className={classes.button}
                endIcon={<RemoveSVG/>}
                onClick={handleIsDelete}
            >
                {deleteIntl}
            </Button>
        </div>
    );
}

export default VoteDeleteButton;

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: "25px",
        border: "solid 2px #fff",
        width: "calc(100% + 25px)",
        boxShadow: "none",
        marginLeft: "-25px",
        color: '#fff',
        fontWeight: "bold",
        position: "relative",
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor
        },
        "& .MuiButton-endIcon": {
            position: "absolute",
            right: 7,
            top: "50%",
            transform: "translateY(-50%)"
        },
        "&:hover": {
            boxShadow: "none",
            background: `${theme.props.secondaryColor} !important`,
            color: "#fff",
            opacity: 0.6
        },
    },
    root: {
        marginLeft: "25px",
        marginBottom: 5
    },
}))
