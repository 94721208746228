import {getGlobal} from "reactn";
import {CALENDAR_COLLECTION} from "../config/constants";
import {get} from "lodash";
import {db} from "../firebaseConfig";

export const myCal = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    if (!user_id) {
        // msgError('user not found')
        return;
    }
    return db.collection(CALENDAR_COLLECTION)
        .doc(user_id)
}
