import momentTz from "moment-timezone";
import getUserTimeZone from "./getUserTimeZone";
import moment from "moment";

export const zoneHHmm = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format("HH:mm")
    return momentTz.tz(time, getUserTimeZone()).format("HH:mm")
}

export const zoneDateTime = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format('YYYY-MM-DD HH:mm')
    return momentTz.tz(time, getUserTimeZone()).format("YYYY-MM-DD HH:mm")
}

export const zoneDate = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format("YYYY-MM-DD")
    return momentTz.tz(time, getUserTimeZone()).format("YYYY-MM-DD")
}

export const zoneCustomFormat = (time, format, isLocal = false) => {
    if (isLocal)
        return moment(time).local().format(format)
    return momentTz.tz(time, getUserTimeZone()).format(format)
}

export const zoneCustomDateFormat = (time, language) => {
    let result;
    switch (language) {
        case 'ja-jp' :
            result = zoneObj(time).format("MM 月 DD 日")
            break;
        case 'vi-vn':
            result = zoneObj(time).format("[Ngày] DD [Tháng] MM")
            break;
        case 'en-us':
            result = zoneObj(time).format("MMM Do")
            break;
        default:
            result = zoneObj(time).format("DD/MM/YYYY")
    }
    return result;
}

export const zoneObj = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local()

    return momentTz.tz(time, getUserTimeZone())
}
