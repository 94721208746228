import React from 'react'
import {useGlobal} from 'reactn'
import LoginTab from "./LoginTab";
import CalendarGoogleList from "./CalendarGoogleList";


function CalendarSyncSetting(props) {
    const [googleStatus] = useGlobal('googleStatus')
    // console.log({googleStatus})
    return (
        <div>
            {googleStatus.is_login ?
                <CalendarGoogleList/>
                :
                <LoginTab/>
            }
        </div>
    )
}

export default CalendarSyncSetting
