import moment from "moment";
import {roundDateTimeSTART} from "../../components/Meeting/Fullcalendar/Actions/freeSlot";
import isEnoughTime from "../../common/isEnoughTime";

export const autoFreeTimes = (events = [], timeVote = 0.5) => {
    if (!events?.length) return []
    return events.map((e) => {
        if (moment().isSameOrBefore(e?.start)) return {...e}
        else if (moment().isBetween(e?.start, e?.end)) {
            const currentTime = moment().format()
            const newEvent = {
                ...e,
                start: roundDateTimeSTART(moment(currentTime).minutes(), currentTime),
            }
            if (isEnoughTime(newEvent, timeVote)) {
                return {...newEvent}
            }
        }
        return null
    }).filter(x => x)
}
