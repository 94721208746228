import React, {useState} from 'react';
import {useGlobal} from "reactn";
import Fire, {db} from "../../../firebaseConfig";
import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";
import {
    CALENDAR_COLLECTION,
    MAIL_TEMPLATES_SEND_MAIL_EXPIRED_DOC,
    SUBDOMAIN_APPOINTMENT
} from "../../../config/constants";
import moment from "moment";
import {Dialog, DialogContent} from "@material-ui/core";
import {CKEditor} from "ckeditor4-react";
import DialogActions from "@material-ui/core/DialogActions";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {makeStyles} from "@material-ui/styles";
import useIntlId from "../../../hooks/useIntlId";
import axios from "axios";

function RequestDialog({emailRequest, openForm, setOpenForm, nameRequest, mailTo, usr, scheduleId, setOpen, language}) {
    const classes = useStyles();
    const [user] = useGlobal("user")
    const [messages, setMessages] = useState('')
    const [loading, setLoading] = useState(false)
    const [requestSuccessIntl, cancelIntl, okIntl, anyFailGenerateIntl, requestLinkVoteIntl] = useIntlId([
        'event.toast.requestSuccess',
        'settings.archiveDialogCancel',
        'settings.archiveDialogOk',
        'event.toast.anyFailGenerate',
        'appy.requestLinkVote'
    ])

    const handleSendMail = async () => {
        try {
            setLoading(true)
            if (!user?.user_id) {
                // no user login -> login anonymous
                const data = await Fire.auth().signInAnonymously();
                if (!data.user?.uid)
                    return toast.error(`${anyFailGenerateIntl}`);
            }
            const batch = Fire.firestore().batch()
            const uuidEmail = `email_request_${uuidv4()}`
            const mailDocRef = db.doc(`mails/${uuidEmail}`)
            // const link = `https://${subDomain}.geniam.com/scheduleAdjustment?uid=${scheduleId}&usr=${usr}`
            const link = `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/shareUrl`
            // convert to dynamic url
            const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                    "domainUriPrefix": "https://appygeniam.page.link",
                    "link": link,
                    "androidPackageName": null,
                    "iosBundleId": null
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )

            const languages = language || 'ja-jp';
            const languageSnap = await Fire.firestore().doc(`intl/calendarIntl/langColl/${languages}`).get();
            const languageData = languageSnap.exists ? languageSnap.data() : {};
            const btnAccessRemind = `${languageData['email.message.btnAccessRemind']}`;
            const dontRely = `${languageData['email.message.dontReply']}`;
            const requestContent = `${languageData['email.message.requestContentId']}`;
            const subjectRequest = `${languageData['email.message.subject.request']}`.split("|");

            let subject
            if (language) {
                subject = subjectRequest[0] + emailRequest + subjectRequest[1]
            } else {
                subject = `Your schedule adjustment URL link to ${emailRequest} has expired`
            }

            batch.set(mailDocRef, {
                to: `${mailTo}`,
                template: {
                    name: MAIL_TEMPLATES_SEND_MAIL_EXPIRED_DOC,
                    data: {
                        subject: subject || '',
                        subDomain: SUBDOMAIN_APPOINTMENT,
                        content: requestContent || "",
                        messages: messages || '',
                        link: shortLink || '',
                        btnAccessRemind: btnAccessRemind,
                        dontRely: dontRely
                    }
                },
            })
            const notiUid = `request_${uuidv4()}`
            const notificationRef = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${usr}/notifications/${notiUid}`)
            const notificationData = {
                id: notiUid,
                avatar: "https://calendar.geniam.com/favicon.ico",
                content: requestLinkVoteIntl.toString().replace('〜',emailRequest),
                scheduleAjustment: true,
                // messageId: "schedule.adjustment.receive",
                nickname: emailRequest,
                createdAt: moment().format()
            }
            batch.set(notificationRef, notificationData)
            await batch.commit()
            setLoading(false)
        } catch (e) {
            console.log(e);
            toast.error(e.toString)
        } finally {
            setOpenForm(false)
            setOpen(false)
            toast.success(`${requestSuccessIntl}`)
        }
    }

    return (
        <Dialog open={openForm}>
            <DialogContent>
                <CKEditor
                    data={messages}
                    onChange={e => setMessages(e.editor.getData())}
                    onReady={editor => {
                        if (editor)
                            editor.editing.view.focus();
                    }}
                />
            </DialogContent>
            <DialogActions>
                <CLFButtonSVG
                    name={cancelIntl}
                    size={'default'}
                    onClick={() => setOpenForm(false)}
                    className={classes.secondary}
                />
                <CLFButtonSVG
                    name={okIntl}
                    size={'default'}
                    onClick={handleSendMail}
                    className={classes.primary}
                    loading={loading}
                    disable={loading}
                />
            </DialogActions>

        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
    },
}))


export default RequestDialog;
