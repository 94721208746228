import React, {useEffect, useRef, useState} from 'react';
import {setGlobal, useGlobal} from "reactn";
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import momentPlugin from '@fullcalendar/moment';
import moment from "moment";
import {headerRender} from "./common";
import SelectEvent from "./selectEvent";
import {concat} from 'lodash'
import RequestChangeTime from "./RequestChangeTime";
import {eventRender} from "../EventsVote/EventsRender";
import {Loader, LoadingOverlay} from "react-overlay-loader";
import convertIdEvtHistory from "../common/convertIdEvtHistory";
import removeHistorySync from "../common/removeHistorySync";
import getEventsInRange from "../../../context/common/getEventsInRange";
import checkHeight from "../common/checkHeight";
import getDataHover from "../common/getDataHover";
import {useStyles} from "./useStyles";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {useCalendarEvents} from "../../../context/CalendarEventsContext";
import {useAdjustmentEvents} from "../../../context/AdjustmentEventsContext";
import useIntlId from "../../../hooks/useIntlId";
import {zoneObj} from "../../../common/moment/format";
import gtm from "../../../common/moment/gtm"
import getUserTimeZone from '../../../common/moment/getUserTimeZone';

function ShareFreeTimeUrlCal({
                                 infoShare,
                                 freeTimeEvent,
                                 modify,
                                 eventConfirm,
                                 setEventConfirm,
                                 fullHeight,
                                 isPreview = false,
                                 loading = false,
                                 expired = null,
                                 setShowExpired,
                                 settingUser,
                                 selectedProjectId,
                                 selectedColorId
                             }) {
    const [api, setApi] = useState(null)
    const [user] = useGlobal("user")
    const [myFreeTime] = useGlobal("myFreeTime")
    const [openChangeTime, setOpenChangeTime] = useState(false)
    const ref = useRef()
    const classes = useStyles();
    const {voteUrl, geEvents, ggEvents, voteOthers, loadingData} = useCalendarEvents();
    const {eventConfirmed} = useAdjustmentEvents();
    const [dialogTitleIntl, pleaseContactOwnerURLIntl, pleaseContactURLIntl, pleaseReSettingURLIntl, urlNotExistIntl] = useIntlId([
        'event.dialog.request.dialogTitle',
        'event.dialog.request.pleaseContactOwnerURL',
        'event.dialog.request.pleaseContactURL',
        'event.dialog.request.pleaseReSettingURL',
        'event.dialog.request.urlNotExist'
    ])

    useEffect(() => {
        if (ref.current) {
            const apiRef = ref.current.getApi()
            setApi(apiRef)
        }
        // eslint-disable-next-line
    }, [ref])

    useEffect(() => {
        if (api && infoShare?.id) {
            const userTz = getUserTimeZone()
            const start = moment(infoShare.start).tz(userTz, true).format()
            const end = moment(infoShare.end).tz(userTz, true).format()

            api.changeView("timeGrid", {start, end})
            const geniamEvents = isPreview ? [] : getEventsInRange(geEvents, start, end)
            const googleEvents = isPreview ? [] : getEventsInRange(ggEvents, start, end)
            const eventNotSchedules = removeHistorySync(concat(geniamEvents, googleEvents), concat(voteUrl, voteOthers), infoShare.id)

            let nextMyEvents = concat(eventNotSchedules, convertIdEvtHistory(voteOthers)).map(e => {
                if (!e) return null
                else if (e.scheduleId === infoShare.id) {
                    return {
                        ...e,
                        backgroundColor: "#f89321",
                        borderColor: "transparent",
                        editable: true
                    }
                }
                return {
                    ...e,
                    backgroundColor: "#808080",
                    borderColor: "transparent",
                    editable: false
                }
            }).filter(x => x)

            const isBoxView = myFreeTime && myFreeTime?.viewEvent?.length && myFreeTime?.viewEvent[0]?.checked
            const isViewTitle = myFreeTime && myFreeTime?.viewEvent?.length && myFreeTime?.viewEvent[1]?.checked

            nextMyEvents = isBoxView ? nextMyEvents : [];

            if (!isViewTitle) {
                nextMyEvents = nextMyEvents.length ? nextMyEvents.map(e => ({...e, title: ''})) : []
            }
            const nextEvents = concat(nextMyEvents, freeTimeEvent, voteUrl)
            if (eventConfirm && (!nextMyEvents || !nextMyEvents?.length)) nextEvents.push({...eventConfirm})
            //if (eventConfirmed) nextEvents.push({...eventConfirmed})
            api.setOption("slotMinTime", `${settingUser?.workTime?.start}:00`)
            api.setOption("slotMaxTime", `${settingUser?.workTime?.end}:00`)
            api.removeAllEvents()
            api.addEventSource(nextEvents)
            checkHeight(api, "shareTime", fullHeight, false)
        }
        // eslint-disable-next-line
    }, [infoShare, api, ggEvents, geEvents, freeTimeEvent, isPreview, voteUrl, voteOthers, myFreeTime, eventConfirm])

    const isValidTime = (event) => {
        let result = false
        freeTimeEvent.forEach(evt => {
            if (zoneObj(event.start).isBetween(evt.start, evt.end, null, "[]") &&
                zoneObj(event.end).isBetween(evt.start, evt.end, null, "[]")
            ) {
                result = true
            }
        })
        return result
    }

    useEffect(() => {
        checkHeight(api, "shareTime", fullHeight, false)
        // eslint-disable-next-line
    }, [api, fullHeight])

    return (
        <div id={'shareTime'}
             className={`${fullHeight ? classes?.meetingScreenFull : classes?.meetingScreenFullMeeting1} shareTime`}>
            {
                Boolean(expired) &&
                <div className={classes.divExpired}>
                    <div className={classes.divExpiredRoot}>
                        <div className={classes.absoluteExpired}>
                            {
                                !expired?.isExpired ? `${urlNotExistIntl}`
                                    :
                                    (
                                        expired?.isMaxTimes ?
                                            expired?.usr === user?.user_id ?
                                                `${pleaseReSettingURLIntl}`
                                                :
                                                `${pleaseContactURLIntl}`
                                            :
                                            `${pleaseContactOwnerURLIntl}`
                                    )
                            }
                        </div>
                        <div className={classes.absoluteExpiredButton}>
                            <CLFButtonSVG
                                name={dialogTitleIntl}
                                minWidth={'250px'}
                                margin={'auto'}
                                onClick={() => setShowExpired(true)}
                            />
                        </div>
                    </div>
                </div>
            }
            <LoadingOverlay>
                <Loader loading={loading || loadingData} text={""}/>
                <FullCalendar
                    ref={ref}
                    plugins={[timeGridPlugin, interactionPlugin, momentPlugin, momentTimezonePlugin]}
                    height={"100%"}
                    contentHeight={'auto'}
                    locale={'is-is'}
                    timeZone={getUserTimeZone()}
                    slotDuration={'01:00:00'}
                    snapDuration={'00:30:00'}
                    titleFormat={{
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                    }}
                    slotLabelFormat={
                        "HH:mm"
                    }
                    eventTimeFormat={"HH:mm"}
                    weekNumbers={false}
                    views={{
                        CustomMonth: {
                            type: 'timeGrid',
                            duration: {month: 1},
                        },
                        CustomWeek: {
                            type: 'timeGrid',
                            duration: {week: 2},
                        },
                        CustomDay: {
                            type: 'timeGrid',
                            duration: {day: 14},
                        },
                    }}
                    eventMinHeight={1}
                    slotEventOverlap={false}
                    initialView={'CustomDay'}
                    headerToolbar={false}
                    dayHeaders={true}
                    progressiveEventRendering={true}
                    nowIndicator={true}
                    selectMirror={true}
                    displayEventTime={true}
                    allDayText={gtm(getUserTimeZone())}
                    allDaySlot={true}
                    eventTextColor={'white'}
                    eventResizableFromStart={false}
                    firstDay={1}
                    dayHeaderContent={(args) => {
                        return {html: headerRender(args, classes)}
                    }}
                    dayHeaderClassNames={['headerV5']}
                    eventContent={(info) => eventRender(info, classes, false, null, false)}
                    editable={modify}
                    selectable={false}
                    droppable={modify}
                    eventResize={(info) => {
                        info.revert()
                    }}
                    eventDrop={info => {
                        if (isValidTime(info.event)) {
                            setOpenChangeTime(info)
                            return
                        }
                        info.revert()
                    }}
                    eventMouseEnter={info => {
                        if (modify || user?.user_id === infoShare?.usr)
                            return;
                        if (info?.event?.extendedProps?.freeSlot) {
                            const {
                                timeStart,
                                timeEnd,
                                top,
                                left,
                                width,
                                height,
                                heightDuration,
                                timeDuration
                            } = getDataHover(info, infoShare)

                            if (moment(timeStart).isBefore(info.event.start) || expired)
                                return;
                            setGlobal({
                                hoverPos: {
                                    top,
                                    left,
                                    width,
                                    height,
                                    layerY: info.jsEvent.layerY,
                                    heightDuration,
                                    timeDuration,
                                    timeStart,
                                    timeEnd,
                                    event: {
                                        start: info.event.start,
                                        end: info.event.end,
                                        id: infoShare.id,
                                        user: infoShare.usr,
                                        email: infoShare.email,
                                        name: infoShare.name
                                    },
                                }
                            })
                        } else {
                            setGlobal({
                                hoverPos: null
                            })
                        }
                    }}
                />
            </LoadingOverlay>
            <SelectEvent api={api} info={infoShare} setEventConfirm={setEventConfirm} selectedProjectId={selectedProjectId} selectedColorId={selectedColorId}/>
            <RequestChangeTime open={openChangeTime} setOpen={setOpenChangeTime} eventConfirm={eventConfirmed}
                               info={infoShare}/>
        </div>
    );
}

export default ShareFreeTimeUrlCal;
