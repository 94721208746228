import React, {useState} from 'react';
import {Button, TextField} from '@material-ui/core'
import useIntlId from "../../hooks/useIntlId";

function Coupon(props) {
    const [coupon, setCoupon] = useState('')
    const [voucher, label] = useIntlId(['settings.activeVoucher', 'settings.couponVoucher'])
    return (
        <div>
            <h3>
                {label}
            </h3>
            <TextField fullWidth
                       variant={"outlined"}
                       value={coupon}
                       onChange={e => setCoupon(e.target.value)}
                       style={{marginBottom: 30}}
            />
            <Button
                disabled={!coupon}
                variant={"contained"}
                color={'primary'}
            >
                {voucher}
            </Button>

        </div>
    );
}

export default Coupon;
