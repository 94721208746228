import React, {createContext, useContext, useEffect, useState} from 'react'
import {useGlobal} from 'reactn'
import moment from "moment";
import {getMessagesCollRef} from "../common/firebaseRef/meetings";
import {useAppointmentEvent} from "./AppointmentEventContext";

export const MessageContext = createContext([])


export const MessageContextProvider = ({children}) => {
    const messages = useChatMessages()
    return (
        <MessageContext.Provider value={messages}>
            {children}
        </MessageContext.Provider>
    )
}

export const useMessages = () => {
    return useContext(MessageContext);
}

const useChatMessages = () => {
    const [messages, setMessages] = useState([])
    const [user] = useGlobal("user")
    const {meeting} = useAppointmentEvent()

    useEffect(() => {
        let sub
        if (!meeting?.id || !user?.user_id)
            return
        sub = getMessagesCollRef(meeting.id)
            .orderBy("date", "asc")
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                    position: doc.data().userId !== user.user_id ? "left" : "right",
                    dateString: moment(doc.data().dateString).local().fromNow(),
                    replyButton: !Boolean(doc.data().reply)
                }))
                setMessages(data)
            })

        return () => {
            if (sub) {
                sub()
            }
        }
        // eslint-disable-next-line
    }, [meeting?.id, user?.user_id])

    return [messages, setMessages]
}
