import {db} from "../../firebaseConfig";
import {APP_GLOBAL_ID, CALENDAR_COLLECTION, USER_COLLECTION} from '../../config/constants';

export const getNaviCollRef = (userId) => {
    return db.collection(USER_COLLECTION)
        .doc(userId)
        .collection('navigations')
        .doc(APP_GLOBAL_ID)
}

export const getURLCollRef = (userId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('addUrls')
}

export const getGlobalUrls = (userId) => {
    return db.collection(USER_COLLECTION)
        .doc(userId)
        .collection('navigations')
}
