import {isArray, isString} from 'lodash';
import {LanguageMessageContext} from '../context/LanguageMessageContext';
import {useContext} from 'react';
import store from 'store';

/**
 * @param {string|array} id
 * @param values
 */

const useIntlId = (id, values) => {

    const message = useContext(LanguageMessageContext)
    if (!message)
        return id

    return isArray(id) ?
        id.map(i => message[i])
        : message[id]
}

export default useIntlId

const getIntlId = (id) => {
    if (!id) return;
    const language = store.get('userInfo')?.['language']
    const langMsg = store.get('langMsg')?.[language]
    if (!langMsg) return;

    const getOneIntl = (id) => {
        return langMsg[id]
    }
    if (isString(id))
        return getOneIntl(id)
    if (isArray(id))
        return id.map(i => getOneIntl(i));
}

export {
    getIntlId
}
