import {makeStyles} from "@material-ui/core";
import {useAnnouncement} from "../../context/AnnouncementContext";
import {useGlobal} from "reactn";
import useIntlId from "../../hooks/useIntlId";
import {db} from "../../firebaseConfig";
import {barRef} from "../../common/firebaseRef";
import AnnouncementCheck from "../Custom/AnnouncementCheck";

function AnnouncementBar() {
    const classes = useStyles()
    const [user] = useGlobal('user')
    const {bar} = useAnnouncement()

    const [
        announcementBarDisplayIntl,
        RotationIntl,
        newsIntl,
    ] = useIntlId([
        'settings.announcement.display',
        'settings.announcement.rotation',
        'settings.announcement.new',
    ])

    const updateAnnouncementBar = fieldName => async (value) => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                [fieldName]: value
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={classes.root}>
            <AnnouncementCheck check={bar?.announcementBar === undefined ? true : bar?.announcementBar}
                               setCheck={updateAnnouncementBar("announcementBar")}
                               name={announcementBarDisplayIntl}/>
            <AnnouncementCheck name={RotationIntl} value={bar?.rotationInterval || 1} label={"s"}
                               setValue={updateAnnouncementBar("rotationInterval")}/>
            <AnnouncementCheck check={bar?.geniamNews}
                               setCheck={updateAnnouncementBar("geniamNews")} name={newsIntl} borderBottom={'none'}/>
        </div>
    )
}

export default AnnouncementBar

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        position: 'absolute',
        width: '68%',
        top: '50px',
        background: '#fff',
        borderRadius: 15,
        margin: '0 15px',
        padding: 20,
    },
    iconButton: {
        marginRight: 10,
    }
}))
