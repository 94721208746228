import {concat, findIndex} from 'lodash'

export const swapProjectByOrder = (sources = [], orders = []) => {
    if (!orders.length)
        return [...sources]
    let projects = [...sources]
    let nextProjects = []
    orders.forEach(id => {
        let idx = findIndex(projects, {id})
        if (idx !== -1) {
            nextProjects.push(projects[idx])
            projects.splice(idx, 1)
        }
    })
    return concat(nextProjects, projects)

}
