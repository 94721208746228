import {COOKIE_GENIAM_REFRESH_TOKEN_KEY, getCookie} from "../common/cookies";
import {getGlobal} from 'reactn';

const localToken = () => {
    const {accessToken} = getGlobal();
    // const geniam_accessToken = localStorage.getItem('accessToken')
    const geniam_refreshToken = getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
    return {geniam_accessToken: accessToken, geniam_refreshToken, token: accessToken, refreshToken: geniam_refreshToken}
}

export default localToken;
